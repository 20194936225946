<template>
    <div class="main_content">
        <div class="darkBg">
            <div class="intro" style="padding-top: 117px;padding-bottom:87px">
                <div>
                    <span class="introText">系统优势</span>
                </div>
                <div>
                    <span class="introDes">SYSTEM ADVANTAGES</span>
                </div>
            </div>
            <!-- 产品优势 -->
            <div class="mianCenter">
                <ul class="productAdvantages">
                    <li v-for="(item, index) in productAdvantagesList" :key="index">
                        <img :src="item.img" alt="">
                        <div class="title">{{ item.name }}</div>
                        <div class="tag">{{ item.tag }}</div>
                        <div class="des">{{ item.des }}</div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="featureBg">

            <div class="intro" style="padding-top: 88px;padding-bottom:115px">
                <div>
                    <span class="introText">功能特性</span>
                </div>
                <div>
                    <span class="introDes">FUNCTION CHARACTERISTICS</span>
                </div>
            </div>
            <div class="mianCenter ">
                <div class="featureList">
                    <div class="featureLeft">
                        <div v-for="(item, index) in featuresList " :key="index"
                            :class="item.choosed ? 'selectFeature featureItem' : 'featureItem'"
                            @mouseenter="chooseFeature(item, index)">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="featureRight">
                        <div class="featuresItem" v-show="activeFeature == index"
                            v-for="( item, index ) in featuresList " :key="index">
                            <!-- <div class="featuresIntro">{{ item.name }}</div> -->
                            <div class="textContent" v-if="index == 0 || index == 6">
                                <div class="text">
                                    <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                        <div class="title">{{ it.title }}</div>
                                        <div class="des">{{ it.textContent }}</div>
                                        <div class="img" v-if="it.img"><img :src="it.img" alt=""></div>
                                    </div>
                                </div>
                                <div class="itemImg">
                                    <ul>
                                        <li v-for="( it, i ) in item.imgList " :key="i"
                                            :style="{ 'width': (96 / item.imgList.length) + '%' }">
                                            <div>
                                                <img :src="it.img" alt="">
                                            </div>
                                            <div class="smallDes">{{ it.des }}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="textContent" v-if="index == 1 || index == 5">
                                <div class="text">
                                    <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                        <div class="title">{{ it.title }}</div>
                                        <div class="des">{{ it.textContent }}</div>
                                        <div class="img" v-if="it.img"><img :src="it.img" alt=""></div>
                                    </div>
                                </div>
                                <div class="itemImg">
                                    <ul>
                                        <li v-for="( it, i ) in item.imgList " :key="i">
                                            <div :class="i == 1 ? 'img2' : 'imgs'">
                                                <img :src="it.img" alt="">
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="imgDes">
                                        {{ item.imgDes }}
                                    </div>
                                </div>
                            </div>
                            <div class="textContent1" v-if="index == 2">
                                <div class="text">
                                    <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                        <div class="des">{{ it.textContent }}</div>
                                    </div>
                                    <div class="textTotal">
                                        <div class="desItem" v-for="(item, index) in item.desItemList" :key="index">
                                            <div class="desTitle">{{ item.title }}</div>
                                            <div class="desDes">{{ item.des }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="itemImg">
                                    <ul>
                                        <li v-for="( it, i ) in item.imgList " :key="i">
                                            <div>
                                                <img :src="it.img" alt="">
                                                <div class="imgDes">{{ it.des }}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="textContent" v-if="index == 3 || index == 4">
                                <div class="text">
                                    <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                        <div class="des">{{ it.textContent }}</div>
                                    </div>
                                </div>
                                <div class="itemImg">
                                    <ul>
                                        <li v-for="( it, i ) in item.imgList " :key="i">
                                            <div>
                                                <img :src="it.img" alt="">
                                            </div>
                                            <div class="imgDes">{{ it.des }}</div>
                                        </li>
                                    </ul>
                                    <div class="imgDes">
                                        {{ item.imgDes }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="darkBg1">
            <div class="intro" style="padding-top: 117px;padding-bottom:87px">
                <div>
                    <span class="introText">典型应用</span>
                </div>
                <div>
                    <span class="introDes">TYPICAL APPLICATIONS</span>
                </div>
            </div>
            <!-- 产品优势 -->
            <div class="typicalAppContent">
                <div class="topicList" v-for="(item, index) in topicList" :key="index">
                    <div class="topicContent">
                        <img :src="item.img" alt="">
                        <div class="topicTitle">{{ item.title }}</div>
                        <div class="topicC">{{ item.content }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeFeature: 0,
            productAdvantagesList: [
                {
                    name: "新内核",
                    tag: '为新型基础测绘而生',
                    des: "为新型基础测绘构建全新数据内核，为点线面体等二三维数据以及空天地多源数据的一体化处理提供强大支撑。",
                    img: require("../../../assets/image/productAdvantages_img1.png")
                },
                {
                    name: "高精度",
                    tag: '算法严密 工具完备',
                    des: "将源于倾斜测图的按边采集模式应用到单体采集过程中，辅以完备的单体采集功能与丰富的质检工具，助力实现高精度的单体采集。",
                    img: require("../../../assets/image/productAdvantages_img2.png")
                },
                {
                    name: "易操作",
                    tag: '博采众长 自成体系',
                    des: "开发过程博采众长，研发实现了一系列人性化且智能易用的单体模型构建与编辑功能，配合面面俱到的纹理解决方案，形成了一套简捷易用、作业高效的单体采集体系。",
                    img: require("../../../assets/image/productAdvantages_img3.png")
                },
                {
                    name: "双平台加持",
                    tag: '行业应用 游刃有余',
                    des: "具备双平台加持，既有Summit SDK二次开发平台提供上千个开发接口扩充现有功能、满足个性化应用需要，亦可联动SummitLab教学培训平台对系统的各项功能和丰富单体化建模技巧进行沉浸式培训。",
                    img: require("../../../assets/image/productAdvantages_img4.png")
                }
            ],
            featuresList: [
                {
                    name: "空天地多源一体的单体采集模式",
                    desList: [
                        {
                            title: '基于点云数据采集单体',
                            textContent: "加载海量点云数据并根据需求进行多样化渲染，基于点云数据快速采集单体。",
                            img: require("../../../assets/image/ProjectSummitSoild_img1.png"),
                        },
                        {
                            title: '基于点云+全景数据采集单体',
                            textContent: "点云与全景数据联动配合，非常适合基于移动测量数据的建筑底商、高速公路等单体采集。"
                        },
                        {
                            title: '基于点云+倾斜三维模型数据采集单体',
                            textContent: "点云与倾斜三维模型数据融合作业，适用于建筑底商、立交桥等需要空地一体数据源结合进行单体采集的场合。",
                            img: require("../../../assets/image/ProjectSummitSoild_img2.png"),
                        },
                        {
                            title: "基于倾斜三维模型数据采集单体",
                            textContent: "利用源于SummitMap成图平台的核心采集能力，平均作业效率相比同类软件高约10-20%。",
                            img: require("../../../assets/image/ProjectSummitSoild_img3.png"),
                        },
                        {
                            title: "基于立体模型数据采集单体",
                            textContent: "兼容多种常见的立体数据源，支持多种立体显示模式，可准确采集柱体、人字顶、圆柱、圆环等典型单体。"
                        }
                    ],
                    choosed: true,
                },
                {
                    name: "高精度的单体采集体系",
                    desList: [
                        {
                            textContent: "将源于倾斜三维测图的高精度采集模式应用到单体采集过程中，为用户打造高精度的单体采集体系。通过自动吸附三维模型提取坐标实现单体模型表面与三维模型的完美贴合，按边采集、面面相交整体求解保障单体构建精度，辅以覆盖点线面体与纹理检查的丰富质检工具高效捕捉单体模型细微缺陷。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img4.png"),
                            des: ""
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img5.png"),
                            des: ""
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img6.png"),
                        }
                    ],
                    imgDes: "系统采集构建的高精度复杂单体",
                    flag: true,
                    choosed: false,
                },
                {
                    name: "简明易用的单体采集功能",
                    desList: [
                        {
                            textContent: "博采众长，融合创新，为单体模型采集编辑研发了一系列人性化、智能化功能。并做到一个命令，场景不同，配置各异，满足个性化应用需求；不同命令，配置相似，方便用户学习掌控。"
                        }
                    ],
                    desItemList: [
                        {
                            title: '三维捕捉',
                            des: "自动化捕捉顶点、中点、最近点、垂足、中心点、切点、圆弧中心等。"
                        },
                        {
                            title: '局部坐标系',
                            des: "支持单点，面片，面片+线，三点等多种坐标系模式。"
                        },
                        {
                            title: '复杂面构建',
                            des: "通过简单拉伸便可构建女儿墙等复杂面，而无需对多个面依次进行处理。"
                        },
                        {
                            title: '特色单体编辑功能',
                            des: "倒角、挤出、面切割、面收缩、压平顶点、合并面片等。"
                        },
                        {
                            title: '复杂屋顶制作',
                            des: "多种线型绘制屋脊线，一键生成复杂屋顶。"
                        },
                        {
                            title: '部件半自动拷贝',
                            des: "提取复制建筑相似的结构部件并自动配准到目标位置，大幅简化重复部件的单体化建模工作。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img8.png"),
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img7.png"),
                            des: "复杂屋顶一键提取"
                        },
                    ],
                    choosed: false,
                },
                {
                    name: "多样化的纹理选择",
                    desList: [
                        {
                            textContent: "为单体模型提供多样化的纹理来源，包括三维模型纹理、原始影像投影纹理、通用纹理等，并可自动选择最佳纹理填充模型。内置丰富的通用纹理材质库，并根据建筑类型、部件类型、纹理色系等要素对纹理进行了三级索引分类，便于作业员按类别筛选所需纹理，创建个性化纹理方案，一键赋予批量建筑白模通用纹理。同时支持用户根据个性化需求调整模型纹理亮度、饱和度，调整过程中，纹理方案效果可实时预览。",
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img9.png"),
                            des: "自动选择最佳纹理"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img10.png"),
                            des: "批量赋予LOD1.3模型通用纹理"
                        }
                    ],
                    choosed: false,
                },
                {
                    name: "丰富全面的质检工具",
                    desList: [
                        {
                            textContent: "具备覆盖点线面体与纹理检查的丰富质检工具，能够对单体模型全部结构与纹理问题进行精准查验，高效捕捉单体模型包含的细微缺陷。",
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img11.png"),
                            // des: "自动选择最佳纹理"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img12.png"),
                            // des: "批量赋予LOD1.3模型通用纹理"
                        }
                    ],
                    choosed: false,
                },
                {
                    name: "三维模型精细化修编",
                    desList: [
                        {
                            textContent: "集成三维模型压平、剪切、删除漂浮物、补空洞、单体替换模型、纹理编辑等一系列三维模型修编功能，充分满足用户对三维模型精细化编辑应用的拓展需求。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img13.png"),
                            des: "模型纹理智能修复"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img5.png"),
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img14.png"),
                        },
                    ],
                    imgDes: "模型纹理智能修复",
                    choosed: false,
                },
                {
                    name: "智能提取多级建筑单体",
                    desList: [
                        {
                            title: '全自动提取LOD1.3建筑单体',
                            textContent: "自动提取卫片、点云、倾斜三维模型屋顶范围，生成建筑轮廓线，并在此基础上创建单体模型。",
                        },
                        {
                            title: '半自动提取LOD1.2/LOD2.0/LOD2.2建筑单体',
                            textContent: "只需人工点选确定墙面及屋檐位置，随后即可自动提取建筑轮廓线，并贴合三维模型构建单体模型。",
                        },
                        {
                            title: '通过矢量全自动生成LOD1.2/LOD1.3/LOD2.3建筑单体',
                            textContent: "基于已有矢量数据，自动提取建筑轮廓、地面高程和建筑顶部高程等结构信息，并在此基础上贴合三维模型构建单体模型。",
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img15.png"),
                            des: "基于卫星影像模型提取LOD1.2级建筑单体"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img16.png"),
                            des: "基于点云提取LOD1.2建筑单体"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img17.png"),
                            des: "基于矢量提取LOD1.3建筑单体"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img18.png"),
                            des: "基于倾斜三维模型提取LOD2.2建筑单体"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitSoild_img19.png"),
                            des: "基于矢量提取LOD2.3建筑单体"
                        },
                    ],
                    choosed: false,
                }
            ],
            topicList: [
                {
                    title: '数字公共基础设施建设',
                    content: '凭借强大的单体模型提取能力，为数字公共基础设施建设项目高效构建海量CIM2级建筑单体白模，襄助湖北多地“数公基”项目顺利实施。',
                    img: require("../../../assets/image/solidApplication1.png")
                },
                {
                    title: '三维不动产',
                    content: '基于建筑模型与矢量文件自动创建建筑地上地下、分层分户单体模型，满足不动产业务多维度、单体化、图属关联的管理应用需求。',
                    img: require("../../../assets/image/solidApplication2.png")
                },
            ]
        };
    },
    methods: {
        chooseFeature(data, index) {
            this.activeFeature = index;
            this.featuresList.forEach(e => {
                e.choosed = false;
            });
            data.choosed = true;
        }
    }

};
</script>

<style scoped lang="scss" scoped>
.main_content {
    .intro {
        text-align: center;
        display: flex;
        flex-direction: column;

        .introDes {
            font-size: 30px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 43.44px;
            color: #CCCCCC;
            text-align: center;
            vertical-align: top;
        }

        .introText {
            font-size: 40px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 57.92px;
            color: #fff;
            text-align: center;
            vertical-align: top;
        }
    }

    .productAdvantages {
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 132px;

        li {
            flex: 1;
            text-align: center;
            border-right: 1px solid rgba(128, 128, 128, 1);
            padding: 0 38px;

            img {
                width: 111px;
            }

            .title {
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 40px;
                color: #383838;
                text-align: center;
                vertical-align: top;
                margin: 10px 0;

            }

            .tag {
                border-radius: 30px;
                background: rgba(42, 130, 228, 1);
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 40px;
                color: #FFFFFF;
                vertical-align: top;

            }

            .des {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 32px;
                color: #4F4F4F;
                text-align: justify;
                vertical-align: top;
                padding-top: 10px;

            }


        }

        li:last-child {
            border-right: 0px;
        }

        // li {
        //     // width: 44%;
        //     height: 310px;
        //     background-color: rgb(243, 243, 243);
        //     margin-bottom: 68px;
        //     font-size: 16px;
        //     font-weight: 400;
        //     letter-spacing: 0px;
        //     line-height: 23.17px;
        //     color: rgba(102, 102, 102, 1);
        //     text-align: left;
        //     vertical-align: top;
        //     padding: 80px 52px 0;
        //     box-sizing: border-box;
        //     position: relative;

        //     img {
        //         width: 100px;
        //         height: 100px;
        //         position: absolute;
        //         top: -50px;
        //         left: 0;
        //         right: 0;
        //         margin: 0 auto;
        //     }

        //     div {
        //         display: flex;
        //         justify-content: center;
        //     }

        //     .title {
        //         font-size: 26px;
        //         line-height: 37.65px;
        //         color: rgba(56, 56, 56, 1);
        //         margin-bottom: 24px;
        //     }
        // }
    }

    .featureBg {
        // background-image: url(../../../assets/image/solidFeature.png);
        background-size: 100% 100%;
        background-color: #152235;

    }

    .functionCharacteristics {
        display: flex;
        justify-content: center;
        margin-bottom: 121px;

        // .item_function {
        //     width: 1000px;
        //     display: flex;
        //     justify-content: center;
        //     position: relative;
        // }

        // img {
        //     width: 735px;
        //     height: 735px;
        // }

        .cover_div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .cover {
            width: 100%;
            height: 25%;
            // background-color: rgba(201, 236, 120, 0.3);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .cover1 {
            width: 85%;
        }

        .cover2 {
            width: 95%;
            top: 25%;
            // background-color: rgba(145, 211, 231, 0.3);
        }

        .cover3 {
            width: 95%;
            top: 50%;
            // background-color: rgba(235, 147, 213, 0.3);
        }

        .cover4 {
            width: 85%;
            top: 75%;
            // background-color: rgba(236, 182, 145, 0.3);
        }
    }

    // .featuresContent {
    //     font-size: 16px;
    //     font-weight: 400;
    //     line-height: 23.17px;
    //     color: rgba(56, 56, 56, 1);
    //     text-align: justify;
    //     vertical-align: top;

    //     .featuresItem {
    //         margin-bottom: 64px;

    //         .featuresIntro {
    //             display: flex;
    //             align-items: center;
    //             font-size: 26px;
    //             line-height: 37.65px;
    //             color: rgba(0, 94, 255, 1);
    //             text-align: left;
    //             margin-bottom: 48px;

    //             span {
    //                 display: block;
    //                 width: 7px;
    //                 height: 30px;
    //                 opacity: 1;
    //                 background: rgba(97, 171, 255, 1);
    //                 margin-right: 20px;
    //             }
    //         }

    //         .itemText {
    //             display: flex;
    //             align-items: center;

    //             .dot {
    //                 width: 12px;
    //                 height: 12px;
    //                 opacity: 1;
    //                 background: rgba(56, 56, 56, 1);
    //                 border-radius: 50%;
    //                 margin-right: 12px;
    //             }
    //         }

    //         .decomposeIntro {
    //             padding-left: 37px;

    //             ul {
    //                 margin-top: 50px;
    //                 display: flex;
    //                 justify-content: space-between;
    //                 flex-wrap: wrap;

    //                 li {
    //                     width: 16%;
    //                     font-size: 14px;
    //                     font-weight: 400;
    //                     letter-spacing: 0px;
    //                     line-height: 20.27px;
    //                     color: rgba(166, 166, 166, 1);
    //                     text-align: left;
    //                     vertical-align: top;

    //                     div {
    //                         display: flex;
    //                         justify-content: center;
    //                     }

    //                     img {
    //                         width: 75px;
    //                         margin-bottom: 12px;
    //                     }
    //                 }
    //             }
    //         }

    //         .textContent {
    //             display: flex;
    //             justify-content: space-between;
    //             // align-items: center;

    //             .text {
    //                 width: 44%;
    //                 // background-color: pink;

    //                 .item {
    //                     display: flex;
    //                     margin-bottom: 35px;
    //                 }

    //                 .div_test {
    //                     width: 24px;
    //                     padding-top: 5px;

    //                     .dot {
    //                         width: 12px;
    //                         height: 12px;
    //                         opacity: 1;
    //                         background: rgba(56, 56, 56, 1);
    //                         border-radius: 50%;
    //                         margin-right: 12px;
    //                     }
    //                 }
    //             }

    //             .itemImg {
    //                 width: 48%;
    //                 // height: 200px;
    //                 // background-color: yellowgreen;
    //                 font-size: 14px;
    //                 font-weight: 400;
    //                 letter-spacing: 0px;
    //                 line-height: 20.27px;
    //                 color: rgba(166, 166, 166, 1);
    //                 text-align: left;
    //                 vertical-align: top;

    //                 ul {
    //                     display: flex;
    //                     justify-content: space-between;

    //                     li {
    //                         display: flex;
    //                         flex-wrap: wrap;

    //                         div {
    //                             width: 100%;
    //                             display: flex;
    //                             justify-content: center;
    //                             margin-bottom: 20px;

    //                             img {
    //                                 width: 100%;
    //                             }
    //                         }

    //                         .color {
    //                             border-bottom: 30px solid rgb(226, 226, 226);
    //                             border-radius: 0 0 10px 10px;
    //                         }

    //                         .color1 {
    //                             box-sizing: border-box;
    //                             border: 5px solid rgb(70, 70, 70);
    //                             border-radius: 10px 10px 0 0;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .mianCenter {
        width: 1200px;
        margin: 0 auto;
    }


    .darkBg {
        // background-color: rgba(21, 34, 53, 1);
        background-image: url(../../../assets/image/solidBg.png);
        background-size: 100% 100%;

        .intro {
            .introDes {
                color: #CCCCCC;
            }

            .introText {
                color: #383838;
            }
        }
    }


    .darkBg1 {
        // background-color: rgba(21, 34, 53, 1);
        background-image: url(../../../assets/image/solidBg1.png);
        background-size: 100% 100%;
        padding-bottom: 100px;

        .intro {
            .introDes {
                color: #CCCCCC;
            }

            .introText {
                color: #383838;
            }
        }
    }

    .featureList {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 85px;
        display: flex;

        .featureLeft {
            width: 300px;
            height: 620px;
            opacity: 1;
            background: #276FBE;
            display: flex;
            flex-direction: column;
            font-size: 18px;
            font-weight: 400;

            .featureItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                flex: 1;
                padding: 0 10px;
                color: #fff;
                justify-content: center;
            }

            .selectFeature {
                background-color: #2B85E5;
                color: #FFFFFF;
            }

        }

        .featureRight {
            flex: 1;
            height: 620px;
            opacity: 1;
            background: rgba(255, 255, 255, 1);
            overflow: auto;

            .featuresItem {
                padding: 22px 25px;
                display: flex;
                flex-direction: column;
                height: 100%;

                .featuresIntro {
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 34.75px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .itemText {
                    padding-bottom: 14px;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 32px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;

                    .dot {
                        width: 12px;
                        height: 12px;
                        opacity: 1;
                        background: rgba(56, 56, 56, 1);
                        border-radius: 50%;
                        margin-right: 12px;
                    }

                }

                .decomposeIntro {
                    flex: 1;
                    height: 100%;
                    padding-bottom: 35px;

                    ul {
                        display: flex;
                        height: 100%;

                        li {
                            // width: 177px;
                            // height: 318px;
                            flex: 1;
                            opacity: 1;
                            border-radius: 8px;
                            background: rgba(255, 255, 255, 1);
                            box-shadow: 0px 2px 8px rgba(146, 158, 168, 0.25);
                            padding: 30px 10px;
                            margin-left: 10px;
                            display: flex;
                            flex-direction: column;

                            text-align: center;

                            .decomTag {
                                opacity: 1;
                                border-radius: 20px;
                                background: rgba(43, 133, 229, 1);
                                margin: 0 17px;
                                color: #fff;
                            }

                            .decomImg {
                                // flex: 1;
                                height: 25%;
                            }

                            .decomDes {
                                font-size: 14px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 24px;
                                color: rgba(56, 56, 56, 1);
                                text-align: center;
                                vertical-align: top;
                                padding-top: 12px;

                                p {
                                    text-align: left;
                                    display: inline-block;
                                }

                            }
                        }

                        li:first-child {
                            margin-left: 0;
                        }
                    }
                }

                .textContent {
                    display: flex;
                    flex-direction: column; // justify-content: space-between;

                    .text {
                        // width: 44%;
                        // background-color: pink;
                        margin-top: 10px;
                        padding-bottom: 10px;

                        .item {
                            // display: flex;
                            margin-bottom: 10px;
                            line-height: 32px;

                            .title {
                                font-size: 20px;
                                font-weight: 500;
                                letter-spacing: 0px;
                                line-height: 32px;
                                color: rgba(42, 130, 228, 1);
                                text-align: left;
                                vertical-align: top;
                            }

                            .des {
                                font-size: 16px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 32px;
                                color: rgba(56, 56, 56, 1);
                                text-align: left;
                                vertical-align: top;
                                width: calc(100% - 24px);
                            }

                            .img {
                                text-align: center;
                                padding: 10px 0;
                            }
                        }

                        .div_test {
                            width: 24px;
                            padding-top: 10px;

                            .dot {
                                width: 12px;
                                height: 12px;
                                opacity: 1;
                                background: rgba(56, 56, 56, 1);
                                border-radius: 50%;
                                margin-right: 12px;
                            }
                        }
                    }

                    .itemImg {
                        // width: 48%;
                        // height: 200px;
                        // background-color: yellowgreen;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 20.27px;
                        text-align: center;
                        vertical-align: top;
                        // padding-top: 10px;

                        .imgDes {
                            padding-top: 10px;
                            color: rgba(56, 56, 56, 1);
                        }

                        ul {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            li {

                                div {
                                    img {
                                        width: 100%;
                                    }
                                }

                                .smallDes {
                                    font-size: 10px;
                                    font-weight: 400;
                                    letter-spacing: -1px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                }
                            }
                        }

                        .img2 {
                            padding: 10px;
                        }

                    }
                }

                .textContent1 {
                    display: flex;
                    padding-top: 20px;

                    .text {
                        margin-right: 10px;

                        .item {
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 32px;
                            color: rgba(56, 56, 56, 1);
                            text-align: left;
                            vertical-align: top;

                        }

                        .textTotal {
                            // padding: 15px;
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;

                            .desItem {
                                width: 30%;
                                // padding: 10px;
                                margin: 7px;
                                border-radius: 10px;
                                background: rgba(255, 255, 255, 1);
                                overflow: hidden;

                                .desTitle {
                                    opacity: 1;
                                    background: rgba(42, 130, 228, 1);
                                    padding: 10px;
                                    text-align: center;
                                    font-size: 18px;
                                    font-weight: 500;
                                    letter-spacing: 0px;
                                    line-height: 32px;
                                    color: rgba(255, 255, 255, 1);
                                    vertical-align: top;
                                }

                                .desDes {
                                    font-size: 13px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 0.89);
                                    text-align: justify;
                                    vertical-align: top;
                                    padding: 5px 10px;
                                }
                            }
                        }
                    }

                    .itemImg {
                        text-align: center;

                        ul {
                            li {
                                img {
                                    margin-bottom: 20px;

                                }

                                .imgDes {
                                    font-size: 12px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                    text-align: center;
                                    vertical-align: top;

                                }
                            }
                        }
                    }
                }

                .diffFlex {
                    flex-direction: row;

                    .text {
                        width: 50%;
                    }

                    .itemImg {
                        width: 50%;
                    }
                }
            }
        }
    }
}

.typicalAppContent {
    display: flex;
    justify-content: center;

    .topicList:first-child {
        margin-right: 33px;
    }

    .topicContent {
        position: relative;
    }

    .topicTitle {
        position: absolute;
        top: 30px;
        left: 24px;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 37.65px;
        color: rgba(255, 255, 255, 1);
        vertical-align: top;

    }

    .topicC {
        position: absolute;
        top: 90px;
        left: 24px;
        width: 50%;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 50px;
        color: rgba(255, 255, 255, 1);
        text-align: justify;
        vertical-align: top;

    }
}
</style>