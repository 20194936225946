<template>
    <div class="block_center">
        <div class="banner"></div>
        <div class="productTypes">
            <div v-for="item in typeList" :key="item.name" class="typeItem">
                <span class="desItem">{{ item.des }}</span>
                <span class="textItem">{{ item.name }}</span>
            </div>
        </div>
        <div class="introduction">
            <div class="mianCenter">

                <div class="intro">
                    <div>
                        <span class="introDes">{{ typeList[0].des }}</span>
                    </div>
                    <div style="margin-top: -20px;">
                        <span class="introText">{{ typeList[0].name }}</span>
                    </div>
                </div>
                <div class="introContent">
                    <!-- 文本 -->
                    <div class="leftIntro leftBase">
                        <div class="introTitle">SummitLab 智能化内业培训系统</div>
                        <div class="introTextContent">SummitLab
                            智能化内业培训系统（简称：峰岭教培系统）是峰岭科技潜心研制的智能化内业培训软件，首发1.0版本专注突破内业矢量采集生产培训中长期以来存在的瓶颈该系统采用生产软件嵌入模式， 内建海量案例，
                            通过以练代学的模式，让学员快速掌握软件功能、符号表达、判绘综合等知识。
                            系统改变了矢量采集培训传统的教师口授模式，通过沉浸式自助培训，充分调动学员的学习潜力，将传统内业培训4~6个月的上图周期压缩到2个月以内，开创了内业定量化培训的新模式。 </div>
                    </div>
                    <!-- 视频 -->
                    <div class="rightBase">
                        <VideoPlayer></VideoPlayer>
                    </div>
                </div>

                <div class="intro" style="margin-top: 50px;">
                    <div>
                        <span class="introDes">{{ typeList[1].des }}</span>
                    </div>
                    <div style="margin-top: -20px;">
                        <span class="introText">{{ typeList[1].name }}</span>
                    </div>
                </div>
                <div class="adventages">
                    <div class="leftAdv leftBase">
                        <div class="advItem" v-for="item in advantageList" :key="item.name">
                            <div>
                                <img :src="item.img" alt="">
                            </div>
                            <div>
                                <span class="advItemTitle">{{ item.title }}</span>
                                <div class="advItemDes">{{ item.des }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="rightAdv rightBase">
                        <div class="advImg">
                            <!-- <img src="../../../assets/image/advanShow1.png" alt=""> -->
                        </div>
                        <div class="advText">插件嵌入图</div>
                    </div>
                </div>
                <div class="intro" style="margin-top: 50px;">
                    <div>
                        <span class="introDes">{{ typeList[2].des }}</span>
                    </div>
                    <div style="margin-top: -20px;">
                        <span class="introText">{{ typeList[2].name }}</span>
                    </div>
                </div>
                <div class="featureStyle">
                    <div class="leftFea leftBase">
                        <div class="leftTitle">学习内容定制可控</div>
                        <ul style="list-style-type: disc;">
                            <li class="contentItems">丰富的剧本编辑功能：如调试、跳转、导入、导出 。 </li>
                            <li class="contentItems">大量的插件命令：如鼠标键盘的检测、工具栏的高亮显示、矢量结果的比对、飞行展示等。 </li>
                            <li class="contentItems">所有的剧本可以根据需要导出形成新的模组，然后与其它模组合并形成新的流程。 </li>
                        </ul>
                    </div>
                    <div class="rightFea rightBase">
                        <div class="featureImg">

                        </div>
                    </div>
                </div>
                <div class="featureStyle">
                    <div class="leftFea leftBase">
                        <div class="leftTitle">学习内容定制可控</div>
                        <ul style="list-style-type: disc;">
                            <li class="contentItems">丰富的剧本编辑功能：如调试、跳转、导入、导出 。 </li>
                            <li class="contentItems">大量的插件命令：如鼠标键盘的检测、工具栏的高亮显示、矢量结果的比对、飞行展示等。 </li>
                            <li class="contentItems">所有的剧本可以根据需要导出形成新的模组，然后与其它模组合并形成新的流程。 </li>
                        </ul>
                    </div>
                    <div class="rightFea rightBase">
                        <div class="featureImg">

                        </div>
                    </div>
                </div>
            </div>
            <div class="uniqueBg">
                <div class="intro" style="margin-top: 50px;">
                    <div>
                        <span class="introDes">{{ typeList[3].des }}</span>
                    </div>
                    <div style="margin-top: -20px;">
                        <span class="introText">{{ typeList[3].name }}</span>
                    </div>
                </div>
                <div class="uniqueStyle baseContent">
                    <div class="leftBase leftUni">
                        <div class="uniqueImg"></div>
                    </div>
                    <div class="rightBase rightUni">
                        <div class="uniqueItem" v-for="item in uniqueList" :key="item.name">
                            <div style="flex: 1;">
                                <span class="uniqueItemTitle">{{ item.title }}</span>
                                <div class="uniqueItemDes">{{ item.des }}</div>
                            </div>
                            <div>
                                <img :src="item.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="intro" style="margin-top: 50px;">
                <div>
                    <span class="introDes">{{ typeList[4].des }}</span>
                </div>
                <div style="margin-top: -20px;">
                    <span class="introText">{{ typeList[4].name }}</span>
                </div>
            </div>
            <div class="baseContent systemCompStyle">
                <div class="compItems">
                    <div class="compItem1">
                        <div class="itemTitle1">资源库</div>
                        <div class="itemDes">提供了便捷的工具、统一管理、查询各种资原、素材。资源包括ppt、图片、文档、视频、音频等，用户可以基于资源库制作个性化的项目案例。</div>
                    </div>
                    <div class="compItem2">
                        <div class="itemTitle">实操考练</div>
                    </div>
                </div>
                <div class="centerStyle">
                    SYSTEM COMPOSITION
                </div>
                <div class="compItems" style="margin-top: 30px;">
                    <div class="compItem3">
                        <div class="itemTitle">考核评估</div>

                    </div>
                    <div class="compItem4">
                        <div class="itemTitle">双版本同步控制</div>

                    </div>
                </div>
            </div>
            <div class="intro" style="margin-top: 50px;">
                <div>
                    <span class="introDes">{{ typeList[5].des }}</span>
                </div>
                <div style="margin-top: -20px;">
                    <span class="introText">{{ typeList[5].name }}</span>
                </div>
            </div>
            <div class="baseContent systemReqStyle">
                <div class="system">
                    <div class="system_left">
                        <img :src="requireContent.image" alt="" />
                        <h4 v-html="requireContent.leftH"></h4>
                    </div>
                    <div class="system_right">
                        <h4 class="srTitle">{{ requireContent.rightH }}</h4>
                        <p class="srDes">{{ requireContent.rightP }}</p>
                        <div></div>
                        <div class="optional">
                            <div class="optional_left" v-for="(ite,index) in requireContent.optional" :key="index">
                                <div class="standard">
                                    <h4>{{ ite.leftH }}</h4>
                                </div>
                                <p v-for="(it,index) in ite.leftP" :key="index">
                                    <span class="weights">{{ it.weights }}</span>
                                    {{ it.spa }}
                                </p>
                                <div class="demand" v-for="(requireContent,index) in ite.demand" :key="index">
                                    <span>{{ requireContent.leftspa }}</span>
                                    <span>{{ requireContent.spa }}</span>
                                </div>
                                <span style="font-size: 20px;">
                                    <span class="weights">{{ ite.weights }}</span>
                                    {{ ite.spa }}
                                </span>
                            </div>
                            <div class="optional_right" v-for="(ite,index) in requireContent.optionalRight" :key="index">
                                <div class="standard">
                                    <h4>{{ ite.leftH }}</h4>
                                    <img :src="ite.image" alt="" style="width: 45px" />
                                </div>
                                <p v-for="(it,index) in ite.leftP" :key="index">
                                    <span class="weights">{{ it.weights }}</span>
                                    {{ it.spa }}
                                </p>
                                <div class="demand" v-for="(requireContent,index) in ite.demand" :key="index">
                                    <span>{{ requireContent.leftspa }}</span>
                                    <span>{{ requireContent.spa }}</span>
                                </div>
                                <span style="font-size: 20px;">
                                    <span class="weights">{{ ite.weights }}</span>
                                    {{ ite.spa }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="intro" style="margin-top: 50px;">
                <div>
                    <span class="introDes">{{ typeList[6].des }}</span>
                </div>
                <div style="margin-top: -20px;">
                    <span class="introText">{{ typeList[6].name }}</span>
                </div>
            </div>
            <!-- 推荐订阅 -->
            <div class="baseContent suggestfollow">
                <div class="suggestItem">
                    <div class="itemName">
                        SummitMAP
                    </div>
                    <div class="itemDes">（倾斜测图模块）</div>
                    <div class="itemMoney">$99</div>
                    <div class="youhui">首月优惠</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 免费</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="itemBtn">
                        <el-button round type="warning">立即订阅</el-button>
                    </div>
                </div>
                <div class="suggestItem activeItem">
                    <div class="itemName">
                        SummitMAP
                    </div>
                    <div class="itemDes">（倾斜测图模块）</div>
                    <div class="itemMoney">$99</div>
                    <div class="youhui">首月优惠</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 免费</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="itemBtn">
                        <el-button round type="warning">立即订阅</el-button>
                    </div>
                </div>
                <div class="suggestItem ">
                    <div class="itemName">
                        SummitMAP
                    </div>
                    <div class="itemDes">（倾斜测图模块）</div>
                    <div class="itemMoney">$99</div>
                    <div class="youhui">首月优惠</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 免费</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="checkItem"><i class="el-icon-check"></i> 模块名称</div>
                    <div class="itemBtn">
                        <el-button round type="warning">立即订阅</el-button>
                    </div>
                </div>
            </div>
            <div class="moreTaocan">
                查看更多套餐选择 >
            </div>
            <div class="intro" style="margin-top: 50px;">
                <div>
                    <span class="introDes">{{ typeList[7].des }}</span>
                </div>
                <div style="margin-top: -20px;">
                    <span class="introText">{{ typeList[7].name }}</span>
                </div>
            </div>
            <!-- 用户评价 -->
            <div class="userComments baseContent">
                <div class="commentTitle">真实用户评价</div>
                <div class="commentItem">
                    <img src="../../../assets/image/uniqueBg1.png" alt="">
                    <div class="commentText">
                        <div class="companyName">—来自武汉易米景科技有限公司</div>
                        <div class="commentContent">
                            这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这
                        </div>
                    </div>
                </div>
                <div class="commentItem">
                    <img src="../../../assets/image/uniqueBg1.png" alt="">
                    <div class="commentText">
                        <div class="companyName">—来自武汉易米景科技有限公司</div>
                        <div class="commentContent">
                            这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这
                        </div>
                    </div>
                </div>
                <div class="commentItem">
                    <img src="../../../assets/image/uniqueBg1.png" alt="">
                    <div class="commentText">
                        <div class="companyName">—来自武汉易米景科技有限公司</div>
                        <div class="commentContent">
                            这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这软件好这
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
export default {
    components: {
        VideoPlayer,
    },
    data() {
        return {
            typeList: [
                {
                    name: '产品介绍',
                    des: "Product Introduction"
                },
                {
                    name: '产品优势',
                    des: "Product advantages"
                },
                {
                    name: '产品特点',
                    des: "Product Features"
                },
                {
                    name: '特有功能',
                    des: "Unique features"
                },
                {
                    name: '系统组成',
                    des: "System Composition"
                },
                {
                    name: '系统要求',
                    des: "System Requirements"
                },
                {
                    name: '推荐订阅',
                    des: "Suggested Follows"
                },
                {
                    name: '用户评价',
                    des: "User Evaluation"
                },

            ],
            advantageList: [
                {
                    img: require('../../../assets/image/advantage1.png'),
                    title: "插件嵌入",
                    des: "采用插件嵌入方式和生产软件实时联机，获取生产软件中的鼠标键盘的消息，同步执行脚本命令，实现了游戏级教学体验。 "
                },
                {
                    img: require('../../../assets/image/advantage1.png'),
                    title: "沉浸式教学体系",
                    des: "将图片、文字、视频等内容嵌入到生产软件中，信息丰富生动，实时联机生产软件让操作教学过程更直观，采用TTS技术实现实时语音播报，充分调动学员的听觉、视觉、触觉、建立高效的学习反馈链路。 "
                },
                {
                    img: require('../../../assets/image/advantage1.png'),
                    title: "TTS智能语音辅助",
                    des: "采用TTS技术对重要环节的操作进行了语音录制，语音流畅生动。 "
                },
                {
                    img: require('../../../assets/image/advantage1.png'),
                    title: "剧本编辑引擎",
                    des: "强大的剧本引擎能支持各种场景，包括生产软件界面提示、操作结果判定、命令启动、停止、语音视频文字展示与关闭等。 "
                },
            ],
            uniqueList: [
                {
                    img: require('../../../assets/image/uniqueBg2.png'),
                    title: "网络管理 成竹在胸",
                    des: "账户、成绩统一管理、便于授课、评分、成绩排名一目了然。客户端与服务器随时转换，连接数量实时可查。  "
                },
                {
                    img: require('../../../assets/image/uniqueBg3.png'),
                    title: "精度指导 耳提面命",
                    des: "采集过程中实时反馈单点精度，帮助作业员培养良好的采集惯。 "
                },
                {
                    img: require('../../../assets/image/uniqueBg4.png'),
                    title: "自助考核 左右互搏",
                    des: "系统内置了自助考核功能，作业员可以在一段时间学习后，提交图幅成果，系统会自动对比标准成果，对其进行多个维度的评估，不仅打分，还会给出改进意见。 "
                },
                {
                    img: require('../../../assets/image/uniqueBg5.png'),
                    title: "分级学习 循序渐进",
                    des: "将学习内容按难易，类别划分成不同的等级，指导学员循序渐进的掌握软件，地物地类知识，采集编辑操作。 "
                },
            ],
            requireContent: {
                image: require('../../../assets/image/SP图标.png'),
                leftH: `SummitMap<br />一站式智能成图平台`,
                rightH: `操作系统`,
                rightP: 'windows XP 64位/windows7 64位/windows10 64位',
                optional: [
                    {
                        leftH: '基本配置',
                        leftP: [
                            {
                                weights: 'CPU:',
                                spa: 'intel i5-8500T、AMD 锐龙5 1500X'
                            },
                            {
                                weights: '内存:',
                                spa: 'DDR3代 8GB'
                            },
                            {
                                weights: '硬盘:',
                                spa: '7200转机械硬盘2TB'
                            },
                            {
                                weights: '显示系统',
                                spa: ''
                            }
                        ],
                        demand: [
                            {
                                leftspa: '有立体作业需求：',
                                spa: '丽台P400显卡、明基2411p显示器、NVIDIA 立体发射器、立体眼镜'
                            },
                            {
                                leftspa: '无立体作业需求：',
                                spa: 'NVIDIA GTX650、100HZ以上显示器'
                            }
                        ],
                        weights: '其它(选配):',
                        spa: '脚盘'
                    }
                ],
                optionalRight: [
                    {
                        leftH: '标准配置',
                        image: require('../../../assets/image/推荐.png'),
                        leftP: [
                            {
                                weights: 'CPU:',
                                spa: 'intel 酷睿12代 i7'
                            },
                            {
                                weights: '内存:',
                                spa: 'DDR4代 32GB'
                            },
                            {
                                weights: '硬盘:',
                                spa: 'SSD：4TB'
                            },
                            {
                                weights: '显示系统',
                                spa: ''
                            }
                        ],
                        demand: [
                            {
                                leftspa: '有立体作业需求：',
                                spa: '丽台T1000 4GB显卡、明基2411p显示器、NVIDIA 立体发射器、立体眼镜'
                            },
                            {
                                leftspa: '无立体作业需求：',
                                spa: 'NVIDIA GTX3060'
                            }
                        ],
                        weights: '其它(选配):',
                        spa: '手轮、脚盘、接口盒'
                    }
                ]
            }
        };
    },
    mounted() {

    },
    watch: {

    },
    methods: {

    }
};
</script>
<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;

    .banner {
        background-image: url("../../../assets/image/banner.png");
        height: 300px;
        background-size: 100% 100%;
    }

    .productTypes {
        display: flex;
        margin-top: -40px;
        font-size: 16px;

        .typeItem {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: center;

            .desItem {
                color: rgba(237, 237, 237, 0.3);
            }

            .textItem {
                color: #fff;
                margin-top: -10px;
            }
        }

    }

    .introduction {
        background-image: url("../../../assets/image/productBg.png");
        background-size: 100%;
        background-repeat: no-repeat;

        .intro {
            padding: 80px 50px;
            text-align: center;
            display: flex;
            flex-direction: column;

            .introDes {
                font-size: 35px;
                color: rgba(237, 237, 237, 1);
                font-weight: 400;
            }

            .introText {
                font-size: 25px;
                font-weight: 700;
                letter-spacing: 0px;
                color: rgba(0, 0, 0, 1);
                position: relative;
            }

            .introText::after {
                position: absolute;
                bottom: -16px;
                left: 0;
                right: 0;
                height: 5px;
                background-color: #2A82E4;
                content: "";
                transform: scaleY(.5);
            }

            // .introLine {
            //     height: 5px;
            //     width: 20px;
            //     background-color: #2A82E4;
            //     text-align: center;
            // }

        }

        .introContent {
            // padding: 0 50px;
            display: flex;

            .leftIntro {
                .introTitle {
                    font-size: 26px;
                    font-weight: 400;
                    text-align: center;
                    margin-bottom: 10px;
                    color: rgba(42, 130, 228, 1);
                }

                .introTextContent {
                    font-size: 16px;
                    line-height: 36px;
                }
            }

        }

        .leftBase {
            flex: 1;
            padding-right: 30px;
        }

        .rightBase {
            flex: 1;
            // padding: 0 50px;
        }

        .adventages {
            // padding: 0 50px;
            display: flex;

            .leftAdv {
                flex: 1;
                // padding: 20px 40px;

                .advItem {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;

                    img {
                        width: 70px;
                        margin-right: 20px;
                    }

                    .advItemTitle {
                        font-size: 20px;
                        font-weight: 500;
                        color: rgba(9, 132, 255, 1);

                    }

                    .advItemDes {
                        margin-top: 10px;
                        font-size: 16px;
                    }
                }
            }

            .rightAdv {


                .advImg {
                    width: 100%;
                    height: 400px;
                    background-image: url("../../../assets/image/advanShow1.png");
                    background-position: -98px -117px;
                    background-size: 150%;

                    border-radius: 8px;
                    // background: rgba(250, 250, 250, 1);

                    border: 1px solid rgba(255, 255, 255, 1);

                    box-shadow: 0px 2px 16px rgba(148, 164, 168, 0.45);
                }

                .advText {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 10px 0;
                }
            }

        }

        .featureStyle {
            display: flex;
            margin-bottom: 100px;

            .leftFea {
                background-image: url('../../../assets/image/featureBg3.png');
                background-size: 50%;
                background-repeat: no-repeat;
                background-position-x: center;

                .leftTitle {
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 37.65px;
                    color: rgba(0, 94, 255, 1);
                    position: relative;

                }

                .leftTitle::after {
                    position: absolute;
                    bottom: 0;
                    left: -25px;
                    right: 0;
                    width: 7px;
                    height: 100%;
                    background-color: #61ABFF;
                    content: "";
                    transform: scaleY(0.7);
                }

                .contentItems {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 23.17px;
                    color: rgba(56, 56, 56, 1);
                    text-align: justify;
                    vertical-align: top;
                    margin: 50px 0;
                }
            }

            .rightFea {
                .featureImg {
                    width: 100%;
                    height: 100%;
                    background-image: url("../../../assets/image/featureBg.png");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-position-y: center;
                }
            }
        }

        .baseContent {
            width: 1200px;
            margin: 0 auto;
            display: flex;
        }

        .uniqueBg {

            // background-image: url("../../../assets/image/productBg11.png");
            .uniqueStyle {
                // padding: 0 100px;


                .leftUni {
                    .uniqueImg {
                        width: 100%;
                        height: 100%;
                        background-image: url("../../../assets/image/uniqueBg1.png");
                        // background-size: 100% auto;
                        background-repeat: no-repeat;
                        background-position-y: center;
                    }
                }

                .rightUni {
                    padding-left: 100px;

                    .uniqueItem {
                        display: flex;
                        text-align: right;
                        margin: 30px 0;

                        img {
                            width: 70px;
                            margin-left: 20px;
                        }

                        .uniqueItemTitle {
                            font-size: 20px;
                            font-weight: 500;
                            color: rgba(9, 132, 255, 1);

                        }

                        .uniqueItemDes {
                            margin-top: 10px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .systemCompStyle {
            flex-direction: column;
            position: relative;

            .compItems {
                display: flex;
                height: 214px;

                .compItem1 {
                    flex: 1;
                    background-image: url("../../../assets/image/systemComBg1.png");
                    margin-right: 20px;

                    .itemTitle1 {
                        font-size: 26px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 37.65px;
                        color: rgba(255, 255, 255, 1);
                        text-align: center;
                        vertical-align: top;
                        margin: 30px 0 10px;
                    }

                    .itemDes {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 23.17px;
                        color: rgba(255, 255, 255, 1);
                        text-align: justify;
                        vertical-align: top;
                        padding: 0 60px;
                    }
                }

                .itemTitle {
                    font-size: 26px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 37.65px;
                    color: rgba(42, 130, 228, 1);
                    text-align: center;
                    vertical-align: top;
                    margin: 30px 0 10px;

                }

                .compItem2 {
                    flex: 1;
                    background-image: url("../../../assets/image/systemComBg2.png");
                    margin-left: 20px;

                }

                .compItem3 {
                    flex: 1;
                    background-image: url("../../../assets/image/systemComBg3.png");
                    margin-right: 20px;
                }

                .compItem4 {
                    flex: 1;
                    background-image: url("../../../assets/image/systemComBg4.png");
                    margin-left: 20px;
                }
            }

            .centerStyle {
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 0px;
                line-height: 130px;
                color: rgb(42, 130, 228);
                text-align: center;
                background-image: url("../../../assets/image/systemComBg5.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 60% auto;
                position: absolute;
                top: 37%;
                left: 42.3%;
                height: 130px;
                width: 200px;

            }
        }

        .systemReqStyle {
            .system {
                display: flex;
                width: 100%;
                border-radius: 33px;
                background: #fff;
                box-shadow: 0px 2px 12px #00000057;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-around;
                align-items: center;
                padding-bottom: 30px;

                .system_left {
                    // width: 30%;
                    padding: 5% 0;

                    img {
                        display: block;
                        margin: 15px auto;
                        width: 70%;
                    }

                    h4 {
                        text-align: center;
                        color: #383838;
                        font-size: 16px;
                    }
                }

                .system_right {
                    width: 80%;

                    .srTitle {
                        font-size: 20px;
                    }

                    .srDes {
                        font-size: 17px;
                    }

                    .standard {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        // height: 18px;
                    }

                    h4 {
                        margin-bottom: 1%;
                    }
                }

                .optional {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    .optional_left {
                        width: 51%;
                        padding-right: 20px;

                        h4 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 17px;
                        }
                    }

                    .optional_right {
                        width: 48%;

                        h4 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 17px;
                        }
                    }

                    p {
                        margin: 15px 0;
                    }
                }

                .weights {
                    font-weight: bold;
                    margin-right: 20px;
                }

                .demand {
                    display: flex;
                    font-size: 17px;
                    margin-bottom: 10px;

                    span:nth-child(2) {
                        width: 58%;
                    }
                }
            }
        }

        .suggestfollow {
            justify-content: space-between;


            .suggestItem {
                // flex: 1;
                opacity: 1;
                border-radius: 10px;
                // background: #FFFFFF;
                border: 1px solid #E5E5E5;
                width: 30%;
                padding: 20px;

                .itemName {
                    font-size: 28px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    line-height: 39.2px;
                    color: rgba(35, 61, 99, 1);
                    text-align: center;
                }

                .itemDes {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 23.17px;
                    color: rgba(35, 61, 99, 1);
                    vertical-align: top;

                }

                .itemMoney {
                    font-size: 48px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    color: rgba(255, 190, 86, 1);
                    text-align: center;
                    vertical-align: top;
                    margin-top: 15px;

                }

                .youhui {
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0px;
                    color: rgba(135, 150, 172, 1);
                    text-align: center;
                    vertical-align: top;
                    margin-bottom: 20px;
                }

                .checkItem {
                    font-size: 16px;
                    font-weight: 300;
                    letter-spacing: 0px;
                    color: rgba(135, 150, 172, 1);
                    text-align: center;
                    vertical-align: top;
                    margin: 10px 0;

                    i {
                        color: #5355EE;
                        font-weight: bold;
                    }

                }

                .itemBtn {
                    text-align: center;
                    margin: 40px 20px 20px;
                }
            }

            .activeItem {
                background-color: #035B9E;

                .itemName,
                .itemMoney,
                .checkItem,
                .youhui,
                .itemDes {

                    color: #fff !important;
                }
            }
        }

        .userComments {
            flex-direction: column;

            .commentTitle {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20px;
                color: rgba(0, 115, 211, 1);
                text-align: left;
                vertical-align: top;
            }

            .commentItem {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid #DCDFE6;
                margin-bottom: 20px;
                img {
                    width: 350px;
                }

                .commentText {
                    margin-left: 30px;

                    .companyName {
                        font-size: 18px;
                        font-weight: 700;
                        letter-spacing: 0px;
                        line-height: 26.06px;
                        color: rgba(51, 51, 51, 1);
                        text-align: left;
                        vertical-align: top;
                        margin-bottom: 10px;
                    }

                    .commentContent {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 23.17px;
                        color: rgba(51, 51, 51, 1);
                        text-align: left;
                        vertical-align: top;

                    }
                }
            }
        }
    }
}

.moreTaocan {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(128, 128, 128, 1);
    margin-top: 20px;

}

.mianCenter {
    width: 1200px;
    margin: 0 auto;
}
</style>