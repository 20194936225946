<template>
  <div>
    <Header v-show="!isFull" />
    <div class="line"></div>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else />
    <!-- <Login v-if="showLoginPane"></Login> -->
    <SidebarPart :paneFlag="paneFlag" v-show="!isFull"></SidebarPart>
    <Footer v-show="!isFull" />
    <!-- <Message></Message> -->
  </div>
</template>

<script>
import Footer from "./footer/Footer.vue";
import Header from "./header/Header.vue";
import Login from "@/views/Login/login.vue";
import SidebarPart from '@/views/Home/SidebarPart';
// import Message from '../Message'
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: { Header, Footer, Login, SidebarPart },
  data() {
    return {
      paneFlag: false,
      previousScrollPosition: 0,
    };
  },
  computed: {
    ...mapState("user", ["showLoginPane"]),
    isFull: {
      set(value) {
        this.$store.commit("isFull", value);
        if (value) {
          this.isFooter = false;
        }
      },
      get() {
        return this.$store.state.isFull;
      },
    },
    isFooter: {
      set(value) {
        this.$store.commit("isFooter", value);
      },
      get() {
        return this.$store.state.isFooter;
      },
    }
  },
  mounted() {
    console.log('uuuwwwww');
    window.addEventListener('scroll', this.handleScrollSidebar);
    if (this.$route.path.includes("Retailer")) {
      this.$store.dispatch("user/getRetailerInfo");
    } else {
      this.$store.dispatch("user/getInfo");
    }

    let prop = this;
    window.onresize = function temp() {
      console.log("窗口变动了");
      if (!prop.utils.isFullScreen()) {
        if (!prop.$route.path.includes("Search") && !prop.$store.state.isFullScreen) {
          prop.isFull = false;
        }
      }
    };
  },
  methods: {
    handleScrollSidebar() {
      const currentScrollPosition = window.scrollY;
      if (currentScrollPosition > this.previousScrollPosition) {
        console.log("向下");
        // 向下滚动
        this.paneFlag = true;
        // 更新滚动位置
        this.previousScrollPosition = currentScrollPosition;
      } else if (currentScrollPosition < this.previousScrollPosition) {
        console.log("向上");
        // 向上滚动
        this.paneFlag = false;
        // 更新滚动位置
        this.previousScrollPosition = currentScrollPosition;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollSidebar);
  }
};
</script>

<style lang="scss" scoped>
// .line {
// 	padding-top: 60px;
// }</style>
