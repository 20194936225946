<template>
  <div class="footer" v-if="this.screenWidth >= 840">
    <div class="footer_div">
      <div class="left">
        <img src="../../../assets/image/峰岭科技LOGO.png" alt="" />
        <ul style="list-style: none;margin-top: 20px;">
          <li>
            <span class="laber">地址</span>
            <p>湖北省武汉市高新大道999号未来科技城B区1栋2楼</p>
          </li>
          <li>
            <span class="laber">电话</span>
            <p>+86&nbsp;177&nbsp;8608&nbsp;3608</p>
          </li>
          <li>
            <span class="laber">邮箱</span>
            <p>sales@summitek.xyz</p>
          </li>
          <!-- 删除 -->
        </ul>
        <!-- <p style="text-align: center; margin-top: 43px">鄂ICP备2022010992号-1</p> -->
      </div>
      <div class="center">
        <table>
          <tr style="line-height: 65px; font-weight: bold;font-size: 16px;">
            <th>产品介绍</th>
            <!-- <th>免费试用</th> -->
            <th>按需订阅</th>
            <th>免费软件</th>
            <th>服务支持</th>
            <th>关于我们</th>
          </tr>
          <tr>
            <td @click="goTo('/AllProducts', 'SummitMap')">SummitMap</td>
            <!-- <td @click="down('/downloads', 'first')">免费试用</td> -->
            <td @click="down('/Buy', 'SummitMap')">SummitMap</td>
            <!-- <td>软件支持</td> -->
            <td @click="goTo('/FreeSoft')">SummitView</td>
            <td @click="goToService('/Service', 0)">软件支持</td>
            <td @click="goTo('/AboutUs', 'intro')">企业介绍</td>
          </tr>
          <tr>
            <td @click="goTo('/AllProducts', 'SummitSolid')">SummitSolid</td>
            <td @click="down('/Buy', 'SummitSolid')">SummitSolid</td>
            <!-- <td @click="goTo('/SummitSolid')">SummitLab</td> -->
            <td></td>
            <!-- <td @click="down('/downloads', 'second')">试用生产</td> -->
            <!-- <td @click="down('/buy', '1')">功能列表</td> -->
            <!-- <td>问题交流</td> -->
            <td @click="goToService('/Service', 1)">问题交流</td>
            <td @click="goTo('/AboutUs', 'culture')">企业文化</td>
          </tr>
          <tr>
            <td @click="goTo('/AllProducts', 'SummitLab')">SummitLab</td>
            <!-- <td></td> -->
            <td @click="down('/Buy', 'SummitLab')">SummitLab</td>
            <td></td>
            <!-- <td @click="down('/buy', '3')">代理商申请</td> -->
            <td @click="goToService('/Service', 2)">联系我们</td>
            <td @click="goTo('/AboutUs', 'qualifications')">荣誉资质</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <!-- <td></td> -->
            <!-- <td @click="down('/buy', '4')">使用指南</td> -->
            <!-- <td @click="down('/downloads', 'fourth')">试用许可</td> -->
            <td @click="goTo('/AboutUs', 'aboutmap')">公司地图</td>
            <!-- <td @click="goTo('/about#About_Contact')">联系方式</td> -->
          </tr>
        </table>
      </div>
      <div class="right">
        <div style="margin-right:44px;">
          <img src="../../../assets/image/二维码.png" alt="" />
          <p>加入我们 &nbsp; 了解峰岭</p>
        </div>
        <div>
          <img src="../../../assets/image/二维码2.png" alt="" />
          <p>峰岭官方视频号</p>
        </div>
      </div>
    </div>
    <div class="div_e">鄂ICP备2022010992号-1</div>
  </div>
  <!-- <div class="footer" v-else>
    <div class="footer_top">
      <img class="logo" src="../../../assets/image/峰岭科技LOGO.png" alt="" />
      <ul>
        <li>
          <i class="iconfont icon-iconfontzhizuobiaozhun023134"></i>
          177&nbsp;8608&nbsp;3608
        </li>
        <li>地址：湖北武汉东湖新技术开发区华中科技园青创园704</li>
        <li>邮箱：sales@summitek.xyz</li>
      </ul>
      <div class="footer_top_right">
        <img src="../../../assets/image/二维码.png" alt="" />
        <p>加入我们 &nbsp; 了解峰岭</p>
      </div>
    </div>
    <ul class="footer_bottom">
      <li @click="goTo('/summitMap')">产品中心</li>
      <li>服务与支持</li>
      <li @click="down('/downloads', 'first')">下载安装</li>
      <li @click="down('/buy', '1')">购买</li>
      <li @click="goTo('/about#About_Introduzir')">关于我们</li>
    </ul>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: {
      immediate: true,
      handler: function (newval) { }
    }
  },
  methods: {
    goTo(url, value) {
      // this.$router.push(url);
      if (value) {
        this.$router.push({
          path: `${url}`,
          query: {
            name: value
          }
        });
      } else {
        this.$router.push({
          path: `${url}`,
        });
      }
    },
    down(url, data) {
      this.$router.push({
        path: `${url}`,
        query: {
          value: data
        }
      });
    },
    goToService(path, index) {
      if (index == 0) {
        this.$router.push({
          path,
          query: {
            type: 'update'
          }
        });
      } else if (index == 1) {
        window.open('https://summitek.freshdesk.com/support/home');
      } else if (index == 2) {
        this.$router.push({
          path,
          query: {
            type: 'contact'
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  // max-width: 1680px;
  // margin: 0 auto;
  // margin-top: 100px;
  // display: flex;
  // padding: 30px 77px;
  background-color: #2B85E5;

  .footer_div {
    width: 100vw;
    margin: 0 auto;
    // margin-top: 100px;
    display: flex;
    padding: 30px 77px 20px 77px;
    background-color: #2B85E5;
  }

  .div_e {
    width: 100vw;
    background-color: #2B85E5;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
    padding-bottom: 25px;
  }

  .left {
    width: 30%;
    height: 250px;
    color: #E5E5E5;
    text-align: left;
    border-right: 2px solid #fff;
    // padding-top: 14px;

    img {
      // margin-left: 113px;
      margin-left: 17%;
      width: 200px;
      object-fit: contain;
      image-rendering: -webkit-optimize-contrast;
    }

    ul {
      padding-left: 20px;

      li {
        margin-bottom: 20px;

        .laber {
          // margin: 0 10px 0 101px;
          margin: 0 10px 0 17%;
          float: left;
        }

        p {
          // margin-left: 152px;
          margin-left: 29%;
          padding-right: 47px;
          font-size: 14px;
          // padding-right: 125px;
        }
      }
    }

    &:nth-child(2) li {
      float: left;
    }
  }

  .center {
    width: 50%;
    height: 250px;
    border-right: 2px solid #fff;

    table {
      width: 95%;
      text-align: center;
      line-height: 35px;

      th {
        color: white !important;
      }

      td {
        color: #E5E5E5;
        font-size: 14px;
      }
    }
  }

  .right {
    // background: skyblue;
    width: 20%;
    height: 250px;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 67px;

    div {
      text-align: center;
    }

    img {
      width: 100px;
      // margin: 47px 0 7px 107px;
      // display: block;
      // margin: 18% auto 5%;
      image-rendering: -webkit-optimize-contrast;
    }

    p {
      text-align: center;
      color: #E5E5E5;
      font-size: 14px;
    }
  }

  td {
    cursor: pointer;
  }
}

@media screen and (max-width: 840px) {
  .footer {
    flex-direction: column;
    color: #fff;

    .footer_top {
      display: block;
      display: flex;
      align-items: center;

      .logo {
        width: 28%;
        object-fit: contain;
        display: block;
        margin-right: 33px;
      }

      ul {
        // width: 100%;
        min-width: 43%;
        list-style: none;
        display: block;
      }
    }

    .footer_top_right {
      img {
        width: 44%;
        display: block;
        margin: 0 auto;
      }

      p {
        text-align: center;
        font-size: 12px;
      }
    }

    .footer_bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: nowrap;
      font-size: 16px;
      list-style: none;
      margin-bottom: 26px;
      cursor: pointer;
    }
  }
}
</style>
