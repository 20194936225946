export default {
  login: {
    title: 'Account Password Login',
    scanTitle: 'APP Scan Login',
    codeTitle: 'Verification Code Login',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    rule: 'Username Or Tel',
    version: 'V',
    upper: 'Caps locked',
    scanTip: 'APP Scan code login',
    accountTip: 'Please enter the account number',
    passwordTip: 'Please enter your password',
    codeTip: 'Please enter your verification code',
    changeCode: 'Click to switch verification code',
    mobile: 'Please enter mobile number',
    rightMobile: 'Please enter the correct mobile number',
    smsCode: 'Please enter the verification code',
    getCode: 'Get code',
    reSend: 'Resend',
    company: 'Please enter company name',
    contacts: 'Please enter contact'
  },
}