<template>
    <div class="Authentication">
        <el-form ref="dataForm" :model="dataForm" size="medium" label-width="150px" :rules="rules" label-position="top">
            <el-form-item label="姓名" prop="username">
                <el-input v-model='dataForm.username' clearable></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phonenum">
                <el-input v-model='dataForm.phonenum' clearable></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="email">
                <el-input v-model='dataForm.email' clearable></el-input>
            </el-form-item>

            <div class="companyPart">
                <div class="companyLeft">
                    <el-form-item label="公司名称" prop="companyname">
                        <el-input v-model='dataForm.companyname' clearable></el-input>
                    </el-form-item>
                    <el-form-item label="社会统一信用代码" prop="companysocialcreditcode">
                        <el-input v-model='dataForm.companysocialcreditcode' clearable></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="地址" prop="address">
                    <el-input type="textarea" :rows="2" v-model="dataForm.address"></el-input>
                </el-form-item>
            </div>
            <el-form-item>
            </el-form-item>
            <el-form-item>
                <el-button style="float: right;" type="primary" :loading="btnLoading" @click="dataFormSubmit()">保 存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import request from '@/utils/request'
import { mapState, mapMutations } from "vuex";
export default {
    data() {
        return {
            dataForm: {
                id: "",
                usertype: "0",
                clientidentity: "4",
                userleval: "1",// 用户类型（公司）
                companyname: '',// 公司名
                companysocialcreditcode: '',// 统一社会代码
                companytaxation: '',// 税务登记号
                sortcode: undefined,
                description: '',
                username: '',// 用户名
                address: '',// 地址
                phonenum: '',// 手机号
                email: '',// 邮箱
                bankaccountname: '',// 银行开户行名
                bankaccount: '',// 银行账户
            },
            rules: {
                username: [
                    { required: true, message: "请输入名称", trigger: 'blur' }
                ],
                companyname: [
                    { required: true, message: "请输入公司名称", trigger: 'blur' },
                ],
                email: [
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ]
            },
            btnLoading: false,
        };
    },
    computed: {
        ...mapState("user", ["userInfo"]),
    },
    mounted() {
        this.dataForm.id = this.userInfo.id
        this.dataForm.username = this.userInfo.username
        this.dataForm.phonenum = this.userInfo.phonenum
        this.dataForm.companyname = this.userInfo.companyname
        this.dataForm.companysocialcreditcode = this.userInfo.companysocialcreditcode
        this.dataForm.email = this.userInfo.email
        this.dataForm.address = this.userInfo.address
    },
    watch: {

    },
    methods: {
        dataFormSubmit() {
            this.$refs["dataForm"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    console.log("this.dataForm", this.dataForm);
                    request({
                        url: '/api/business/C_customers/' + this.userInfo.id,
                        method: 'PUT',
                        data: this.dataForm
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.btnLoading = false;
                                    this.$router.go(0)
                                }
                            })
                        }
                    })
                }
            });
        }
    }
}
</script>

<style scoped lang="scss" scoped>
.Authentication {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5%;
    padding-top: 60px;
    margin-top: 35px;

    opacity: 1;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);

    .el-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .el-form-item {
        margin-bottom: 20px;
        width: 48%;
    }

    :deep .el-form-item__label {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(128, 128, 128, 0.8);
    }

    :deep .el-input__inner {
        height: 50px;
        border-radius: 8px;
        background: rgba(245, 245, 245, 1);
    }

    :deep .el-textarea__inner {
        height: 100px;
        border-radius: 8px;
        background: rgba(245, 245, 245, 1);
    }
}

.companyPart {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    border-top: 1px solid rgba(229, 229, 229, 1);
    padding-top: 10px;

    .companyLeft {
        width: 48%;

        .el-form-item {
            margin-bottom: 30px;
            width: 100%;
        }
    }
}
</style>