export default {
  login: {
    title: '账户密码登录',
    scanTitle: 'APP扫码登录',
    codeTitle: '手机验证登录',
    logIn: '登录',
    register: '注册',
    username: '请输入用户名或手机号码',
    password: '请输入密码',
    rule: '用户名或手机号',
    version: '版本',
    upper: '大写已锁定',
    scanTip: 'APP扫码登录',
    accountTip: '请输入账号',
    passwordTip: '请输入密码',
    codeTip: '请输入验证码',
    changeCode: '点击切换验证码',
    mobile: '请输入手机号',
    rightMobile: '请输入正确的手机号',
    smsCode: '请输入验证码',
    getCode: '获取验证码',
    reSend: '重新发送',
    company: '请输入公司名',
    contacts: '请输入联系人',
    ForgetPassword: '忘记密码',
  },
  register: {
    gender: '请输入正确性别',
    rightMobile: '请输入正确手机号码',
    username: '请输入正确格式的账号：字母、数字组成',
    name: '请输入正确姓名',
    organization: '请选择所在组织',
    password: '请输入正确的密码格式',
    organizationTitle:'请输入正确的组织名称'
  }
}