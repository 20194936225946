import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../components/layout/index.vue";
import Home from "@/views/Home/Home.vue";
import AboutUs from "@/views/business/AboutUs.vue";
import login from "@/views/Login/login.vue";
import loginRetailer from "@/views/Login/loginRetailer.vue";
import paySteps from "@/views/business/pay/paySteps.vue";
import Buy from "@/views/business/pay/Buy.vue";
import BuyMenu from "@/views/business/pay/BuyMenu.vue";
import AllProducts from "@/views/business/products/AllProducts.vue";
import Productsintro from "@/views/business/products/ProductIntro.vue";
import Cart from "@/views/business/pay/Cart.vue";
import Service from "@/views/business/service/Service.vue";
import Communicate from "@/views/business/service/Communicate/Communicate.vue";
import ContactUs from "@/views/business/service/ContactUs/ContactUs";
import PersonalCenter from "@/views/business/PersonalCenter/PersonalCenter";
import SoftwareSupportDetail from "@/views/business/service/SoftwareSupport/SoftwareSupportDetail";
import SoftwareSupport from "@/views/business/service/SoftwareSupport/SoftwareSupport";
import AccountSupportDetail from "@/views/business/service/AccountSupport/AccountSupportDetail";
import AccountSupportContent from "@/views/business/service/AccountSupport/AccountSupportContent";
import AccountSupport from "@/views/business/service/AccountSupport/AccountSupport";
import NotFount from "@/views/business/defaultPage/NotFount";
import NetworkAnomaly from "@/views/business/defaultPage/NetworkAnomaly";
import NotOpen from "@/views/business/defaultPage/NotOpen";
import SearchNone from "@/views/business/defaultPage/SearchNone";
import logincopy from "@/views/Login/logincopy";
import Retailer from "@/views/business/Retailer/index";

import { getToken, setToken, removeToken } from "@/utils/auth";
import store from "../store/index";
import FreeSoft from "@/views/business/products/FreeSoft.vue";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const routePC = [
  {
    path: "/",
    name: "Layout",
    redirect: "Home",
    component: Layout,
    children: [
      {
        path: "Home",
        name: "首页",
        component: Home,
      },
      {
        path: "AboutUs",
        name: "关于我们",
        component: AboutUs,
      },
      {
        path: "/login",
        name: "Login",
        component: login,
      },
      {
        path: "/loginRetailer",
        name: "loginRetailer",
        component: loginRetailer,
      },
      {
        path: "/logincopy",
        name: "logincopy",
        component: logincopy,
      },
      {
        path: "/paystep",
        name: "支付流程",
        component: paySteps,
      },
      {
        path: "/Buy",
        name: "订阅购买",
        component: Buy,
      },
      {
        path: "/BuyMenu",
        name: "套餐选择",
        component: BuyMenu,
      },
      {
        path: "/cart",
        name: "购物车",
        component: Cart,
      },
      {
        path: "/AllProducts",
        name: "全部产品",
        component: AllProducts,
      },
      {
        path: "/FreeSoft",
        name: "免费软件",
        component: FreeSoft,
      },
      {
        path: "/Productsintro",
        name: "产品介绍",
        component: Productsintro,
      },
      {
        path: "/Service",
        name: "服务与支持",
        component: Service,
      },
      {
        path: "/Communicate",
        name: "问题交流",
        component: Communicate,
      },
      {
        path: "/addQuestionDetail",
        component: () =>
          import("@/views/business/service/Communicate/addQuestionDetail.vue"),
      },
      {
        path: "/AddForumPost",
        component: () =>
          import("@/views/business/service/Communicate/AddForum.vue"),
      },
      {
        path: "/ContactUs",
        name: "联系我们",
        component: ContactUs,
      },
      {
        path: "/Retailer",
        name: "分销商",
        component: Retailer,
      },
      {
        path: "/PersonalCenter",
        name: "我的中心",
        component: PersonalCenter,
      },
      {
        path: "/SoftwareSupportDetail",
        name: "软件支持详情",
        component: SoftwareSupportDetail,
      },
      {
        path: "/SoftwareSupport",
        name: "软件支持",
        component: SoftwareSupport,
      },
      {
        path: "/AccountSupportDetail",
        name: "账号支持详情",
        component: AccountSupportDetail,
      },
      {
        path: "/AccountSupportContent",
        name: "账号支持详情内容",
        component: AccountSupportContent,
      },
      {
        path: "/AccountSupport",
        name: "账号支持",
        component: AccountSupport,
      },
      {
        path: "/404",
        name: "404",
        component: NotFount,
      },
      {
        path: "/NetworkAnomaly",
        name: "网络异常",
        component: NetworkAnomaly,
      },
      {
        path: "/NotOpen",
        name: "功能未开放",
        component: NotOpen,
      },
      {
        path: "/SearchNone",
        name: "未搜到结果",
        component: SearchNone,
      },
    ],
  },
];
const routerM = [
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "Home",
    name: "首页",
    redirect: "/",
  },
  {
    path: "AboutUs",
    name: "关于我们",
    redirect: "/",
  },
  {
    path: "/login",
    name: "Login",
    redirect: "/",
  },
  {
    path: "/paystep",
    name: "支付流程",
    redirect: "/",
  },
  {
    path: "/Buy",
    name: "订阅购买",
    redirect: "/",
  },
  {
    path: "/BuyMenu",
    name: "套餐选择",
    redirect: "/",
  },
  {
    path: "/cart",
    name: "购物车",
    redirect: "/",
  },
  {
    path: "/AllProducts",
    name: "全部产品",
    redirect: "/",
  },
  {
    path: "/Service",
    name: "服务与支持",
    redirect: "/",
  },
  {
    path: "/Communicate",
    name: "问题交流",
    redirect: "/",
  },
  // 订阅版 线上
  // {
  //   path: "/",
  //   name: "HomeM",
  //   component: () => import("@/views/Home/HomeM.vue"),
  // },
  // {
  //   path: "/ApplyList",
  //   name: "ApplyList",
  //   component: () => import("@/views/business/mobileLicense/ApplyList.vue"),
  // },
  // {
  //   path: "/ApplyHome",
  //   name: "ApplyHome",
  //   component: () => import("@/views/business/mobileLicense/ApplyHome.vue"),
  // },

  // 申请版 线下/试用
  // {
  //   path: "/apply",
  //   name: "apply",
  //   component: () => import("@/views/Home/ApplyM.vue"),
  // },
  // {
  //   path: "/applylistm",
  //   name: "applylistm",
  //   component: () => import("@/views/Home/ApplyListM.vue"),
  // },
  // 线下/试用
  {
    path: "/apply",
    name: "apply",
    component: () => import("@/views/Home/ApplyM.vue"),
  },
  {
    path: "/applylistm",
    name: "applylistm",
    component: () => import("@/views/Home/ApplyListM.vue"),
  },
  {
    path: "/",
    name: "HomeM",
    component: () => import("@/views/Home/ApplyM.vue"),
  },
  // 线下/试用 end

  // 订阅版
  {
    path: "/create",
    name: "HomeM",
    component: () => import("@/views/Home/HomeM.vue"),
  },
  {
    path: "/applyTrial",
    name: "HomeM",
    component: () => import("@/views/Home/HomeM.vue"),
  },
  {
    path: "/ApplyList",
    name: "ApplyList",
    component: () => import("@/views/business/mobileLicense/ApplyList.vue"),
  },
  {
    path: "/ApplyHome",
    name: "ApplyHome",
    component: () => import("@/views/business/mobileLicense/ApplyHome.vue"),
  },
  // 订阅版 end
];
var routes = [];

let str = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);

// console.log(window.location.href.split('=')[1].split('&'))
if (str) {
  if (window.location.href.indexOf("id") != -1) {
    routes = routerM;
    console.log("许可");
  } else {
    routes = routerM;
    // routes = routerT;
    console.log("移动端提示");
  }
} else {
  routes = routePC;
  console.log("电脑端");
}

const createRouter = () =>
  new VueRouter({
    mode: "history", // require service support
    base: "/pc",
    routes,
  });
// 解决重复点击相同路径报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

// 获取用户信息缓存
Storage.prototype.getExpire = (key) => {
  let val = localStorage.getItem(key);
  if (!val) {
    return val;
  }
  val = JSON.parse(val);
  if (Date.now() - val.time > val.expire) {
    localStorage.removeItem(key);
    return null;
  }
  return val.data;
};

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
const router = createRouter();
router.beforeEach((to, from, next) => {
  //   console.log(str == null && to.path != "/login", "to");
  //   if (
  //     to.path != "/login" &&
  //     JSON.parse(localStorage.getItem("token"))
  //   ) {
  //     let phoneM = JSON.parse(localStorage.getItem("token"));
  //     let obj = {
  //       phone: phoneM.data,
  //     };
  //     if (to.path.includes("home")) {
  //       obj.home = 1;
  //     } else if (to.path.includes("summit")) {
  //       obj.summit = 1;
  //     } else if (to.path.includes("downloads")) {
  //       obj.downloads = 1;
  //     } else if (to.path.includes("buy")) {
  //       obj.purchase = 1;
  //     } else if (to.path.includes("user")) {
  //       obj.user = 1;
  //     } else if (to.path.includes("Member")) {
  //       obj.userMember = 1;
  //     } else if (to.path.includes("about")) {
  //       obj.about = 1;
  //     }
  //     AddLoginPageView(obj);
  // }
  // if (!localStorage.getExpire("token")) {
  //   if (to.path !== "/login") {
  //     return next("/login");
  //   }
  // }
  if (to.query) {
    if (to.query.out_trade_no) {
      //  this.$route.query.out_trade_no  //获取订单编号
      // 1.支付成功之后，判断现在的url地址是否含有订单号   ==>如果有的话跳转到到订单页面  ==>没有的话还是直接获取到登陆页面
      // 2.跳转到订单页面之前，需要cookie的获取
      // 3.通过订单号 调用订单号查询接口进行查询，重新刷新这个订单数据
      let out_trade_no = to.query.out_trade_no;
      const str = {
        OutTradeNo: out_trade_no,
        phone: localStorage.getExpire("token"),
      };
      PayFun(str).then((res) => {
        if (res.data.code === 200) {
          return next("/user/orderManagement");
        }
      });
    }
  }
  next();
});

export default router;
