<template>
    <div class="block_center">
        <div class="banner">
            <div class="usTitle">
                <div class="titleText">产品介绍</div>
                <div class="titleDes">PRODUCT INTRODUCTION</div>
            </div>
            <div :class="isSticky ? 'stickynav' : 'project'">
                <div class="productTypes">
                    <div class="productTypes_div">
                        <div v-for="(item, index) in typeList" :key="index" class="typeItem"
                            @click="changeProject(item.name)" :class="{ 'color1': item.select }">
                            <div class="productImg">
                                <img v-if="!item.select"
                                    :src="require('../../../assets/image/productIcon' + (index + 1) + '-2.png')" alt="">
                                <img v-else
                                    :src="require('../../../assets/image/productIcon' + (index + 1) + '-2select.png')"
                                    alt="">
                            </div>
                            <div class="productText">
                                <div class="desItem">{{ item.name }} {{ item.des }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="introduction" ref="myElement" id="introductionP">
            <div class="mianCenter">
                <div class="introContent">
                    <div class="leftIntro leftBase">
                        <div class="introTitle">{{ introTitle }}</div>
                        <div class="introDes">{{ introDes }}</div>
                        <div class="introTag">
                            <span>{{ introTag }}</span>
                        </div>
                        <div class="introTextContent" v-html="introTextContent"></div>
                    </div>
                    <div class="rightBase">
                        <VideoPlayer :videosrc="videoSrc" :autoPlay="autoPlay"></VideoPlayer>
                    </div>
                </div>
            </div>
            <ProjectSummitMap v-if="SummitMapFlag"></ProjectSummitMap>
            <ProjectSummitSoild v-if="SummitSoildFlag"></ProjectSummitSoild>
            <ProjectSummitLab v-if="SummitLabFlag"></ProjectSummitLab>
        </div>
    </div>
</template>

<script>
import ProjectSummitMap from '@/views/business/products/ProjectSummitMap';
import ProjectSummitSoild from '@/views/business/products/ProjectSummitSoild';
import ProjectSummitLab from '@/views/business/products/ProjectSummitLab';
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
import { getAllProduction, getSoftDownloadList } from '@/assets/API/pay';
export default {
    components: {
        ProjectSummitMap,
        ProjectSummitSoild,
        ProjectSummitLab, VideoPlayer
    },
    data() {
        return {
            isSticky: false,
            stickyOffset: 0,
            typeList: [
                {
                    des: '一站式智能成图平台',
                    name: "SummitMap",
                    select: true
                },
                {
                    des: '一站式单体化系统',
                    name: "SummitSolid",
                    select: false
                },
                {
                    des: '智能化内业培训系统',
                    name: "SummitLab",
                    select: false
                },
            ],
            introTitle: "SummitMap",
            introDes: "一站式智能成图平台",
            introTag: "开创新型空间数据生产工厂化时代",
            introTextContent: "SummitMap一站式智能成图平台（简称：峰岭成图平台）由峰岭科技自主研发，是一套集成多模式测图功能，真正实现了内外业一体、采编一体、图库一体的平台型矢量成图系统，具备数据多源化、采集自动化、要素全息化、表达实体化、生产协作工厂化等一系列核心优势，能够全面满足新型基础测绘、房地一体、城市勘测设计、高精度地图制作、城市道路移动测量等多种应用场景下的矢量成图需求。",
            img: require('../../../assets/image/projectIntroduction_img.png'),
            SummitMapFlag: true,
            SummitSoildFlag: false,
            SummitLabFlag: false,
            softList: [
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                },
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                },
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                },
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                }
            ],
            loopSoftList: [],
            softDownList: [],
            autoPlay: false,
            videoSrc: '',

        };
    },
    mounted() {
        const { name } = this.$route.query;
        if (name == "SummitMap") {
            this.changeProject("SummitMap", 'first');
        } else if (name == "SummitSolid") {
            this.changeProject("SummitSolid", 'first');
        } else if (name == "SummitLab") {
            this.changeProject("SummitLab", 'first');
        } else {
            this.changeProject("SummitMap", 'first');
        }
        window.addEventListener('scroll', this.handleScroll);

        // 获取软件下载地址
        let query = {
            superQueryJson: "",
            currentPage: 1,
            pageSize: 20,
            sort: "desc",
            sidx: "",
            type: 0
        };
        getSoftDownloadList(query).then(res => {
            this.softDownList = res.data.list;
            let data = this.softDownList.filter(v => v.videosrc != null);
            if (data.length == 1) {
                this.installData = data[0];
            }

        });
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                console.log(newVal, 'iiiiii');
                switch (true) {
                    case ["/AllProducts"].indexOf(newVal.path) != -1:
                        if (newVal.query.name == "SummitMAP") {
                            this.changeProject("SummitMap", 'first');
                        } else if (newVal.query.name == "SummitSOLID") {
                            this.changeProject("SummitSolid", 'first');
                        } else if (newVal.query.name == "SummitLab") {
                            this.changeProject("SummitLab", 'first');
                        } else {
                            this.changeProject("SummitMap", 'first');
                        }
                        break;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        getProduct(type) {
            let data = {
                typename: type,
                saletype: "online",
                enabledmark: 1
            };
            getAllProduction(data).then((res) => {
                this.softList = res.data.list;
                this.softListNum = Number(this.softList.length % 4);
                if (this.softList.length % 4 == 0) {
                    this.softListNum = Number(this.softList.length / 4);
                } else {
                    this.softListNum = parseInt(this.softList.length / 4) + 1;
                }
                this.loopSoftList = this.softList.slice(0, 4);
            });
        },
        handleScroll() {
            // this.isSticky = window.scrollY > this.stickyOffset;
            let element = this.$refs.myElement;
            let rect = element.getBoundingClientRect();
            this.stickyOffset = rect.top;
            // console.log('Element distance from top:', this.stickyOffset);
            if (this.stickyOffset < 124) {
                this.isSticky = true;
            } else if (this.stickyOffset > 124) {
                this.isSticky = false;
            }
        },
        changeProject(value, type) {
            console.log("value", value);
            this.getProduct(value);
            this.typeList.forEach(item => {
                if (item.name != value) {
                    item.select = false;
                } else {
                    item.select = true;
                }
            });
            if (value == "SummitMap") {
                this.SummitMapFlag = true;
                this.SummitSoildFlag = false;
                this.SummitLabFlag = false;
                this.introTitle = "SummitMap";
                this.introDes = "一站式智能成图平台";
                this.introTag = "开创新型空间数据生产工厂化时代";
                this.introTextContent = "SummitMap一站式智能成图平台（简称：峰岭成图平台）由峰岭科技自主研发，是一套集成多模式测图功能，真正实现了内外业一体、采编一体、图库一体的平台型矢量成图系统，具备数据多源化、采集自动化、要素全息化、表达实体化、生产协作工厂化等一系列核心优势，能够全面满足新型基础测绘、房地一体、城市勘测设计、高精度地图制作、城市道路移动测量等多种应用场景下的矢量成图需求。";
                this.img = require('../../../assets/image/projectIntroduction_img.png');
                this.videoSrc = "https://www.yesmap.xyz/api/videos/GetMate?videoId=cbb16297-87a3-4bc0-827b-ae19de5c83b5";
            } else if (value == "SummitSolid") {
                this.SummitMapFlag = false;
                this.SummitSoildFlag = true;
                this.SummitLabFlag = false;
                this.introTitle = "SummitSolid";
                this.introDes = "一站式单体化系统";
                this.introTag = "快、易、准！自动化构建多级复杂单体";
                this.introTextContent = "SummitSolid一站式单体化系统（简称：峰岭单体化系统）是一款以真三维几何引擎为核心，专注实景三维模型单体化处理和模型修编的软件产品，不仅能够支持空天地多源数据，更吸收了多款建模软件的优点，集直观三维建模单体化与快速航测法单体化为一体，具备高精度、智能化、功能实用、操作便捷、扩展性强的优势。";
                this.videoSrc = "https://www.yesmap.xyz/api/videos/GetMate?videoId=9283a75f-41a5-4613-83ee-bf153d321dd8";
                this.img = require('../../../assets/image/projectIntroduction_img2.png');
            } else if (value == "SummitLab") {
                this.SummitMapFlag = false;
                this.SummitSoildFlag = false;
                this.SummitLabFlag = true;
                this.introTitle = "SummitLab";
                this.introDes = "智能化内业培训系统";
                this.introTag = "打造航测内业教学新模式";
                this.introTextContent = "SummitLab智能化内业培训系统（简称：峰岭教培系统）是峰岭科技专为航测内业教学量身定制的一款集在线学习、仿真实践、课后考核等功能于一体的定量化交互培训软件。系统内嵌SummitMap一站式智能成图平台、SummitSolid一站式单体化系统两款作业软件，内建海量真实案例，以练代学让学员快速掌握软件功能、项目生产作业流程与关键要点。<br>系统改变了航测内业教学传统的教师口授模式，通过沉浸式自助培训，充分调动学员的学习潜力。经实测，系统可将传统内业培训4~6个月的上图周期压缩到2个月以内，在提升100%培训效率的同时节约80%培训成本。";
                this.videoSrc = "https://www.yesmap.xyz/api/videos/GetMate?videoId=a0605144-7a34-4d89-8ab6-3a4c40b60ded";
                this.img = require('../../../assets/image/projectIntroduction_img3.png');
            }
            this.$bus.$emit('reloadVideo', this.videoSrc, this.autoPlay);

            // 定位到最上面
            if (type != 'first') {
                var top = document.querySelector('#introductionP').offsetTop;
                window.scrollTo({
                    top: top,
                    behavior: 'smooth'
                });
            }

        },
        toDownload() {
            let result;
            if (this.SummitLabFlag) {
                // 下载lab软件
                let data = this.softDownList.filter(v => v.descrh.includes("SummitLab"));
                if (data.length == 1) {
                    result = data[0];
                }
            }
            if (this.SummitSoildFlag) {
                // 下载solid软件
                let data = this.softDownList.filter(v => v.descrh.includes("SummitSolid"));
                if (data.length == 1) {
                    result = data[0];
                }
            }
            if (this.SummitMapFlag) {
                // 下载map软件
                let data = this.softDownList.filter(v => v.descrh.includes("SummitMap"));
                if (data.length == 1) {
                    result = data[0];
                }
            }
            if (result) {
                let url = result.f + "&pwd=" + result.m;
                window.open(url, '_blank');
            } else {
                this.$message.error("未查找到该软件");
            }
        },
        getProduct(type) {
            let data = {
                typename: type,
                saletype: "online",
                enabledmark: 1
            };
            getAllProduction(data).then((res) => {
                this.softList = res.data.list;
                this.loopSoftList = this.softList.slice(0, 4);
            });
        },
        toBuy(id) {
            this.$router.push({
                path: "/BuyMenu",
                query: {
                    id
                }
            });
        },
        toSubscribeBuy() {
            let value = "";
            if (this.SummitMapFlag) {
                value = "SummitMap";
            }
            if (this.SummitSoildFlag) {
                value = "SummitSolid";
            }
            if (this.SummitLabFlag) {
                value = "SummitLab";
            }
            this.$router.push({ name: "订阅购买", path: "/Buy", query: { value } });
            this.$bus.$emit('reloadactivePath', "/Buy");
        },
        toFree() {
            // 跳转到免费试用页
            let value = "free";
            this.$router.push({ name: "订阅购买", path: "/Buy", query: { value } });
            this.$bus.$emit('reloadactivePath', "/Buy");
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>

<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;

    .banner {
        // background-image: url("../../../assets/image/project_banner.png");
        // height: 442px;
        background-image: url("../../../assets/image/allProduct.png");
        height: 593px;
        background-size: 100% 100%;
        position: relative;
        display: flex;
        align-items: flex-end;

        .usTitle {
            padding-bottom: 265px;
            padding-left: 350px;

            .titleText {
                font-size: 48px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 69.5px;
                color: rgba(255, 255, 255, 1);
                vertical-align: top;
            }

            .titleDes {
                font-size: 40px;
                font-weight: 200;
                letter-spacing: 0px;
                line-height: 57.92px;
                color: rgba(35, 173, 222, 1);
                text-align: left;
                vertical-align: top;
            }
        }
    }

    .project {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        // bottom: 0;
        bottom: -80px;
    }

    .stickynav {
        width: 100%;
        display: flex;
        justify-content: center;
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: 10;
        // text-shadow: 0px 2px 4px rgba(30, 102, 184, 0.5);
        background-color: rgba(255, 255, 255, 1);
    }

    // .productTypes {
    //     width: 1300px;
    //     margin: 0 auto;
    //     display: flex;
    //     font-size: 20px;
    //     justify-content: space-between;

    //     .productTypes_div {
    //         width: 65%;
    //         display: flex;
    //         justify-content: space-between;
    //     }

    //     .typeItem {
    //         width: 25%;
    //         display: flex;
    //         flex-direction: column;
    //         text-align: center;
    //         color: #fff;
    //         cursor: pointer;

    //         .desItem {
    //             font-size: 20px;
    //             font-weight: 400;
    //             line-height: 28.96px;
    //             text-align: center;
    //             vertical-align: top;
    //         }

    //         .textItem {
    //             font-weight: 500;
    //             font-size: 16px;
    //             line-height: 23.17px;
    //             text-align: center;
    //             vertical-align: top;
    //             margin-bottom: 8px;
    //         }

    //         .line_span {
    //             display: flex;
    //             justify-content: center;
    //         }

    //         .line {
    //             width: 128px;
    //             height: 3px;
    //             opacity: 1;
    //             background: rgba(42, 130, 228, 1);
    //         }

    //         .line:hover {
    //             display: block;
    //         }
    //     }

    //     .color1 {
    //         color: rgba(42, 130, 228, 1);
    //     }

    //     .typeItem:hover {
    //         color: rgba(42, 130, 228, 1);
    //     }

    //     .download {
    //         display: flex;
    //         align-items: center;
    //     }

    // }

    .productTypes {
        width: 1400px;
        display: flex;
        justify-content: center;
        // background-color: #e9ccaf;
        position: relative;

        .productTypes_div {
            display: flex;
            height: 80px;

            .typeItem {
                width: 400px;
                padding: 17px 30px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20.27px;
                color: rgba(56, 56, 56, 1);
                box-sizing: border-box;
                cursor: pointer;
                text-align: left;
                vertical-align: top;
                background: rgba(233, 235, 240, 1);
                margin-left: 1px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .typeItem:nth-child(1) {
                margin-left: 0;
            }

            // .typeItem:nth-child(2) {
            //     background: rgba(4, 175, 184, 1);
            // }

            // .typeItem:nth-child(3) {
            //     background: rgba(3, 110, 183, 1);
            // }

            .color1 {
                font-size: 18px;
                background-color: rgba(43, 133, 229, 1);
                color: rgba(255, 255, 255, 1);
                // font-weight: 400;
                // line-height: 37.65px;
                // padding: 17px 30px;
            }

            .productImg {
                margin-right: 10px;

                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        .download {
            position: absolute;
            top: 100px;
            right: 0;
            z-index: 5;

            div {
                width: 50px;
                height: 50px;
                border-radius: 8px;
                background: rgba(228, 235, 240, 1);
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            div:hover {
                background: rgba(43, 133, 229, 1);
                color: rgba(255, 255, 255, 1);
            }

            // ::v-deep .el-button{
            //     border: none;
            //     background: rgba(228, 235, 240, 1);
            //     font-size:14px;
            // }
        }

    }

    .introduction {
        // background-image: url("../../../assets/image/productBg.png");
        // background-size: 100%;
        // background-repeat: no-repeat;

        .intro {
            // padding: 80px 50px;
            // padding-bottom: 50px;
            text-align: center;
            display: flex;
            flex-direction: column;

            .introDes {
                font-size: 30px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 43.44px;
                color: rgba(204, 204, 204, 1);
                text-align: center;
                vertical-align: top;
            }

            .introText {
                font-size: 40px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 57.92px;
                color: rgba(56, 56, 56, 1);
                text-align: center;
                vertical-align: top;
            }

            // .introText::after {
            //     position: absolute;
            //     bottom: -16px;
            //     left: 0;
            //     right: 0;
            //     height: 5px;
            //     background-color: #2A82E4;
            //     content: "";
            //     transform: scaleY(.5);
            // }
        }

        .introContent {
            // padding: 0 50px;

            display: flex;

            .leftIntro {
                width: 48%;
                padding-right: 20px;

                .introTitle {
                    font-size: 48px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 69.5px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .introDes {
                    font-size: 48px;
                    font-weight: 300;
                    letter-spacing: 0px;
                    line-height: 69.5px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;

                }

                .introTag {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 26.06px;
                    color: rgba(255, 255, 255, 1);
                    text-align: justify;
                    vertical-align: top;
                    padding-bottom: 35px;
                    padding-top: 20px;
                    border-bottom: 1px solid rgba(128, 128, 128, 1);

                    span {
                        background: rgba(43, 133, 229, 1);
                        border-radius: 30px;
                        padding: 5px 20px;

                    }
                }

                .introTextContent {
                    // font-size: 16px;
                    // font-weight: 400;
                    // line-height: 36px;
                    // color: rgba(56, 56, 56, 1);
                    // text-align: left;
                    padding-top: 35px;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 36px;
                    color: rgba(56, 56, 56, 1);
                    text-align: justify;
                    vertical-align: top;

                }
            }

            .rightBase {
                display: flex;
                align-items: center;
                flex: 1;
            }

            .rightBase img {
                width: 650px;
                height: 462px;
                margin-left: 42px;
            }

        }

        .productContent {
            display: flex;
            justify-content: center;
            padding: 40px 0;
            // height: 735px;
            width: 1200px;
            height: 550px;

            .product {
                width: 22%;
                // padding: 10px 0 5px;
                // margin-top: 30px;
                // height: 100%;
                // margin-right: 48px;
                opacity: 1;
                border-radius: 14px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 3px 30px 33px rgba(111, 118, 138, 0.16);
                color: rgba(56, 56, 56, 1);
                overflow: hidden;
                transition: transform 0.3s;

                .itemContent {

                    .stayContent {
                        padding-top: 30px;
                        display: flex;
                        justify-content: center;

                        .stayTuned {
                            border-radius: 50%;
                            background-color: rgba(43, 133, 229, 1);
                            width: 125px;
                            height: 125px;
                            font-size: 36px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 52.13px;
                            color: rgba(255, 255, 255, 1);
                            text-align: center;
                            vertical-align: top;
                            padding: 12px;

                            .firstSpan {
                                border-bottom: 1px solid #fff;
                            }
                        }
                    }

                    .typeText {
                        height: 72px;
                        opacity: 1;
                        background: rgba(43, 133, 229, 1);
                        box-shadow: 3px 30px 33px rgba(111, 118, 138, 0.16);
                        // font-size: 24px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        // line-height: 72px;
                        color: rgba(255, 255, 255, 1);
                        text-align: center;
                        vertical-align: top;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .price {
                        display: flex;
                        align-items: baseline;
                        justify-content: center;
                        padding: 30px 0;

                        .priceValue {
                            font-size: 32px;
                            font-weight: 700;
                            letter-spacing: -1.58px;
                            line-height: 46.34px;
                            color: rgba(13, 21, 46, 1);
                            margin-right: 10px;
                        }

                        .priceType {
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: -0.53px;
                            line-height: 23.17px;
                            color: rgba(56, 56, 56, 1);
                        }
                    }

                    .buyBtn {
                        margin: 0 10px;
                        width: 90%;
                        background-color: rgba(65, 111, 244, 1);
                    }

                    .features {
                        margin-top: 45px;

                        .download {
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 24px;
                            text-decoration-line: underline;
                            color: rgba(56, 56, 56, 1);
                            text-align: center;
                            vertical-align: top;

                        }

                        .baseContent {
                            font-size: 12px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 24px;
                            color: rgba(56, 56, 56, 1);
                            text-align: center;
                            vertical-align: top;
                            margin-top: 10px;

                            ul li {
                                text-align: left;
                                position: relative;
                                padding-left: 22px;
                            }

                            ul li::after {
                                position: absolute;
                                left: 10px;
                                right: 0;
                                top: 10px;
                                height: 5px;
                                width: 5px;
                                background-color: #2A82E4;
                                border-radius: 50%;
                                content: "";
                            }

                        }
                    }
                }
            }

            .product:hover {
                transform: scale(1.025);

                .itemContent {
                    .typeText {
                        opacity: 1;
                        background: rgba(255, 141, 26, 1);
                    }

                    .stayContent .stayTuned {
                        background-color: rgba(255, 141, 26, 1);
                    }
                }

                .buyBtn {
                    background-color: #ff8d1a;
                    border-color: #ff8d1a;
                }
            }
        }


        .select {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 23.17px;
            color: rgba(128, 128, 128, 1);
            text-align: center;
            vertical-align: top;
            padding-bottom: 50px;
            cursor: pointer;
        }

        .userComments {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            margin-bottom: 200px;

            .commentTitle {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20px;
                color: rgba(0, 115, 211, 1);
                text-align: left;
                vertical-align: top;
            }

            .commentItem {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid rgb(215, 217, 224);
                margin-bottom: 20px;

                img {
                    width: 350px;
                }

                .commentText {
                    margin-left: 30px;

                    .companyName {
                        font-size: 18px;
                        font-weight: 700;
                        letter-spacing: 0px;
                        line-height: 26.06px;
                        color: rgba(51, 51, 51, 1);
                        text-align: left;
                        vertical-align: top;
                        margin-bottom: 10px;
                    }

                    .commentContent {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 23.17px;
                        color: rgba(51, 51, 51, 1);
                        text-align: left;
                        vertical-align: top;

                    }
                }
            }

            .commentItem:last-child {
                border: none;
            }

        }
    }


    .mianCenter {
        width: 1200px;
        margin: 0 auto;
        padding-top: 230px;
        padding-bottom: 200px;
    }

    .mianCenter1 {
        width: 1200px;
        margin: 0 auto;
    }


}

.recommend {
    background-image: url(../../../assets/image/productBg3.png);
    background-size: 100% 100%;
}

.recommend1 {
    background-image: url(../../../assets/image/productBg4.png);
    background-size: 100% 100%;

    .intro {
        .introDes {
            color: rgba(17, 63, 111, 1) !important;
        }

        .introText {
            color: rgba(255, 255, 255, 1) !important;
        }
    }

}
</style>