<template>
    <div class="ContractManagement">
        <Search @search="search" :searchContent="searchContent"></Search>
        <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark"
            element-loading-text="Loading" fit :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column prop="contractnum_name" label="合同编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name_name" label="合同名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="contractmoney_name" label="合同总金额" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="starttime_name" label="合同开始时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="endtime_name" label="合同结束时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" fixed="right" width="100">
                <template slot-scope="scope">
                    <el-button type="text" @click="goDetail(scope.row.id)">详情</el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="product" label="关联订单" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="number" label="产品名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="buyWay" label="创建名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="duration" label="合同内容" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="物流订单号" align="center" show-overflow-tooltip></el-table-column> -->
        </el-table>
        <el-pagination :total="total" :page.sync="listQuery.currentPage" :limit.sync="listQuery.pageSize"
            @pagination="getInit" />
        <ContractDetail ref="ContractDetail" v-if="detailVisible" @refresh="detailVisible = false"></ContractDetail>
    </div>
</template>

<script>
import Search from './Search.vue'
import ContractDetail from './ContractDetail.vue'
import request from '@/utils/request'
import { mapState, mapMutations } from "vuex";
export default {
    components: {
        Search, ContractDetail
    },
    data() {
        return {
            tableData: [
                {
                    contractnum_name:"de"
                },
                {
                    contractnum_name:"de"
                }
            ],
            loading: false,
            listQuery: {
                superQueryJson: '',
                currentPage: 1,
                pageSize: 20,
                sort: "desc",
                sidx: "",
            },
            total: 0,
            query: {
                purchaserid: '',// 用户id
            },
            searchContent: "输入合同编号",
            detailVisible: false,
        };
    },
    computed: {
        ...mapState("user", ["userInfo"]),
    },
    mounted() {
        this.query.purchaserid = this.userInfo.id;
        this.getInit()
    },
    watch: {

    },
    methods: {
        // 数据初始化
        getInit() {
            this.loading = true;
            if (this.query.purchaserid) {
                let _query = {
                    ...this.listQuery,
                    ...this.query
                };
                request({
                    url: `/api/business/C_contracts/getList`,
                    method: 'post',
                    data: _query
                }).then(res => {
                    if (res.code == 200) {
                        this.loading = false;
                        this.tableData = res.data.list
                        this.total = res.data.pagination.total
                    }
                })
            }
        },
        goDetail(id) {
            this.detailVisible = true;
            this.$nextTick(() => {
                this.$refs.ContractDetail.init(id);
            });
        },
        search(val) {
            console.log("val", val);
            this.query.contractnum = val
            this.getInit()
        },
    }

}
</script>
<style scoped lang="scss" scoped>
.ContractManagement {
    height: 100vh;
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 32px;

    // table
    ::v-deep .el-table {
        height: calc(100% - 200px) !important;
    }

    :deep .el-table__header {
        border-radius: 20px 20px, 0px, 0px;
        background-color: rgba(245, 245, 245, 1);
    }

    :deep .el-table th.el-table__cell {
        background-color: rgba(245, 245, 245, 1);
        border-radius: 20px;
        border: none;
    }

    :deep .el-table__body tr:hover>td {
        background-color: rgba(245, 245, 245, 1) !important;
    }

    :deep .el-table tr {
        height: 46px;
    }

    :deep .el-table__body-wrapper {
        height: calc(100% - 47px);
        overflow-y: auto;
    }

    :deep .el-table__empty-block {
        min-height: 50px;
    }

    :deep .el-table th.el-table__cell>.cell {
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        color: rgba(128, 128, 128, 0.7);
    }

    ::v-deep .el-pagination {
        padding-top: 20px;
    }
}
</style>