<template>
    <div class="payCenter">
        <div class="payCart">
            <div class="crumbs">
                <span>订阅</span>
                <img src="../../../assets/image/solidArrow.png" alt="">
                <span>购物车</span>
            </div>
            <div class="orderList">
                <el-table :data="tableData" style="width: 100%" fit v-loading="loading" :row-key="getRowKey"
                    @selection-change="handleSelectionChange" empty-text="— 暂无产品 —">
                    <el-table-column type="selection" align="center" :reserve-selection="true" width="55"></el-table-column>
                    <el-table-column prop="img" width="120" align="center">
                        <template slot-scope="scope">
                            <img src="../../../assets/image/summitmapLogo.png" alt="" v-if="scope.row.producttype == 'SummitMap'">
                            <img src="../../../assets/image/summitmapLogo.png" alt="" v-if="scope.row.producttype == 'SummitMapHUBEI'">
                            <img src="../../../assets/image/summitsolidLogo.png" alt="" v-if="scope.row.producttype == 'SummitSolid'">
                            <img src="../../../assets/image/summitlabLogo.png" alt="" v-if="scope.row.producttype == 'SummitLab'">
                        </template>
                    </el-table-column>
                    <el-table-column prop="detailedlist" label="商品信息">
                        <template slot-scope="scope">
                            <p class="packageName">{{ scope.row.producttype }}</p>
                            <p>{{ scope.row.productname }}*{{ scope.row.duration }}个{{ scope.row.unit }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="单价" align="center" width="200">
                        <template slot-scope="scope">
                            <p>{{ scope.row.price }}元/{{ scope.row.unit }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="订阅周期" align="center" width="100">
                        <template slot-scope="scope">
                            <p>{{ scope.row.duration }}{{ scope.row.unit }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="number" label="数量" align="center" width="200">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.number" @change="handleChange(scope.row)" :min="1"
                                label="描述文字"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="金额" width="200">
                        <template slot-scope="scope">
                            <p>{{ scope.row.price * scope.row.number * scope.row.duration | numFilter }}元</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="img" width="50" align="center" class-name="delete_img">
                        <template slot-scope="scope">
                            <div @click="pDelete(scope.row)">
                                <img src="../../../assets/image/Cross.png" alt="">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="countOrderTotal">
                <!-- <div class="code">
                    <div class="invitationCode">
                        <el-input v-model="invitationCode" placeholder="邀请码"></el-input>
                        <el-button type="info">添加</el-button>
                    </div>
                    <div class="prompt">已添加邀请码：g'g'g'g</div>
                </div> -->
                <div class="productsTotal">已选商品<span>{{ choosedItem }}</span>件</div>
                <div class="orderTotal">
                    <div class="subtotal">
                        <span>小计</span>
                        <span>￥{{ subtotalMoney | numFilter }}</span>
                    </div>
                    <div class="preferential">
                        <span>优惠</span>
                        <span><span class="minus">-</span>￥{{ preferentialMoney | numFilter }}</span>
                    </div>
                    <div class="totalAmount">
                        <span>总金额</span>
                        <span class="money">￥{{ totalMoney | numFilter }}</span>
                    </div>
                    <el-button type="primary" @click="toPay" :disabled="totalMoney <= 0">结账</el-button>
                    <div class="assist">需要协助？请联系17786083608</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getCartList, addCart, deleteCartProjrct
} from "@/assets/API/pay";
import { mapState, mapMutations } from "vuex";
import request from '@/utils/request';
export default {
    components: {
    },
    computed: {
        ...mapState("user", ["cartListCount"]),
    },
    data() {
        return {
            tableData: [],
            loading: false,
            multipleSelection: [],
            choosedItem: 0,
            invitationCode: "",
            subtotalMoney: undefined,
            preferentialMoney: 5,
            totalMoney: undefined,
            userInfo: null,
            multipleSelectionIds: []
        };
    },
    filters: {
        numFilter(value) {
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2);
            return realVal;
        }
    },
    watch: {
    },
    mounted() {
        // 判断是否登录 未登录跳转登录页
        if (!this.$store.state.user.isLogin) {
            this.$router.replace({
                name: "Login",
                path: "/login",
            });
        }
        let userInfo = this.$store.state.user.userInfo;
        if (userInfo) {
            userInfo = JSON.parse(localStorage.getItem("USERINFO"));
        }
        this.userInfo = userInfo;
        console.log("this.userInfo", this.userInfo);
        this.getCartList();
    },
    methods: {
        getRowKey(row) {
            return row.id;
        },
        // 数据初始化,获取购物车list
        getCartList() {
            this.loading = true;
            let data = {};
            data.userid = this.userInfo.id;
            getCartList(data).then(res => {
                if (res.code == 200) {
                    let list = res.data.list;
                    if (list.length > 0) {
                        list.forEach(item => {
                            if (item.durationunion == "week") {
                                item.price = item.productmes.weekprice;
                                item.unit = "周";
                            } else if (item.durationunion == "month") {
                                item.price = item.productmes.monthprice;
                                item.unit = "月";
                            } else if (item.durationunion == "season") {
                                item.price = item.productmes.seasonprice;
                                item.unit = "季";
                            } else if (item.durationunion == "halfyear") {
                                item.price = item.productmes.halfyearprice;
                                item.unit = "半年";
                            }
                        });
                    }
                    this.tableData = list;
                    this.calculateTotalAmount();
                }
                this.loading = false;
            });
        },
        handleSelectionChange(val) {
            const res = val.map(item => item.id);
            this.multipleSelectionIds = res;
            let list = [];
            if (val.length > 0) {
                val.forEach(item => {
                    const index = this.tableData.findIndex(val => (val.id === item.id));
                    if (index >= 0) {
                        list.push(item);
                    }
                });
                this.multipleSelection = list;
            } else {
                this.multipleSelection = val;
            }
            this.calculateTotalAmount();
        },
        pDelete(row) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteCartProjrct(row.id).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getCartList();
                        const index = this.multipleSelection.findIndex(val => (val.id === row.id));
                        this.multipleSelection.splice(index, 1);
                        this.$store.dispatch("user/getCartListCount", { userid: this.userInfo.id });
                    }
                });
            }).catch(() => {

            });
        },
        handleChange(row) {
            let dataForm = {};
            dataForm.id = row.id;
            dataForm.userid = this.userInfo.id;
            dataForm.username = this.userInfo.username;
            dataForm.number = row.number;
            dataForm.duration = row.duration;
            dataForm.durationunion = row.durationunion;
            dataForm.productid = row.productid;
            dataForm.producttype = row.producttype;
            dataForm.productname = row.productname;
            addCart(dataForm, row.id).then((res) => {
                if (res.code == 200) {
                    this.calculateTotalAmount();
                    this.$store.dispatch("user/getCartListCount", { userid: this.userInfo.id });
                }
            });
        },
        // 计算总金额
        calculateTotalAmount() {
            let sum = 0;
            let choosedItem = 0;
            this.multipleSelection.forEach(item => {
                sum += item.price * item.number;
                choosedItem += item.number;
            });
            this.subtotalMoney = sum;
            this.choosedItem = choosedItem;
            if (this.subtotalMoney > 0) {
                this.preferentialMoney = 0;
                this.totalMoney = this.subtotalMoney - this.preferentialMoney;
            } else {
                this.totalMoney = this.subtotalMoney;
                this.preferentialMoney = 0;
            }
        },
        // 结账
        toPay() {
            if (this.multipleSelection.length == 0) {
                this.$message("请至少选择一项产品!");
            } else {
                const ids = this.multipleSelectionIds.join();
                request({
                    url: `/api/business/C_cart/batchRemove/${ids}`,
                    method: 'DELETE'
                }).then(res => {
                    let datas = this.multipleSelection.map(item => {
                        let obj = item.productmes;
                        obj.durationunion = item.durationunion;
                        obj.duration = item.duration;
                        obj.count = item.number;
                        obj.price = item.price;
                        return obj;
                    });
                    console.log("datas", datas);
                    this.$router.push({
                        name: "支付流程",
                        path: "/paystep",
                        query: {
                            data: datas,
                            source: "cart"
                        },
                    });
                    this.multipleSelectionIds.forEach(item => {
                        const index = this.multipleSelection.findIndex(val => (val.id === item));
                        this.multipleSelection.splice(index, 1);
                    });
                    this.$store.dispatch("user/getCartListCount", { userid: this.userInfo.id });
                });
            }
        },
    }
};
</script>

<style>
::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(203, 203, 203, 1);
    text-align: left;
    vertical-align: top;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(203, 203, 203, 1);
    text-align: left;
    vertical-align: top;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(203, 203, 203, 1);
    text-align: left;
    vertical-align: top;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(203, 203, 203, 1);
    text-align: left;
    vertical-align: top;
}
</style>

<style scoped lang="scss" scoped>
.payCenter {
    padding-top: 60px;

    .payCart {
        width: 1200px;
        margin: 0 auto;
    }

    .crumbs {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(135, 135, 134, 1);
        text-align: left;
        vertical-align: top;
        margin-bottom: 30px;

        img {
            height: 12px;
        }

        span {
            padding: 0 10px;
        }
    }

    .orderList {
        border-bottom: 1px solid #EBEEF5;
        margin-bottom: 20px;

        img {
            height: 60px;
        }

        .delete_img {
            img {
                height: 15px;
                cursor: pointer;
            }
        }

        // ::v-deep .el-table__body {
        //     border-bottom: 1px solid #EBEEF5;
        //     margin-bottom: 20px;
        // }

        ::v-deep .el-table tr {
            font-size: 14px;
            font-weight: 400;
            color: rgba(135, 135, 134, 1);
        }

        ::v-deep .el-table th.el-table__cell>.cell {
            font-size: 16px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
        }

        ::v-deep .el-table td.el-table__cell {
            border: none;
        }

        ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
            padding: 25px 0;
        }

        ::v-deep .el-input-number {
            width: 133px;
        }

        .packageName {
            font-size: 16px;
            font-weight: 400;
            color: rgba(28, 28, 27, 1);
        }

        ::v-deep .el-table__body-wrapper {
            min-height: 380px;
        }

        ::v-deep .el-table__empty-block {
            min-height: 120px;
        }

        ::v-deep .el-table__empty-text {
            font-size: 14px;
            font-weight: 400;
            color: rgba(166, 166, 166, 1);
        }
    }

    .countOrderTotal {
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;

        .code {
            width: 29%;

            .invitationCode {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;

                ::v-deep .el-input {
                    width: 228px;
                }

                ::v-deep .el-button {
                    padding: 12px 35px;
                }
            }

            .prompt {
                padding-left: 9px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 18px;
                color: rgba(212, 48, 48, 1);
                text-align: left;
                vertical-align: top;
            }
        }

        .productsTotal {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20px;
            color: rgba(56, 56, 56, 1);

            span {
                color: rgba(255, 141, 26, 1);
                margin: 0 5px;
            }
        }

        .orderTotal {
            width: 30%;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(56, 56, 56, 1);
            vertical-align: top;

            .subtotal {
                display: flex;
                justify-content: space-between;
                margin-bottom: 13px;
                padding: 8px 14px 0 10px;
            }

            .preferential {
                display: flex;
                justify-content: space-between;
                margin-bottom: 28px;
                padding: 0 14px 0 10px;

                .minus {
                    color: red;
                    font-weight: 700;
                    font-size: 18px;
                }
            }

            .totalAmount {
                display: flex;
                justify-content: space-between;
                padding: 31px 14px 29px 10px;
                border-top: 1px solid rgba(200, 200, 200, 1);
                color: rgba(40, 40, 40, 1);

                .money {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 31.86px;
                    color: rgba(56, 56, 56, 1);
                }
            }

            ::v-deep .el-button--primary {
                background-color: rgba(12, 111, 250, 1);
                font-size: 16px;
                width: 100%;
                margin-bottom: 12px;
            }

            ::v-deep .el-button--primary.is-disabled {
                background-color: #a0cfff;
            }

            .assist {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 1px;
                line-height: 17.38px;
                color: rgba(128, 128, 128, 1);
                text-align: center;
                vertical-align: top;
            }
        }
    }


}
</style>