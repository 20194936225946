<template>
    <div class="MemberCenter">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="增值服务" name="first">增值服务</el-tab-pane>
            <el-tab-pane label="教学视频" name="second">教学视频</el-tab-pane>
            <el-tab-pane label="软件更新" name="third">软件更新</el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: 'first',
        };
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }

}
</script>

<style scoped lang="scss" scoped>
.MemberCenter {
    padding-left: 35px;
    
    :deep .el-tabs__header {
        padding-top: 15px;
    }

    :deep .el-tabs__active-bar {
        border-radius: 8px;
        background-color: rgb(123, 177, 238);
    }

    :deep .el-tabs__item {
        font-size: 16px;
        color: rgba(128, 128, 128, 1);
    }

    :deep .el-tabs__item.is-active {
        color: rgba(42, 130, 228, 1);
    }

    :deep .el-tabs__nav-wrap::after {
        display: none;
    }
}
</style>