// 开发环境接口配置
// const APIURl =  'http://localhost:58504'
//process.env.NODE_ENV === 'development'
// let APIURl = 'http://localhost:30040'
// let APIURl = 'http://121.40.213.199:5851'
// let APIURl = 'http://192.168.110.88:30040'
let APIURl = 'https://www.summitek.xyz/owapi/'

// if (process.env.NODE_ENV == 'development') {
// 	APIURl = 'http://localhost:58504'
// } else {
// 	// 生产
// 	// APIURl = 'http://47.99.221.200:9022' //ymj
// 	APIURl = 'http://47.99.221.200:8092' //ymj2
// }
module.exports = {
	APIURl: APIURl,
	timeout: process.env.NODE_ENV === 'development' ? 1000000 : 1000000,
	WebSocketUrl: process.env.NODE_ENV === 'development' ? APIURl.replace('http', 'ws') + '/api/message/websocket' : process.env.VUE_APP_BASE_WSS,
	comUploadUrl: process.env.VUE_APP_BASE_API + '/api/file/Uploader',
	comUrl: process.env.VUE_APP_BASE_API,
	// 本地文件预览
	filePreviewServer: process.env.NODE_ENV === 'development' ? 'http://192.168.110.77:30003/preview' : 'http://192.168.110.77:30003/preview',
	// 大屏应用前端路径
	dataV: process.env.NODE_ENV === 'development' ? 'http://localhost:20001/DataV' :  'http://192.168.110.77:20001/DataV/',
	// 数据报表接口-java boot
	reportServer: process.env.NODE_ENV !== 'development' ? 'http://localhost:30002' : 'http://192.168.110.77:30002',
	// 数据报表接口-java cloud
	// reportServer: process.env.NODE_ENV === 'development' ? 'http://localhost:30000' : process.env.VUE_APP_BASE_API,
	// 报表前端
	report: process.env.NODE_ENV !== 'development' ? 'http://localhost:20002/html' : 'http://192.168.110.77:20002'
}
