<template>
    <el-dialog title="合同详情" :close-on-click-modal="false" lock-scroll append-to-body width="1200px" :visible.sync="visible"
        :show-close="false">
        <div class="dialog-main">
            <el-form ref="elForm" :model="dataForm" size="small" label-width="100px" label-position="right">
                <template v-if="!loading">
                    <el-col :span="24">
                        <el-divider content-position="left">合同基本内容 </el-divider>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同名称" prop="name">
                            <p>{{ dataForm.name }}</p>
                        </el-form-item>
                        <el-form-item label="订单" prop="ordernames">
                            <p>{{ dataForm.ordernames }}</p>
                        </el-form-item>
                        <el-form-item label="合同类型" prop="contracttype">
                            <p>{{ dataForm.contracttype | dynamicText(contracttypeOptions) }} </p>
                        </el-form-item>
                        <el-form-item label="合同开始时间" prop="starttime">
                            <!-- <p>{{ Summit.toDate(dataForm.starttime, "yyyy-MM-dd HH:mm:ss") }}</p> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同编号" prop="contractnum">
                            <p>{{ dataForm.contractnum }}</p>
                        </el-form-item>
                        <el-form-item label="订单ids" prop="orderids">
                            <p>{{ dataForm.orderids }}</p>
                        </el-form-item>
                        <el-form-item label="合同总金额" prop="contractmoney">
                            <p>{{ dataForm.contractmoney }}</p>
                        </el-form-item>
                        <el-form-item label="合同结束时间" prop="endtime">
                            <!-- <p>{{ Summit.toDate(dataForm.endtime, "yyyy-MM-dd HH:mm:ss") }}</p> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-divider content-position="left">采购方信息</el-divider>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="采购方公司" prop="purchasercompany">
                            <p>{{ dataForm.purchasercompany }}</p>
                        </el-form-item>
                        <el-form-item label="电话号码" prop="purchaserphonenum">
                            <p>{{ dataForm.purchaserphonenum }}</p>
                        </el-form-item>
                        <el-form-item label="社会信用代码" prop="socialcreditcode">
                            <p>{{ dataForm.socialcreditcode }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="采购人姓名" prop="purchasername">
                            <p>{{ dataForm.purchasername }}</p>
                        </el-form-item>
                        <el-form-item label="地址" prop="purchaseraddress">
                            <p>{{ dataForm.purchaseraddress }}</p>
                        </el-form-item>
                        <el-form-item label="资金类型" prop="moneytype">
                            <p>{{ dataForm.moneytype | dynamicText(moneytypeOptions) }} </p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-divider content-position="left">销售方信息</el-divider>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="销售方" prop="salername">
                            <p>{{ dataForm.salername }}</p>
                        </el-form-item>
                        <el-form-item label="合同相关人员" prop="contractrelated">
                            <p>{{ dataForm.contractrelated }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同责任人" prop="contractresponsible">
                            <p>{{ dataForm.contractresponsible }}</p>
                        </el-form-item>
                    </el-col>
                </template>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancel">取消</el-button>
            <!-- <el-button type="primary" @click="onConfirm()">确定</el-button> -->
        </span>
    </el-dialog>
</template>

<script>
import request from '@/utils/request';

export default {
    components: {},
    data() {
        return {
            visible: false,
            loading: false,
            dataForm: {
                id: '',
                salername: '',
                salerid: '',
                purchasername: '',
                purchaserid: '',
                purchasercompany: '',
                purchaserphonenum: '',
                purchaseraddress: '',
                name: '',
                socialcreditcode: '',
                contractnum: '',
                ordernames: '',
                orderids: '',
                contractmoney: '',
                starttime: '',
                endtime: '',
                creatortime: '',
                creatoruserid: '',
                description: '',
                contractfiles: [],
                contractresponsible: '',
                contractresponsibleid: '',
                contractrelated: '',
                contractrelatedid: '',
                department: '',
                contractrange: '',
                contracttype: "",
                moneytype: "",
            },
            contracttypeOptions: [{ "fullName": "销售合同", "id": "0" }, { "fullName": "采购合同", "id": "1" }],
            moneytypeOptions: [{ "fullName": "汇款", "id": "0" }, { "fullName": "付款", "id": "1" }],
        };
    },
    computed: {

    },
    filters: {
        dynamicText(value, options) {
            if (!value) return ''
            if (Array.isArray(value)) {
                if (!options || !Array.isArray(options)) return value.join()
                let textList = []
                for (let i = 0; i < value.length; i++) {
                    let item = options.filter(o => o.id == value[i])[0]
                    if (!item || !item.fullName) {
                        textList.push(value[i])
                    } else {
                        textList.push(item.fullName)
                    }
                }
                return textList.join()
            }
            if (!options || !Array.isArray(options)) return value
            let item = options.filter(o => o.id == value)[0]
            if (!item || !item.fullName) return value
            return item.fullName
        }
    },
    methods: {
        init(id) {
            this.dataForm.id = id || 0;
            this.visible = true;
            this.$nextTick(() => {
                if (this.dataForm.id) {
                    this.loading = true;
                    request({
                        url: '/api/business/C_contracts/detail/' + this.dataForm.id,
                        method: 'get'
                    }).then(res => {
                        this.dataInfo(res.data);
                        this.loading = false;
                    });
                }

            });
        },
        dataInfo(dataAll) {
            let _dataAll = dataAll;
            _dataAll.contractfiles = _dataAll.contractfiles ? JSON.parse(_dataAll.contractfiles) : [];
            _dataAll.contracttype = _dataAll.contracttype ? JSON.parse(_dataAll.contracttype) : [];
            _dataAll.moneytype = _dataAll.moneytype ? JSON.parse(_dataAll.moneytype) : [];
            this.dataForm = _dataAll;
        },
        cancel() {
            this.visible = false;
        },

    }
};
</script>
<style scoped lang="scss">
// .dialog-main {

::v-deep .el-dialog__body {
    padding-top: 0px;
}

::v-deep .el-dialog {
    margin-top: 10vh !important;
}

// table
// ::v-deep .el-table {
//     height: calc(100% - 125px) !important;
// }

:deep .el-table__header {
    border-radius: 20px 20px, 0px, 0px;
    background-color: rgba(245, 245, 245, 1);
}

:deep .el-table th.el-table__cell {
    background-color: rgba(245, 245, 245, 1);
    border-radius: 20px;
    border: none;
}

:deep .el-table__body tr:hover>td {
    background-color: rgba(245, 245, 245, 1) !important;
}

:deep .el-table tr {
    height: 46px;
}

// :deep .el-table__body-wrapper {
//     height: calc(100% - 47px);
//     overflow-y: auto;
// }

:deep .el-table__empty-block {
    min-height: 50px;
}

:deep .el-table th.el-table__cell>.cell {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(128, 128, 128, 0.7);
}

// }
</style>