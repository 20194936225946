import request from '@/utils/request'

// 用户登录
export function login(data) {
	return request({
		url: '/api/oauth/Login',
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		data
	})
}
// 注册
// 新建用户
export const createUser = data => {
	return request({
		url: '/api/permission/Users/Register',
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		data
	})
}

// 获取当前用户信息
export function getInfo() {
	return request({
		url: '/api/oauth/CurrentUser',
		method: 'get'
	})
}

// 退出登录
export function logout() {
	return request({
		url: '/api/oauth/Logout',
		method: 'get'
	})
}

// 锁屏解锁登录
export function unlock(data) {
	return request({
		url: '/oauth/LockScreen',
		method: 'post',
		data
	})
}

// 获取默认配置
export function getConfig(account) {
	return request({
		url: `/oauth/getConfig/${account}`,
		method: 'get'
	})
}

// 个人中心问答
export function GetQuestionByOne() {
	return request({
		url: '/api/Business/cquestion/GetQuestionByOne',
		method: 'get'
	})
}

// 获取部门下拉框列表(公司+部门)
// export function getDepartmentSelector(id) {
// 	return request({
// 		url: `/api/permission/role/Selector/${!!id ? id : 0}`,
// 		method: 'get'
// 	})
// }
export function getDepartmentSelector(id) {
	return request({
		url: `/api/permission/Organize/Department/Selector/${!!id ? id : 0}`,
		method: 'get'
	})
}
