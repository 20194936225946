import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isFull: false,
    isFooter: true,
    isFullScreen: false,
    ShLogin: false,
    LikeGood: 1,
    collectCount: 1,
    counter: "",
  },
  mutations: {
    isFull(state, value) {
      state.isFull = value;
    },
    isFooter(state, value) {
      state.isFooter = value;
    },
    isFullScreen(state, value) {
      state.isFullScreen = value;
    },
    ShLogin(state, value) {
      state.ShLogin = value;
    },
    LikeGood(state, value) {
      state.LikeGood = state.LikeGood + value;
    },
    collectCount(state, value) {
      state.collectCount = state.collectCount + value;
    },
    updataCount(state, num) {
      state.counter = num;
    }
  },
  actions: {},
  getters: {
    token: (state) => state.user.token,
    socket: (state) => state.user.socket,
  },
  modules: {
    user,
  },
});
