<template>
    <div class="paystepsCenter">
        <div class="steps">
            <el-steps :active="activeStep" finish-status="success" align-center>
                <el-step title="填写信息">
                </el-step>
                <el-step title="检查订单">
                </el-step>
                <el-step title="订单支付"></el-step>
                <el-step title="支付未完成"></el-step>
                <el-step title="完成支付"></el-step>
            </el-steps>
            <div class="stepContent">
                <div class="mesContent" v-show="activeStep == 0">
                    <!-- 填写信息 -->
                    <el-row :gutter="15" class=" main" :style="{ margin: '0 auto', width: '100%' }">
                        <el-form ref="ruleForm" :model="dataForm" :rules="rules" size="small" label-width="100px"
                            label-position="top">
                            <el-col :span="11">
                                <el-form-item label="用户姓名" prop="username">
                                    <el-input v-model="dataForm.username" placeholder="" clearable
                                        :style='{ "width": "100%" }'>
                                    </el-input>
                                </el-form-item>

                                <el-form-item label="联系电话" prop="receiverphone">
                                    <el-input v-model="dataForm.receiverphone" placeholder="" clearable
                                        :style='{ "width": "100%" }'>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="邮箱" prop="receiveremail">
                                    <el-input v-model="dataForm.receiveremail" placeholder="" clearable
                                        :style='{ "width": "100%" }'>
                                    </el-input>
                                </el-form-item>

                                <!-- <el-form-item label="邀请码" prop="retailercode">
                                    <el-input v-model.trim="dataForm.retailercode" placeholder="" clearable
                                        :style='{ "width": "100%" }' @blur="checkCode">
                                    </el-input>
                                    <div class="retailer" v-show="dataForm.retailercode">
                                        {{ retailerName }}
                                    </div>
                                </el-form-item> -->
                            </el-col>
                            <el-col :span="2">
                            </el-col>
                            <el-col :span="11">
                                <!-- <el-form-item label="收件人" prop="receivername">
                                    <el-input v-model="dataForm.receivername" placeholder="" clearable
                                        :style='{ "width": "100%" }'>
                                    </el-input>
                                </el-form-item> -->
                                <el-form-item label="公司名称" prop="companyname">
                                    <el-input v-model="dataForm.companyname" placeholder="" clearable
                                        :style='{ "width": "100%" }'>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="是否开具发票" prop="isinvoice">
                                    <div class="invoiceChoose">
                                        <el-select v-model="dataForm.isinvoice" @change="selectIsinvoice">
                                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <!-- <el-tooltip effect="light" content="如需多个订单合开一张发票，请前往个人中心-订单管理申请"
                                            placement="top">
                                            <i class="el-icon-warning-outline"></i>
                                        </el-tooltip> -->
                                    </div>

                                    <div v-show="isinvoiceFlag" class="invoice">
                                        <!-- <el-input v-model="invoiceInfo.EnterpriseRegistrationName" placeholder="企业注册名称"
                                            clearable :style='{ "width": "100%" }'>
                                        </el-input> -->
                                        <el-input v-model="invoiceInfo.TaxpayerIdentificationNumber" :required="true"
                                            :placeholder="typeText" clearable :style='{ "width": "100%" }'>
                                        </el-input>
                                        <el-button type="primary" class="saveBtn" :loading="saveCompanyLoading"
                                            v-show="showSaveBtn" @click="saveCompanyMes">保存至个人信息</el-button>
                                    </div>
                                </el-form-item>
                                <!-- <el-form-item label="地址" prop="receiveraddress">
                                    <el-input v-model="dataForm.receiveraddress" placeholder="" clearable
                                        :style='{ "width": "100%" }' type="textarea" :rows="2">
                                    </el-input>
                                </el-form-item> -->
                            </el-col>
                        </el-form>
                    </el-row>
                    <div class="nextStep">
                        <el-button class="btn" type="primary" @click="submitForm()">下一步</el-button>
                    </div>
                </div>
                <div class="mesContent" v-show="activeStep == 1">
                    <!-- 订单详情 -->
                    <div class="mesTitle">
                        <div class="productDetial">产品详情</div>
                        <div class="productMes">产品信息</div>
                        <div class="productIntro">产品说明</div>
                        <div class="duration">购买时间</div>
                        <div class="num">数量</div>
                        <div class="money">金额</div>
                    </div>
                    <div class="mesTitle" v-for="(item, index) in orderData" :key="index">
                        <div class="productDetial">
                            <img v-if="item.type_name.includes('SummitMap')" src="../../../assets/image/summitmapLogo.png" alt="">
                            <img v-if="item.type_name.includes('SummitSolid')" src="../../../assets/image/summitsolidLogo.png" alt="">
                            <img v-if="item.type_name.includes('SummitLab')" src="../../../assets/image/summitlabLogo.png" alt="">
                        </div>
                        <div class="productMes">
                            <div class="mes1">购买产品：{{ item.type_name }} </div>
                            <div class="mes2">产品名称：{{ item.name }} </div>
                        </div>
                        <div class="productIntro">
                            <div class="intro1" v-show="item.description" v-html="item.description"> </div>
                            <!-- <div class="intro2">购买方式：订阅模式</div> -->
                        </div>
                        <div class="duration">{{ item.duration }}{{ item.unit }}</div>
                        <div class="num">X {{ item.count }}</div>
                        <div class="money">￥{{ item.orderPrice * item.count * item.duration | numFilter }}</div>
                    </div>
                    <!-- <div class="mesTitle">
                        <div class="productDetial">
                            <img src="../../../assets/image/summitmapLogo.png" alt="">
                        </div>
                        <div class="test_div">
                            <div class="productMes">
                                <div class="mes1">购买产品：SummitMap全模块 </div>
                                <div class="mes2">产品说明：基于倾斜模型的矢量成图</div>
                            </div>
                            <div class="productIntro">
                                <div class="intro1">产品名称：SP倾斜测图 </div>
                                <div class="intro2">购买方式：订阅模式</div>
                            </div>
                        </div>
                        <div class="duration">一年</div>
                        <div class="num">X 1</div>
                        <div class="money">￥1280.00</div>
                    </div> -->
                    <div class="invoiceMes">
                        <!-- <el-form-item label="邀请码" prop="retailercode"> -->
                        <div class="mes">
                            <div class="invoiceTxt">输入邀请码查看优惠价</div>
                            <el-input class="invoiceInput" v-model.trim="retailercode" placeholder="" clearable
                                :style='{ "width": "100%" }' @blur="checkCode" @clear="clearCode"
                                @keyup.enter.native="checkCode">
                            </el-input>
                        </div>
                        <div class="retailer" v-show="retailercode">
                            <!-- {{ retailerName }} -->
                        </div>
                        <!-- </el-form-item> -->
                    </div>
                    <!-- <div class="totalMoney" v-show="retailerName">
                        {{ "折扣价: " + totalMoney + "*" + retailCount }}
                    </div> -->
                    <div class="moneyDetail">
                        <div class="moneyList">
                            <div class="moneyItem">产品金额：{{ totalMoney }}</div>
                            <!-- <div class="moneyItem">邀请码抵扣：{{ (totalMoney - (totalMoney * this.retailCount)) | numFilter }}</div>
                            <div class="realMoney">
                                实付：<span>￥{{ totalMoney * this.retailCount | numFilter }}</span>
                            </div> -->
                            <div class="moneyItem">邀请码抵扣：{{ (totalMoney - resultMoney) | numFilter }}</div>
                            <div class="realMoney">
                                实付：<span>￥{{ resultMoney }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="totalMoney">
                        <div class="tDes">总金额</div>
                        <div class="tCount">￥{{ totalMoney * retailCount | numFilter }}</div>
                    </div> -->
                    <div class="orderdetails">
                        <div class="odetail">
                            <div class="receiverText">送货详情</div>
                            <div class="part1">
                                <!-- <div class="dPart1">收件人：{{ dataForm.receivername }}</div> -->
                                <div class="dPart2">邮箱： {{ dataForm.receiveremail }}</div>
                            </div>
                            <div class="part1">
                                <div class="dPart1">联系电话：{{ dataForm.receiverphone }}</div>
                                <!-- <div class="dPart2">地址：{{ dataForm.receiveraddress }}</div> -->
                            </div>
                        </div>
                        <div class="odetail" v-if="invoiceDes">
                            <div class="receiverText">发票详情</div>
                            <div class="part2">
                                <div class="part2_d">
                                    <div class="dPart1">{{ invoiceDes }}</div>
                                </div>
                                <div class="part2_d" v-if="dataForm.isinvoice == 2">
                                    <div class="dPart2">企业名称：{{ dataForm.companyname }}</div>
                                    <div class="dPart2">纳税人识别号：{{ invoiceInfo.TaxpayerIdentificationNumber }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="odetail">
                            <div class="receiverText">许可证申请</div>
                            <div class="part1">
                                <div class="dPart1">需申请：许可文件X1</div>
                            </div>
                        </div>
                        <!-- <div class="odetail">
                            <div class="receiverText">机器码</div>
                            <div class="part1">
                                <div class="dPart1">GSD545S4</div>
                            </div>
                        </div> -->
                    </div>
                    <div class="nextStep">
                        <div class="readSoftXieyi">
                            <el-checkbox v-model="checked" @change="clickAgree()">我已阅读并同意<a
                                    style="color: rgba(43, 133, 229, 1);"
                                    target="_blank">《产品订阅须知》《峰岭科技个人计算机软件许可协议》</a></el-checkbox>
                        </div>
                        <el-button class="btn back" @click="toPrevious">上一步</el-button>
                        <el-button class="btn" type="primary" :disabled="!checked" @click="toNext">下一步</el-button>
                    </div>
                </div>
                <div class="mesContent" v-show="activeStep == 2">
                    <div class="payText">支付方式</div>
                    <div class="payWay">
                        <div class="payItem" @click="openWarning">
                            <img src="../../../assets/image/wechat.png" alt="">
                            <span>微信支付</span>
                        </div>
                        <!-- <div class="payItem" @click="toAliPay">
                            <img src="../../../assets/image/zfb.png" alt="">
                            <span>支付宝支付</span>
                        </div> -->
                        <!-- <iframe id="alipayFrame" frameborder="0"></iframe> -->
                    </div>

                    <div class="nextStep">
                        <el-button class="btn back" @click="toPrevious">上一步</el-button>
                        <el-button class="btn" type="primary" @click="toNext">下一步</el-button>
                    </div>
                </div>
                <div class="mesContent" v-show="activeStep == 3">
                    <div class="pendingPaymentOrder">
                        您有一笔待支付订单
                    </div>
                    <div class="toPaymentOrder">
                        <p>点击"继续支付"以前往支付宝/微信尽快完成付款。付款务必在30分钟内完成</p>
                    </div>
                    <div class="nextStep">
                        <el-button class="btn" type="primary" @click="toPaymentOrder">继续支付</el-button>
                    </div>
                </div>
                <div class="mesContent" v-show="activeStep == 4">
                    <div class="finishStep">
                        您的支付已完成
                    </div>
                    <div class="finishSoft">
                        软件信息下载
                    </div>
                    <div class="softs">
                        <div class="softmap" v-show="isShow('SummitMap')">
                            <div class="softImg_div">
                                <div class="softImg">
                                    <img src="../../../assets/image/summitmapLogo.png" alt="">
                                </div>
                            </div>
                            <div style="text-align: center;margin-bottom: 10px;">SummitMap</div>
                            <div class="softDownload">
                                <span @click="toDownload('map')">安装包</span>
                                <span @click="toApply">去申请许可证</span>
                                <el-tooltip effect="light" content="打开软件在许可信息弹窗中可获取机器ID" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="softmap" v-show="isShow('SummitMapTrial')">
                            <div class="softImg_div">
                                <div class="softImg">
                                    <img src="../../../assets/image/summitmapLogo.png" alt="">
                                </div>
                            </div>
                            <div style="text-align: center;margin-bottom: 10px;">SummitMapTrial</div>
                            <div class="softDownload">
                                <span @click="toDownload('maptrial')">安装包</span>
                                <span @click="toApply">去申请许可证</span>
                                <el-tooltip effect="light" content="打开软件在许可信息弹窗中可获取机器ID" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="softmap" v-show="isShow('SummitMapHUBEI')">
                            <div class="softImg_div">
                                <div class="softImg">
                                    <img src="../../../assets/image/summitmapLogo.png" alt="">
                                </div>
                            </div>
                            <div style="text-align: center;margin-bottom: 10px;">SummitMapHUBEI</div>

                            <div class="softDownload">
                                <span @click="toDownload('HUBEI')">安装包</span>
                                <span @click="toApply">去申请许可证</span>
                                <el-tooltip effect="light" content="打开软件在许可信息弹窗中可获取机器ID" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="softmap" v-show="isShow('SummitSolidSGJ')">
                            <div class="softImg_div">
                                <div class="softImg">
                                    <img src="../../../assets/image/summitsolidLogo.png" alt="">
                                </div>
                            </div>
                            <div style="text-align: center;margin-bottom: 10px;">SummitSolidSGJ</div>

                            <div class="softDownload">
                                <span @click="toDownload('solidSGJ')">安装包</span>
                                <span @click="toApply">去申请许可证</span>
                                <el-tooltip effect="light" content="打开软件在许可信息弹窗中可获取机器ID" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="softmap" v-show="isShow('SummitSolidTrial')">
                            <div class="softImg_div">
                                <div class="softImg">
                                    <img src="../../../assets/image/summitsolidLogo.png" alt="">
                                </div>
                            </div>
                            <div style="text-align: center;margin-bottom: 10px;">SummitSolidTrial</div>
                            <div class="softDownload">
                                <span @click="toDownload('solidtrial')">安装包</span>
                                <span @click="toApply">去申请许可证</span>
                                <el-tooltip effect="light" content="打开软件在许可信息弹窗中可获取机器ID" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="softmap" v-show="isShow('SummitLab')">
                            <div class="softImg_div">
                                <div class="softImg">
                                    <img src="../../../assets/image/summitlabLogo.png" alt="">
                                </div>
                            </div>
                            <div class="softDownload">
                                <span @click="toDownload('lab')">安装包</span>
                                <span @click="toApply">去申请许可证</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="backCart">
                        <img src="../../../assets/image/backCart.png" alt="" style="cursor: pointer;">
                        <div style="cursor: pointer;margin-right: 10px;" @click="goBackCart">返回购物车</div>
            
                    </div> -->
                    <div class="contactUs">
                        <div class="text">联系我们</div>
                        <img src="../../../assets/image/companyContact.jpg" style="width:150px;" />
                    </div>
                </div>
                <el-dialog title="支付" :visible.sync="centerDialogVisible" width="60%" center>
                    <span>正在支付，请勿关闭该窗口</span>
                    <span>若支付完成后，长时间未跳转请联系客服</span>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="centerDialogVisible = false">取消支付</el-button>
                        <el-button type="primary" @click="finishPay">已完成支付</el-button>
                    </span>
                </el-dialog>
                <el-dialog title="微信支付" :visible.sync="centerWeChatDialogVisible" width="300px" center
                    :before-close="handleClose" style="text-align: center;">
                    <div class="money" style="font-size: 20px;text-align: center;">￥{{ totalMoney * retailCount |
                        numFilter }}</div>
                    <img :src="wechatPayImg" alt="">
                </el-dialog>
                <el-dialog :visible.sync="checkVisible" width="1000px" center :before-close="handleCheckClose"
                    style="text-align: center;">
                    <div style="height: 70vh;overflow-y: auto;" ref="scrollbarRef" @scroll="handleScroll">
                        <pdf :src="pdfEvent" v-for="i in pages" :key="i" :page="i"></pdf>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button disabled type="primary">请上滑看完本条款再同意</el-button>
                        <el-button type="primary" @click="agreeCheck" v-show="isBottom">
                            同意本条款</el-button>
                    </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getAliPayQr, createOrder, getOrderMes, getOrderDetailMes, makeSingleLicense, downloadLicense, getWeChatPayQr, getSoftDownloadList
} from "@/assets/API/pay";
import request from "@/utils/request";
import define from './../../../utils/define';
import pdf from 'vue-pdf';
export default {
    components: {
        pdf
    },
    data() {
        const regExp = /^1[3456789]\d{9}$/;
        return {
            activeStep: 0,
            invoiceInfo: {
                EnterpriseRegistrationName: '',
                TaxpayerIdentificationNumber: ''
            },
            popoverVisible: false,
            currentPage: 1,
            totalPages: 0,
            pages: [], // 存放所有页面的数组
            dataForm: {
                id: undefined,
                username: '',
                userid: '',
                ordernum: '',
                ordername: '',
                purchasemethod: 'offline',
                paymentstate: '0',
                paymentamount: 0,
                productiontype: '',
                tracknum: '',
                receivername: '',
                receiverphone: '',
                receiveraddress: '',
                receiveremail: '',
                alipayordernum: '',
                isinvoice: 0,
                invoiceInfo: {
                },
                invoiceid: '',
                retailercode: '',
                afterservice: '',
                invitationcode: '',
                machinecode: '',
                companyname: ''
            },
            retailerName: '',
            retailCount: 1,
            checked: false,
            typeText: '请填写纳税人识别号',
            rules:
            {
                username: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    },
                ],
                userid: [
                    {
                        required: true,
                        message: '请输入',
                        trigger: 'blur'
                    },
                ],
                machinecode: [
                    {
                        required: true,
                        message: '请输入',
                        trigger: 'blur'
                    },
                ],
                receivername: [
                    {
                        required: true,
                        message: '请输入',
                        trigger: 'blur'
                    },
                ],
                receiverphone: [
                    {
                        required: true,
                        message: '请输入',
                        trigger: 'blur'
                    },
                    {
                        pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/,
                        message: '请输入正确的手机号或者固话',
                        trigger: ['blur', 'change']
                    },
                ],
                receiveremail: [
                    {
                        required: false,
                        message: '请输入',
                        trigger: 'blur'
                    },
                    {
                        type: 'email',
                        message: '请输入正确的邮箱地址',
                        trigger: ['blur', 'change']
                    }
                ],
                receiveraddress: [
                    {
                        required: true,
                        message: '请输入',
                        trigger: 'blur'
                    },
                ],
                companyname: [
                    {
                        required: false,
                        message: '请输入公司名称',
                        trigger: 'blur'
                    },
                ]
            },
            retailercode: '',
            aliImgData: '',
            centerDialogVisible: false,
            centerWeChatDialogVisible: false,
            buyData: [],
            orderMes: {},
            licenseData: {},
            wechatPayImg: '',
            options: [
                {
                    value: 2,
                    label: "电子普通发票 - 企业抬头"
                },
                {
                    value: 3,
                    label: "电子专用发票 - 企业抬头"
                },
                {
                    value: 1,
                    label: "电子普通发票 - 个人抬头"
                },
                {
                    value: 0,
                    label: "无需发票"
                },
                // {
                //     value: 3,
                //     label: "增值税专用发票"
                // }
            ],
            isinvoiceFlag: false,
            invoiceDes: "",
            source: "",
            orderData: [],
            intervalPay: undefined,
            saveCompanyLoading: false,
            userInfo: {},
            retailerProductids: [], // 分销商可使用折扣的软件ids
            resultMoney: 0,
            pdfSrc: '',
            checkVisible: false,
            isBottom: false,
            pdfEvent: undefined,
            downloadList: []
        };
    },
    destroyed() {
        localStorage.removeItem('BUYDATA');
    },
    mounted() {
        let userInfo = this.$store.state.user.userInfo;
        this.userInfo = this.$store.state.user.userInfo;
        if (!userInfo.id) {
            userInfo = JSON.parse(localStorage.getItem("USERINFO"));
        }

        this.dataForm.userid = userInfo.id;
        if (!this.dataForm.userid) {
            this.$router.replace({
                name: "Login",
                path: "/login",
            });
        }
        this.dataForm.username = userInfo.username;
        // 如果认证过了 自动填入公司信息
        this.invoiceInfo.EnterpriseRegistrationName = userInfo.companyname;
        this.dataForm.companyname = userInfo.companyname;
        this.invoiceInfo.TaxpayerIdentificationNumber = userInfo.companysocialcreditcode;
        // console.log(this.invoiceInfo, 'iiii');
        // 获取立即订阅的订单信息
        // this.buyData = [JSON.parse(this.$route.query.data)];
        this.buyData = [JSON.parse(localStorage.getItem("BUYDATA"))];
        this.source = [this.$route.query.source];
        if (this.source == "cart") {
            this.buyData = this.buyData[0];
        }
        this.orderData = [...this.buyData];
        this.orderData.forEach(item => {
            if (item.durationunion == "week") {
                item.orderPrice = item.weekprice;
                item.unit = "周";
            } else if (item.durationunion == "month") {
                item.orderPrice = item.monthprice;
                item.unit = "月";
            } else if (item.durationunion == "season") {
                item.orderPrice = item.seasonprice;
                item.unit = "季";
            } else if (item.durationunion == "halfyear") {
                item.orderPrice = item.halfyearprice;
                item.unit = "半年";
            }
        });
        if (this.orderData[0].pdffile) {
            let files = JSON.parse(this.orderData[0].pdffile);
            this.pdfSrc = files.length > 0 ? files[0].url : '';
            this.pdfEvent = pdf.createLoadingTask(this.define.APIURl + this.pdfSrc);
            this.pdfEvent.promise.then(pdf => {
                this.pages = pdf.numPages;
                console.log(this.pages);
            });
        }
        console.log("this.orderData", this.orderData);
        // 获取下载链接
        let query = {
            dataType: 1,
            sort: "desc",
            sidx: "",
            type: 1
        };
        getSoftDownloadList(query).then(res => {
            this.downloadList = res.data.list;
        });
    },
    filters: {
        numFilter(value) {
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2);
            return realVal;
        }
    },
    watch: {
        // centerWeChatDialogVisible(newVal, oldVal) {
        //     console.log(newVal)
        //     if (newVal) {
        //         // 支付中
        //         this.intervalPay = setInterval(() => {
        //             console.log('付钱');
        //             this.finishPay();
        //         }, 2000);
        //     } else {
        //         clearInterval(this.intervalPay);
        //     }
        // }

    },
    computed: {
        totalMoney() {
            let money = 0;
            this.buyData.forEach(e => {
                if (this.source == "cart") {
                    money += e.price * e.duration * e.count;
                } else {
                    if (e.durationunion == 'week') {
                        money += e.weekprice * e.duration * e.count;
                    } else if (e.durationunion == 'month') {
                        money += e.monthprice * e.duration * e.count;
                    } else if (e.durationunion == 'season') {
                        money += e.seasonprice * e.duration * e.count;
                    } else if (e.durationunion == 'halfyear') {
                        money += e.halfyearprice * e.duration * e.count;
                    }
                }

            });
            this.resultMoney = money;
            return money;
        },
        showSaveBtn() {
            // if(this.dataForm.invoiceInfo.EnterpriseRegistrationName) TaxpayerIdentificationNumber
            if (this.dataForm.companyname == this.userInfo.companyname && this.invoiceInfo.TaxpayerIdentificationNumber == this.userInfo.companysocialcreditcode) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        toDownload(value) {
            // 软件下载链接
            let url = "";
            let typeName = '';
            if (value == 'map') {
                // url = "https://pan.baidu.com/s/1r9GeqkWtIObwvyiZHVIvXw" + "&pwd=" + "l7yv";
                typeName = "SummitMap";
            } else if (value == 'HUBEI') {
                // url = "https://pan.baidu.com/s/1t401sb1rpcfLeeAzs5g-Ng" + "&pwd=" + "w154";
                typeName = "SummitMapHUBEI";

            } else if (value == 'solid') {
                // url = "https://pan.baidu.com/s/1RvyukDTDdb5wiXtKZRYBOA" + "&pwd=" + "qk1d";
                typeName = "SummitSolid";

            } else if (value == 'lab') {
                // url = "https://pan.baidu.com/s/17oYO_QunJ_mibuQayjmi1w" + "&pwd=" + "ppg3";
                typeName = "SummitLab";

            } else if (value == 'environment') {
                // url = "https://pan.baidu.com/s/1ZLP7bBZssqLK9Db8sH-gTA?pwd=wqyq";
                typeName = "SummitMapEnvironment";
            } else if (value == 'solidSGJ') {
                // url = "https://pan.baidu.com/s/1WpsKKZQWCxLIfCE6SQ5fwg" + "&pwd=" + "vleo";
                typeName = "SummitSolidSGJ";
            } else if (value == 'maptrial') {
                // url = "https://pan.baidu.com/s/1WpsKKZQWCxLIfCE6SQ5fwg" + "&pwd=" + "vleo";
                typeName = "SummitMapTrial";
            } else if (value == 'solidtrial') {
                // url = "https://pan.baidu.com/s/1WpsKKZQWCxLIfCE6SQ5fwg" + "&pwd=" + "vleo";
                typeName = "SummitSolidTrial";
            }
            let res = this.downloadList.filter(n => n.descrh == typeName);
            if (res.length == 1) {
                url = res[0].f + "&pwd=" + res[0].m;
                window.open(url, '_blank');
            }
        },
        toApply() {
            // 跳转申请许可证
            this.$router.push({
                name: "我的中心",
                path: "/PersonalCenter",
                query: { value: 'license' }
            });
        },
        toNext() {
            if (this.activeStep == 1) {
                // 创建未支付订单
                if (!this.dataForm.userid) {
                    this.$router.replace({
                        name: "Login",
                        path: "/login",
                    });
                } else {
                    let productDetails = [];
                    let orderName = [];
                    if (this.buyData.length == 0 || !this.buyData[0].type) {
                        return;
                    }
                    this.buyData.forEach(e => {
                        orderName.push(e.type);
                        productDetails.push({
                            dogState: e.dogState,
                            durationunion: e.durationunion,
                            duration: e.duration,
                            featureid: e.profeatureid,
                            number: e.count,
                            price: e.price,
                            productcode: e.code,
                            productid: e.id,
                            productname: e.name,
                            producttype: e.type
                        });
                    });
                    let orderNameValue = orderName.join('-') + "购买";
                    // this.resultMoney = this.totalMoney;
                    // if (this.retailCount != 1) { // todo
                    //     resultMoney = this.totalMoney * this.retailCount;
                    //     resultMoney = resultMoney.toFixed(2);
                    // }
                    let _data = {
                        ordername: orderNameValue,
                        paymentstate: "0",
                        productDetails: productDetails,
                        paymentamount: this.resultMoney,
                        baseamount: this.totalMoney,
                        purchasemethod: 'online',
                        userid: this.dataForm.userid,
                        username: this.dataForm.username,
                        receiveraddress: this.dataForm.receiveraddress,
                        receiveremail: this.dataForm.receiveremail,
                        receivername: this.dataForm.receivername,
                        receiverphone: this.dataForm.receiverphone,
                        retailercode: this.dataForm.retailercode,
                        company: this.dataForm.companyname
                    };
                    console.log("_data", _data);
                    createOrder(_data).then(res => {
                        this.orderMes = res.data;
                    });
                }

            }
            if (this.activeStep == 2) {
                this.finishPay();
            }
            if (this.activeStep == 0 || this.activeStep == 1) {
                this.activeStep++;
            }
        },
        // 填写信息确认
        submitForm() {
            if (this.isinvoiceFlag) {
                if (!this.invoiceInfo.TaxpayerIdentificationNumber) {
                    this.$message({
                        type: 'warning',
                        message: this.typeText
                    });
                    return false;
                }
            }
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.toNext();
                } else {
                    return false;
                }
            });
        },
        toPrevious() {
            this.activeStep--;
        },
        toAliPay() {
            // 跳转zfb支付
            // let data = {
            //     desc: "测试",
            //     orderNum: "DD2023122200007",
            //     price: 0.05,
            //     title: "测试订单"
            // }; 
            let data = {
                desc: "",
                orderNum: this.orderMes.ordernum,
                price: this.orderMes.paymentamount,
                title: this.orderMes.ordername
            };
            getAliPayQr(data).then(res => {
                this.centerDialogVisible = true;
                this.aliImgData = res;
            }).then(res => {
                let divForm = document.getElementsByTagName('divform');
                if (divForm.length) {
                    document.body.removeChild(divForm[0]);
                }
                const div = document.createElement('divform');
                div.innerHTML = this.aliImgData;
                document.body.appendChild(div);
                document.forms[1].setAttribute('target', '_blank');
                document.getElementById('_alipaysubmit_').submit();
            });
        },
        openWarning() {
            this.$confirm('数字许可证订阅后，将无法撤销并退款。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.toWeChatPay();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消支付'
                });
            });
        },
        // 微信支付
        toWeChatPay() {
            let data = {
                desc: "",
                orderNum: this.orderMes.ordernum,
                price: this.orderMes.paymentamount,
                title: this.orderMes.ordername
            };
            getWeChatPayQr(data).then(res => {
                this.centerWeChatDialogVisible = true;
                this.wechatPayImg = res.msg;
                var _this = this;
                this.intervalPay = setInterval(() => {
                    console.log('pay');
                    _this.checkPay();
                }, 2000);
                setTimeout(() => {
                    clearInterval(this.intervalPay);
                }, 20000);
            });
        },
        checkPay() {
            // 确认是否支付完成
            getOrderMes(this.orderMes.id).then(res => {
                if (res.data.paymentstate == 1) {
                    this.centerDialogVisible = false;
                    // this.activeStep++;
                    // 生成许可证
                    // this.makeLicense();
                    this.activeStep = 4;
                    clearInterval(this.intervalPay);
                    this.createInvocie();
                    // this.$nextTick(() => {
                    //     setTimeout(() => {
                    //         this.$router.push({
                    //             name: "我的中心",
                    //             path: "/PersonalCenter",
                    //         });
                    //     }, 2000);
                    // });
                    this.centerWeChatDialogVisible = false;
                }
            });
        },
        // 支付完成
        finishPay() {
            // 确认是否支付完成
            getOrderMes(this.orderMes.id).then(res => {
                if (res.data.paymentstate == 1) {
                    this.centerDialogVisible = false;
                    // this.activeStep++;
                    // 生成许可证
                    // this.makeLicense();
                    this.activeStep = 4;
                    clearInterval(this.intervalPay);
                    this.createInvocie();
                    // this.$nextTick(() => {
                    //     setTimeout(() => {
                    //         this.$router.push({
                    //             name: "我的中心",
                    //             path: "/PersonalCenter",
                    //         });
                    //     }, 2000);
                    // });
                    this.centerWeChatDialogVisible = false;

                } else {
                    // 未支付 返回我的订单页 todo
                    this.centerDialogVisible = false;
                    this.activeStep = 3;
                }
            });
        },
        createInvocie() {
            // 完成支付 判断是否生成发票数据
            if (this.dataForm.isinvoice != 0) {
                console.log(this.invoiceInfo, this.orderMes, '888888');
                let dataForm = {
                    username: this.orderMes.username,
                    userid: this.orderMes.userid,
                    creatoruserid: this.orderMes.userid,
                    orderid: this.orderMes.id,
                    ordername: this.orderMes.ordernum,
                    company: this.dataForm.companyname,
                    status: "0",
                    amount: this.orderMes.paymentamount,
                    amountgross: 0,
                    content: "[]",
                    type: "0",
                    taxrate: '',
                    ratenumber: this.invoiceInfo.TaxpayerIdentificationNumber,
                    address: '',
                    phone: this.dataForm.receiverphone,
                    email: this.dataForm.receiveremail,
                    banknum: '',
                };
                console.log(dataForm, '9999999999');
                request({
                    url: '/api/business/C_invoice',
                    method: 'post',
                    data: dataForm
                }).then((res) => {

                });
            }
        },
        makeLicense() {
            // 生成许可证
            let query = {
                ordercode: this.orderMes.id
            };
            getOrderDetailMes(query).then(res => {
                let detailids = res.data.list;
                if (detailids.length > 0) {
                    let data = {
                        productid: this.buyData[0].id,
                        producttype: this.buyData[0].type,
                        state: 0,
                        filedata: '',
                        duration: this.buyData[0].duration,
                        machinecode: this.dataForm.machinecode,
                        contactphone: '',
                        contactname: '',
                        companyname: '',
                        ordercode: this.orderMes.id,
                        guestmes: '',
                        endtime: '',
                        creatoruserid: '',
                        orderuserid: this.orderMes.userid,
                        orderusername: this.orderMes.username,
                        orderdetailproductid: detailids[0].id
                    };
                    makeSingleLicense(data).then(res => {
                        this.licenseData = res.data;
                    });

                }
            });
        },
        // 下载许可证
        download() {
            // 下载许可证
            downloadLicense(this.licenseData.id).then(res => {
                let url = "/api/business/C_license/downloadFile?data=" + res.data;
                // this.Summit.downloadFile(url);
                if (!url) return;
                // const baseUrl = url.indexOf("http") > -1 ? "" : this.define.comUrl;
                const baseUrl = url.indexOf("http") > -1 ? "" : this.define.APIURl;
                const a = document.createElement("a");
                a.setAttribute("download", res.data);
                let name = null;
                const realUrl = baseUrl + url + (name ? "&name=" + name : "");
                a.setAttribute("href", realUrl);
                a.click();
            });
        },
        handleClose() {
            this.centerWeChatDialogVisible = false;
            this.centerDialogVisible = true;
            clearInterval(this.intervalPay);
        },
        handleCheckClose() {
            this.checked = false;
            this.checkVisible = false;

        },
        agreeCheck() {
            this.checked = true;
            this.checkVisible = false;
        },
        // 是否需要开具发票
        selectIsinvoice(val) {
            this.dataForm.invoiceInfo = {};
            if (val == 2 || val == 3) {
                this.isinvoiceFlag = true;
                this.rules.companyname[0].required = true;
                this.rules.receiveremail[0].required = true;
                this.typeText = '请填写纳税人识别号';
            } else if (val == 1) {
                this.isinvoiceFlag = true;
                this.rules.receiveremail[0].required = true;
                this.typeText = '请填写身份证号';
            } else {
                this.isinvoiceFlag = false;
                this.rules.companyname[0].required = false;
                this.rules.receiveremail[0].required = false;
            }
            if (val == 0) {
                this.invoiceDes = "无需发票";
            } else if (val == 1) {
                this.invoiceDes = "您已选择开具个人发票";
            } else if (val == 2) {
                this.invoiceDes = "您已选择开具企业发票";
            } else if (val == 3) {
                this.invoiceDes = "您已选择开具增值税专用发票";
            }
        },
        // 继续支付
        toPaymentOrder() {
            this.activeStep = 2;
        },
        // 返回购物车
        goBackCart() {
            this.$router.push({ name: "购物车", path: "/cart" });
        },
        checkCode() {
            // 分销商代码检验
            if (this.retailercode) {
                this.dataForm.retailercode = this.retailercode;
                let query = {
                    retailcode: this.dataForm.retailercode
                };
                request({
                    url: '/api/business/C_retailers/getList',
                    method: 'post',
                    data: query
                }).then(res => {
                    let result = res.data.list;

                    if (result.length == 1) {
                        // 判断是否适配购买的软件
                        if (result[0].productid) {
                            this.retailerProductids = result[0].productid.split(',');
                            this.retailerName = result[0].companyname + " | " + result[0].retailername;
                            this.retailCount = result[0].discount;
                            // 计算折后价格 (买的软件和可打折软件id的交集)
                            let discountMoney = 0;
                            this.buyData.forEach(e => {
                                if (this.retailerProductids.includes(e.id)) {
                                    // this.resultMoney = this.resultMoney + e.weekprice * e.duration * e.count * this.retailCount;
                                    if (e.durationunion == 'week') {
                                        discountMoney += e.weekprice * e.duration * e.count * this.retailCount;
                                    } else if (e.durationunion == 'month') {
                                        discountMoney += e.monthprice * e.duration * e.count * this.retailCount;
                                    } else if (e.durationunion == 'season') {
                                        discountMoney += e.seasonprice * e.duration * e.count * this.retailCount;
                                    } else if (e.durationunion == 'halfyear') {
                                        discountMoney += e.halfyearprice * e.duration * e.count * this.retailCount;
                                    }
                                } else {
                                    if (e.durationunion == 'week') {
                                        discountMoney += e.weekprice * e.duration * e.count;
                                    } else if (e.durationunion == 'month') {
                                        discountMoney += e.monthprice * e.duration * e.count;
                                    } else if (e.durationunion == 'season') {
                                        discountMoney += e.seasonprice * e.duration * e.count;
                                    } else if (e.durationunion == 'halfyear') {
                                        discountMoney += e.halfyearprice * e.duration * e.count;
                                    }
                                }
                            });
                            this.resultMoney = discountMoney.toFixed(2);
                        } else {
                            this.retailerName = "";
                            this.retailCount = 1;
                            this.resultMoney = this.totalMoney;
                        }

                    } else {
                        this.$message({
                            message: "分销商信息匹配失败",
                            type: 'error',
                            duration: 1000,
                        });
                        this.retailerName = "";
                        this.retailCount = 1;
                        this.resultMoney = this.totalMoney;

                    }
                });
            } else {
                this.retailerName = "";
                this.retailCount = 1;
                this.resultMoney = this.totalMoney;
            }
        },
        clearCode() {
            this.dataForm.retailercode = '';
            this.retailerName = "";
            this.retailCount = 1;
        },
        saveCompanyMes() {
            // if(this.dataForm.invoiceInfo.EnterpriseRegistrationName) TaxpayerIdentificationNumber
            let userData = this.userInfo;
            userData.companyname = this.dataForm.companyname;
            userData.companysocialcreditcode = this.invoiceInfo.TaxpayerIdentificationNumber;
            request({
                url: '/api/business/C_customers/' + userData.id,
                method: 'PUT',
                data: userData
            }).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        duration: 1000,
                        onClose: () => {
                            // this.btnLoading = false;
                            // this.$router.go(0);
                        }
                    });
                }
            });
        },
        isShow(type) {
            let typeList = this.orderData.map(e => e.type);
            if (typeList.includes(type)) {
                return true;
            } else {
                return false;
            }
        },
        clickAgree() {
            console.log(this.checked, 'dddddd');
            if (this.checked) {
                // 点击了 弹框确认后到下一步
                this.checkVisible = true;
            }
        },
        handleScroll(event) {
            const scrollbar = event.target;
            // console.log(scrollbar.clientHeight)
            // console.log(scrollbar.scrollTop)
            // console.log(scrollbar.scrollHeight)
            if (scrollbar.clientHeight + scrollbar.scrollTop >= scrollbar.scrollHeight - 10) {
                console.log("滚动条到达底部");
                // 在滚动条到达底部时执行你的操作
                // 例如加载更多数据等
                this.isBottom = true;
            }
        },
    }
};
</script>

<style scoped lang="scss" scoped>
.paystepsCenter {
    margin-top: 60px;

    .steps {
        margin: 100px 100px 180px;
        padding: 50px;
        opacity: 1;
        border-radius: 33px;
        background: #FFFFFF;
        box-shadow: 0px 1px 5px #808080;

        .el-steps--simple {
            background-color: #fff;
        }

        .stepContent {
            padding: 60px;

            .mesContent {
                position: relative;

                .contactUs {
                    .text {
                        text-align: center;
                    }

                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                }

                .nextStep {
                    padding-top: 70px;
                    text-align: center;

                    .btn {
                        width: 16%;
                    }

                    .back {
                        background-color: rgba(229, 229, 229, 1);
                    }
                }

                ::v-deep .el-col {
                    padding-bottom: 10px;
                }

                ::v-deep .el-form-item {
                    margin-bottom: 40px;
                }

                ::v-deep .el-form-item__label {
                    line-height: 45px;
                }

                ::v-deep .el-input__inner {
                    background-color: #F5F5F5;
                    border: 0px;
                    height: 45px;
                    line-height: 45px;
                    border-radius: 10px;
                }

                ::v-deep .el-textarea__inner {
                    background-color: #F5F5F5;
                    border: 0px;
                    height: 100px;
                    border-radius: 10px;
                }

                .invoice {
                    padding-top: 12px;

                    ::v-deep .el-input__inner {
                        border-radius: 10px;
                        border: 1px solid rgba(56, 56, 56, 1);
                        background-color: #fff;
                        margin-bottom: 10px
                    }

                    ::-webkit-input-placeholder {
                        /* WebKit, Blink, Edge */
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        color: rgba(56, 56, 56, 1);
                    }

                    :-moz-placeholder {
                        /* Mozilla Firefox 4 to 18 */
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        color: rgba(56, 56, 56, 1);
                    }

                    ::-moz-placeholder {
                        /* Mozilla Firefox 19+ */
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        color: rgba(56, 56, 56, 1);
                    }

                    :-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        color: rgba(56, 56, 56, 1);
                    }

                    .saveBtn {
                        float: right;
                    }
                }

                .payText {
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 28.96px;
                    color: rgba(0, 0, 0, 1);
                    text-align: left;
                    vertical-align: top;

                }

                .payWay {

                    .payItem {
                        width: 438.54px;
                        height: 72px;
                        opacity: 1;
                        background: #F7F7F7;
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;

                        img {
                            height: 70%;
                        }

                        span {
                            margin-left: 10px;
                            font-size: 18px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 30px;
                            color: rgba(56, 56, 56, 1);
                            vertical-align: top;
                        }
                    }

                }
            }
        }
    }
}

.mesTitle {
    display: flex;
    text-align: center;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px 20px;
    align-items: center;
    font-size: 20px;
    font-weight: 400;


    .productDetial {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 34.75px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
        flex: 1;
    }

    .test_div {
        flex: 2.5;
        justify-content: space-between;
    }

    .productMes {
        flex: 1;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(56, 56, 56, 1);

        .mes1 {
            padding-bottom: 10px;
        }


    }

    .productIntro {
        flex: 1.5;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(56, 56, 56, 1);
        padding-left: 10px;

        .intro1 {
            padding-bottom: 10px;
        }
    }

    .duration,
    .num,
    .money {
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(56, 56, 56, 1);
    }

    .num {
        flex: 1
    }

    .money {
        flex: 1
    }
}

.invoiceMes {
    opacity: 1;
    background: rgba(43, 133, 229, 0.11);
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 20px;
    align-items: end;
    flex-direction: column;

    .mes {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        padding-bottom: 20px;
        align-items: center;
    }

    .invoiceTxt {
        margin-right: 10px;
    }

    .invoiceInput {
        width: 270px !important;

        ::v-deep .el-input__inner {
            border-radius: 4px !important;
            background: rgba(255, 255, 255, 1) !important;
            width: 260px !important;
            height: 35px !important;
        }
    }

    .retailer {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(128, 128, 128, 1);
        padding-right: 10px;
    }

}

.moneyDetail {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;

    .moneyList {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 28.96px;
        color: rgba(128, 128, 128, 1);
        text-align: right;
        vertical-align: top;

        .moneyItem {
            padding: 10px 60px;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
        }

        .realMoney {
            display: flex;
            align-items: center;
            justify-content: end;
            font-size: 26px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 37.65px;
            color: rgba(56, 56, 56, 1);
            text-align: right;
            vertical-align: top;
            padding: 15px 0;

            span {
                font-size: 36px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 52.13px;
                color: rgba(255, 85, 0, 1);
                text-align: right;
                vertical-align: top;
            }

        }

    }
}

.totalMoney {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 30px 0;

    .tDes {
        font-size: 26px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 37.65px;
        color: rgba(56, 56, 56, 1);
    }

    .tCount {
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 37.65px;
        color: rgba(56, 56, 56, 1);
        padding: 0 20px;
    }
}

.orderdetails {

    .odetail {
        display: flex;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 28.96px;
        color: rgba(56, 56, 56, 0.8);
        text-align: left;
        vertical-align: top;
        margin-bottom: 60px;

        .receiverText {
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 34.75px;
            color: rgba(0, 0, 0, 1);
            text-align: left;
            vertical-align: top;
            width: 20%;
        }

        .part1 {
            width: 30%;

            .dPart1 {
                margin-bottom: 20px;
            }
        }

        .part2 {
            width: 60%;

            .part2_d {
                display: flex;
                justify-content: space-between;
            }

            .dPart1 {
                margin-bottom: 20px;
            }

            .dPart2 {
                width: 50%;
            }
        }
    }
}

.readSoftXieyi {
    text-align: center;
    margin-bottom: 30px;
}

#alipayFrame {
    width: 600px;
    height: 300px;
}

.finishStep {
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(56, 56, 56, 1);
    text-align: center;
    vertical-align: top;
    margin-bottom: 10px;
}

.finishSoft {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(56, 56, 56, 1);
    text-align: center;
    vertical-align: top;
    margin-bottom: 80px;
}

.softs {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;

    .softmap {
        margin: 0 50px;

        .softImg_div {
            height: 82px;
            display: flex;
            justify-content: center;
            margin-bottom: 8px;
        }

        .softImg {
            width: 82px;
            height: 82px;
            opacity: 1;
            border-radius: 12px;
            background: rgba(242, 242, 242, 1);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 62px;
            }
        }
    }

    .softDownload {
        display: flex;
        justify-content: space-between;

        span {
            border-bottom: 1px solid rgba(56, 56, 56, 1);
            padding: 0 3px;
            margin: 0 10px;
            cursor: pointer;
        }
    }
}

.backCart {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 56px;
    color: rgba(128, 128, 128, 1);
    text-align: left;
    vertical-align: top;

    img {
        height: 20px;
        margin-right: 10px;
    }
}

.pendingPaymentOrder {
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(56, 56, 56, 1);
    text-align: center;
    vertical-align: top;
    margin-bottom: 20px;
}

.toPaymentOrder {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 30px;
    color: rgba(128, 128, 128, 1);
    text-align: center;
    vertical-align: top;
    display: flex;
    justify-content: center;

    p {
        width: 580px;
    }
}

.invoiceChoose {
    width: 100%;
    display: flex;
    align-items: center;

    .el-select {
        flex: 1
    }

    .el-tooltip {
        width: 26px;
        text-align: center;
    }
}
</style>