<template>
    <div :class="position ? 'search_div_position' : 'search_div'">
        <div class="search">
            <input type="text" :placeholder="searchContent" v-model="value">
            <span @click="search">搜索</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        position: {
            type: Boolean,
        },
        searchContent: {
            type: String,
        }
    },
    data() {
        return {
            value: ""
        };
    },
    methods: {
        search() {
            this.$emit('search', this.value);
        }
    }

};
</script>

<style>
::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.2px;
    color: rgba(166, 166, 166, 1);
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.2px;
    color: rgba(166, 166, 166, 1);
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.2px;
    color: rgba(166, 166, 166, 1);
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.2px;
    color: rgba(166, 166, 166, 1);
}
</style>

<style lang="scss" scoped>
.search_div {
    display: flex;
    justify-content: flex-end;
}

.search_div_position {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 50px;
    top: 70px
}

.search {
    width: 348px;
    height: 38px;
    opacity: 1;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 10px;

    input {
        height: 38px;
        border: none;
        width: 75%;
        background: rgba(255, 255, 255, 1);
    }

    span {
        width: 67px;
        height: 32px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        color: rgba(42, 130, 228, 1);
        text-align: center;
        cursor: pointer;
    }
}
</style>