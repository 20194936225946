<template>
	<!--在视频外面加一个容器-->
	<div class="input_video">
		<video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"
			@pause="onPlayerPause($event)"></video-player>
	</div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-flash';
// import { homePage } from '@/api/index'
export default {
	props: ["videosrc"],
	data() {
		return {
			// 视频播放
			playerOptions: {
				playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
				autoplay: false, //如果true,浏览器准备好时开始回放。
				muted: true, // 默认情况下将会消除任何音频。
				loop: false, // 视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [
					{
						type: 'video/mp4',
						// src:require('../../../public/video/Summit.mp4')
						src: 'https://www.yesmap.xyz/api/videos/GetMate?videoId=5c7dbd39-451a-4ddc-9999-f4ed8414907d'
					}
				],
				poster: require('../../../public/static/1666832102195.png'), //封面地址
				notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: true, //当前时间和持续时间的分隔符
					durationDisplay: true, //显示持续时间
					remainingTimeDisplay: false, //是否显示剩余时间功能
					fullscreenToggle: true //全屏按钮
				}
			},
		};
	},
	mounted() {
		if (this.videosrc) {
			this.playerOptions.sources[0].src = this.videosrc;
			// delete this.playerOptions.poster;
		}
		this.$bus.$off('suspend');
		this.$bus.$on('suspend', () => {
			this.onPlayerPause();
		});
		this.$bus.$off('reloadVideo');
		this.$bus.$on('reloadVideo', (msg , flag) => {
			this.playerOptions.sources[0].src = msg;
			this.playerOptions.autoplay = flag
		});
		// homePage().then(res=>{
		// 	// console.log(res,'res');
		// 	this.playerOptions.sources[0].src=res.data.data[0].videoSrc
		// })
	},
	methods: {
		onPlayerPause(player) {
			// console.log('player pause!', player)
		},

	}
};
</script>
<style>
.input_video {
	width: 99.9%;
	margin: 0 auto;
}

.vjs-poster {
	display: inline-block;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: 50% 52%;
	background-size: cover;
	background-color: #000000;
	cursor: pointer;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
}
</style>
