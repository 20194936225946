import { login, logout, getInfo, unlock } from "@/assets/API/user";
import { getCartList } from "@/assets/API/pay";
import { GetUserMesByToken, GetRetailerMesByToken } from "@/assets/API/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import qs from "qs";
import md5 from "js-md5";
const define = require("@/utils/define");

const state = {
  isLogin: false,
  token: getToken(),
  socket: "",
  showLoginPane: false,
  loginLoading: false,
  userInfo: {},
  retailerInfo: {},
  messageBadgeCount: 0,
  cartListCount: 0,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_SOCKET: (state, socket) => {
    state.socket = socket;
  },
  SET_LOGINSTATE: (state, isLogin) => {
    state.isLogin = isLogin;
  },
  SET_LOGINVISBILIT: (state, showLoginPane) => {
    state.showLoginPane = showLoginPane;
  },
  SET_LOGIN_LOADING: (state, loginLoading) => {
    state.loginLoading = loginLoading;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_RETAILERINFO: (state, userInfo) => {
    state.retailerInfo = userInfo;
  },
  SET_MESSAGEBADGECOUNT: (state, messageBadgeCount) => {
    state.messageBadgeCount = messageBadgeCount;
  },
  SET_CARTLISTCOUNT: (state, cartListCount) => {
    state.cartListCount = cartListCount;
  },
};
const actions = {
  login({ commit, dispatch }, userInfo) {
    const { account, password, code, timestamp, origin } = userInfo;
    return new Promise((resolve, reject) => {
      login(
        qs.stringify({
          account: account.trim(),
          password: md5(password),
          origin,
          code,
          timestamp,
          client_id: "admin",
          client_secret: "123456",
          scope: "all",
          grant_type: "password",
        })
      )
        .then((response) => {
          const { data } = response;
          commit("SET_TOKEN", data.token);
          setToken(data.token);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 保存token
  setToken({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_TOKEN", data.token);
      commit("SET_LOGINSTATE", true);
      setToken(data.token);
      resolve(data);
    });
  },
  // 获取用户信息
  getInfo({ state, commit, dispatch }) {
    if (!state.token) return false;
    return new Promise((resolve, reject) => {
      GetUserMesByToken()
        .then((response) => {
          const { data } = response;
          if (!data) reject("验证失败，请重新登录。");
          // if (!data) resolve('SIGN_IN_AGAIN')
          // if (!menuList.length) {
          //   reject("您的权限不足，请联系管理员");
          //   // resolve('INSUFFICIENT_PERMISSIONS')
          //   return false;
          // }
          commit("SET_USERINFO", data);
          localStorage.setItem("USERINFO", JSON.stringify(data));
          dispatch("getCartListCount", { userid: data.id });
          commit("SET_LOGINVISBILIT", false);
          commit("SET_LOGINSTATE", true);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取经销商用户信息
  getRetailerInfo({ state, commit, dispatch }) {
    if (!state.token) return false;
    return new Promise((resolve, reject) => {
      GetRetailerMesByToken()
        .then((response) => {
          const { data } = response;
          if (!data) reject("验证失败，请重新登录。");
          // if (!data) resolve('SIGN_IN_AGAIN')
          // if (!menuList.length) {
          //   reject("您的权限不足，请联系管理员");
          //   // resolve('INSUFFICIENT_PERMISSIONS')
          //   return false;
          // }
          commit("SET_RETAILERINFO", data);
          localStorage.setItem("RETAILERINFO", JSON.stringify(data));
          // dispatch('getCartListCount', {userid:data.id})
          commit("SET_LOGINVISBILIT", false);
          commit("SET_LOGINSTATE", true);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取购物车产品数量
  getCartListCount({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      getCartList(data)
        .then((response) => {
          let list = response.data.list;
          let count = 0;
          list.forEach((item) => {
            count += item.number;
          });
          if (count > 99) {
            count = "99+";
          }
          commit("SET_CARTLISTCOUNT", count);
          resolve(count);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_USERINFO", {});
          commit("SET_LOGINSTATE", false);
          removeToken();
          resetRouter();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  logoutRetailer({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_RETAILERINFO", {});
          commit("SET_LOGINSTATE", false);
          localStorage.removeItem("RETAILERINFO");
          removeToken();
          resetRouter();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_USERINFO", {});
      commit("SET_LOGIN_LOADING", false);
      removeToken();
      resolve();
    });
  },
};

const getters = {
  socket: (state) => state.user.socket,
  messageBadgeCount: (state) => state.user.messageBadgeCount,
  userInfo: (state) => state.user.userInfo,
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
