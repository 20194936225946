<template>
  <div class="block_center">
    <div class="banner">
      <div class="usTitle">
        <div class="titleText">关于我们</div>
        <div class="titleDes">ABOUT US</div>
      </div>
    </div>
    <div class="introduction" id="intro">
      <div class="introLeft">
        <div class="introText">MAPPING SOFTWARE BUILDER</div>
        <div class="introImg">
          <img src="../../assets/image/峰岭科技LOGO2.png" alt="" />
        </div>
      </div>
      <div class="introRight">
        <div class="titleBod">
          <div class="introTitle">
            <span class="blueTag">ENTERPRISE <br />INTRODUTION</span>
          </div>
          <div class="introT">企 业 介 绍</div>
          <div class="introContent">
            <div class="contentItem">
              武汉峰岭科技有限公司成立于2020年，位于武汉未来科技城，是一家专业研发空间数据处理平台的高新技术企业。
            </div>
            <div class="contentItem">
              公司长期专注于智能化空间数据处理软件的深入研发与持续创新升级，旗下自主研发的Summit系列软件在新型场景化测绘、单体化自动建模以及航测内业培训教学等领域均得到广泛应用。
            </div>
            <div class="contentItem">
              其中，SummitMap一站式智能成图平台集成新型基础测绘、激光点云数据成图和智能矢量采编入库功能，打造了工厂化的高效空间数据生产模式;SummitSolid一站式单体化系统融合多款单体化建模软件的先进技术，形成了独有的自动单体化建模体系，另有SummitLab智能化内业培训系统通过与生产软件的实时联机，实现定量化高效内业培训。
            </div>
            <div class="contentItem">
              铭记“成图软件建构者”的企业使命，峰岭科技始终坚持走国产化自主研发路线，力争成为空间信息处理软件的行业隐形冠军，不仅为基础测绘、自然资源、水利、电力、交通、规划等众多行业领域上下游客户赋能，更为空间信息处理软件的国产化自主可控贡献力量，深入服务好行业合作伙伴!
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mianCenter" id="culture">
      <div class="aboutContent">
        <div class="leftPart">
          <div class="leftText">
            <div class="introTitle">
              <span class="blueTag">CORPORATE <br />CULTURE</span>
            </div>
            <div class="introT">企 业 文 化</div>
          </div>
        </div>
        <div class="middle"></div>
        <div class="rightPart">
          <div class="desItem">
            <div class="desNum">01</div>
            <div class="desContent">
              <div class="title">愿景</div>
              <div class="titleDes">成图软件建构者</div>
            </div>
          </div>
          <div class="desItem">
            <div class="desNum">02</div>
            <div class="desContent">
              <div class="title">理念</div>
              <div class="titleDes">细节铸就产品 服务赢取市场</div>
              <div class="titleDes">规则定义底线 文化确立上限</div>
            </div>
          </div>
          <div class="desItem">
            <div class="desNum">03</div>
            <div class="desContent">
              <div class="title">使命</div>
              <div class="titleDes">为客户研发业内领先的生产力工具软件</div>
              <div class="titleDes">基于生产力工具软件提供生产工艺改进服务</div>
              <div class="titleDes">通过生产工艺改进助力智慧型地理数据工厂建设</div>
            </div>
          </div>
          <div class="desItem">
            <div class="desNum">04</div>
            <div class="desContent">
              <div class="title">核心价值观</div>
              <div class="titleDes">每个人通过自己的努力</div>
              <div class="titleDes">都能获取合理的报酬和有尊严的生活</div>
            </div>
          </div>
        </div>
      </div>
      <div class="honors" id="qualifications">
        <div class="honorImg">
          <div class="imgItem1">
            <img src="../../assets/image/qualification5.png" alt="" />
            <img src="../../assets/image/qualification6.png" alt="" />
          </div>
          <div class="imgItem">
            <img src="../../assets/image/qualification3.png" alt="" />
            <img src="../../assets/image/qualification4.png" alt="" />
          </div>
          <div class="imgItem1">
            <img src="../../assets/image/qualification1.png" alt="" />
            <img src="../../assets/image/qualification2.png" alt="" />

          </div>
        </div>
        <div class="certificate">
          <div class="textContent">
            <div class="honorText">
              <div class="introTitle">
                <span class="blueTag">HONORARY<br />QUALIFICATIONS</span>
              </div>
              <div class="introT">荣 誉 资 质</div>
            </div>
          </div>
          <div class="honorDes">
            <div class="desContetn">
              经过长期自主研发创新，公司目前已拥有自主知识产权30余项，发明专利7项，并以每年新增2~3项发明专利的速度快速构建自有技术保护体系。
            </div>
            <div class="honorNumContent">
              <div class="honorNum">
                <div class="hNum">
                  <span class="bigU">30</span>
                  <span>+</span>
                </div>
                <div class="hDes">自主知识产权</div>
              </div>
              <div class="numDivide"></div>
              <div class="honorNum">
                <div class="hNum">
                  <span class="bigU">7</span>
                  <span>+</span>
                </div>
                <div class="hDes">发明专利</div>
              </div>
            </div>
          </div>
          <div>
            <el-carousel indicator-position="outside" :autoplay="false" @change="changeLoop">
              <el-carousel-item v-for="item in certificateImgListNum" :key="item">
                <div class="certificateImg">
                  <div class="certificateItem" v-for="(item, index) in loopcertificateImgList" :key="index">
                    <el-image style="
                        width: 100%;
                        box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.25);
                      " :src="item.img" :preview-src-list="item.srcList"></el-image>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="contactUs" id="aboutmap">
        <div class="contactText">
          <div class="introTitle">
            <span class="blueTag">CONTACT US</span>
          </div>
          <div class="introT">联 系 我 们</div>
        </div>

        <div class="mapContainer">
          <iframe class="mapIframe" :src="srcIframe"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      srcIframe: "/pc/static/aboutUs/address.html",
      certificateImgList: [
        {
          img: require("../../assets/image/certificate_img1.jpg"),
          srcList: [require("../../assets/image/certificate_img1.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img2.jpg"),
          srcList: [require("../../assets/image/certificate_img2.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img3.jpg"),
          srcList: [require("../../assets/image/certificate_img3.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img4.jpg"),
          srcList: [require("../../assets/image/certificate_img4.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img5.jpg"),
          srcList: [require("../../assets/image/certificate_img5.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img6.jpg"),
          srcList: [require("../../assets/image/certificate_img6.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img7.jpg"),
          srcList: [require("../../assets/image/certificate_img7.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img8.jpg"),
          srcList: [require("../../assets/image/certificate_img8.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img9.jpg"),
          srcList: [require("../../assets/image/certificate_img9.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img10.jpg"),
          srcList: [require("../../assets/image/certificate_img10.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img11.jpg"),
          srcList: [require("../../assets/image/certificate_img11.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img12.jpg"),
          srcList: [require("../../assets/image/certificate_img12.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img13.jpg"),
          srcList: [require("../../assets/image/certificate_img13.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img14.jpg"),
          srcList: [require("../../assets/image/certificate_img14.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img15.jpg"),
          srcList: [require("../../assets/image/certificate_img15.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img16.jpg"),
          srcList: [require("../../assets/image/certificate_img16.jpg")],
        },
        {
          img: require("../../assets/image/certificate_img17.jpg"),
          srcList: [require("../../assets/image/certificate_img17.jpg")],
        },
      ],
      loopcertificateImgList: [],
      certificateImgListNum: 0,
      cooperativePartnerImgList: [
        {
          img: require("../../assets/image/cooperativePartner_img1.png"),
          height: "37px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img2.png"),
          height: "50px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img3.png"),
          height: "37px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img4.png"),
          height: "46px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img5.png"),
          height: "46px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img6.png"),
          height: "33px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img7.png"),
          height: "41px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img8.png"),
          height: "46px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img9.png"),
          height: "37px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img10.png"),
          height: "35px",
        },
        {
          img: require("../../assets/image/cooperativePartner_img11.png"),
          height: "40px",
        },
      ],
      rollHonorsList: [
        {
          img: require("../../assets/image/rollHonors_img1.png"),
        },
        {
          img: require("../../assets/image/rollHonors_img2.png"),
        },
        {
          img: require("../../assets/image/rollHonors_img3.png"),
        },
        {
          img: require("../../assets/image/rollHonors_img4.png"),
        },
        {
          img: require("../../assets/image/rollHonors_img5.png"),
        },
      ],
    };
  },
  mounted() {
    this.certificateImgListNum = Number(this.certificateImgList.length % 5);
    if (this.certificateImgList.length % 5 == 0) {
      this.certificateImgListNum = Number(this.certificateImgList.length / 5);
    } else {
      this.certificateImgListNum =
        parseInt(this.certificateImgList.length / 5) + 1;
    }
    this.loopcertificateImgList = this.certificateImgList.slice(0, 5);

    const { name } = this.$route.query;
    if (name) {
      var top = document.querySelector("#" + name).offsetTop;
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    }
  },
  watch: {},
  methods: {
    changeLoop(value) {
      this.loopcertificateImgList = this.certificateImgList.slice(
        value * 5,
        (value + 1) * 5
      );
    },
    handleMouseEnter() {
      document.querySelector(".rollHonors_ul").style.animationPlayState =
        "paused"; // animationPlayState 为paused时暂停
    },
    handleMouseLeave() {
      document.querySelector(".rollHonors_ul").style.animationPlayState = ""; // animationPlayState 为空时继续播放
    },
  },
};
</script>

<style scoped lang="scss" scoped>
.block_center {
  margin-top: 60px;

  .banner {
    display: flex;
    align-items: flex-end;
    // justify-content: center;
    // background-image: url("../../assets/image/aboutus_banner.png");
    // height: 389px;
    background-image: url("../../assets/image/关于我们Banner.png");
    height: 628px;
    background-size: 100%;
    // margin-bottom: 31px;

    .usTitle {
      padding-bottom: 265px;
      padding-left: 500px;

      .titleText {
        font-size: 48px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 69.5px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        vertical-align: top;
      }

      .titleDes {
        font-size: 40px;
        font-weight: 200;
        letter-spacing: 0px;
        line-height: 57.92px;
        color: rgba(35, 173, 222, 1);
        text-align: left;
        vertical-align: top;
      }
    }
  }
}

.introduction {
  background-image: url("../../assets/image/aboutusIntro.png");
  background-size: 100%;
  display: flex;
  padding: 170px 310px 163px;
  justify-content: space-between;

  .introLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .introText {
      writing-mode: vertical-rl;
      transform: rotateY(-180deg) rotateX(180deg);
    }

    .introImg {
      width: 80px;
      height: 80px;
    }
  }

  .introRight {
    width: 65%;

    .titleBod {
      .introTitle {
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 56px;
        color: rgba(56, 56, 56, 1);
        text-align: left;
        vertical-align: top;
      }

      .introT {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 13px;
        line-height: 43.44px;
        color: rgba(56, 56, 56, 1);
        text-align: left;
        vertical-align: top;
      }

      .introContent {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(99, 99, 99, 1);
        text-align: justify;
        vertical-align: top;
        padding-top: 50px;

        .contentItem {
          padding-top: 35px;
        }
      }
    }
  }

  .blueTag {
    position: relative;
    z-index: 10;
  }

  .blueTag::after {
    content: "";
    display: inline-block;
    background-color: rgba(43, 133, 229, 1);
    position: absolute;
    right: 0;
    top: 20px;
    height: 10px;
    width: 83px;
    z-index: -1;
  }
}

.intro_title {
  text-align: center;
  display: flex;
  flex-direction: column;

  .titleText {
    font-size: 36px;
    font-weight: 500;
    color: rgba(13, 21, 46, 1);
    margin-bottom: 5px;
  }

  .titleDes {
    font-size: 28px;
    font-weight: 400;
    color: rgba(13, 21, 46, 1);
  }

  .introTitle {
    font-size: 36px;
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 56px;
    color: rgba(56, 56, 56, 1);
    text-align: center;
    vertical-align: top;
  }

  .introT {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 13px;
    line-height: 43.44px;
    color: rgba(56, 56, 56, 1);
    text-align: center;
    vertical-align: top;
  }

  .blueTag {
    position: relative;
    z-index: 10;
  }

  .blueTag::after {
    content: "";
    display: inline-block;
    background-color: rgba(43, 133, 229, 1);
    position: absolute;
    right: -25px;
    top: 14px;
    height: 10px;
    width: 83px;
    z-index: -1;
  }
}

.mianCenter {
  .intro_title {
    text-align: center;
    display: flex;
    flex-direction: column;

    .titleText {
      font-size: 36px;
      font-weight: 500;
      color: rgba(13, 21, 46, 1);
      margin-bottom: 5px;
    }

    .titleDes {
      font-size: 28px;
      font-weight: 400;
      color: rgba(13, 21, 46, 1);
    }
  }

  .introdution {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 32px;
    color: rgba(99, 99, 99, 1);
    text-align: justify;
    vertical-align: top;

    .leftText {
      width: 58%;
    }

    img {
      width: 464px;
    }
  }

  .culture {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .cultureItem {
      width: 25%;
      height: 350px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(112, 112, 112, 1);
      vertical-align: top;
      padding-top: 89px;
      // background-color: rgba(223, 139, 139,0.6);

      .itemTitle {
        font-size: 20px;
        font-weight: 500;
        line-height: 28.96px;
        color: rgba(56, 56, 56, 1);
        margin-bottom: 30px;
        text-align: center;
      }

      .itemDes {
        text-align: center;
      }

      img {
        width: 165.34px;
        height: 9.48px;
        margin-left: 40px;
        margin-bottom: 30px;
      }
    }

    .itemOne {
      background-image: url("../../assets/image/culture_bg.png");
      background-size: 100%;
      background-repeat: no-repeat;

      .divider {
        display: flex;
        align-items: center;
        margin-left: 40px;
        margin-bottom: 30px;
        color: rgba(0, 119, 255, 1);

        .line {
          width: 105.68px;
          height: 2px;
          opacity: 1;
          background: rgba(0, 119, 255, 1);
        }

        .dot {
          width: 9.34px;
          height: 9.48px;
          opacity: 1;
          background: rgba(0, 119, 255, 1);
          border-radius: 50%;
        }
      }
    }
  }

  .chronicles {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .leftImg {
      width: 470px;
      height: 470px;
      background-image: url("../../assets/image/chronicles_img.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: relative;

      .year {
        font-size: 80px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 115.84px;
        color: rgba(0, 119, 255, 1);
        position: absolute;
        top: 25px;
        right: -115px;
      }
    }

    .rightContent {
      width: 529px;
      padding-top: 126px;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.3px;
      line-height: 28.96px;
      color: rgba(0, 0, 0, 1);
      text-align: justify;
      vertical-align: top;

      .rightItem {
        margin-bottom: 27px;
      }

      ::v-deep .el-pagination {
        text-align: center;
        margin-top: 84px;
      }
    }
  }

  .honors {
    // .rollHonors {
    //   width: 100vw;
    //   // background: rgb(241, 203, 209);
    //   overflow: hidden;
    //   margin-bottom: 154px;

    //   .rollHonors_ul {
    //     // width: 1500px;
    //     // height: 390px;
    //     // background: yellowgreen;
    //     animation: slideLeftToRight 20s linear infinite;
    //     display: flex;

    //     li {
    //       width: 245px;
    //       height: 337px;
    //       position: relative;
    //       margin-right: 24px;
    //       background: rgb(235, 207, 212);

    //       .pic {
    //         width: 245px;
    //         height: 337px;
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         z-index: 3;
    //       }

    //       .bg {
    //         width: 259px;
    //         height: 356px;
    //         position: absolute;
    //         left: 26px;
    //         top: 0;
    //         z-index: 2;
    //       }
    //     }
    //   }
    // }
    background: linear-gradient(to bottom, rgba(240, 240, 240, 1) 0%, rgba(240, 240, 240, 1) 100%) no-repeat;
    background-size: 600px 100%;
    // background-position: center;
    display: flex;

    .honorImg {
      display: flex;
      width: 730px;
      flex-direction: column-reverse;
      // align-items: flex-end;

      .imgItem {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 40px;

        img {
          width: 255px;
          height: 185px;
          padding-left: 40px;
        }
      }

      .imgItem1 {
        display: flex;
        padding-bottom: 40px;

        img {
          width: 255px;
          height: 185px;
          padding-left: 40px;
        }
      }
    }

    .textContent {
      display: flex;
      justify-content: left;
      padding-bottom: 25px;
    }

    .honorDes {
      padding-bottom: 100px;
      display: flex;

      .desContetn {
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 34px;
        color: rgba(99, 99, 99, 1);
        text-align: justify;
        vertical-align: top;

      }

      .honorNumContent {
        display: flex;
        padding-left: 100px;

        .numDivide {
          margin: 20px 25px;
          width: 1px;
          background-color: rgba(229, 229, 229, 1);
          padding: 10px 0;
        }

        .honorNum {
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(128, 128, 128, 1);
          text-align: left;
          vertical-align: top;

          .hNum {
            font-size: 60px;
            font-weight: 700;
            letter-spacing: 0px;
            // line-height: 60px;
            color: rgba(42, 130, 228, 1);
            text-align: left;
            vertical-align: bottom;
          }

        }
      }
    }

    .honorText {
      .introTitle {
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 56px;
        color: rgba(56, 56, 56, 1);
        text-align: left;
        vertical-align: top;
      }

      .introT {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 13px;
        line-height: 43.44px;
        color: rgba(56, 56, 56, 1);
        text-align: left;
        vertical-align: top;
      }

      .blueTag {
        position: relative;
        z-index: 10;
      }

      .blueTag::after {
        content: "";
        display: inline-block;
        background-color: rgba(43, 133, 229, 1);
        position: absolute;
        right: 10px;
        top: 16px;
        height: 10px;
        width: 83px;
        z-index: -1;
      }

    }

    .certificate {
      flex: 1;
      padding: 123px 311px 72px 10px;
      // background: rgba(21, 34, 53, 1);

      .certificateImg {
        width: 1200px;
        margin: 0 auto;
        display: flex;

        .certificateItem {
          width: 19%;
          box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.25);
          display: flex;
          align-items: center;
          margin-left: 15px;

          img {
            width: 100%;
            box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.25);
          }
        }
      }

      ::v-deep .el-carousel__container {
        height: 300px;
      }

      ::v-deep .el-carousel__indicators--outside {
        display: none;
      }

      ::v-deep .el-carousel__arrow {
        background: rgba(179, 174, 174, 0.6);
      }
    }
  }

  .cooperativePartner {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 67px;
    background-image: url("../../assets/image/cooperativePartner_bg.png");
    background-size: 1045.3px 677px;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 51px;

    .zhu {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(56, 56, 56, 1);
      text-align: center;
      vertical-align: top;
      margin-bottom: 68px;
    }

    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        width: 220px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        border-radius: 2px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 12px rgba(42, 130, 228, 0.1);
        margin-bottom: 42px;
        margin-right: 25px;

        .item {
          display: flex;
        }

        .text {
          font-size: 16.5px;
          font-weight: 500;
          letter-spacing: -0.3px;
          line-height: 23.89px;
          color: rgba(0, 0, 0, 1);
          text-align: left;
          vertical-align: top;

          .eng {
            font-size: 5.5px;
            letter-spacing: -0.1px;
            line-height: 7.96px;
            color: rgba(0, 0, 0, 1);
          }
        }
      }

      li:nth-child(5n) {
        margin-right: 0;
      }

      li:hover {
        background: rgba(0, 119, 255, 1);
        box-shadow: 0px 0px 4px rgba(42, 130, 228, 0.25);
      }
    }
  }

  .aboutContent {
    background-color: #152235;
    padding: 100px 310px;
    display: flex;

    .blueTag {
      position: relative;
      z-index: 10;
    }

    .blueTag::after {
      content: "";
      display: inline-block;
      background-color: rgba(43, 133, 229, 1);
      position: absolute;
      right: -90px;
      top: 16px;
      height: 10px;
      width: 83px;
      z-index: -1;
    }


    .leftPart {
      width: 450px;
      height: 685px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .leftText {}

      .introTitle {
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 56px;
        color: #fff;
        text-align: left;
        vertical-align: top;
      }

      .introT {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 13px;
        line-height: 43.44px;
        color: #fff;
        text-align: left;
        vertical-align: top;
      }

    }

    .middle {
      width: 1px;
      background-color: #B1B8C7;
      margin: 0 100px;
    }

    .rightPart {
      display: flex;
      flex-direction: column;

      .desItem {
        flex: 1;
        display: flex;
        padding-top: 20px;

        .desNum {
          font-size: 60px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 60px;
          color: rgba(43, 133, 229, 1);
          text-align: justify;
          vertical-align: top;
        }

        .desContent {
          margin-left: 10px;

          .title {
            padding-top: 26px;
            padding-bottom: 10px;

            font-size: 24px;
            font-weight: 400;
            letter-spacing: -0.5px;
            line-height: 34.75px;
            color: #fff;
            text-align: left;
            vertical-align: top;
          }

          .titleDes {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.5px;
            line-height: 32px;
            color: #B1B8C7;
            text-align: left;
            vertical-align: top;

          }
        }

      }
    }
  }

  .contactUs {
    background: rgba(247, 248, 251, 1);

    .contactText {
      padding: 100px 0;
    }

    .introTitle {
      font-size: 36px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 56px;
      color: rgba(56, 56, 56, 1);
      text-align: center;
      vertical-align: top;
    }

    .introT {
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 13px;
      line-height: 43.44px;
      color: rgba(56, 56, 56, 1);
      text-align: center;
      vertical-align: top;
    }

    .blueTag {
      position: relative;
      z-index: 10;
    }

    .blueTag::after {
      content: "";
      display: inline-block;
      background-color: rgba(43, 133, 229, 1);
      position: absolute;
      right: -20px;
      top: 16px;
      height: 10px;
      width: 83px;
      z-index: -1;
    }
  }

  // .intro {
  //     padding: 80px 50px;
  //     text-align: center;
  //     display: flex;
  //     flex-direction: column;

  //     .introDes {
  //         font-size: 35px;
  //         color: rgba(237, 237, 237, 1);
  //         font-weight: 400;
  //     }

  //     .introText {
  //         font-size: 25px;
  //         font-weight: 700;
  //         letter-spacing: 0px;
  //         color: rgba(0, 0, 0, 1);
  //         position: relative;
  //     }

  //     .introText::after {
  //         position: absolute;
  //         bottom: -16px;
  //         left: 0;
  //         right: 0;
  //         height: 5px;
  //         background-color: #2A82E4;
  //         content: "";
  //         transform: scaleY(.5);
  //     }

  // }

  // .companyProfile {
  //     padding: 30px 200px;
  //     display: flex;

  //     .profileText {
  //         flex: 1;
  //         font-size: 28px;
  //         font-weight: 400;
  //         letter-spacing: 0px;
  //         line-height: 62px;
  //         color: rgba(0, 0, 0, 1);
  //         text-align: left;
  //         vertical-align: top;
  //         margin-right: 50px;
  //         text-indent: 2em;
  //     }

  //     .profileImg {
  //         flex: 1;
  //         background-image: url("../../assets/image/办公环境.png");
  //     }
  // }

  // .cultureContent {
  //     display: flex;

  //     .cultureItem {
  //         flex: 1;

  //         .cultureTitle {
  //             font-size: 32px;
  //             font-weight: 400;
  //             letter-spacing: 0px;
  //             line-height: 46.34px;
  //             color: rgba(0, 0, 0, 1);
  //             text-align: center;
  //             vertical-align: top;
  //             padding: 80px 0;
  //         }

  //         .cultureDes {
  //             font-size: 20px;
  //             font-weight: 400;
  //             letter-spacing: 0px;
  //             line-height: 28.96px;
  //             color: rgba(0, 0, 0, 1);
  //             text-align: center;
  //             vertical-align: top;
  //             padding-bottom: 80px;

  //         }
  //     }

  //     .singleItem {
  //         background-image: url("../../assets/image/aboutusBg3.png");

  //     }

  //     .mutiItem {
  //         background-image: url("../../assets/image/aboutusBg4.png");

  //     }
  // }

  // .qualificationBg {
  //     background-image: url("../../assets/image/aboutusBg2.png");
  //     background-repeat: no-repeat;

  //     .qualificationText {
  //         margin-top: 30px;
  //         font-size: 20px;
  //         font-weight: 400;
  //         letter-spacing: 0px;
  //         line-height: 28.96px;
  //         color: rgba(56, 56, 56, 1);
  //     }

  //     .qualifiImg {
  //         text-align: center;
  //         padding-bottom: 40px;
  //     }

  //     .qualificationIntro {
  //         padding: 80px 50px 20px;
  //     }
  // }

  .mapContainer {
    width: 1200px;
    height: 600px;
    margin: 0 auto;
    // padding: 0 100px;
    margin-bottom: 80px;

    .mapIframe {
      width: 100%;
      height: 100%;
      border: 0px;
      border-radius: 20px;
      overflow: hidden;
    }
  }
}

@keyframes slideLeftToRight {
  0% {
    transform: translateX(100%);
  }

  /* 初始位置，向右移动100% */
  100% {
    transform: translateX(-100%);
  }

  /* 最终位置，向左移动100% */
}
</style>