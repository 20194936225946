<template>
    <el-dialog title="发票详情" class="abow_dialog" :close-on-click-modal="false" lock-scroll append-to-body width="1200px"
        :visible.sync="visible" :show-close="false">
        <div class="dialog-main">
            <el-form ref="elForm" :model="dataForm" size="small" label-width="100px" label-position="right">
                <template v-if="!loading">
                    <el-col :span="24">
                        <el-divider content-position="left">基本信息</el-divider>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户" prop="username">
                            <p>{{ dataForm.username }}</p>
                        </el-form-item>
                        <el-form-item label="开票公司" prop="company">
                            <p>{{ dataForm.company }}</p>
                        </el-form-item>
                        <el-form-item label="纳税人识别号" prop="ratenumber">
                            <p>{{ dataForm.ratenumber }}</p>
                        </el-form-item>
                        <el-form-item label="开票方地址" prop="address">
                            <p>{{ dataForm.address }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开票方电话" prop="phone">
                            <p>{{ dataForm.phone }}</p>
                        </el-form-item>
                        <el-form-item label="开票方邮箱" prop="email">
                            <p>{{ dataForm.email }}</p>
                        </el-form-item>
                        <el-form-item label="开户行及账号" prop="banknum">
                            <p>{{ dataForm.banknum }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-divider content-position="left">订单信息</el-divider>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="订单号" prop="ordername">
                            <p>{{ dataForm.ordername }}</p>

                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-show="orderDatas.length > 0">
                        <el-form-item label="订单详情" prop="ordernames">
                            <!-- 显示订单详细信息 -->
                            <div v-for="(item, index) in orderDatas" :key="item.id">
                                <div>
                                    <span>{{ item.ordername }}</span> ：
                                    <span>{{ getDetailOrder(item.id, index) }}</span>
                                </div>

                            </div>
                            <!-- 产品总和 -->

                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开票状态" prop="status">
                            <el-select v-model="dataForm.status" @change="changeData('status', -1)" placeholder="请选择"
                                clearable :style='{ "width": "100%" }'>
                                <el-option v-for="(item, index) in statusOptions" :key="index" :label="item.fullName"
                                    :value="item.id" :disabled="true"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发票类型" prop="type">
                            <el-select v-model="dataForm.type" @change="changeData('type', -1)" placeholder="请选择"
                                clearable :style='{ "width": "100%" }'>
                                <el-option v-for="(item, index) in typeOptions" :key="index" :label="item.fullName"
                                    :value="item.id" :disabled="true"></el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实付金额" prop="amount">
                            <p>{{ dataForm.amount }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="可开票金额" prop="amountgross">
                            <p>{{ dataForm.amountgross }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="税率" prop="taxrate">
                            <p>{{ dataForm.taxrate }}</p>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="24">
                        <el-form-item label="发票内容" prop="content">
                            <Summit-UploadFz v-model="dataForm.content" @change="changeData('content', -1)" :fileSize="10"
                                sizeUnit="MB" :limit="9" buttonText="点击上传">

                            </Summit-UploadFz>
                        </el-form-item>
                    </el-col> -->
                </template>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancel">取消</el-button>
            <!-- <el-button type="primary" @click="onConfirm()">确定</el-button> -->
        </span>
    </el-dialog>
</template>

<script>
import request from '@/utils/request';

export default {
    components: {},
    data() {
        return {
            visible: false,
            loading: false,
            dataForm: {
                id: '',
                creatortime: '',
                creatoruserid: '',
                username: '',
                userid: '',
                orderid: '',
                company: '',
                status: "0",
                amount: 0,
                amountgross: 0,
                content: [],
                type: "0",
                taxrate: '',
                ratenumber: '',
                address: '',
                phone: '',
                banknum: '',
            },
            statusOptions: [{ "fullName": "未开票", "id": 0 }, { "fullName": "已开票", "id": 1 }],
            typeOptions: [{ "fullName": "普通发票", "id": 0 }, { "fullName": "专用发票", "id": 1 }],
            orderDatas: [],
            orderDetailList: []
        };
    },
    computed: {
    },
    filters: {
        dynamicText(value, options) {
            if (!value) return '';
            if (Array.isArray(value)) {
                if (!options || !Array.isArray(options)) return value.join();
                let textList = [];
                for (let i = 0; i < value.length; i++) {
                    let item = options.filter(o => o.id == value[i])[0];
                    if (!item || !item.fullName) {
                        textList.push(value[i]);
                    } else {
                        textList.push(item.fullName);
                    }
                }
                return textList.join();
            }
            if (!options || !Array.isArray(options)) return value;
            let item = options.filter(o => o.id == value)[0];
            if (!item || !item.fullName) return value;
            return item.fullName;
        }
    },
    methods: {
        init(id) {
            this.dataForm.id = id || 0;

            this.$nextTick(() => {
                if (this.dataForm.id) {
                    this.loading = true;
                    request({
                        url: '/api/business/C_invoice/detail/' + this.dataForm.id,
                        method: 'get'
                    }).then(res => {
                        this.dataInfo(res.data);
                        this.loading = false;
                    });
                }

            });
        },
        dataInfo(dataAll) {
            let _dataAll = dataAll;
            _dataAll.status = _dataAll.status ? JSON.parse(_dataAll.status) : [];
            _dataAll.content = _dataAll.content ? JSON.parse(_dataAll.content) : [];
            _dataAll.type = _dataAll.type ? JSON.parse(_dataAll.type) : [];
            this.dataForm = _dataAll;
            this.showBackOrder(dataAll);
        },
        cancel() {
            this.visible = false;
        },
        // 回显订单详情数据
        showBackOrder(data) {
            console.log(data, 'qqq');
            if (data.orderid.length > 0) {
                this.orderDatas = [];
                let ids = data.orderid.split(',');
                ids.forEach(ele => {
                    let query = {
                        ordercode: ele
                    };
                    let orderDetail = [];
                    request({
                        url: '/api/business/C_orderDetail/getList/',
                        method: 'post',
                        data: query
                    }).then(res => {
                        res.data.list.forEach(e => {
                            orderDetail.push(e.productname + '*' + e.number + '*' + e.duration + "个月");
                        });
                    });
                    this.orderDetailList.push(orderDetail);
                    request({
                        url: '/api/business/C_orderManage/detail/' + ele,
                        method: 'get',
                    }).then(res => {
                        this.orderDatas.push(res.data);
                        this.visible = true;
                    });
                });
            } else {
                this.visible = true;
            }
        },
        // 获取订单详情数据
        getDetailOrder(id, index) {
            return this.orderDetailList[index].toString();
        },
    }
};
</script>
<style scoped lang="scss">
// .abow_dialog {
//     display: flex;
//     justify-content: center;
//     align-items: Center;
//     overflow: hidden;

//     .el-dialog {
//         margin: 0 auto !important;
//         height: 90%;
//         overflow: hidden;

//         .el-dialog__body {
//             position: absolute;
//             left: 0;
//             top: 54px;
//             bottom: 0;
//             right: 0;
//             padding: 0;
//             z-index: 1;
//             overflow: hidden;
//             overflow-y: auto;
//         }
//     }
// }

.dialog-main {
    height: 60vh;
}

::v-deep .el-dialog__body {
    padding-top: 0px;
}

::v-deep .el-dialog {
    margin-top: 10vh !important;
}

// table
// ::v-deep .el-table {
//     height: calc(100% - 125px) !important;
// }

:deep .el-table__header {
    border-radius: 20px 20px, 0px, 0px;
    background-color: rgba(245, 245, 245, 1);
}

:deep .el-table th.el-table__cell {
    background-color: rgba(245, 245, 245, 1);
    border-radius: 20px;
    border: none;
}

:deep .el-table__body tr:hover>td {
    background-color: rgba(245, 245, 245, 1) !important;
}

:deep .el-table tr {
    height: 46px;
}

// :deep .el-table__body-wrapper {
//     height: calc(100% - 47px);
//     overflow-y: auto;
// }

:deep .el-table__empty-block {
    min-height: 50px;
}

:deep .el-table th.el-table__cell>.cell {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(128, 128, 128, 0.7);
}

// }</style>