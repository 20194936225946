<template>
    <el-dialog title="订单详情" :close-on-click-modal="false" lock-scroll append-to-body width="1200px"
        :visible.sync="visible" :show-close="false">
        <div class="dialog-main">
            <el-col :span="24">
                <el-divider content-position="left">订单明细</el-divider>
            </el-col>
            <el-col :span="24">
                <el-table :data="productDataList" size='medium' class="orderDetailTable">
                    <el-table-column type="index" width="80" label="序号" align="center" />
                    <el-table-column prop="productname" label="产品名称" width="" align="center" />
                    <!-- <el-table-column prop="productcode" label="产品编号" width="" /> -->
                    <el-table-column prop="projectprice" label="价格" width="" align="center">
                        <template slot-scope="scope">
                            ￥{{ scope.row.projectprice }}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="featureid" label="FeatureID" width="" /> -->
                    <el-table-column prop="duration" label="订阅时长(/月)" width="" align="center" />
                    <el-table-column prop="number" label="数量" width="" align="center" />
                </el-table>
            </el-col>
            <el-col :span="24">
                <div class="totalMoneyClass">订单总价：<div class="money">￥{{ totalMoney }}</div>
                </div>
            </el-col>
            <el-col :span="24">
                <el-form ref="elForm" :model="dataForm" size="small" label-width="120px" label-position="right">
                    <el-col :span="24">
                        <el-divider content-position="left">订单信息</el-divider>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="交易状态" prop="paymentstate">
                            <p>{{ getPaymentStateValue(dataForm.paymentstate) }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="优惠码" prop="retailercode">
                            <p>{{ dataForm.retailercode ? dataForm.retailercode : "-" }}</p>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="是否售后" prop="afterservice">
                            <p>{{ dataForm.afterservice ? dataForm.afterservice : "-" }}</p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-divider content-position="left">发票信息</el-divider>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否开具发票" prop="isinvoice">
                            <p>{{ dataForm.isinvoice ? dataForm.isinvoice : "-" }}</p>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-divider content-position="left">用户信息</el-divider>
                    </el-col>
                    <!-- <el-col :span="12">
                    <el-form-item label="下单用户id" prop="userid">
                        <p>{{ dataForm.userid ? dataForm.userid : "-" }}</p>
                    </el-form-item>
                    <el-form-item label="订单名" prop="ordername">
                        <p>{{ dataForm.ordername ? dataForm.ordername : "-" }}</p>
                    </el-form-item>
                    <el-form-item label="付款金额" prop="paymentamount">
                        <p>{{ dataForm.paymentamount ? dataForm.paymentamount : "-" }}</p>
                    </el-form-item>
                    <el-form-item label="支付宝订单号" prop="alipayordernum">
                        <p>{{ dataForm.alipayordernum ? dataForm.alipayordernum : "-" }}</p>
                    </el-form-item>
                    <el-form-item label="发票id" prop="invoiceid">
                        <p>{{ dataForm.invoiceid ? dataForm.invoiceid : "-" }}</p>
                    </el-form-item>
                    <el-form-item label="邀请码" prop="invitationcode">
                        <p>{{ dataForm.invitationcode ? dataForm.invitationcode : "-" }}</p>
                    </el-form-item>
                </el-col> -->
                    <!-- <el-col :span="24">
                    <el-divider content-position="left">收件人信息</el-divider>
                </el-col> -->
                    <el-col :span="12">
                        <el-form-item label="用户姓名" prop="receivername">
                            <p>{{ dataForm.receivername ? dataForm.receivername : "-" }}</p>
                        </el-form-item>
                        <el-form-item label="电子邮箱" prop="receiveremail">
                            <p>{{ dataForm.receiveremail ? dataForm.receiveremail : "-" }}</p>
                        </el-form-item>

                        <!-- <el-form-item label="物流单号" prop="tracknum">
                        <p>{{ dataForm.tracknum ? dataForm.tracknum : "-" }}</p>
                    </el-form-item> -->
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" prop="receiverphone">
                            <p>{{ dataForm.receiverphone ? dataForm.receiverphone : "-" }}</p>
                        </el-form-item>
                        <el-form-item label="地址" prop="receiveraddress">
                            <p>{{ dataForm.receiveraddress ? dataForm.receiveraddress : "-" }}</p>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-col>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancel">取消</el-button>
            <!-- <el-button type="primary" @click="onConfirm()">确定</el-button> -->
        </span>
    </el-dialog>
</template>

<script>
import request from '@/utils/request';

export default {
    components: {},
    data() {
        return {
            visible: false,
            loading: false,
            productDataList: [],
            dataForm: {
                id: '',
                username: '',
                userid: '',
                ordernum: '',
                ordername: '',
                purchasemethod: '',
                paymentstate: '',
                paymentamount: 0,
                productiontype: '',
                tracknum: '',
                receivername: '',
                receiverphone: '',
                receiveraddress: '',
                receiveremail: '',
                alipayordernum: '',
                isinvoice: '',
                invoiceid: '',
                retailercode: '',
                afterservice: '',
                invitationcode: '',
            },
            paymentStateList: [
                {
                    value: "0",
                    label: '未支付'
                },
                {
                    value: "1",
                    label: '已支付'
                },
                {
                    value: "2",
                    label: '支付失败'
                },
            ],
            totalMoney: 0,
        };
    },
    computed: {

    },
    methods: {
        init(id) {
            this.dataForm.id = id || 0;
            this.visible = true;
            this.$nextTick(() => {
                if (this.dataForm.id) {
                    // this.loading = true;
                    request({
                        url: '/api/business/C_orderManage/detail/' + this.dataForm.id,
                        method: 'get'
                    }).then(res => {
                        this.dataForm = res.data;
                    });
                    let query = {
                        ordercode: this.dataForm.id
                    };
                    // 初始化订单明细数据
                    request({
                        url: '/api/business/C_orderDetail/getList',
                        method: 'post',
                        data: query
                    }).then(res => {
                        this.productDataList = res.data.list;
                        this.productDataList.forEach(e => {
                            if (e.durationunion == "week") {
                                e.projectprice = e.weekprice;
                            } else if (e.durationunion == "month") {
                                e.projectprice = e.monthprice;
                            } else if (e.durationunion == "season") {
                                e.projectprice = e.seasonprice;
                            } else if (e.durationunion == "halfyear") {
                                e.projectprice = e.halfyearprice;
                            }
                        });
                        this.totalMoney = this.getTotalMoneyNum();
                        // this.loading = false;
                    });
                }
            });
        },
        // 计算总价
        getTotalMoneyNum() {
            let result = 0;
            for (let i = 0; i < this.productDataList.length; i++) {
                const e = this.productDataList[i];
                if (e.durationunion == "week") {
                    result += e.duration * e.weekprice * e.number;
                } else if (e.durationunion == "month") {
                    result += e.duration * e.monthprice * e.number;
                } else if (e.durationunion == "season") {
                    result += e.duration * e.seasonprice * e.number;
                } else if (e.durationunion == "halfyear") {
                    result += e.duration * e.halfyearprice * e.number;
                }
            }
            return result;
        },
        getPaymentStateValue(val) {
            let res = this.paymentStateList.find(function (e) {
                return e.value == val;
            });
            return res.label;
        },
        cancel() {
            this.visible = false;
        },

    }
};
</script>
<style scoped lang="scss">
// .dialog-main {

::v-deep .el-dialog__body {
    padding-top: 0px;
}

::v-deep .el-dialog {
    margin-top: 4vh !important;
}

// table
// ::v-deep .el-table {
//     height: calc(100% - 125px) !important;
// }

:deep .el-table__header {
    border-radius: 20px 20px, 0px, 0px;
    background-color: rgba(245, 245, 245, 1);
}

// :deep .el-table th.el-table__cell {
//     background-color: rgba(245, 245, 245, 1);
//     border-radius: 20px;
//     border: none;
// }
.orderDetailTable {
    :deep .el-table__header .has-gutter tr th {
        background-color: rgba(213, 231, 250, 1);
        border-right: 1px solid #fff;
        color: rgba(56, 56, 56, 1);
    }
}

:deep .el-table__body tr:hover>td {
    background-color: rgba(245, 245, 245, 1) !important;
}

:deep .el-table tr {
    height: 46px;
}

// :deep .el-table__body-wrapper {
//     height: calc(100% - 47px);
//     overflow-y: auto;
// }

:deep .el-table__empty-block {
    min-height: 50px;
}

// :deep .el-table th.el-table__cell>.cell {
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 22.4px;
//     color: rgba(128, 128, 128, 0.7);
// }

// }
.totalMoneyClass {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(141, 141, 141, 1);
    vertical-align: middle;
    padding: 24px;
    display: flex;
    justify-content: end;

    .money {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(255, 94, 0, 1);
        text-align: left;
        vertical-align: middle;
        display: inline-block;
    }
}
</style>