<template>
  <div class="login_bg">
    <div class="login_logo">
      <img src="../../assets/image/峰岭科技LOGO.png" alt="" />
      <p>成图软件的建构者</p>
    </div>
    <div class="loginPart">

      <div class="loginTable">
        <!-- 微信扫码 -->
        <div class="vxIframe">
          <iframe :src="vxLoginMsg" frameborder="0"></iframe>
        </div>
        <!-- 验证码登录 -->
        <div class="verCodePart">
          <el-tabs v-model="activeType">
            <el-tab-pane label="用户版" name="user">
              <el-form ref="form" :model="formUserItem" :rules="ruleValidate">
                <el-form-item prop="phone">
                  <el-input v-model="formUserItem.phone" placeholder="请输入手机号" @input="usernameInput()"
                    required></el-input>
                </el-form-item>
                <el-form-item prop="verification">
                  <el-input class="verCodeInput" v-model="formUserItem.verification" placeholder="请输入短信验证码"
                    @keyup.enter.native="handleSubmit()"></el-input>
                  <el-button type="primary" ghost class="verCodeBtn" :disabled="disable" @click="Sends()">
                    {{ yzm }}
                  </el-button>
                </el-form-item>
              </el-form>
              <div class="loginBtn">
                <el-button type="primary" :loading="loading" @click="handleSubmit()">立即登录</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="经销商" name="retailer"></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="loginText">登录视为您已同意第三方账号绑定协议、服务条款、隐私政策</div>
    </div>

    <p class="footer_bottom">
      Copyright&nbsp;©&nbsp;2022 &nbsp;武汉峰岭科技有限公司&nbsp;All Rights
      Reserved. &nbsp;&nbsp;&nbsp;
      <a href="https://beian.miit.gov.cn">鄂ICP备2022010992号-1</a>
      技术支持：武汉峰岭科技有限公司&nbsp;&nbsp;电话：177 8608 3608
    </p>
  </div>
</template>

<script>
// import VxLogin from "./components/vxLogin";
import {
  renderVxModel,
  GetVxUserMes,
  SendVerCode,
  LoginByWx,
  LoginByPhone
} from "@/assets/API/login";
// import { getUserIp, getMapaddren } from "@/api/ip.js";
import AntiShake from "@/utils/deTh.js";
export default {
  components: {},
  data() {
    return {
      yzm: "获取验证码",
      totalTime: 60,
      bool: true,
      disable: true,
      logins: true,
      dialogVisible: false,
      //  存放手机验证码
      verifica: "",
      //  存放已有用户手机号码
      tel: [],
      // 手机号码正则
      dialog:
        "欢迎访问峰岭科技网站!本网站目前只服务于专属用户，如需访问请联系客服，联系电话：177 8608 3608",
      formUserItem: {
        phone: "",
        verification: "",
      },
      ruleValidate: {
        phone: [
          {
            required: true,
            message: "手机号码不能为空",
            trigger: "blur",
          },
        ],
        verification: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      objCodes: "",
      ip: "",
      vxLoginMsg: '',
      activeType: 'user'
    };
  },
  computed: {
    codes() {
      return this.$route.query.code ? this.$route.query.code : "";
    },
  },
  mounted() {
    // 设置全屏
    this.$store.commit("isFull", true);
    // 扫码登录后跳转login路由 后拼接code state参数
    if (this.codes) {
      let query = {
        code: this.codes,
        state: this.$route.query.state
      };
      GetVxUserMes(query).then((res) => {
        // 老用户返回accessToken userInfo 新用户/老用户未绑定微信不返回
        this.objCodes = res.data;
        if (this.objCodes.accessToken && this.objCodes.userInfo) {
          // 登录成功 通过token获得用户数据
          let param = {
            token: res.data.accessToken,
          };
          this.$store.dispatch("user/setToken", param).then((res) => {
            // this.$router.push({
            //   path: "/Home",
            // });
            // 登录后返回上一页
            // let url = this.$route.query.redirect;
            // this.$router.push({
            //   path: decodeURIComponent(url),
            // });
            this.$store.dispatch("user/getInfo");
            const curr = localStorage.getItem('preRoute');
            if (curr == null) {
              this.$router.push({
                path: "/Home",
              });
            } else {
              this.$router.push({ path: curr });
            }
          });
        } else {
          // 新用户/老用户未绑定微信 跳转手机号发送验证码逻辑
          this.$message({
            message: '温馨提示：第一次扫码登陆需要绑定手机号。',
            type: 'warning'
          });
        }
      });
    } else {
      // 获取微信登录界面
      renderVxModel().then(res => {
        this.vxLoginMsg = res.msg;
      });
    }

  },
  watch: {},
  methods: {
    usernameInput() {
      const re = /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
      if (re.test(this.formUserItem.phone) && this.formUserItem.phone * 1 != "NaN") {
        if (this.totalTime < 60) {
          this.disable = true;
        }
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    handleSubmit() {
      if (!this.codes) {
        this.loading = true;
        const re =
          /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[0-9])\d{8}$/;
        if (re.test(this.formUserItem.phone)) {
          const data = {
            phone: this.formUserItem.phone,
            verCode: this.formUserItem.verification,
          };
          // 短信验证码登录
          LoginByPhone(data).then(res => {
            if (res.msg) {
              let param = {
                token: res.msg,
              };
              this.$store.dispatch("user/setToken", param).then((res) => {
                this.$store.dispatch("user/getInfo");
                this.$router.push({
                  path: "/Home",
                });
              });
            } else {
              this.$message({
                message: '登录失败',
                type: 'warning'
              });
            }
          });
        } else {
          this.dialogVisible = true;
        }
      } else {
        // GetLoginGwByWeixin // 首次扫码登录
        const re =
          /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
        if (re.test(this.formUserItem.phone)) {
          const data = {
            phone: this.formUserItem.phone,
            verCode: this.formUserItem.verification,
            openId: this.objCodes.openid,
            unionId: this.objCodes.unionid,
          };
          // 设置用户信息缓存
          LoginByWx(data).then(res => {
            if (res.msg) {
              let param = {
                token: res.msg,
              };
              this.$store.dispatch("user/setToken", param).then((res) => {
                this.$router.push({
                  path: "/Home",
                });
              });
            } else {
              this.$message({
                message: '登录失败',
                type: 'warning'
              });
            }
          });
        } else {
          this.dialogVisible = true;
        }
      }
    },
    // 60S验证码
    Sends() {
      this.disable = true;
      const data = this.formUserItem.phone.toString();
      SendVerCode(data).then((res) => {
        if (res.msg == "false") {
          this.dialogVisible = true;
          this.disable = true;
        } else {
          this.bool = false;
        }
      });
      this.yzm = this.totalTime + "s后重新发送";
      const clock = setInterval(() => {
        this.yzm = this.totalTime-- + "s后重新发送";
        if (this.totalTime < 0) {
          clearInterval(clock);
          this.disable = false;
          this.yzm = "重新发送验证码";
          this.totalTime = 60;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.login_bg {
  background-image: url("../../assets/image/loginbg1.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: cover;
  padding: 20px;
  position: relative;
  z-index: 999;

  .login_logo {
    img {
      width: 223px;
    }

    p {
      color: #fff;
      font-size: 22px;
      // letter-spacing: 0.4375rem;
      // padding-left: 30px;
      width: 250px;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 24%;
    width: 3rem;
    position: absolute;
    top: 32%;
    right: 12%;
    background-color: #eaf1f9ff;
    padding: 25px 0px;


    .table_form {
      width: 75%;

      ::v-deep.ivu-form-item-content {
        display: flex;
      }

      .ivu-input-wrapper {
        margin-right: 20px;
      }
    }
  }

  .btn {
    width: 30%;
    margin: 14px 0;
  }
}

.footer_bottom {
  font-size: 14px;
  color: #fff;
  position: absolute;
  bottom: 0px;

  a {
    color: #fff;
  }
}

.el-divider__text {
  background-color: #eaf1f9;
}

.otherLogin {
  cursor: pointer;
}

.loginPart {
  position: absolute;
  width: 50%;
  right: 10%;
  top: 20%;
  min-width: 500px;
  height: 518px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(166, 166, 166, 0.25);
  display: flex;
  flex-direction: column;

  .loginText {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: rgba(128, 128, 128, 1);
    text-align: center;
    vertical-align: top;
    padding: 20px 0;
  }

  .loginTable {
    flex: 1;
    display: flex;


    .vxIframe {
      flex: 1;
      position: relative;

      iframe {
        margin-top: 60px;
        width: 100%;
        height: 100%;
      }
    }

    .vxIframe::after {
      content: "";
      position: absolute;
      left: 93%;
      width: 2px;
      height: 100%;
      background: #ddd;
      transform: scaleY(0.8);
    }


  }
}
</style>
<style lang="scss">
.verCodePart {
  flex: 1;

  .el-tabs {

    margin: 54px 130px 0 0;

    .el-tabs__nav-wrap::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 150px;
      height: 2px;
      background-color: #E4E7ED;
      z-index: 1;

    }

    .el-tabs__item {
      font-size: 18px;
    }

    .verCodeInput {
      width: 62%;
    }

    .verCodeBtn {
      margin-left: 20px;
    }

    .el-tabs__content {
      padding-top: 26px;
    }

    .el-form-item {
      margin-bottom: 29px;

      .el-form-item__content {
        display: flex;
      }
    }
  }
}

.loginBtn {
  text-align: center;

  .el-button {
    width: 100%;
  }
}
</style>
// 支付成功之后，会有一个链接解析订单号
