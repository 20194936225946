<template>
    <div>
        <div class="banner">
        </div>
        <div class="container">

            <el-row style="min-height:calc(100vh - 200px )">
                <el-col :span="18" style="min-height:calc(100vh - 200px )">
                    <div class="card">
                        <el-tabs style="margin: 0 20px;min-height:calc(100vh - 200px )" v-model="type"
                            @tab-click="handleClick">
                            <el-tab-pane label="最新问答" name="0">
                                <TabContentVue :type="0" :questionId="this.questionId.fid" :tagList="this.tagList"
                                    ref="tabContent">
                                </TabContentVue>
                            </el-tab-pane>
                            <el-tab-pane label="热门问答" name="1">
                                <TabContentVue :type="1" :questionId="this.questionId.fid" :tagList="this.tagList">
                                </TabContentVue>
                            </el-tab-pane>
                            <el-tab-pane label="等待回答" name="2">
                                <TabContentVue :type="2" :questionId="this.questionId.fid" :tagList="this.tagList">
                                </TabContentVue>
                            </el-tab-pane>
                        </el-tabs>
                    </div>

                </el-col>
                <el-col :span="6" style="min-height:calc(100vh - 200px )">
                    <div class="card rightCard">
                        <div class="notice-title"> <span class="icon-Personal_list_line iconfont"
                                style="margin-right: 5px;"></span>创作中心
                        </div>
                        <div class="text-center">
                            <div class="row">
                                <div class="col">
                                    <div class="col-item">{{ adminQcount }}</div>
                                    <div class="col-item">我的提问</div>
                                </div>
                                <div class="col">
                                    <div class="col-item">{{ adminAcount }}</div>
                                    <div class="col-item">我的回答</div>
                                </div>
                                <!-- <div class="col">
                  <div class="col-item">0</div>
                  <div class="col-item">我的积分</div>
                </div> -->
                            </div>
                        </div>
                        <div class="card_button">
                            <div class="col">
                                <el-popover popper-class="addForumPopper" placement="bottom" width="300" trigger="click">
                                    <div class="be-dropdown-menu">
                                        <div @click="toAddForum(i.fid)" class="col_item click_hover"
                                            v-for="i in questionTypeList" :key="i.id">
                                            {{ i.forummoduleTitle }}
                                        </div>
                                    </div>
                                    <el-button icon="el-icon-edit-outline" class="btn" slot="reference" style="width:100%"
                                        size="mini">发帖</el-button>
                                </el-popover>
                            </div>
                        </div>
                    </div>
                    <!-- <el-dialog :visible.sync="addDialogVisible" width="30%" :before-close="handleClose">
                        <div class="addDialogMes">

                            <i class="el-icon-user-solid"></i>
                            <el-input placeholder="写下您的问题，准确地描述问题更容易得到解答" v-model="addQuestionTitle">
                            </el-input>
                        </div>
                        <el-input style="margin-bottom: 10px;" v-show="addQuestionTitle != ''" type="textarea" :rows="4"
                            placeholder="输入问题背景、条件等详细信息（选填）" v-model="addQuestionDes">
                        </el-input>

                        <span slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="sendQuestion">发布问题</el-button>
                        </span>
                    </el-dialog> -->
                </el-col>
            </el-row>
        </div>
    </div>
</template>
  
<script>
import define from '@/utils/define';
import { mapState, mapMutations, mapActions } from "vuex";
import { GetForumModuleNoPageList, GetForumPostsList, GetForumCommentList, GetCommentList, AddForumPost } from "@/assets/API/forum.js";
import TabContentVue from "./TabContent.vue";
export default {
    name: 'QuestionList', // todo name命名是否有规范
    props: {
        data: {

        }
    },
    data() {
        return {
            type: '0',
            tagList: [],
            questionId: {},
            adminQcount: 0,
            adminAcount: 0,
            addDialogVisible: false,
            addQuestionTitle: '',
            addQuestionDes: '',
            addQuestionTags: [],
            questionTypeList: []
        };
    },
    components: {
        TabContentVue
    },
    created() {
        this.getAllData();
    },
    computed: {
        ...mapState("user", ["isLogin", "userInfo"]),
    },
    watch: {
        isLogin: {
            handler(val) {
                if (val) {
                    this.getAdminData();
                }
            }
        }
    },
    mounted() {
        // let userInfo = this.$store.state.user.userInfo;
    },
    methods: {
        ...mapMutations("user", ["SET_LOGINVISBILIT"]),
        getAllData() {
            GetForumModuleNoPageList().then(res => {
                let result = res.data.filter(a => a.forummoduleTitle == "问题交流");
                this.questionId = result[0];
                this.questionTypeList = res.data.filter(a => a.biboId == this.questionId.fid);
            });
            // .then(res => {
            //     if (this.isLogin) {
            //         this.getAdminData();
            //     }
            // });
        },
        getAdminData() {
            // self send question
            let queryData = {
                currentPage: 1,
                dataType: 0,
                pageSize: 20,
                sidx: "",
                sort: "desc",
                superQueryJson: "",
                postAdminid: this.userInfo.id,
            };
            GetForumPostsList(queryData).then((res) => {
                this.adminQcount = res.data.pagination.total;
            });

            let queryAdminReply = {
                commentAdmin: this.userInfo.userId,
            };
            GetCommentList(queryAdminReply).then(res => {
                this.adminAcount = res.data.pagination.total;
            });
        },
        // 切换标签方法
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        toDetail() {
            this.$router.push({
                path: 'QuestionDetail',
                query: {
                    data: 'testId'
                }
            });
        },
        addQuestion() {
            // 是否登录判断
            if (this.isLogin == false) {
                this.$router.replace({
                    name: "Login",
                    path: "/login",
                });
            } else {
                // this.$router.push({
                //   path: "AddForumPost",
                //   query: {
                //     data: this.questionId.F_Id,
                //   },
                // });
                this.addDialogVisible = true;
            }
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.addQuestionTags = [];
                    this.addQuestionDes = '';
                    this.addQuestionTitle = '';
                    done();
                })
                .catch(_ => { });
        },
        toAddForum(id) {
            // 是否登录判断
            if (this.isLogin == false) {
                this.$router.replace({
                    name: "Login",
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: "AddForumPost",
                    query: {
                        data: id,
                    },
                });
            }

        }
    }
};
</script>
  
<style lang="scss" scoped>
.banner {
    background-image: url('../../../../assets/image/communBg.png');
    height: 480px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.container {
    width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    // padding-top: 60px;

    .card {
        width: 100%;
        min-height: calc(100vh - 200px);
        position: relative;
        box-shadow: 0 1px 3px rgba(26, 26, 26, .1) !important;
        box-sizing: border-box;
        border: unset !important;
        border-radius: 2px;
        background-color: #fefefe;
        margin: 10px;
        padding: 10px;
        word-wrap: break-word;
        border-radius: 4px;
        // min-height: 36px;
        box-sizing: border-box;
    }

    .rightCard {
        margin-left: 30px;
        min-height: calc(100vh - 200px);
    }
}

.notice-title {
    font-size: 16px;
    padding: 8px;
}

.text-center {
    text-align: center;
    overflow: hidden;
    line-height: 1.5;
    background-color: rgba(240, 245, 255, 0.95);
    margin: 10px 0px;

    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;

        .col {
            padding: 15px;
            text-align: center !important;

            .col-item:first-child {
                font-size: 18px;
                color: #0099ee;
            }

            .col-item:nth-child(2) {
                font-size: 13px;
                color: #888888;
            }
        }
    }
}

.ques_tag {
    font-size: 12px;
    background-color: #EBF8FF;
    opacity: 70%;
    color: white;
    padding: 3px 10px;
    margin-right: 8px;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 5px;
}

.rank-list {
    padding: 10px;

    .rank-item {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.5;

        .rank_icon {
            height: 26px;
            vertical-align: middle;
            margin-right: 8px;
        }
    }

    .float-right {
        float: right;
        color: orange;
    }

    .rank-item:first-child {
        .rank-number {
            color: #fff;
            background: #0099ee;
        }
    }

    .rank-item:nth-child(2) {
        .rank-number {
            color: #fff;
            background: #0099ee;
        }
    }

    .rank-item:nth-child(3) {
        .rank-number {
            color: #fff;
            background: #0099ee;
        }
    }

    .rank-number {
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        color: #999;
        border-radius: 2px;
        display: inline-block;
        margin-right: 10px;
    }
}

.click_hover:hover {
    cursor: pointer;
}

.addDialogMes {
    display: flex;
    margin-bottom: 10px;

    img {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }
}

.card_button {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    // margin: 10px;
    // padding: 10px;
    word-wrap: break-word;
    min-height: 36px;

    display: flex;
    flex-wrap: wrap;

    .col {
        // flex: 0 0 50%;
        width: 100%;

        .btn {
            width: 100%;
            border: 1px solid #64c8ff;
            color: #0099ee;
            line-height: 1.5;
            font-size: 14px;
        }
    }

    .col:first-child {
        padding-right: 5px;
    }

    .col:nth-child(2) {
        img {
            width: 13px;
            height: 13px;
            vertical-align: middle;
        }
    }
}

.click_hover:hover {
    cursor: pointer;
    color: #0068a2 !important;
}

// 下拉框样式
.be-dropdown-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .col_item {
        text-align: center;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding: 0 5px;
        line-height: 40px;
        font-size: 14px;
        color: #222;
        white-space: nowrap;
    }
}
</style>