<template>
    <div class="block_center">
        <div class="banner">
            <!-- <div class="bContent">
                <div class="bannerText">
                    成图软件建构者
                </div>
                <div class="bannerDes">
                    <div><span class="dot"></span>为客户研发业内领先的生产力工具软件<br></div>
                    <div><span class="dot"></span>基于生产力工具软件提供生产工艺改进服务<br></div>
                    <div><span class="dot"></span>通过生产工艺改进助力智慧型地理数据工厂建设<br></div>
                </div>
                <div class="bannerBtn">
                    <el-button round @click="toAboutUs">点击查看</el-button>
                </div>
            </div> -->
            <el-carousel trigger="click" height="3rem">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <img :src="item.imgSrc" alt="" @click="clickBanner(item)">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="productSys">
            <div class="intro">
                <div>
                    <span class="introText">产品体系</span>
                </div>
                <div>
                    <span class="introDes">PRODUCT SYSTEM</span>
                </div>
                <div class="introDD">
                    <span class="introD">使用100%自研技术助力更多行业用户解决生产实践问题</span>
                </div>
            </div>
            <div class="productList">
                <div class="productItem" v-for="item in productIntroList" :key="item.title"
                    @click="toLookMore(item.title)">
                    <div class="title">{{ item.title }}</div>
                    <div class="des">{{ item.des }}</div>
                    <div class="intro" v-html="item.intro"></div>
                    <img :src="item.imgSrc" alt="">
                </div>
            </div>
            <div class="sdkPart">
                <div class="sdkText">Summit SDK</div>
                <div class="sdkDes">空间数据处理工具平台</div>
            </div>
        </div>
        <div class="topicApplicationPart">
            <div class="topicText">
                <div class="tText">
                    典型应用
                </div>
                <div class="tDes">
                    TYPICAL<br>
                    APPLICATIONS
                </div>
                <div class="tDivide"></div>
                <div class="tIntro">
                    发挥自研软件力量<br>
                    服务测绘+应用场景
                </div>
            </div>
            <div class="topicImgs">
                <div class="leftPza" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                    <div v-show="cehuiShow" class="insideLeftPza">
                        <div class="tTag">测绘</div>
                        <div class="tDivide"></div>
                        <div class="tMore">
                            直角房屋提取<br>立面测量<br>大比例尺测图<br>点云融合三维模型测图<br>单体提取<br>复杂单体构建
                        </div>
                        <div class="tImg">
                            <img src="../../assets/image/indexIcon1.png" alt="">
                        </div>
                    </div>
                    <div class="totalImg" v-show="!cehuiShow">
                        <img src="../../assets/image/测绘.png" alt="">
                    </div>
                </div>
                <div class="rightPart">
                    <div class="rightTop" @mouseenter="handleMouseEnterJZ" @mouseleave="handleMouseLeaveJZ">
                        <div class="insideRightTop" v-show="jianzhuShow">
                            <div class="tTag">建筑</div>
                            <div class="tDivide"></div>
                            <div class="tMore">
                                三维不动产分层分户单体制作<br>室内停车场三维建模
                            </div>
                            <div class="tImg">
                                <img src="../../assets/image/indexIcon2.png" alt="">
                            </div>
                        </div>
                        <div class="totalImg" v-show="!jianzhuShow">
                            <img src="../../assets/image/建筑.png" alt="">
                        </div>
                    </div>
                    <div class="rightBottom">
                        <div class="bottomItemContent elec" @mouseenter="handleMouseEnterDL"
                            @mouseleave="handleMouseLeaveDL">
                            <div class="bottomItem" v-show="dianliShow">
                                <div class="tTag">电力</div>
                                <div class="tDivide"></div>
                                <div class="tMore"></div>
                                <div class="tImg">
                                    <img src="../../assets/image/indexIcon3.png" alt="">
                                </div>
                            </div>
                            <div class="totalImg" v-show="!dianliShow">
                                <img src="../../assets/image/电力.png" alt="">
                            </div>
                        </div>
                        <div class="bottomItemContent pipe" @mouseenter="handleMouseEnterGX"
                            @mouseleave="handleMouseLeaveGX">
                            <div class="bottomItem" v-show="guanxianShow">
                                <div class="tTag">管线</div>
                                <div class="tDivide"></div>
                                <div class="tMore"></div>
                                <div class="tImg">
                                    <img src="../../assets/image/indexIcon4.png" alt="">
                                </div>
                            </div>
                            <div class="totalImg" v-show="!guanxianShow">
                                <img src="../../assets/image/管线.png" alt="">
                            </div>
                        </div>
                        <div class="bottomItemContent educa" @mouseenter="handleMouseEnterJY"
                            @mouseleave="handleMouseLeaveJY">
                            <div class="bottomItem" v-show="jiaoyuShow">
                                <div class="tTag">教育</div>
                                <div class="tDivide"></div>
                                <div class="tMore"></div>
                                <div class="tImg">
                                    <img src="../../assets/image/indexIcon5.png" alt="">
                                </div>
                            </div>
                            <div class="totalImg" v-show="!jiaoyuShow">
                                <img src="../../assets/image/教育.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="aboutComp">
            <div class="aboutText">
                <div class="aTitle">关于峰岭</div>
                <div class="aTitleE">ABOUT US</div>
                <div class="aDivide"></div>
                <div class="aDes">
                    长期专注空间数据处理高新技术与智能化平台产品研发<br>以完全自研可控的Summit系列软件为依托<br>为新型场景化测绘、单体自动建模以及航测内业培训教学<br>提供高质量解决方案与定制化服务
                </div>
            </div>
            <div class="aboutRight">
                <div class="aItem item1">
                    <div class="aNum">
                        <span class="num">30</span>
                        <span>+</span>
                    </div>
                    <div class="aNDivide"></div>
                    <div class="aNDes">自主知识产权</div>
                </div>
                <div class="aItem item2">
                    <div class="aNum">
                        <span class="num">7</span>
                        <span>+</span>
                    </div>
                    <div class="aNDivide"></div>
                    <div class="aNDes">发明专利</div>
                </div>
                <div class="aItem item3">
                    <div class="aNum">
                        <span class="num">80%</span>
                        <span>+</span>
                    </div>
                    <div class="aNDivide"></div>
                    <div class="aNDes">研发人员占比</div>
                </div>
                <div class="aItem item4">
                    <div class="aNum">
                        <span class="num">100%</span>
                        <span>+</span>
                    </div>
                    <div class="aNDivide"></div>
                    <div class="aNDes">复合增长率</div>
                </div>
            </div>
        </div>
        <div class="indexMore">
            <div class="moreText">获取最新Summit系列产品及服务</div>
            <div class="moreBtn" @click="toLookMore">点击获取</div>
        </div>

    </div>
</template>
<script>
import { getVideo } from "@/assets/API/home.js";
import { getAllProduction } from '@/assets/API/pay';

export default {
    components: {

    },
    data() {
        return {
            bannerList: [
                {
                    imgSrc: require('../../assets/image/banner1.jpg'),
                    value: 'SummitView'
                },
                // {
                //     imgSrc: require('../../assets/image/banner2.png'),
                //     value: 'SummitMap'
                // },
                {
                    imgSrc: require('../../assets/image/banner3.jpg'),
                    value: 'SummitMap',
                    id: '533930056309881733'
                },
                {
                    imgSrc: require('../../assets/image/banner4.jpg'),
                    value: 'SummitLab'
                },
                {
                    imgSrc: require('../../assets/image/banner5.jpg'),
                    value: 'SummitMap',
                    id: '491164190321442629'
                },
            ],
            productIntroList: [
                {
                    title: 'SummitMap',
                    des: '一站式智能成图平台',
                    intro: '开创新型<br>空间数据生产工厂化时代',
                    imgSrc: require('../../assets/image/SummitMap.png'),
                },
                {
                    title: 'SummitSolid',
                    des: '一站式单体化系统',
                    intro: '快、易、准<br>轻松构建多级复杂单体',
                    imgSrc: require('../../assets/image/SummitSolid.png'),
                },
                {
                    title: 'SummitLab',
                    des: '智能化内业培训系统',
                    intro: '节约80%培训成本<br>提升100%培训效率',
                    imgSrc: require('../../assets/image/SummitLab.png'),
                },
            ],
            cehuiShow: true,
            jianzhuShow: true,
            dianliShow: true,
            guanxianShow: true,
            jiaoyuShow: true,
        };
    },
    mounted() {
        this.$store.commit("isFull", false);
        window.addEventListener('scroll', this.handleScrollSidebar);
    },
    watch: {

    },
    methods: {
        clickBanner(data) {
            let value = data.value;
            if (value == 'SummitMap' || value == 'SummitLab') {
                // 跳转map界面
                this.$router.push({
                    path: "/Buy",
                    query: {
                        value,
                        id: data.id
                    }
                });
            } else if (value == 'SummitView') {
                // 跳转免费界面
                this.$router.push({
                    path: "/FreeSoft",
                });
            }
        },
        handleScrollSidebar() {
            const currentScrollPosition = window.scrollY;
            console.log("currentScrollPosition", currentScrollPosition);
            if (currentScrollPosition > this.previousScrollPosition) {
                // 向下滚动
                this.paneFlag = true;
                // 更新滚动位置
                this.previousScrollPosition = currentScrollPosition;
            } else if (currentScrollPosition < this.previousScrollPosition) {
                // 向上滚动
                this.paneFlag = false;
                // 更新滚动位置
                this.previousScrollPosition = currentScrollPosition;
            }
        },
        toBuy(value) {
            this.$router.push({
                path: "/BuyMenu",
                query: {
                    id: value,
                },
            });
        },
        toAboutUs() {
            this.$router.push({ name: "关于我们", path: "/AboutUs" });
            this.$bus.$emit('reloadactivePath', "/AboutUs");
        },
        toSubscribe(value) {
            this.$router.push({
                path: "/Buy",
                query: {
                    value,
                },
            });
            this.$bus.$emit('reloadactivePath', "/Buy");
        },
        toLookMore(value) {
            this.$router.push({
                path: "/AllProducts",
                query: {
                    name: value
                },
            });
            this.$bus.$emit('reloadactivePath', "/AllProducts");
        },
        toContactUs() {
            this.$router.push({ name: "联系我们", path: "/ContactUs" });
        },
        clickVideo(data, index) {
            if (data.title == "SummitMap") {
                this.videoSrc = "https://www.yesmap.xyz/api/videos/GetMate?videoId=cbb16297-87a3-4bc0-827b-ae19de5c83b5";
                this.autoPlay = true;
            } else if (data.title == "SummitLab") {
                this.videoSrc = "https://www.yesmap.xyz/api/videos/GetMate?videoId=a0605144-7a34-4d89-8ab6-3a4c40b60ded";
                this.autoPlay = true;
            } else if (data.title == "SummitSolid") {
                this.videoSrc = "https://www.yesmap.xyz/api/videos/GetMate?videoId=9283a75f-41a5-4613-83ee-bf153d321dd8";
                this.autoPlay = true;
            } else {
                this.videoSrc = "https://www.yesmap.xyz/api/videos/GetMate?videoId=5c7dbd39-451a-4ddc-9999-f4ed8414907d";
            }
            this.$bus.$emit('reloadVideo', this.videoSrc, this.autoPlay);
            this.currentIntro = index;
        },
        handleMouseEnter() {
            // 当鼠标进入按钮时触发该函数
            this.cehuiShow = false;
        },
        handleMouseLeave() {
            // 当鼠标离开按钮时触发该函数
            this.cehuiShow = true;
        },
        handleMouseEnterJZ() {
            this.jianzhuShow = false;
        },
        handleMouseLeaveJZ() {
            this.jianzhuShow = true;
        },
        handleMouseEnterDL() {
            this.dianliShow = false;
        },
        handleMouseLeaveDL() {
            this.dianliShow = true;
        },
        handleMouseEnterGX() {
            this.guanxianShow = false;
        },
        handleMouseLeaveGX() {
            this.guanxianShow = true;
        },
        handleMouseEnterJY() {
            this.jiaoyuShow = false;
        },
        handleMouseLeaveJY() {
            this.jiaoyuShow = true;
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScrollSidebar);
    }
};
</script>
<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;

    .banner {
        // background-image: url('../../assets/image/indexBg.png');
        // background-image: url('../../assets/image/首页Banner.jpg');
        background-size: 100% 100%;
        height: 628px;
    }

    // .el-carousel__container {
    //     height: 593px;
    // }

    .el-carousel__item img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .productSys {
        background-image: url('../../assets/image/indexBg1.png');
        background-size: 100% 100%;

        .intro {
            text-align: center;
            display: flex;
            flex-direction: column;

            .introDes {
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 43.44px;
                color: rgba(166, 166, 166, 1);
                text-align: center;
                vertical-align: top;
            }

            .introText {
                font-size: 42px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 57.92px;
                color: rgba(56, 56, 56, 1);
                text-align: center;
                vertical-align: top;
            }

            .introDD {
                margin: 28px 0;
            }

            .introD {
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 36px;
                color: rgba(56, 56, 56, 1);
                text-align: center;
                vertical-align: top;
            }
        }

        .productList {
            margin: 0 auto;
            width: 1200px;
            display: flex;
            justify-content: space-around;
            cursor: pointer;

            .productItem {
                border-radius: 10px;
                background: rgba(255, 255, 255, 1);
                padding: 17px 23px;
                width: 343px;
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 30px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 43.44px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;

                }

                .des {
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 34.75px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                    border-bottom: 1px solid rgba(166, 166, 166, 1);
                    padding-bottom: 10px;
                }

                .intro {
                    padding-top: 10px;
                    font-size: 22px;
                    font-weight: 300;
                    letter-spacing: 0px;
                    line-height: 31.86px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;

                }

                img {
                    width: 70%;
                    align-self: flex-end;
                    padding-top: 70px;
                }
            }

            .productItem:hover {
                background: linear-gradient(225.49deg, rgba(134, 184, 236, 0.69) 0%, rgba(255, 255, 255, 0) 100%);
            }


        }

        .sdkPart {
            background-image: url('../../assets/image/sdkBg.png');
            height: 200px;
            background-size: 100% auto;

            text-align: center;

            .sdkText {
                margin-top: 50px;
                font-size: 60px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 86.88px;
                color: rgba(43, 133, 229, 1);
                vertical-align: top;
            }

            .sdkDes {
                font-size: 36px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 52.13px;
                color: rgba(43, 133, 229, 1);
                text-align: center;
                vertical-align: top;

            }
        }
    }

    .topicApplicationPart {
        background-image: url('../../assets/image/sdkBg6.png');
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        color: #fff;
        padding: 112px 177px;

        .topicText {
            padding-right: 60px;

            .tText {
                font-size: 42px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 60.82px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
                vertical-align: top;
            }

            .tDes {
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 34.75px;
                color: rgba(166, 166, 166, 1);
                text-align: left;
                vertical-align: middle;
            }

            .tDivide {
                width: 94px;
                height: 4px;
                opacity: 1;
                border-radius: 10px;
                background: rgba(43, 133, 229, 1);
                margin: 16px 0 27px;
            }

            .tIntro {
                font-size: 24px;
                font-weight: 300;
                letter-spacing: 0px;
                line-height: 40px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
                vertical-align: middle;
            }
        }

        .topicImgs {
            flex: 1;
            display: flex;

            .leftPza {
                height: 685px;
                width: 45%;
                background-image: url('../../assets/image/sdkBg1.png');
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                margin-right: 14px;

                .insideLeftPza {
                    padding: 26px 46px;
                }

                .tTag {
                    font-size: 36px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 52.13px;
                    color: rgba(255, 255, 255, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .tDivide {
                    width: 93px;
                    height: 4px;
                    opacity: 1;
                    border-radius: 5px;
                    background: rgba(255, 255, 255, 1);
                }

                .tMore {
                    flex: 1;
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 40px;
                    color: rgba(229, 229, 229, 1);
                    text-align: left;
                    vertical-align: top;
                    padding-top: 25px;
                }

                .tImg {
                    align-self: flex-end;
                }

            }

            .totalImg {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .rightPart {
                display: flex;
                flex-direction: column;
                flex: 1;

                .tTag {
                    font-size: 36px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 52.13px;
                    color: rgba(255, 255, 255, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .tDivide {
                    width: 93px;
                    height: 4px;
                    opacity: 1;
                    border-radius: 5px;
                    background: rgba(255, 255, 255, 1);
                    margin-bottom: 25px;
                }

                .tMore {
                    flex: 1;
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 40px;
                    color: rgba(229, 229, 229, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .tImg {
                    align-self: flex-end;
                }

                .rightTop {
                    display: flex;
                    flex-direction: column;
                    background-image: url('../../assets/image/sdkBg2.png');
                    background-size: 100% 100%;

                    .insideRightTop {
                        padding: 26px 26px;
                    }
                }

                .rightBottom {
                    display: flex;
                    margin-top: 14px;
                    flex: 1;

                    .bottomItemContent {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        background-size: 100% 100%;
                    }

                    .bottomItem {
                        padding: 21px 26px;
                    }

                    .elec {
                        background-image: url('../../assets/image/sdkBg3.png');
                    }

                    .pipe {
                        background-image: url('../../assets/image/sdkBg4.png');
                    }

                    .educa {
                        background-image: url('../../assets/image/sdkBg5.png');

                    }
                }
            }
        }
    }
}

.aboutComp {
    background-image: url('../../assets/image/aboutCpBg.png');
    background-size: 100% 100%;
    padding: 154px 440px;
    display: flex;

    .aboutText {
        padding-right: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .aTitle {
            font-size: 42px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 60.82px;
            color: rgba(56, 56, 56, 1);
            text-align: left;
            vertical-align: top;
        }

        .aTitleE {
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 34.75px;
            color: rgba(166, 166, 166, 1);
            text-align: left;
            vertical-align: middle;

        }

        .aDivide {
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            padding-top: 27px;
            margin-bottom: 27px;

        }

        .aDes {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 36px;
            color: rgba(56, 56, 56, 1);
            text-align: left;
            vertical-align: top;

        }
    }

    .aboutRight {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        justify-content: space-between;


        .aItem {
            width: 220px;
            height: 220px;
            margin-bottom: 20px;
            padding: 15px 13px;
            background-size: 100% 100%;

            .aNum {
                color: rgba(255, 255, 255, 1);
                font-size: 40px;
                font-weight: 700;

                .num {
                    font-size: 72px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 72px;
                    text-align: left;
                    vertical-align: bottom;
                }

            }

            .aNDivide {
                width: 71px;
                height: 4px;
                opacity: 1;
                background: rgba(255, 255, 255, 1);
                margin: 14px 0;
            }

            .aNDes {
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 24px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
                vertical-align: top;

            }
        }

        .item1 {
            background-image: url('../../assets/image/aboutIconBg1.png');
        }

        .item2 {
            background-image: url('../../assets/image/aboutIconBg2.png');
        }

        .item3 {
            background-image: url('../../assets/image/aboutIconBg3.png');
        }

        .item4 {
            background-image: url('../../assets/image/aboutIconBg4.png');
        }
    }


}

.indexMore {
    background-image: url('../../assets/image/indexBgBottom.png');
    background-size: 100% 100%;
    height: 400px;
    padding-top: 69px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .moreText {
        font-size: 36px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 60px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        vertical-align: top;
    }

    .moreBtn {
        margin-top: 28px;
        text-align: center;
        width: 265.98px;
        height: 56.1px;
        opacity: 1;
        border-radius: 30px;
        background: rgba(255, 255, 255, 1);

        font-size: 24px;
        font-weight: 500;
        letter-spacing: -0.53px;
        line-height: 56.1px;
        color: rgba(43, 133, 229, 1);
        text-align: center;
        vertical-align: middle;
        cursor: pointer;

    }
}
</style>