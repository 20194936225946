<template>
    <div class="SearchNone">
        <div class="summitek-header">
            <div class="m-contain">
                <div class="logo" @click="toHome">
                    <img src="../../../assets/image/LOGO.png" alt="" />
                </div>
            </div>
        </div>
        <div class="content">
            <div class="img">
                <img src="../../../assets/image/notOpen.png" alt="">
            </div>
            <div class="notFount_characters">
                <div class="title">没有搜到结果</div>
                <div class="again">您可点击下方按钮重试</div>
                <el-button type="primary">点击重试</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {

        };
    },
    computed: {
    },
    mounted() {
        // 设置全屏
        this.$store.commit("isFull", true);
    },
    watch: {},
    methods: {
        toHome() {
            this.$router.push({
                path: "/Home",
            });
        }
    },
};
</script>
<style lang="scss" scoped>
.SearchNone {
    position: relative;
    height: 100vh;
    z-index: 999;

    .summitek-header {
        height: 60px;
        width: 100%;
        min-width: 1180px;
        position: fixed;
        top: 0;
        z-index: 11;
        background-color: #fff;

        .m-contain {
            // width: 1200px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            padding: 0 100px;
        }

        .M_contain {
            width: 1200px;
        }

        .logo {
            width: 150px;
            font-size: 16px;
            font-weight: bold;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        height: 100vh;
        background-color: rgb(248, 248, 248);
        padding-left: 15%;

        img {
            height: 400px;
            margin-right: 100px;
        }

        .notFount_characters {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgba(42, 30, 23, 1);
            text-align: left;
            vertical-align: top;
        }

        .title {
            font-size: 48px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 48px;
            color: rgba(42, 30, 23, 1);
            text-align: left;
            vertical-align: top;
            margin-bottom: 24px;
        }

        .again{
            margin-bottom: 32px;
        }
    }
}
</style>