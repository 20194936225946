<template>
    <div class="main_content">
        <div class="featureTotal">
            <div class="intro" style="padding-top: 117px;padding-bottom:87px">
                <div>
                    <span class="introText">功能特性</span>
                </div>
                <div>
                    <span class="introDes">FUNCTION CHARACTERISTICS</span>
                </div>
            </div>
            <div class="featureList">
                <div class="featureLeft">
                    <!-- <div class="featureItem">全面的数据源支持</div> -->
                    <div v-for="(item, index) in featuresList " :key="index"
                        :class="item.choosed ? 'selectFeature featureItem' : 'featureItem'"
                        @mouseenter="chooseFeature(item, index)">
                        {{ item.name }}
                        <!-- <span>></span> -->
                    </div>
                </div>
                <div class="featureRight">
                    <div class="featuresItem" v-show="activeFeature == 0">
                        <div class="itemText">
                            兼容源于航空摄影、倾斜摄影、卫星遥感、激光雷达、移动测量的多传感器、多格式数据，并对数据进行全方位浏览与测图应用。
                        </div>
                        <div class="decomposeIntro">
                            <ul>
                                <li v-for="( item, index ) in decomposeIntroList " :key="index">
                                    <div class="decomImg"><img :src="item.img" alt=""></div>
                                    <div class="decomRight">
                                        <div class="decomTag">{{ item.name }}</div>
                                        <div class="decomDes" v-if="index == 0">
                                            <ul>
                                                <li>UCD、UCX、UCXP、DMC、胶片、SWDC、无人机小数码影像等框幅式影像</li>
                                                <li>ADS40/80/100等推扫式影像</li>
                                            </ul>
                                        </div>
                                        <div class="decomDes" v-else>
                                            <p>{{ item.des }}</p>
                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="featuresItem" v-show="activeFeature == index && activeFeature != 0"
                        v-for="( item, index ) in featuresList " :key="index">
                        <!-- <div class="featuresIntro">{{ item.name }}</div> -->
                        <div class="textContent" :class="index == 7 ? 'diffFlex' : ''">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="div_test" v-if="activeFeature == 1">
                                        <div class="dot"></div>
                                    </div>
                                    <div style="width: calc(100% - 24px);">{{ it.textContent }}</div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i"
                                        :style="{ 'width': (96 / item.imgList.length) + '%' }">
                                        <div>
                                            <img :src="it.img" alt="">
                                        </div>
                                        <div>{{ it.des }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="intro" style="padding-top: 120px;padding-bottom:80px">
            <div>
                <span class="introText" style="color: #383838;">典型应用</span>
            </div>
            <div>
                <span class="introDes">TYPICAL APPLICATIONS</span>
            </div>
        </div>
        <div class="mianCenter uniqueBg">
            <div class="totalType">
                <div class="uniqueItem" v-for="item in uniqueList" :key="item.name"
                    :style="`background: url(${item.img}) no-repeat;background-size: 100% 100%;`">
                    <div class="uniqueItemTitle">
                        <span>{{ item.title }}</span>
                    </div>
                    <div class="uniqueItemDes">{{ item.des }}</div>
                </div>
            </div>
        </div>
        <!-- <div class="mianCenter uniqueBg">
            <div class="uniqueStyle baseContent">
                <div class="leftBase leftUni">
                    <div class="uniqueImg">
                        <img src="../../../assets/image/uniqueBg_img.png" alt="">
                    </div>
                    <div class="des">地理实体快速构建</div>
                </div>
                <div class="rightBase rightUni">
                    <div class="uniqueItem" v-for=" item  in  uniqueList " :key="item.name">
                        <div style="flex: 1;">
                            <span class="uniqueItemTitle">{{ item.title }}</span>
                            <div class="uniqueItemDes">{{ item.des }}</div>
                        </div>
                        <div>
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            decomposeIntroList: [
                {
                    name: '航空摄影',
                    des: "UCD、UCX、UCXP、DMC、胶片、SWDC、无人机小数码影像等框幅式影像ADS40/80/100等推扫式影像",
                    img: require("../../../assets/image/projectSummitMap_img1.png")
                },
                {
                    name: '倾斜摄影',
                    des: "倾斜摄影三维模型数据",
                    img: require("../../../assets/image/projectSummitMap_img2.png")
                },
                {
                    name: '卫星遥感',
                    des: "国内外带RPC参数的卫星遥感影像、InSAR数据等",
                    img: require("../../../assets/image/projectSummitMap_img3.png")
                },
                {
                    name: '激光雷达',
                    des: "机载Lidar数据",
                    img: require("../../../assets/image/projectSummitMap_img4.png")
                },
                {
                    name: '移动测量',
                    des: "全景影像、全景视频、背包/车载激光扫描数据、手机拍照数据、声纳回波数据等",
                    img: require("../../../assets/image/projectSummitMap_img5.png")
                },
            ],
            featuresList: [
                {
                    name: "全面的数据源支持",
                    desList: [
                        {
                            textContent: "平台具备优秀的数据兼容能力，支持源于航空摄影、倾斜摄影、卫星遥感、激光雷达、移动测量的多传感器、多格式数据的全方位浏览与测图应用。"
                        }
                    ],
                    imgList: [],
                    choosed: true,
                },
                {
                    name: "灵活的多源数据融合",
                    desList: [
                        {
                            textContent: "在多源数据读写与多源数据分屏联动的基础上，进一步实现了对多源数据的融合支持。"
                        },
                        {
                            textContent: "在底层强大的SummitSensor KIT一体化多源融合引擎支撑下，多源数据间既能分屏联动，也能根据需要融合到一个窗口内，实现无感切换。作业员可在同一个窗口中、采用同一套工具灵活切换数据源采集要素。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img6.png"),
                            des: "点云二三维数据与全景影像联动测图"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img7.png"),
                            des: "倾斜三维模型与激光点云融合测图"
                        }
                    ],
                    choosed: false
                },
                {
                    name: "多样化的采集作业模式",
                    desList: [
                        {
                            textContent: "兼具裸眼三维采集、立体采集、真立体采集、正射采集、截面采集、半自动采集、立面采集等丰富多样化的地物地貌采集模式。通过作业过程中多种采集模式的灵活切换，全面满足不同项目场景的个性化作业需要，有效提高成图精度与效率。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img8.png"),
                            des: "真立体采集"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img9.png"),
                            des: "截面采集"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img10.png"),
                            des: "立面采集"
                        }
                    ],
                    choosed: false
                },
                {
                    name: "智能化要素提取工具",
                    desList: [
                        {
                            textContent: "依托自研AI算法，开发实现了智能化的地物地貌提取功能，可以自动/半自动提取独栋直角房屋、建筑物立面门窗结构、车道线与地面标志物、路灯等重要地物要素，以及高程点、等高线、地物横截面线等典型地貌要素，大幅缩减作业时长与人力成本。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img12.png"),
                            des: "批量房屋提取"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img11.png"),
                            des: "车道线与地面标志提取"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img13.png"),
                            des: "等高线提取"
                        }
                    ],
                    choosed: false
                },
                {
                    name: "便捷全面的采编功能",
                    desList: [
                        {
                            textContent: "内置一系列丰富易用的矢量采编功能。包括以按边采线、按边切割面、3点画阳台、自动生成房屋高度注记、设置条件筛选查找地物、采集之后自动录入属性为代表的一系列快捷采集功能，以及用于点、单线、双线、面状、注记的数百项编辑功能，完全覆盖作业过程中的地物地貌采编需求，令作业更快捷、更高效。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img21.jpg"),
                            des: "按边切割面"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img22.png"),
                            des: "3点画阳台"
                        },
                    ],
                    choosed: false
                },
                {
                    name: "完备的成图建库能力",
                    desList: [
                        {
                            textContent: "完全打通了采集、编辑、成图、入库四大作业工序，形成了矢量采编成图建库全链条处理能力。既能够输出图式表达规范的矢量图，也能够生产符合拓扑完整要求的入库数据。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img14.png"),
                            des: ""
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img15.png"),
                            des: "库图一体"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img16.png"),
                            des: ""
                        }
                    ],
                    choosed: false
                },
                {
                    name: "一站式地理实体生产",
                    desList: [
                        {
                            textContent: "为新型基础测绘地理实体生产提供全程支持。不仅能够基于多源数据直接采集生产地理实体，也能够基于已有基础地理信息要素存量数据转换生成地理实体，实现单实体、组合实体、聚合实体生产，并提供全面的编辑功能来维护实体间逻辑关系。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img17.png"),
                            des: "基于DEM与DLG的地理实体生产"
                        },
                        {
                            img: require("../../../assets/image/projectSummitMap_img18.png"),
                            des: "聚合实体生产"
                        },
                    ],
                    // flag: true,
                    choosed: false
                },
                {
                    name: "工厂化协作生产模式",
                    desList: [
                        {
                            textContent: "针对规模化项目生产任务，引入了工厂化流水线作业理念，采用网络协同的作业模式，通过多人共同参与的要素级细分作业、多级质检，实现项目效率与质量的双提升。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img19.png"),
                            des: ""
                        },
                    ],
                    choosed: false
                },
                {
                    name: "强大的二次开发接口",
                    desList: [
                        {
                            textContent: "对空间数据生产涉及的纷繁复杂场景进行针对性梳理，细分出了数十个核心组件、涵盖各种生产应用和工具的上千个API，以及底层、高级两种不同级别的应用开发接口，为用户提供便捷的二次开发支持，实现对项目生产和应用的个性化定制需求的快速响应。"
                        },
                        // {
                        //     textContent: " 平台也基于用户广泛的应用需求定制调整功能架构，不断扩展成图平台边界，通过“平台-生产-项目”构建了一套自我循环，同步赋能的生态体系，对平台进行不间断的迭代升级。"
                        // }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/projectSummitMap_img20.png"),
                            des: ""
                        },
                    ],
                    choosed: false
                }
            ],
            uniqueList: [
                {
                    img: require('../../../assets/image/applications_img1.png'),
                    icon: require('../../../assets/image/applications_icon1.png'),
                    title: "地理实体快速构建",
                    des: "基于矢量线划数据与属性信息一键生成地理实体，快速导出白模数据，在全面降低地理实体生产门槛的同时提高作业效率。"
                },
                {
                    img: require('../../../assets/image/applications_img2.png'),
                    icon: require('../../../assets/image/applications_icon2.png'),
                    title: "智能立面测量",
                    des: "一次性截取建筑全部立面，令各立面间互有参照，同时通过智能工具自动提取立面窗体结构，高效完成模型立面测量。"
                },
                {
                    img: require('../../../assets/image/applications_img3.png'),
                    icon: require('../../../assets/image/applications_icon3.png'),
                    title: "大比例尺测图",
                    des: "开放全面的符号库体系、智能易用的采编功能、严密的拓扑检查工具，助力实现高精度的城区大比例尺地形图生产。"
                },
                {
                    img: require('../../../assets/image/applications_img4.png'),
                    icon: require('../../../assets/image/applications_icon4.png'),
                    title: "房地一体确权登记",
                    des: "通过截面测图模式确保采集矢量精度，利用移动单边快速生成分割区，同时快速填写属性信息，高效率完成房屋采集。"
                },
                {
                    img: require('../../../assets/image/applications_img5.png'),
                    icon: require('../../../assets/image/applications_icon5.png'),
                    title: "全息地图采集",
                    des: "开放全面的符号库体系、智能易用的采编功能、严密的拓扑检查工具，助力实现高精度的城区大比例尺地形图生产。"
                },
                {
                    img: require('../../../assets/image/applications_img6.png'),
                    icon: require('../../../assets/image/applications_icon6.png'),
                    title: "室内停车场三维建模",
                    des: "开放全面的符号库体系、智能易用的采编功能、严密的拓扑检查工具，助力实现高精度的城区大比例尺地形图生产。"
                },
                {
                    img: require('../../../assets/image/applications_img7.png'),
                    icon: require('../../../assets/image/applications_icon7.png'),
                    title: "天然气管道调查",
                    des: "开放全面的符号库体系、智能易用的采编功能、严密的拓扑检查工具，助力实现高精度的城区大比例尺地形图生产。"
                },
                {
                    img: require('../../../assets/image/applications_img8.png'),
                    icon: require('../../../assets/image/applications_icon8.png'),
                    title: "电力通道优化选线",
                    des: "开放全面的符号库体系、智能易用的采编功能、严密的拓扑检查工具，助力实现高精度的城区大比例尺地形图生产。"
                }
            ],
            softList: [
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                },
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                },
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                },
                {
                    name: "湖北新型基础测绘专版",
                    type: "SummitMAP",
                    weekprice: "199",
                    description: "产品特点介绍"
                }
            ],
            activeFeature: 0
        };
    },
    mounted() {
    },
    methods: {
        chooseFeature(data, index) {
            this.activeFeature = index;
            this.featuresList.forEach(e => {
                e.choosed = false;
            });
            data.choosed = true;
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.main_content {
    .featureTotal {
        // background-image: url(../../../assets/image/productBg2.png);
        background-color: #152235;
        background-size: 100% 100%;

        .featureList {
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 85px;
            display: flex;

            .featureLeft {
                width: 268px;
                height: 620px;
                opacity: 1;
                background: #276FBE;
                display: flex;
                flex-direction: column;
                font-size: 18px;
                font-weight: 400;

                .featureItem {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    flex: 1;
                    padding: 0 10px;
                    color: #fff;
                    justify-content: center;
                }

                .selectFeature {
                    background-color: #2B85E5;
                    color: #FFFFFF;
                }

            }

            .featureRight {
                flex: 1;
                height: 620px;
                opacity: 1;
                background: rgba(255, 255, 255, 1);
                overflow: auto;

                .featuresItem {
                    padding: 22px 25px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    .featuresIntro {
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 34.75px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: top;
                    }

                    .itemText {
                        padding-bottom: 14px;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 32px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: top;

                        .dot {
                            width: 12px;
                            height: 12px;
                            opacity: 1;
                            background: rgba(56, 56, 56, 1);
                            border-radius: 50%;
                            margin-right: 12px;
                        }

                    }

                    .decomposeIntro {
                        flex: 1;
                        height: 100%;
                        padding-bottom: 35px;

                        ul {
                            display: flex;
                            height: 100%;
                            flex-direction: column;

                            li {
                                flex: 1;
                                // opacity: 1;
                                // border-radius: 8px;
                                // background: rgba(255, 255, 255, 1);
                                // box-shadow: 0px 2px 8px rgba(146, 158, 168, 0.25);
                                // padding: 30px 10px;
                                // margin-left: 10px;
                                display: flex;
                                padding-bottom: 10px;

                                // flex-direction: column;

                                // text-align: center;

                                .decomRight {
                                    flex: 1;
                                }

                                .decomTag {
                                    opacity: 1;
                                    // border-radius: 20px;
                                    // background: rgba(43, 133, 229, 1);
                                    // margin: 0 17px;
                                    color: rgba(43, 133, 229, 1);
                                }

                                .decomImg {
                                    // flex: 1;
                                    // height: 25%;
                                    width: 10%;

                                }

                                .decomDes {
                                    font-size: 14px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                    // text-align: center;
                                    vertical-align: top;
                                    padding: 12px 0;

                                    p {
                                        text-align: left;
                                        display: inline-block;
                                    }

                                }
                            }

                            li:first-child {
                                margin-left: 0;
                            }
                        }
                    }

                    .textContent {
                        display: flex;
                        flex-direction: column; // justify-content: space-between;

                        .text {
                            // width: 44%;
                            // background-color: pink;
                            margin-top: 20px;

                            .item {
                                display: flex;
                                margin-bottom: 10px;
                                line-height: 32px;
                            }

                            .div_test {
                                width: 24px;
                                padding-top: 10px;

                                .dot {
                                    width: 12px;
                                    height: 12px;
                                    opacity: 1;
                                    background: rgba(56, 56, 56, 1);
                                    border-radius: 50%;
                                    margin-right: 12px;
                                }
                            }
                        }

                        .itemImg {
                            // width: 48%;
                            // height: 200px;
                            // background-color: yellowgreen;
                            font-size: 14px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 20.27px;
                            color: rgba(166, 166, 166, 1);
                            text-align: left;
                            vertical-align: top;
                            padding-top: 20px;

                            ul {
                                display: flex;
                                justify-content: space-between;

                                li {
                                    display: flex;
                                    flex-wrap: wrap;

                                    div {
                                        width: 100%;
                                        display: flex;
                                        justify-content: center;
                                        margin-bottom: 20px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        letter-spacing: 0px;
                                        line-height: 20.27px;
                                        color: rgba(56, 56, 56, 1);
                                        text-align: left;
                                        vertical-align: top;


                                        img {
                                            width: 100%;
                                        }
                                    }

                                    .color {
                                        border-bottom: 30px solid rgb(226, 226, 226);
                                        border-radius: 0 0 10px 10px;
                                    }

                                    .color1 {
                                        box-sizing: border-box;
                                        border: 5px solid rgb(70, 70, 70);
                                        border-radius: 10px 10px 0 0;
                                    }
                                }
                            }
                        }
                    }

                    .diffFlex {
                        flex-direction: row;

                        .text {
                            width: 50%;
                        }

                        .itemImg {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .intro {
        // padding: 80px 50px;
        // padding-bottom: 50px;
        text-align: center;
        display: flex;
        flex-direction: column;

        .introDes {
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 43.44px;
            color: #CCCCCC;
            text-align: center;
            vertical-align: top;
        }

        .introText {
            font-size: 42px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 57.92px;
            color: #fff;
            text-align: center;
            vertical-align: top;
        }

        // .introText::after {
        //     position: absolute;
        //     bottom: -16px;
        //     left: 0;
        //     right: 0;
        //     height: 5px;
        //     background-color: #2A82E4;
        //     content: "";
        //     transform: scaleY(.5);
        // }
    }

    .featuresContent {
        font-size: 16px;
        font-weight: 400;
        line-height: 23.17px;
        color: rgba(56, 56, 56, 1);
        text-align: justify;
        vertical-align: top;

        .featuresItem {
            margin-bottom: 64px;

            .featuresIntro {
                display: flex;
                align-items: center;
                font-size: 26px;
                line-height: 37.65px;
                color: rgba(0, 94, 255, 1);
                text-align: left;
                margin-bottom: 48px;

                span {
                    display: block;
                    width: 7px;
                    height: 30px;
                    opacity: 1;
                    background: rgba(97, 171, 255, 1);
                    margin-right: 20px;
                }
            }

            .itemText {
                display: flex;
                align-items: center;

                .dot {
                    width: 12px;
                    height: 12px;
                    opacity: 1;
                    background: rgba(56, 56, 56, 1);
                    border-radius: 50%;
                    margin-right: 12px;
                }
            }

            .decomposeIntro {
                padding-left: 37px;

                ul {
                    margin-top: 50px;
                    display: flex;
                    justify-content: space-between;

                    li {
                        width: 16%;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 20.27px;
                        color: rgba(166, 166, 166, 1);
                        text-align: left;
                        vertical-align: top;

                        div {
                            display: flex;
                            justify-content: center;
                        }

                        img {
                            width: 75px;
                            margin-bottom: 12px;
                        }
                    }
                }
            }

            .textContent {
                display: flex;
                justify-content: space-between;

                .text {
                    width: 44%;
                    // background-color: pink;

                    .item {
                        display: flex;
                        margin-bottom: 35px;
                    }

                    .div_test {
                        width: 24px;
                        padding-top: 5px;

                        .dot {
                            width: 12px;
                            height: 12px;
                            opacity: 1;
                            background: rgba(56, 56, 56, 1);
                            border-radius: 50%;
                            margin-right: 12px;
                        }
                    }
                }

                .itemImg {
                    width: 48%;
                    // height: 200px;
                    // background-color: yellowgreen;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 20.27px;
                    color: rgba(166, 166, 166, 1);
                    text-align: left;
                    vertical-align: top;

                    ul {
                        display: flex;
                        justify-content: space-between;

                        li {
                            display: flex;
                            flex-wrap: wrap;

                            div {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                margin-bottom: 20px;

                                img {
                                    width: 100%;
                                }
                            }

                            .color {
                                border-bottom: 30px solid rgb(226, 226, 226);
                                border-radius: 0 0 10px 10px;
                            }

                            .color1 {
                                box-sizing: border-box;
                                border: 5px solid rgb(70, 70, 70);
                                border-radius: 10px 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .uniqueBg {
        .totalType {
            display: grid;
            grid-template-columns: repeat(2, calc(50% - 29px));
            grid-template-rows: repeat(2, 1fr);
            gap: 38px;
            padding-bottom: 50px;

            // .uniqueItem {
            //     border-radius: 6px;
            //     background: rgba(255, 255, 255, 1);
            //     box-shadow: 0px 2px 8px rgba(225, 232, 240, 1);
            //     padding: 30px 23px 20px;
            //     display: flex;
            //     flex-direction: column;
            //     text-align: center;

            //     .uniqueItemTitle {
            //         font-size: 24px;
            //         font-weight: 500;
            //         letter-spacing: 0px;
            //         line-height: 34.75px;
            //         color: rgba(56, 56, 56, 1);
            //         text-align: center;
            //         vertical-align: top;
            //         padding: 17px 0;
            //         border-bottom: 1px solid rgba(166, 166, 166, 1);
            //     }

            //     .uniqueItemDes {
            //         font-size: 16px;
            //         font-weight: 400;
            //         letter-spacing: 0px;
            //         line-height: 32px;
            //         color: rgba(128, 128, 128, 1);
            //         text-align: justify;
            //         vertical-align: top;
            //         padding-top: 17px;
            //     }

            //     .uniqueImg {
            //         padding-top: 17px;
            //         opacity: 1;
            //         border-radius: 6px;

            //         img {
            //             width: 100%;
            //         }
            //     }
            // }
            .uniqueItem {
                padding: 31px;
                height: 314px;
                // background-size: 100% auto;
                background-blend-mode: overlay;

                .uniqueItemTitle {
                    margin-bottom: 10px;
                    font-size: 26px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 37.65px;
                    color: rgba(255, 255, 255, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .uniqueItemDes {
                    width: 70%;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 32px;
                    color: rgba(255, 255, 255, 1);
                    text-align: justify;
                    vertical-align: top;
                }
            }
        }
    }

    .mianCenter {
        width: 1200px;
        margin: 0 auto;
    }
}
</style>