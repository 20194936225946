import request from "@/utils/request";
// 版块列表
export function GetForumSectionList(query) {
  return request({
    url: `/api/Business/ForumSection/GetNoPageList`,
    method: "GET",
    data: query,
  });
}
// 模块列表（no page)
export function GetForumModuleNoPageList(query) {
  return request({
    url: `/api/business/Forum_modules/getNoPageList`,
    method: "GET",
    data: query,
  });
}
// 模块列表
export function GetForumModuleList(query) {
  return request({
    url: `/api/business/Forum_modules`,
    method: "GET",
    data: query,
  });
}
// 模块详情
export function GetForumModuleDetail(id) {
  return request({
    url: `/api/business/Forum_modules/${id}`,
    method: "GET",
  });
}
// 帖子列表
export function GetForumPostsList(query) {
  return request({
    url: `/api/business/Forum_posts/getListByUser`,
    method: "POST",
    data: query,
  });
}
// 论坛列表
export function GetForumPostsListWithoutQA(query) {
  return request({
    url: `/api/Business/ForumPosts/GetForumList`,
    method: "GET",
    data: query,
  });
}
// 帖子详情
export function GetForumPostsDetail(id, adminId) {
  return request({
    url: `/api/business/Forum_posts/detailPost?id=${id}&&adminId=${adminId}`,
    method: "GET",
  });
}
// 帖子新增
export function AddForumPost(data) {
  return request({
    url: `/api/business/Forum_posts`,
    method: "post",
    data: data,
  });
}
// 帖子删除
export function DelForumPost(id) {
  return request({
    url: `/api/business/Forum_posts/${id}`,
    method: "DELETE",
  });
}
// 帖子评论新增
export function AddForumComment(data) {
  return request({
    url: `/api/business/Forum_comments`,
    method: "post",
    data: data,
  });
}
// 帖子评论的回复新增
export function AddForumReply(data) {
  return request({
    url: `/api/business/Forum_reply`,
    method: "post",
    data: data,
  });
}
// 修改帖子数据
export function EditForumPost(id, data) {
  return request({
    url: `/api/Business/ForumPosts/${id}`,
    method: "PUT",
    data: data,
  });
}

// 获取用户信息
export const GetPersonDetail = (id) => {
  return request({
    url: `/api/permission/Users/${id}`,
    method: "GET",
  });
};
// 模块标签list
export function GetForumModuleTagList(query) {
  return request({
    url: `/api/Business/ForumTags`,
    method: "GET",
    data: query,
  });
}
// 发帖人相关数据
// 用户关注列表 用户详情显示页

// 关注列表
export function GetFocusList(query) {
  return request({
    url: "/api/Business/ForumUserfocus/",
    method: "GET",
    data: query,
  });
}

// 添加关注
export function AddFocus(query) {
  return request({
    url: "/api/Business/ForumUserfocus/",
    method: "post",
    data: query,
  });
}
// 获取关注状态、列表
export function GetFocusStatus(query) {
  return request({
    url: "/api/Business/ForumUserfocus/",
    method: "get",
    data: query,
  });
}
// 取消关注
export function DeleteFocus(id) {
  return request({
    url: `/api/Business/ForumUserfocus/${id}`,
    method: "DELETE",
  });
}

// 添加收藏
export function AddCollect(query) {
  return request({
    url: "/api/Business/ForumCollect/",
    method: "post",
    data: query,
  });
}
// 获取收藏状态、列表
export function GetCollectStatus(query) {
  return request({
    url: "/api/Business/ForumCollect/",
    method: "get",
    data: query,
  });
}
// 取消收藏
export function DeleteCollect(id) {
  return request({
    url: `/api/Business/ForumCollect/${id}`,
    method: "DELETE",
  });
}

// 点赞
export function AddLike(query) {
  return request({
    url: "/api/Business/ForumLike/",
    method: "post",
    data: query,
  });
}

// 取消点赞
export function DeleteLike(id) {
  return request({
    url: `/api/Business/ForumLike/${id}`,
    method: "DELETE",
  });
}

// 获取点赞状态
export function GetLikeData(query) {
  return request({
    url: "/api/Business/ForumLike/",
    method: "get",
    data: query,
  });
}

// 评论列表
export function GetCommentList(query) {
  return request({
    url: `/api/business/Forum_comments/getList`,
    method: "post",
    data: query,
  });
}
//问答
export function GetCommentCour(id) {
  return request({
    url: "/api/Business/ForumComments/GetComment",
    method: "GET",
    data: id,
  });
}

// 帖子评论列表
export function GetForumCommentList(id) {
  return request({
    url: `/api/Business/ForumComments/GetComment`,
    method: "GET",
    data: {
      Comment_postid: id,
      sidx: "Comment_createtime",
    },
  });
}

// 帖子评论列表
export function GetForumCommentListWithUser(id, userId) {
  return request({
    url: `/api/business/Forum_comments/GetComment?id=${id}&&adminId=${userId}`,
    method: "GET",
  });
}

// 编辑评论（点赞数）
export function EditComment(id, query) {
  return request({
    url: `/api/Business/ForumComments/` + id,
    method: "PUT",
    data: query,
  });
}

// 编辑回复（点赞数）
export function EditReply(id, query) {
  return request({
    url: `/api/Business/ForumReply/` + id,
    method: "PUT",
    data: query,
  });
}

// 回复列表
export function GetForumReplyList(id) {
  return request({
    url: `/api/Business/ForumReply`,
    method: "GET",
    data: {
      Reply_postid: id,
    },
  });
}

// 回复列表不需要id
export function GetReplyList(query) {
  return request({
    url: `/api/Business/ForumReply`,
    method: "GET",
    data: query,
  });
}

// 删除评论
export function DelComment(id) {
  return request({
    url: `/api/business/Forum_comments/${id}`,
    method: "DELETE",
  });
}

// 删除回复
export function DelReply(id) {
  return request({
    url: `/api/business/Forum_reply/${id}`,
    method: "DELETE",
  });
}

// 根据标签筛选帖子
export function GetFilterTagList(query) {
  return request({
    url: `/api/Business/ForumPosts/GetFilterTagList`,
    method: "GET",
    data: query,
  });
}

// 积分规则
export function GetScoleRule(data) {
  return request({
    url: `/api/Business/ForumScorerule`,
    method: "GET",
    data: {
      R_ActiveName: data,
    },
  });
}

// 增加积分记录
export function EditScore(query) {
  return request({
    url: `/api/Business/ForumPointslog`,
    method: "post",
    data: query,
  });
}

// 论坛逻辑：模块id => 帖子 => 评论 => 回复
// 课程逻辑：课程id => 问答 => 评论

// 我的问答列表 ym用
export function GetPersonQAList(id) {
  return request({
    url: `/api/Business/ForumPosts/GetPersonQAList/${id}`,
    method: "GET",
  });
}
// 根据课程id 获取所有问答+问答内评论数据 ym用
export function GetPostListWithComment(id) {
  return request({
    url: `/api/Business/ForumPosts/GetPostListWithComment/${id}`,
    method: "GET",
  });
}
// 根据用户id 获取课程的所有问答 ym用
export function GetAllCoursePostList(id) {
  return request({
    url: `/api/Business/ForumPosts/GetAllCoursePostList/${id}`,
    method: "GET",
  });
}
// 我的评论列表 ym用
export function GetPersonCommentList(id) {
  return request({
    url: `/api/Business/ForumComments/GetPersonComment`,
    method: "GET",
    data: {
      Comment_admin: id,
    },
  });
}
