Date.prototype.format = function (format) {
  var date = {
    "M+": this.getMonth() + 1,

    "d+": this.getDate(),

    "h+": this.getHours(),

    "m+": this.getMinutes(),

    "s+": this.getSeconds(),

    "q+": Math.floor((this.getMonth() + 3) / 3),

    "S+": this.getMilliseconds(),
  };

  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in date) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? date[k]
          : ("00" + date[k]).substr(("" + date[k]).length)
      );
    }
  }
  return format;
};
const STORAGEPREFIX = 'Summit_'
const STORAGETYPE = window.localStorage
const utils = {
  groupBy: (list, fn) => {
    const groups = {};
    list.forEach(function (o) {
      const group = typeof fn(o) == "string" ? fn(o) : JSON.stringify(fn(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    // return Object.keys(groups).map(function (group) {
    //     return groups[group];
    // });
    return groups;
  },
  deepClone: (obj) => {
    return JSON.parse(JSON.stringify(obj));
  },
  addMinutes: (date, minutes) => {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
  },
  fullScreen: (trg) => {
    //var element= document.documentElement; //若要全屏页面中div，var element= document.getElementById("divID");
    var element = document.documentElement;
    //IE 10及以下ActiveXObject
    if (window.ActiveXObject) {
      var WsShell = new ActiveXObject("WScript.Shell");
      WsShell.SendKeys("{F11}");
      //写全屏后的执行函数
    }
    //HTML W3C 提议
    else if (element.requestFullScreen) {
      element.requestFullScreen();
      //写全屏后的执行函数
    }
    //IE11
    else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
      //写全屏后的执行函数
    }
    // Webkit (works in Safari5.1 and Chrome 15)
    else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
      //写全屏后的执行函数
    }
    // Firefox (works in nightly)
    else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      //写全屏后的执行函数
    }
  },
  fullExit: () => {
    //var element= document.documentElement;//若要全屏页面中div，var element= document.getElementById("divID");
    var element = document.documentElement;
    //IE ActiveXObject
    if (window.ActiveXObject) {
      var WsShell = new ActiveXObject("WScript.Shell");
      WsShell.SendKeys("{F11}");
      //写退出全屏后的执行函数
    }
    //HTML5 W3C 提议
    else if (element.requestFullScreen) {
      document.exitFullscreen();
      //写退出全屏后的执行函数
    }
    //IE 11
    else if (element.msRequestFullscreen) {
      document.msExitFullscreen();
      //写退出全屏后的执行函数
    }
    // Webkit (works in Safari5.1 and Chrome 15)
    else if (element.webkitRequestFullScreen) {
      document.webkitCancelFullScreen();
      //写退出全屏后的执行函数
    }
    // Firefox (works in nightly)
    else if (element.mozRequestFullScreen) {
      document.mozCancelFullScreen();
      //写退出全屏后的执行函数
    }
  },
  isFullScreen: () => {
    return !!(
      document.fullscreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.webkitFullScreen ||
      document.msFullScreen
    );
  },
  getRanNum: () => {
    var result = [];
    for (var i = 0; i < 3; i++) {
      var ranNum = Math.ceil(Math.random() * 25);
      result.push(String.fromCharCode(65 + ranNum));
    }
    var num = utils.RndNum(2);
    return result.join("") + num;
  },

  RndNum: (n) => {
    var rnd = "";
    for (var i = 0; i < n; i++) rnd += Math.floor(Math.random() * 10);
    return rnd;
  },

  storageSet(obj) {
    for (let i in obj) {
      cacheItem(i, obj[i])
    }

    function cacheItem(key, val) {
      key = STORAGEPREFIX + key
      let valType = typeof (val)
      if (val !== null) {
        var valConstructor = val.constructor
      }
      if (valType === 'string' || valType === 'number' || valType === 'boolean') {
        if (valConstructor === String) {
          val = val + '|String'
        } else if (valConstructor === Number) {
          val = val + '|Number'
        } else if (valConstructor === Boolean) {
          val = val + '|Boolean'
        }
        STORAGETYPE.setItem(key, val)
      } else if (valType === 'object') {
        if (val === null) {
          val = JSON.stringify(val) + '|Null'
          STORAGETYPE.setItem(key, val)
        } else {
          if (valConstructor === Array) {
            val = JSON.stringify(val) + '|Array'
          } else if (valConstructor === Object) {
            val = JSON.stringify(val) + '|Object'
          }
          STORAGETYPE.setItem(key, val)
        }
      }
    }
  },
  storageGet(key) {
    key = STORAGEPREFIX + key
    let keyName = STORAGETYPE.getItem(key)
    if (keyName === null) {
      return null
    }
    let valArr = keyName.split('|')
    let getDataType = valArr[valArr.length - 1]
    valArr.splice(valArr.length - 1, 1)
    let val = valArr.join('')
    if (getDataType === 'Number') {
      val = parseInt(val)
    } else if (getDataType === 'Boolean') {
      if (val === 'true') {
        val = true
      } else {
        val = false
      }
    } else if (getDataType === 'Array' || getDataType === 'Object' || getDataType === 'Null') {
      val = JSON.parse(val)
    }
    return val
  },
  storageRemove(key) {
    STORAGETYPE.removeItem(STORAGEPREFIX + key)
  },
  storageClear() {
    for (let i in STORAGETYPE) {
      if (i.indexOf(STORAGEPREFIX) !== -1) {
        STORAGETYPE.removeItem(i)
      }
    }
  },
};
export default utils;
