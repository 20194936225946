
import utils from '@/utils/utils'
export function getToken() {
  return utils.storageGet('token')
}

export function setToken(token) {
  return utils.storageSet({ token })
}

export function removeToken() {
  return utils.storageRemove('token')
}