<template>
    <div class="InvoiceManagement">
        <!-- <Search @search="search"></Search> -->
        <div class="machineOps">
            <!-- <el-button type="text" icon="el-icon-plus" @click="handleBatchCreate()">批量创建
            </el-button> -->
        </div>
        <!-- <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark"
            element-loading-text="Loading" fit :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column prop="orderNumber" label="订单编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="product" label="开票公司名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="number" label="产品名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="buyWay" label="状态" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="duration" label="订单实付金额" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="可开发票金额" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="发票内容" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="物流订单号" align="center" show-overflow-tooltip></el-table-column>
        </el-table> -->
        <el-table class="custom-tabs" v-loading="listLoading" :data="list" tooltip-effect="dark"
            @sort-change='sortChange' border :span-method="objectSpanMethod" has-c
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" prop="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="machinecode" label="机器码" align="left">
                <template slot="header">
                    机器码
                    <el-tooltip effect="light" content="打开软件在许可信息弹窗中可获取机器ID" placement="top">
                        <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="producttype" label="产品类型" align="left">
                <template slot-scope="scope">
                    {{ scope.row.producttype.split('+')[1] }}
                </template>
            </el-table-column>
            <el-table-column prop="productid" label="产品" align="left">

                <template slot-scope="scope">
                    {{ getProductName(scope.row.productid) }}
                </template>
            </el-table-column>
            <el-table-column prop="ordername" label="订单名" align="left" />
            <el-table-column prop="purchasemethod" label="订单类型" align="left" />
            <el-table-column prop="state" label="订阅状态" align="left">
                <template slot-scope="scope">
                    {{ getStateName(scope.row.state) }}
                </template>
            </el-table-column>
            <el-table-column prop="duration" label="订阅时长" align="left">

                <template slot-scope="scope">
                    {{ getDuration(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column prop="licensemaker" label="许可证制作者" align="left" />
            <el-table-column prop="endtime" label="结束时间" align="left" />
            <el-table-column label="删除" width="100">

                <template slot-scope="scope">
                    <el-button size="mini" type="text" class="JNPF-table-delBtn" v-if="scope.row.state == 0"
                        @click="handleDel(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100" prop="operation">

                <template slot-scope="scope">
                    <el-button size="mini" type="text" class="JNPF-table-delBtn" v-if="scope.row.state == 0"
                        @click="beforeCreateApply(scope.$index, scope.row)">创建许可证</el-button>
                    <el-button size="mini" type="text" class="JNPF-table-delBtn"
                        v-show="ifUpdate(scope.$index, scope.row)"
                        @click="beforeCreateApply(scope.$index, scope.row)">更新许可证</el-button>
                    <el-button size="mini" type="text" class="JNPF-table-delBtn" v-if="scope.row.state == 1"
                        @click="downloadFile(scope.row)">下载</el-button>

                </template>
            </el-table-column>
        </el-table>
        <div class="table-actions" @click="addNew" style="text-align: center;" v-show="licenseMakeStatus">
            <!-- <el-button type="text" icon="el-icon-plus" v-show="licenseMakeStatus">创建许可证</el-button> -->
            您有订单可以<el-button size="mini" type="primary" style="margin-left: 10px;">创建许可证</el-button>
        </div>
        <div class="table-actions1" style="text-align: center;" v-show="!licenseMakeStatus">
            暂无订单需要创建许可证
        </div>
        <el-pagination :total="total" :page.sync="listQuery.currentPage" :limit.sync="listQuery.pageSize"
            @pagination="initData" />
        <mutiOrderLicense ref="mutiOrderLicense" @refreshDataList="receptData"></mutiOrderLicense>

    </div>
</template>

<script>
import Search from './Search.vue';
import request from "@/utils/request";
import mutiOrderLicense from './mutiOrderLicense.vue';

export default {
    components: {
        Search, mutiOrderLicense
    },
    data() {
        return {
            tableData: [],
            loading: false,
            listLoading: true,
            list: [],
            total: 0,
            listQuery: {
                superQueryJson: '',
                currentPage: 1,
                pageSize: 20,
                sort: "desc",
                sidx: "",
            },
            query: {
                productid: undefined,
                producttype: undefined,
                state: undefined,
                duration: undefined,
                licensemaker: undefined,
                companyname: undefined,
                ordercode: undefined,
            },
            applyStateList: [
                {
                    value: 0,
                    label: '未创建'
                },
                {
                    value: 1,
                    label: '正常'
                },
                {
                    value: 2,
                    label: '过期'
                },
            ],
            productList: [],
            multipleSelection: [],
            name: '',
            licenseMakeStatus: false

        };
    },
    mounted() {
        let userInfo = this.$store.state.user.userInfo;
        // this.dataForm.userid = userInfo.id;
        // this.dataForm.username = userInfo.username;

        this.query.userid = userInfo.id;
        this.name = userInfo.username;
        // this.initOrderData();
        this.initData();
        this.getProductList();
        this.getLicenseCreateStatus()
    },
    watch: {

    },
    methods: {
        getLicenseCreateStatus() {
            let query = {
                userid: this.query.userid,// 用户id
                paymentstate: "1", //（0-未支付，1-已支付，2-支付失败）
                purchasemethod: 'online',
                menuId: "491144407886626501"
            };
            request({
                url: `/api/business/C_customers/getOrderListWithStatus`,
                method: 'post',
                data: query
            }).then(res => {
                let resList = res.data.list
                resList.forEach(e => {
                    if (e.licensestatus != 1) {
                        this.licenseMakeStatus = true
                    }
                })

            })
        },
        search(val) {
            console.log("val", val);
        },
        initData() {
            this.listLoading = true;
            if (this.query.userid) {
                let _query = {
                    orderuserid: this.query.userid,
                    state: 1
                };
                request({
                    url: `/api/business/C_orderManage/getlicenseList`,
                    method: 'post',
                    data: _query
                }).then(res => {
                    var _list = [];
                    for (let i = 0; i < res.data.list.length; i++) {
                        let _data = res.data.list[i];
                        _list.push(_data);
                    }
                    this.list = _list.map(o => ({
                        ...o,
                        ...this.expandObj
                    }));
                    this.total = res.data.pagination.total;
                    this.listLoading = false;
                    this.list = this.list.filter(function (e) {
                        return e.purchasemethod == 'online';
                    });
                    this.list.forEach(e => {
                        e.producttype = e.machinecode + '+' + e.producttype + '+' + e.purchasemethod;
                    });
                    this.handleData();
                    this.mergeRow(this.list, ['machinecode', 'producttype']);
                });
            }
        },
        getProductList() {
            let query = {
                pageSize: 100
            };
            request({
                url: `/api/business/C_production/getList`,
                method: 'post',
                data: query
            }).then(res => {
                this.productList = res.data.list;
            });
        },
        // 动态合并
        mergeRow(data, merge) {
            if (!merge || merge.length === 0) {
                return data;
            }
            merge.forEach((m) => {
                const mList = {};
                data = data.map((v, index) => {
                    const rowVal = v[m];
                    if (mList[rowVal] && mList[rowVal].newIndex === index) {
                        mList[rowVal]['num']++;
                        mList[rowVal]['newIndex']++;
                        data[mList[rowVal]['index']][m + '-span'].rowspan++;
                        v[m + '-span'] = { rowspan: 0, colspan: 0 };
                        // 定制操作栏
                        if (m == 'producttype') {
                            data[mList[rowVal]['index']]['operation-span'].rowspan++;
                            data[mList[rowVal]['index']]['selection-span'].rowspan++;
                            v['operation-span'] = { rowspan: 0, colspan: 0 };
                            v['selection-span'] = { rowspan: 0, colspan: 0 };
                        }
                    } else {
                        mList[rowVal] = {
                            num: 1,
                            index: index,
                            newIndex: index + 1
                        };
                        v[m + '-span'] = { rowspan: 1, colspan: 1 };
                        if (m == 'producttype') {
                            v['operation-span'] = { rowspan: 1, colspan: 1 };
                            v['selection-span'] = { rowspan: 1, colspan: 1 };
                        }
                    }
                    return v;
                });
            });
            return data;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            const span = column['property'] + '-span';
            if (row[span]) {
                return row[span];
            }
        },
        sortChange({ column, prop, order }) {
            this.listQuery.sort = order == 'ascending' ? 'asc' : 'desc';
            this.listQuery.sidx = !order ? '' : prop;
            this.initData();
        },
        // 删除子表
        handleDel(index, row) {
            this.list.splice(index, 1);
            this.mergeRow(this.list, ['machinecode', 'producttype']);
        },
        handleSelectionChange(val) {
            // const res = val.map(item => item.id);
            this.multipleSelection = val;
        },
        getStateName(data) {
            if (data == 0) {
                return "未创建";
            } else if (data == 1) {
                return "已创建";
            } else if (data == 2) {
                return "已过期";
            }
        },
        getProductName(data) {
            let res = this.productList.filter(e => {
                return e.id == data;
            });
            if (res[0]) {
                return res[0].name;
            } else {
                return '';
            }
        },
        // 下载单个许可证
        downloadFile(data) {
            request({
                url: `/api/business/C_license/Download/${data.id}`,
                method: 'get',
            }).then(res => {
                let url = "/api/business/C_license/downloadFile?data=" + res.data;
                if (!url) return;
                const baseUrl = url.indexOf("http") > -1 ? "" : this.define.APIURl;
                const a = document.createElement("a");
                a.setAttribute("download", res.data);
                let name = null;
                const realUrl = baseUrl + url + (name ? "&name=" + name : "");
                a.setAttribute("href", realUrl);
                a.click();
            });
        },
        // 批量创建许可证
        handleBatchCreate() {
            if (this.multipleSelection.length == 0) {
                this.$message({
                    type: 'error',
                    message: '请选择数据',
                    duration: 1500,
                });
                return;
            }
            this.dealBatchCreate(this.multipleSelection);
        },
        // 新增添加机器码输入框
        addNew() {
            this.$refs.mutiOrderLicense.initOrderData(this.query.userid, this.list);
        },
        receptData(orderData, machineCode) {
            // 去掉原list中的位置标记
            this.list.forEach(e => {
                delete e['machinecode-span'];
                delete e['producttype-span'];
                delete e['operation-span'];
            });
            orderData.forEach(e => {
                let dataSimple = {
                    productid: e.productid,
                    featureid: e.featureid,
                    producttype: machineCode + '+' + e.producttype + '+' + e.purchasemethod,
                    state: 0,
                    filedata: '',
                    modules: '',
                    duration: e.duration,
                    durationunion: e.durationunion,
                    machinecode: machineCode,
                    purchasemethod: e.purchasemethod,
                    contactphone: '',
                    contactname: '',
                    companyname: '',
                    ordercode: e.ordercode,
                    endtime: '',
                    orderdetailproductid: e.id,
                    ordername: e.ordername,
                };
                this.list.push(dataSimple);
            });
            this.handleData();
            this.mergeRow(this.list, ['machinecode', 'producttype']);
        },
        handleData() {
            // list中数据按照机器码 - 产品类型顺序排列
            let machineList = [];
            this.list.map(mapItem => {
                if (machineList.length == 0) {
                    machineList.push({ code: mapItem.machinecode, List: [mapItem] });
                } else {
                    let res = machineList.some(item => {
                        if (item.code == mapItem.machinecode) {
                            item.List.push(mapItem);
                            return true;
                        }
                    });
                    if (!res) {
                        machineList.push({ code: mapItem.machinecode, List: [mapItem] });
                    }
                }
            });
            let result = [];
            machineList.forEach(e => {
                // 相同机器码下的 相同产品顺序排列 e.List
                let baseList = JSON.parse(JSON.stringify(e.List));
                let producttypeList = [];
                let resList = [];
                baseList.forEach(ele => {
                    if (!producttypeList.includes(ele.producttype)) {
                        let dataR = baseList.filter(item => {
                            return item.producttype == ele.producttype;
                        });
                        resList = [...resList, ...dataR];
                        producttypeList.push(ele.producttype);
                    }
                });
                console.log(resList, 'yyyy');
                result = [...result, ...resList];
            });

            this.list = result;
        },
        // 创建许可证前提示
        beforeCreateApply(index, row) {
            this.$confirm('许可证一旦生成不可撤销，不可转移，请确认机器码从订阅软件运行的电脑上获取。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // this.$message({
                //     type: 'success',
                //     message: '删除成功!'
                // });
                this.createApply(index, row);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        // 创建许可证
        createApply(index, row) {
            console.log(index, row);
            let productCount = row['producttype-span'].rowspan;
            let totalData = [];
            totalData.push(this.list[index]);
            for (let i = 1; i < productCount; i++) {
                totalData.push(this.list[i + index]);
            }
            let idsResult = totalData.map(item => {
                return item.productid;
            });
            let featureidR = totalData.map(item => {
                return item.featureid;
            });
            let durationR = totalData.map(item => {
                return item.duration;
            });
            let durationunionR = totalData.map(item => {
                return item.durationunion;
            });
            let detailIds = totalData.map(item => {
                return item.orderdetailproductid;
            });
            let ordercodeR = totalData.map(item => {
                return item.ordercode;
            });
            let producttypeD = '';
            if (totalData[0].purchasemethod == 'online') {
                producttypeD = totalData[0].producttype.split('+')[1] + "-Online";
            } else {
                producttypeD = totalData[0].producttype.split('+')[1];
            }
            let data = {
                productid: idsResult.toString(),
                featureid: featureidR.toString(),
                producttype: producttypeD,
                state: 0,
                filedata: '',
                duration: durationR.toString(),
                durationunion: durationunionR.toString(),
                machinecode: totalData[0].machinecode,
                contactphone: '',
                contactname: '',
                companyname: '',
                ordercode: ordercodeR.toString(),
                guestmes: '',
                endtime: '',
                creatoruserid: '',
                orderuserid: this.query.userid,
                orderusername: '',
                orderdetailproductid: detailIds.toString(),
                licensemakerid: this.query.userid,
                licensemaker: this.name,
                licensetype: 1
            };
            request({
                url: '/api/business/C_license/makelicense',
                method: 'post',
                data: data
            }).then((res) => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                        this.list.splice(index, productCount);
                        // this.initData();
                        // this.machineDataList.splice(index, 1);
                        this.refreshMachineData();
                        // this.selectData();
                    }
                });
            });
        },
        // 回显机器码数据
        refreshMachineData() {
            let _query = {
                orderuserid: this.query.userid,
                state: 1
            };
            request({
                url: `/api/business/C_orderManage/getlicenseList`,
                method: 'post',
                data: _query
            }).then(res => {
                let addedMachineData = res.data.list;
                // 处理原数据
                addedMachineData.forEach(e => {
                    e.orderdetailproductid = e.orderdetailproductid ? e.orderdetailproductid.split(',') : '';
                    e.productid = e.productid.split(",");
                    e.producttype = e.machinecode + '+' + e.producttype + '+' + e.purchasemethod;
                });
                // console.log(addedMachineData, '??????');
                let base = JSON.parse(JSON.stringify(this.list));
                console.log(base, '----------');

                let totalList = [...addedMachineData, ...base];
                console.log(totalList, 'totalList');
                let idList = totalList.filter(e => { return e.id != null; });
                let noidList = totalList.filter(e => { return e.id == null; });
                let obj = {};
                let resIdList = idList.reduce(function (item, next) {
                    obj[next.id] ? '' : (obj[next.id] = true && item.push(next));
                    return item;
                }, []);
                this.list = [...resIdList, ...noidList];
                console.log(this.list, '++++++');
                this.list = this.list.filter(function (e) {
                    return e.purchasemethod == 'online';
                });

                // this.list.forEach(e => {
                //   e.producttype = e.machinecode + '+' + e.producttype;
                // });
                this.handleData();
                this.mergeRow(this.list, ['machinecode', 'producttype']);
            });
        },
        dealBatchCreate(datas) {
            console.log(datas, 'iiii');
            let dataList = [];
            datas.forEach(ele => {
                let productCount = ele['producttype-span'].rowspan;
                let totalData = [];
                let index = this.list.indexOf(ele);
                totalData.push(this.list[index]);
                for (let i = 1; i < productCount; i++) {
                    totalData.push(this.list[i + index]);
                }
                let idsResult = totalData.map(item => {
                    return item.productid;
                });
                let featureidR = totalData.map(item => {
                    return item.featureid;
                });
                let durationR = totalData.map(item => {
                    return item.duration;
                });
                let durationunionR = totalData.map(item => {
                    return item.durationunion;
                });
                let detailIds = totalData.map(item => {
                    return item.orderdetailproductid;
                });
                let ordercodeR = totalData.map(item => {
                    return item.ordercode;
                });
                let data = {
                    productid: idsResult.toString(),
                    featureid: featureidR.toString(),
                    producttype: totalData[0].producttype.split('+')[1],
                    state: 0,
                    filedata: '',
                    duration: durationR.toString(),
                    durationunion: durationunionR.toString(),
                    machinecode: totalData[0].machinecode,
                    contactphone: '',
                    contactname: '',
                    companyname: '',
                    ordercode: ordercodeR.toString(),
                    guestmes: '',
                    endtime: '',
                    creatoruserid: '',
                    orderuserid: this.query.userid,
                    orderusername: '',
                    orderdetailproductid: detailIds.toString(),
                    licensemakerid: this.query.userid,
                    licensemaker: this.name
                };
                dataList.push(data);
            });
            request({
                url: '/api/business/C_license/mutiBatchCreate',
                method: 'post',
                data: dataList
            }).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                        // this.machineDataList.splice(index, 1);
                        // 处理回显数据 找到选中的新增数据在原数据中的index 然后删除
                        datas.forEach(e => {
                            this.list.splice(this.list.findIndex(item => item.productid == e.productid), 1);
                        });
                        this.refreshMachineData();
                    }
                });
            });

        },
        // 判斷是否需要更新
        ifUpdate(index, data) {
            let count = data['producttype-span'].rowspan;
            let result = false;
            if (count != 1) {
                // 是多选
                let dataTotal = [];
                for (let i = 0; i < count; i++) {
                    dataTotal.push(this.list[index + i].state);
                }
                if (dataTotal.includes(0) && dataTotal.includes(1)) {
                    result = true;
                }
            }
            return result;
        },
        getDuration(data) {
            if (!data.durationunion) {
                return "永久";
            } else {
                if (data.durationunion == 'week') {
                    return data.duration + "周";
                } else if (data.durationunion == "month") {
                    return data.duration + "月";
                } else if (data.durationunion == "season") {
                    return data.duration + "季";
                } else if (data.durationunion == "halfyear") {
                    return data.duration + "半年";
                }
            }
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.InvoiceManagement {
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 32px;
    padding-bottom: 10px;

    margin-top: 30px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);



    // table
    // :deep .el-table__header {
    //     border-radius: 20px 20px, 0px, 0px;
    //     background-color: rgba(245, 245, 245, 1);
    // }

    // :deep .el-table th.el-table__cell {
    //     background-color: rgba(245, 245, 245, 1);
    //     border-radius: 20px;
    //     border: none;
    // }

    :deep .el-table__body tr:hover>td {
        background-color: rgba(245, 245, 245, 1) !important;
    }

    :deep .el-table tr {
        height: 46px;
    }

    :deep .el-table__body-wrapper {
        height: 500px;
        overflow-y: auto;

    }

    :deep .el-table__empty-block {
        min-height: 50px;
    }

    // :deep .el-table th.el-table__cell>.cell {
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 22.4px;
    //     color: rgba(128, 128, 128, 0.7);
    // }

    :deep .el-table__header .has-gutter tr th {
        background-color: rgba(213, 231, 250, 1);
        border-right: 1px solid #fff;
        color: rgba(56, 56, 56, 1);
    }
}

.table-actions1 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 23.17px;
    color: rgba(166, 166, 166, 1);
    text-align: center;
    padding-top: 10px;
    vertical-align: top;
}

.table-actions {
    padding-top: 10px;
}
</style>