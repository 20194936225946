import request from "@/utils/request";

//跳转微信授权登录界面
export function renderVxModel() {
  return request({
    url: "/api/business/C_customers/render",
    method: "post",
    // data,
  });
}

// 获取微信用户信息
export function GetVxUserMes(data) {
  return request({
    url: "/api/business/C_customers/callback",
    method: "post",
    data,
  });
}

// 根据token获得用户信息
export function GetUserMesByToken() {
  return request({
    url: "/api/business/C_customers/userinfo",
    method: "get",
  });
}

// 根据token获得用户信息
export function GetRetailerMesByToken() {
  return request({
    url: "/api/business/C_retailers/userinfo",
    method: "get",
  });
}

// 发送登录验证码
export function SendVerCode(data) {
  return request({
    url: "/api/business/C_customers/sendVerCode?phone=" + data,
    method: "post",
  });
}

// 微信登录同时绑定手机号码
export function LoginByWx(data) {
  return request({
    url: "/api/business/C_customers/loginByWx",
    method: "post",
    data,
  });
}

// 短信登录
export function LoginByPhone(data) {
  return request({
    url: "/api/business/C_customers/loginByPhone",
    method: "post",
    data,
  });
}

// 验证码检验
export function CheckVerCode(data, code) {
  return request({
    url:
      "/api/business/C_customers/checkVerCode?phone=" +
      data +
      "&verCode=" +
      code,
    method: "post",
  });
}

// 分销商短信登录
export function LoginByPhoneRetailer(data) {
  return request({
    url: "/api/business/C_retailers/loginByPhone",
    method: "post",
    data,
  });
}

// 发送登录验证码
export function SendVerCodeRetailer(data) {
  return request({
    url: "/api/business/C_retailers/checkVerCode?phone=" + data,
    method: "post",
  });
}

// 申请许可证
export function ApplyLicense(data) {
  return request({
    url: "/api/business/C_license/applyLicense",
    method: "post",
    data,
  });
}
// 免申请直接生成许可证
export function createPermitLicense(data) {
  return request({
    url: "/api/business/C_license/createPermitLicense",
    method: "post",
    data,
  });
}

// 判断许可码是否可用
export function checkPermit(data) {
  return request({
    url: `/api/business/C_permitcode/getList`,
    method: "post",
    data,
  });
}
