<template>
	<div class="wxLogin">
		<!-- <i class="iconfont icon-zhcc_shoujihao" @click="toShow" title="可切换为验证码登录"></i> -->
		<div class="main_wx">
			<!-- <wxlogin appid="wx9f5288329358a2f4" :scope="'snsapi_login'" :theme="'black'"
				:redirect_uri="encodeURIComponent('https://www.summitek.xyz/login')"
				:href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZxxx='" rel="external nofollow"
				:state="Math.ceil(Math.random() * 1000)"></wxlogin> -->
			<!-- https://open.weixin.qq.com/connect/qrconnect?response_type=code&appid=wx9f5288329358a2f4&redirect_uri=https://www.summitek.xyz/pc/login&scope=snsapi_login&state=794616fbe14c194f5b576d95de3756e8 -->
			<wxlogin appid="wx9f5288329358a2f4" :scope="'snsapi_login'" :theme="'black'"
				:redirect_uri="encodeURIComponent('https://www.summitek.xyz/pc/login')"
				:href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZxxx='" rel="external nofollow"
				:state="Math.ceil(Math.random() * 1000)"></wxlogin>
		</div>
		<p>请使用微信扫描二维码登录</p>
	</div>
</template>

<script>
// npm install vue-wxlogin --save-dev
import wxlogin from 'vue-wxlogin';
// import api from '@api/index'
export default {
	name: 'wxLogin',
	components: { wxlogin },
	data() {
		return {
		};
	},
	mounted() { },
	methods: {
	}
};
</script>

<style lang="scss" scoped>
.title {
	font-size: 16px;
	font-weight: bold;
	text-align: center;
}

p:last-child {
	text-align: center;
}

.icon-zhcc_shoujihao {
	display: inline-block;
	font-size: 46px;
	position: absolute;
	top: -2px;
	right: 5px;
	color: #1c51a6;
	z-index: 2;
	cursor: pointer;
}
</style>
