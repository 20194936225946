export default {
  login: {
    title: '賬戶密碼登錄',
    scanTitle: 'APP掃碼登錄',
    codeTitle: '手機驗證登錄',
    logIn: '登錄',
    username: '請輸入賬號',
    password: '請輸入密碼',
    rule: '用戶名或手機號',
    version: '版本',
    upper: '大寫已鎖定',
    scanTip: 'APP掃碼登錄',
    accountTip: '請輸入賬號',
    passwordTip: '請輸入密碼',
    codeTip: '請輸入驗證碼',
    changeCode: '點擊切換驗證碼',
    mobile: '請輸入手機號',
    rightMobile: '請輸入正確的手機號',
    smsCode: '請輸入驗證碼',
    getCode: '獲取驗證碼',
    reSend: '重新發送',
    company: '請輸入公司名',
    contacts: '請輸入聯系人'
  },
}