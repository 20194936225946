<template>
    <div class="block_center">
        <div class="banner">
            <div class="quit" @click="logout"><i class="el-icon-switch-button" style="margin-right: 5px;"></i>退出</div>
        </div>
        <div class="totalContent">
            <div class="leftPart">
                <div class="personMes leftItem">
                    <div class="retailerName">{{ retailerInfo.retailername }}</div>
                    <div style="text-align: center;">
                        <div class="level">{{ levelData }}</div>
                    </div>
                    <div class="companyMes">
                        {{ retailerInfo.companyname }}
                        {{ retailerInfo.phonenumber }}
                    </div>
                </div>
                <div class="leftItem reCode">
                    <div class="retailText">
                        分销码
                    </div>
                    <span>{{ retailerInfo.retailcode }}</span>
                </div>

            </div>
            <div class="rightPart">
                <div class="numberContent rightItem">
                    <div class="numItem">
                        <div class="num">{{ moneyAbout.retailnum }}</div>
                        <div class="numText">发展分销数量</div>
                    </div>
                    <div class="numItem">
                        <div class="num">{{ moneyAbout.totalcount }}</div>
                        <div class="numText">分销总金额</div>
                    </div>
                    <div class="numItem">
                        <div class="num">{{ commission }}</div>
                        <div class="numText">佣金</div>
                    </div>
                </div>
                <div class="rightItem listContent">
                    <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark"
                        element-loading-text="Loading" fit :default-sort="{ prop: 'date', order: 'descending' }">
                        <el-table-column prop="ordernum_name" label="订单号" align="center"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="ordername_name" label="订单名" align="center"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="username_name" label="下单用户名" align="center"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="receiverphone_name" label="联系电话" align="center"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="company" label="公司" align="center"
                            show-overflow-tooltip></el-table-column>
                        <!-- <el-table-column prop="paymentstate_name" label="交易状态" align="center" show-overflow-tooltip>
                        </el-table-column> -->
                        <el-table-column prop="product" label="产品" align="center"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="number" label="数量" align="center"
                            show-overflow-tooltip></el-table-column>
                        <!-- <el-table-column prop="purchasemethod_name" label="购买方式" align="center"
                        show-overflow-tooltip></el-table-column> -->
                        <!-- <el-table-column prop="duration" label="时长" align="center" show-overflow-tooltip></el-table-column> -->
                        <!-- <el-table-column prop="startTime" label="开始时间" align="center" show-overflow-tooltip></el-table-column> -->
                        <el-table-column prop="paymentamount_name" label="实付款" align="center"
                            show-overflow-tooltip></el-table-column>
                        <!-- <el-table-column label="操作" width="200">
                            <template slot-scope="scope">
                                <el-button type="text" @click="goDetail(scope.row.id)">详情</el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <el-pagination :total="total" :page.sync="listQuery.currentPage" :limit.sync="listQuery.pageSize"
                        @pagination="getInit" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import request from '@/utils/request';
export default {
    data() {
        return {
            retailerInfo: {
                retailername: '',
                companyname: '',
                phonenumber: '',
                retailcode: '',
                retaillevel: ''
            },
            levelData: '',
            commission: '',
            moneyAbout: {},
            total: 0,
            loading: false,
            tableData: [],
            query: {
                userid: '',// 用户id
                paymentstate: "1", //（0-未支付，1-已支付，2-支付失败）
                purchasemethod: 'online',
                retailercode: ''
            },
            listQuery: {
                superQueryJson: '',
                currentPage: 1,
                pageSize: 20,
                sort: "desc",
                sidx: "",
            },
        };
    },
    methods: {
        getLevel() {
            let query = {};
            request({
                url: `/api/business/C_retailLevel/getNpList`,
                method: 'post',
                data: query
            }).then(res => {
                let levelList = [...res.data];
                let levelId = this.retailerInfo.retaillevel;
                let levelData = levelList.find(function (e) {
                    return e.id == levelId;
                });
                this.levelData = levelData ? levelData.levelname : '-';
            });
        },
        getDetail() {
            request({
                url: '/api/business/C_retailers/detail/' + this.retailerInfo.id,
                method: 'get'
            }).then(res => {
                this.commission = res.data.commission;
                this.moneyAbout = res.data;
                this.query.retailercode = this.retailerInfo.retailcode;
                this.getInit();
            });
        },
        getInit() {
            this.loading = true;
            let _query = {
                ...this.listQuery,
                ...this.query,
                dataType: 0
            };
            request({
                url: `/api/business/C_customers/getOrderListWithStatus`,
                method: 'post',
                data: _query
            }).then(res => {
                if (res.code == 200) {
                    this.loading = false;
                    this.tableData = res.data.list;
                    this.total = res.data.pagination.total;

                }
            });
        },
        goDetail(id) {
            // this.detailVisible = true;
            // this.$nextTick(() => {
            //     this.$refs.OrderDetail.init(id);
            // });
        },
        async logout() {
            await this.$store.dispatch("user/logoutRetailer");
            // 退出时保持本页面
            this.$router.push("/loginRetailer");
        },
    },
    computed: {

    },
    mounted() {
        this.$store.commit("isFull", true);
        this.$store.state.isFullScreen = true;
        this.retailerInfo = this.$store.state.user.retailerInfo;
        if (!this.retailerInfo.id && localStorage.getItem("RETAILERINFO") != "undefined") {
            this.retailerInfo = JSON.parse(localStorage.getItem("RETAILERINFO"));
        }
        if (!this.retailerInfo || !this.retailerInfo.id) {
            this.$router.replace({
                name: "loginRetailer",
                path: "/loginRetailer",
            });
        }
        this.getLevel();
        this.getDetail();

    }
};
</script>
<style lang="scss" scoped>
.block_center {
    height: 100%;
    width: 100%;
    background-color: rgba(247, 248, 251, 1);
}

.banner {
    height: 154px;
    opacity: 1;
    background: rgba(43, 133, 229, 1);
    width: 100%;

    .quit {
        float: right;
        margin: 20px;
        color: #fff;
        cursor: pointer;
    }
}

.totalContent {
    margin-top: -50px;
    padding: 0 28px;
    margin-bottom: 20px;
    display: flex;
    height: 100%;
    width: 100%;

    .leftPart {
        width: 276px;
        opacity: 1;
        margin-right: 20px;

        .leftItem {
            width: 100%;
            opacity: 1;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);

        }

        .personMes {
            margin-bottom: 20px;
            text-align: center;

            .retailerName {
                font-size: 24px;
                opacity: 1;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 34.75px;
                color: rgba(0, 0, 0, 1);
                padding-top: 50px;
            }

            .level {
                width: 59px;
                height: 18px;
                opacity: 1;
                border-radius: 4px;
                background: rgba(43, 133, 229, 1);
                font-size: 10px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 14.48px;
                color: rgba(255, 255, 255, 1);
                text-align: center;
                vertical-align: top;
                margin: 10px auto;
            }

            .companyMes {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 30px;
                color: rgba(56, 56, 56, 1);
                text-align: left;
                vertical-align: top;
                padding: 20px;

            }
        }

        .reCode {
            padding: 20px;

            .retailText {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 26.06px;
                font-family: syht;
                color: rgba(56, 56, 56, 1);
                text-align: left;
                vertical-align: top;
                border-bottom: 1px solid rgba(229, 229, 229, 1);
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
        }

    }

    .rightPart {
        flex: 1;

        .rightItem {
            width: 100%;
            opacity: 1;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);
        }

        .numberContent {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 20px 0;

            .numItem {
                flex: 1;
                text-align: center;

                .num {
                    font-size: 36px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 36px;
                    color: rgba(43, 133, 229, 1);
                    text-align: center;
                    vertical-align: top;
                    padding-bottom: 5px;

                }

                .numText {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 26.06px;
                    color: rgba(56, 56, 56, 1);
                    text-align: center;
                    vertical-align: top;

                }
            }
        }

        .listContent {
            height: 100%;
        }
    }
}
</style>
