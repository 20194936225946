<template>
  <div id="app">
    <div class="nav" id="page">
      <el-scrollbar style="overflow-x: hidden !important" v-if="$store.state.user.showLoginPane">
        <router-view />
      </el-scrollbar>
      <router-view v-else />
      <!-- <el-backtop style="height: 40px !important"></el-backtop> -->
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    // if (this._isMobile()) {
    //   window.open("http://47.99.221.200:9020", "_self");
    // }
  },
  computed: {
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="scss">
// html {
// overflow-y: auto !important; // 0216 liuke 修改弹窗滚动bug
// }
#app {
  &.isFullScreen {
    width: 100%;
    height: 100vh;
    // overflow: hidden;
  }

  font-family: syht;
}

.nav {
  // width: 95vw;
  margin: 0 auto;
  // background-color: #f9f9f9;
}

// 20230427 liuke 去掉下拉框横向滚动条
.el-scrollbar__wrap {
  overflow: scroll;
  height: 100%;
  //   overflow-x: hidden !important;
}

// ::-webkit-scrollbar {
// 	width: 2px;
// }

// ::-webkit-scrollbar-track {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// 	border-radius: 10px;
// 	background-color: #f5f5f5;
// }
</style>
