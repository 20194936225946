<template>
    <div class="block_center">
        <div class="banner"></div>
        <div class="mianCenter">
            <ul class="question">
                <li v-for="(item, index) in questionList" :key="index" @click="toAccountSupportDetail(item.name)">
                    <div>
                        <img :src="item.img" alt="">
                    </div>
                    <div class="name">{{ item.name }}</div>
                    <div>{{ item.des }}</div>
                    <div class="lookMore">
                        <span class="more">了解更多</span>
                        <span class="line"></span>
                    </div>
                </li>
            </ul>
            <div class="moreQuestion">
                <div>想了解更多问题或软件咨询</div>
                <div class="contact">请点击下方按钮联系我们！</div>
                <div><el-button type="success" round @click="toContactUs">联系我们</el-button></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            questionList: [
                {
                    img: require('../../../../assets/image/AccountSupport_img1.png'),
                    name: "产品技巧",
                    des: "summit系列产品技巧分享summit系列产品技巧分享summit系列产品技巧分享summit系列产品技巧分享"
                },
                {
                    img: require('../../../../assets/image/AccountSupport_img2.png'),
                    name: "账号解疑",
                    des: "账号问题解疑答惑账号问题解疑答惑账号问题解疑答惑账号问题解疑答惑账号问题解疑答惑账号问题解疑答惑"
                },
                {
                    img: require('../../../../assets/image/AccountSupport_img3.png'),
                    name: "订阅购买",
                    des: "订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买订阅购买"
                },
                {
                    img: require('../../../../assets/image/AccountSupport_img4.png'),
                    name: "许可证问题",
                    des: "许可证问题许可证问题许可证问题许可证问题许可证问题许可证问题许可证问题许可证问题许可证问题许可证问题"
                }
            ]
        }
    },
    methods: {
        toAccountSupportDetail(value) {
            this.$router.push({
                path: "/AccountSupportDetail",
                query: {
                    value
                }
            });
        },
        toContactUs(){
            this.$router.push({ name: "联系我们", path: "/ContactUs" });
        },
    }

}
</script>

<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;
}

.banner {
    width: 100vw;
    height: 628px;
    background-image: url("../../../../assets/image/AccountSupport_banner.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 90px;
}

.question {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 160px;

    li {
        width: 49%;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 28px;
        color: rgb(128, 129, 133);
        text-align: left;
        vertical-align: top;
        margin-bottom: 60px;
        cursor: pointer;

        img {
            width: 100%;
            margin-bottom: 23px;
        }

        .name {
            font-size: 38px;
            line-height: 56px;
            color: rgba(40, 41, 56, 1);
            margin-bottom: 11px;
        }

        .lookMore {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 23px;
        }

        .more {
            font-size: 16px;
            color: rgba(40, 41, 56, 1);
            text-align: left;
        }

        .line {
            display: block;
            width: 80px;
            height: 2px;
            background-color: rgba(40, 41, 56, 1);
        }
    }
}

.moreQuestion {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 64px;
    color: rgba(40, 41, 56, 1);
    text-align: center;
    vertical-align: top;
    margin-bottom: 170px;

    .contact {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: rgb(128, 129, 133);
    }

    ::v-deep .el-button.is-round {
        padding: 8px 70px;
    }
}

.mianCenter {
    width: 1200px;
    margin: 0 auto;
}
</style>