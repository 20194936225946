<template>
    <div class="menuCenter">
        <div class="showImg">
            <!-- <div class="selectPart">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in options" :key="item.index" :command="item.label">{{
                            item.label
                        }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div> -->
            <div class="showImgContent">
                <img src="../../../assets/image/bigMap.png" alt="" v-if="softType == 'SummitMap'">
                <img src="../../../assets/image/bigMap.png" alt="" v-if="softType == 'SummitMapTrial'">
                <img src="../../../assets/image/bigMap.png" alt="" v-if="softType == 'SummitMapHUBEI'">
                <img src="../../../assets/image/bigSolid.png" alt="" v-if="softType == 'SummitSolid'">
                <img src="../../../assets/image/bigSolid.png" alt="" v-if="softType == 'SummitSolidTrial'">
                <img src="../../../assets/image/bigSolid.png" alt="" v-if="softType == 'SummitSolidSGJ'">
                <img src="../../../assets/image/bigLab.png" alt="" v-if="softType == 'SummitLab'">
                <div class="softTitle">{{ choosedProject.type + choosedProject.name }}</div>
                <div class="softDes" v-html="choosedProject.description"></div>
            </div>
        </div>
        <div class="choosePart">
            <!-- <el-tabs v-model="activeName" @tab-click="handleClick" class="softtimeTabs" stretch>
                <el-tab-pane label="订阅套餐" name="soft">
                </el-tab-pane>
                <el-tab-pane label="订阅时间" name="time">
                </el-tab-pane>
            </el-tabs> -->
            <div class="selectProductType">
                <div class="selectItem" v-for="item in softTypeList" :key="item"
                    :class="softType == item ? 'activeSelect' : ''" @click="handleCommand(item)">
                    {{ item }}
                </div>
            </div>
            <div class="selectContent" id="selectContent">
                <!-- 软件选择 -->
                <!-- <div class="softList"> -->
                <div class="softItem" :class="choosedProject.id == item.id ? 'clickSoftItem' : ''"
                    v-for="(item, index) in softList" :key="index" @click="clickSoftItem(item)">
                    <div class="softContext">
                        <div class="softContent">
                            <div class="sText">{{ item.type + item.name }}</div>
                            <!-- <div class="sDes">{{ item.description }}</div> -->
                        </div>
                        <div class="sMoney" v-if="item.ismonth == 1">RMB {{ item.monthprice }}/月起
                        </div>
                        <div class="sMoney" v-else>RMB {{ item.weekprice }}/周起</div>
                    </div>
                    <!-- <div class="softImg">
                        <img src="../../../assets/image/smallMap.png" alt="" v-if="softType == 'SummitMap'">
                        <img src="../../../assets/image/smallMap.png" alt="" v-if="softType == 'SummitMapHUBEI'">
                        <img src="../../../assets/image/smallSolid.png" alt="" v-if="softType == 'SummitSolid'">
                        <img src="../../../assets/image/smallSolid.png" alt="" v-if="softType == 'SummitSolidSGJ'">
                        <img src="../../../assets/image/smallLab.png" alt="" v-if="softType == 'SummitLab'">
                        <div class="softDES">{{ item.description }}</div>
                    </div> -->
                </div>
                <div>

                </div>
            </div>

            <!-- 数量选择 -->
            <div class="fixedPart">
                <div v-show="!limitTemp">
                    <div :class="!subscriptionSwitch ? 'subscriptionData' : 'subscriptionData_d'
                        ">
                        时长单位
                    </div>
                    <div class="show_select_subscriptionData">
                        <span>周</span>
                        <span>月</span>
                        <span>季</span>
                        <span>半年</span>
                    </div>
                    <div class="select_subscriptionData">
                        <el-slider v-model="durationunionValue" :step="1" :max="3" :format-tooltip="PromptText"
                            @change="changeDurationunionValue">
                        </el-slider>
                    </div>
                    <div class="show_select_subscriptionData1">
                        <span v-for="item in choosedPriceList" :key="item">仅需<br>￥{{ item }}元/日</span>
                    </div>
                </div>
                <div class="subscriptionCycle">
                    <span>订阅时长<span v-show="limitTemp">(/{{ getLimitDurationUnion() }})</span></span>
                    <!-- <span>订阅周期</span> -->
                    <el-input-number v-model="duration" @change="handleChange" :min="0" label="描述文字"
                        :disabled="limitDuration"></el-input-number>
                </div>

                <div class="buyCount">
                    <span>软件数量</span>
                    <el-input-number v-model="count" :min="1" label="描述文字" :disabled="countLimit"></el-input-number>
                </div>
                <div class="totalMoney">
                    <span class="totalDes">合计</span>
                    <span class="moneyCount">￥{{ money }}</span>
                </div>
                <div class="operate">
                    <!-- <el-button type="text" class="addCart" @click="toAddCart"
                        :disabled="!this.choosedProjectFlag || duration < 1">加入购物车</el-button> -->
                    <el-button type="primary" @click="toBuyNow"
                        :disabled="!this.choosedProjectFlag || duration < 1">立即订阅</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getAllProduction, addCart } from "@/assets/API/pay";
export default {
    components: {},
    data() {
        return {
            options: [
                // {
                //     label: "SummitLab",
                // },
                // {
                //     label: "SummitSolid",
                // },
                // {
                //     label: "其他",
                // },
                {
                    label: "SummitMap",
                },
            ],
            marks: {
                0: '仅需<br>￥59元/日',
                1: '8°C',
                2: {
                    style: {
                        color: '#1989FA'
                    },
                    label: this.$createElement('strong', '仅需<br>￥59元/日')
                }
            },
            choosedPriceList: [],
            softTypeList: [
                'SummitMap',
                'SummitSolid',
                'SummitLab'
            ],
            limitTemp: false,
            limitDuration: false,
            countLimit: false,
            activeName: "soft",
            softList: [
                {
                    label: "SummitMapSP倾斜测图",
                    des: "加密狗1支、(需申请：许可文件一份)",
                    money: "RMB   99/月起",
                    num: 99,
                },
                {
                    label: "SummitMapSP航测成图",
                    des: "加密狗1支、(需申请：许可文件一份)",
                    money: "RMB 299/月起",
                    num: 299,
                },
                {
                    label: "SummitMapSP点云测图",
                    des: "加密狗1支、(需申请：许可文件一份)",
                    money: "RMB 499/月起",
                    num: 499,
                },
                {
                    label: "SummitMapSP全模块",
                    des: "加密狗1支、(需申请：许可文件一份)",
                    money: "RMB 799/月起",
                    num: 799,
                },
            ],
            duration: 0,
            count: 0,
            // money: 0,
            activeItem: 0,
            choosedProject: {},
            choosedProjectFlag: false,
            durationSelect: [
                {
                    label: "week",
                    name: "周",
                    pri: 96,
                },
                {
                    label: "month",
                    name: "月",
                    pri: 24,
                },
                {
                    label: "season",
                    name: "季",
                    pri: 8,
                },
                {
                    label: "halfyear",
                    name: "半年",
                    pri: 2,
                },
            ],
            subscriptionSwitch: true,
            durationunion: "week",
            durationunionValue: 0,
            dataForm: {
                userid: "", // 用户id
                username: "", // 用户名
                productid: "", // 产品id
                producttype: "", // 产品类型（summitmap/summitsolid/summitlab)
                productname: "", // 产品名
                number: undefined, // 数量
                duration: undefined, // 时长数量
                durationunion: "", // 时长单位（week/month/season/halfyear)
            },
            baseId: '',
            allSoftList: [],
            softType: ''
        };
    },
    mounted() {
        // this.$store.commit('isFullScreen', true);
        this.baseId = this.$route.query.id;

        let data = {
            saletype: "online",
        };
        getAllProduction(data).then((res) => {
            this.allSoftList = res.data.list;
        }).then(res => {
            this.getBaseData(this.baseId);
        }).then(res => {
            setTimeout(() => {
                // console.log(this.choosedProject, '666');
                const scrollToTop = document.querySelector('.clickSoftItem');
                const parentElement = document.getElementById('selectContent');
                // console.log(parentElement);
                var offsetTop = scrollToTop.offsetTop - parentElement.offsetTop;
                parentElement.scrollTop = offsetTop;

            }, 1000);
        });
    },
    watch: {},
    computed: {
        money() {
            if (this.durationunion == "week") {
                return this.choosedProject.weekprice * this.duration * this.count || 0;
            } else if (this.durationunion == "month") {
                return this.choosedProject.monthprice * this.duration * this.count || 0;
            } else if (this.durationunion == "season") {
                return (
                    this.choosedProject.seasonprice * this.duration * this.count || 0
                );
            } else if (this.durationunion == "halfyear") {
                return (
                    this.choosedProject.halfyearprice * this.duration * this.count || 0
                );
            }
        },
    },
    methods: {
        // 根据路由传过来的id，判断购买的产品的类型和具体数据
        getBaseData(id) {
            let baseData = this.allSoftList.filter(v => v.id == id);
            if (baseData.length == 1) {
                this.getProduct(baseData[0].type);
                this.choosedProject = baseData[0];
                this.clickSoftItem(this.choosedProject);
            }
        },
        getProduct(type) {
            this.softType = type;
            let data = {
                typename: type,
                saletype: "online",
                sidx: "sortcode",
                enabledmark: 1
            };
            getAllProduction(data).then((res) => {
                this.softList = res.data.list;
            });
        },
        handleClick() { },
        clickSoftItem(data) {
            this.choosedProject = data;
            this.activeItem = 1;
            this.choosedProjectFlag = true;
            this.subscriptionSwitch = true;
            this.count = 1;
            this.duration = 1;
            this.durationunionValue = 0;
            this.durationunion = "week";
            // // 0429 临时限制倾斜的购买时间
            if (data.ismonth == 1) {
                this.limitTemp = true;
                this.durationunion = "month";
            } else if (data.ismonth == 2) {
                this.limitTemp = true;
                this.durationunion = "week";
            } else {
                this.limitTemp = false;
            }
            // 限制购买数量
            if (data.onemonth) {
                this.count = data.onemonth;
                this.countLimit = true;
            } else {
                this.count = 1;
                this.countLimit = false;
            }
            // 限制购买时长
            if (data.limitduration) {
                this.limitDuration = true;
                this.duration = data.limitduration;
            } else {
                this.limitDuration = false;
            }
            this.choosedPriceList = [];
            this.choosedPriceList.push(parseInt(this.choosedProject.weekprice / 7));
            this.choosedPriceList.push(parseInt(this.choosedProject.monthprice / 30));
            this.choosedPriceList.push(parseInt(this.choosedProject.seasonprice / 90));
            this.choosedPriceList.push(parseInt(this.choosedProject.halfyearprice / 180));
            // 跳转到选择时间
            // let target = document.getElementById('duration');
            // let parent = document.getElementById('selectContent');
            // parent.scrollTo(0, target.offsetTop - parent.offsetTop);
        },
        getLimitDurationUnion() {
            if (this.durationunion == 'month') {
                return '月';
            } else if (this.durationunion == 'week') {
                return '周';
            }
        },
        toBuyNow() {
            // 判断是否登录 未登录跳转登录页
            if (this.$store.state.user.isLogin) {
                // 整合数据
                let datas = this.choosedProject;
                datas.duration = this.duration;
                datas.durationunion = this.durationunion;
                datas.count = this.count;
                localStorage.setItem("BUYDATA", JSON.stringify(datas));
                this.$router.push({
                    name: "支付流程",
                    path: "/paystep",
                    query: {
                        data: datas,
                    },
                });
            } else {
                this.$router.replace({
                    name: "Login",
                    path: "/login",
                });
            }
        },
        handleChange(value) {
            this.subscriptionSwitch = false;
            if (!this.limitTemp) {
                if (value == 2) {
                    if (this.durationunion == "season") {
                        this.duration = 0;
                        this.durationunionValue = 3;
                        this.durationunion = "halfyear";
                    }
                }
                if (value == 3) {
                    if (this.durationunion == "month") {
                        this.durationunionValue = 2;
                        this.duration = 1;
                        this.durationunion = "season";
                    }
                }
                if (value == 4) {
                    if (this.durationunion == "week") {
                        this.duration = 1;
                        this.durationunionValue = 1;
                        this.durationunion = "month";
                    }
                }
            } else {
                this.durationunion == "month";
                this.durationunionValue = 1;
                this.duration = value;
            }

        },
        PromptText(value) {
            let tips = "";
            if (value == 0) {
                tips = "周";
            } else if (value == 1) {
                tips = "月";
            } else if (value == 2) {
                tips = "季";
            } else if (value == 3) {
                tips = "半年";
            }
            return tips;
        },
        changeDurationunionValue(value) {
            this.subscriptionSwitch = false;
            if (value == 0) {
                this.duration = 1;
                this.durationunion = "week";
            } else if (value == 1) {
                this.duration = 1;
                this.durationunion = "month";
            } else if (value == 2) {
                this.duration = 1;
                this.durationunion = "season";
            } else if (value == 3) {
                this.duration = 1;
                this.durationunion = "halfyear";
            }
        },
        // 加入购物车
        toAddCart() {
            // 判断是否登录 未登录跳转登录页
            if (this.$store.state.user.isLogin) {
                let userInfo = this.$store.state.user.userInfo;
                this.dataForm.userid = userInfo.id;
                this.dataForm.username = userInfo.username;
                this.dataForm.number = this.count;
                this.dataForm.duration = this.duration;
                this.dataForm.durationunion = this.durationunion;
                this.dataForm.productid = this.choosedProject.id;
                this.dataForm.producttype = this.choosedProject.type;
                this.dataForm.productname = this.choosedProject.name;
                addCart(this.dataForm).then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            message: "添加成功",
                            type: "success",
                        });
                        this.$store.dispatch("user/getCartListCount", { userid: userInfo.id });
                    }
                });
            } else {
                this.$router.replace({
                    name: "Login",
                    path: "/login",
                });
            }
        },
        // 切换产品
        handleCommand(value) {
            // if (value == 'SummitLab') return;
            this.softType = value;
            let data = {
                typename: value,
                saletype: "online",
                sidx: "sortcode",
                enabledmark: 1
            };
            getAllProduction(data).then((res) => {
                this.softList = res.data.list;
                this.choosedProject = this.softList[0];
                this.clickSoftItem(this.choosedProject);
            });
        },
    },
};
</script>
<style scoped lang="scss" scoped>
.menuCenter {
    margin-top: 60px;
    display: flex;
    height: 900px;

    .showImg {
        flex: 1;
        // height: 800px;
        padding-bottom: 50px;

        .selectPart {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    .showImgContent {
        width: 100%;
        height: 94%;
        // background-image: url("../../../assets/image/menuPic.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        img {
            width: 100%;
            height: 100%;
        }

        .softTitle {
            position: absolute;
            left: 0px;
            top: 159px;
            // width: 721px;
            padding: 0 50px;
            height: 75px;
            opacity: 1;
            background: linear-gradient(90deg, rgba(43, 133, 229, 1) 26.39%, rgba(43, 133, 229, 0) 100%);
            font-size: 36px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 75px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;

        }

        .softDes {
            position: absolute;
            left: 58px;
            top: 253px;
            width: 470px;
            height: 160px;
            opacity: 1;
            /** 文本1 */
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0px;
            line-height: 40px;
            color: rgba(0, 0, 0, 1);
            text-align: justify;
            vertical-align: top;

        }
    }

    .choosePart {
        width: 28%;
        padding: 40px;
        padding-left: 0;
        padding-top: 0;
        height: 860px;
        display: flex;
        flex-direction: column;

        .subscriptionPackage,
        .subscriptionData {
            // padding-left: 9px;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 26.06px;
            color: rgba(42, 130, 228, 1);
            text-align: left;
            vertical-align: top;
        }

        .subscriptionPackage {
            padding-left: 40px;
        }

        .subscriptionPackage_d,
        .subscriptionData_d {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 26.06px;
            text-align: left;
            vertical-align: top;
            color: rgba(56, 56, 56, 1);
        }

        .subscriptionPackage_d {
            padding-left: 40px;
        }

        .selectProductType {
            display: flex;
            justify-content: space-between;
            padding: 27px 0px 23px 40px;

            .selectItem {
                width: 31%;
                height: 56px;
                line-height: 56px;
                box-shadow: 0px 2px 4px rgba(225, 230, 235, 1);
                text-align: center;
                border-radius: 8px;
                font-size: 18px;
                font-weight: 400;
                cursor: pointer;
            }

            .activeSelect {
                background: rgba(43, 133, 229, 1);
                color: #fff;
            }
        }

        .contentCoverSlider {
            padding-right: 40px;
            overflow-y: scroll;
        }

        .selectContent {
            // height: 50%;
            overflow-x: hidden;
            // flex: 1;
            margin-right: -20px;
            padding-right: 20px;
            padding-left: 40px;
            // height: 400px;
            overflow-y: scroll;

            // .softList {
            .desImg {
                // display: none;
                transition: visibility 0.5s, opacity 0.5s;
                visibility: hidden;
                opacity: 0;
                height: 0;
            }

            .softItem {
                opacity: 1;
                border-radius: 12px;
                background: #ffffff;
                border: 1px solid #383838;
                margin-bottom: 15px;

                .softContext {
                    padding: 18px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .softContent {
                    .sText {
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 26.06px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: top;
                        padding-bottom: 4px;
                    }

                    .sDes {
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 17.38px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: top;
                    }
                }

                .sMoney {
                    width: 150px;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 20.27px;
                    color: rgba(56, 56, 56, 1);
                    text-align: right;
                    vertical-align: top;
                }

                .softImg {
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .softDES {
                        position: absolute;
                        opacity: 1;
                        top: 9px;
                        width: 62%;
                        left: 10px;
                        /** 文本1 */
                        font-size: 10px;
                        font-weight: 300;
                        letter-spacing: 1px;
                        line-height: 16px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: top;

                    }
                }
            }

            .clickSoftItem {
                border-radius: 12px 12px, 0px, 0px;
                background: #ffffff;
                border: 2px solid #2a82e4;
                background-image: url('../../../assets/image/softIconBg.png');
                background-repeat: no-repeat;
                background-position: right bottom;

                .desImg {
                    width: 100%;
                    visibility: visible;
                    opacity: 1;
                    height: 200px;
                }
            }

            // }

            .duration {
                padding: 30px 0;

                .dDes {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 26.06px;
                    color: rgba(117, 117, 117, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .sliderDuration {
                    padding: 10px;
                }
            }

            .disablePart {
                opacity: 0.32;
                pointer-events: none;
            }
        }

        .select_subscriptionData {
            // padding-left: 3px;
            // margin-bottom: 30px;
            margin: 0 30px;

            ::v-deep .el-slider__runway {
                background-color: rgba(237, 237, 237, 1);
            }

            ::v-deep .el-slider__button {
                border: none;
                background-color: rgba(212, 214, 217, 1);
            }
        }

        .subscriptionCycle {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin-bottom: 122px;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20.27px;
            color: rgba(56, 56, 56, 1);
            text-align: left;
            vertical-align: top;
        }

        .show_select_subscriptionData {
            // padding-left: 10px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 17.38px;
            color: rgba(56, 56, 56, 1);
            margin: 0 20px;
        }

        .show_select_subscriptionData1 {
            // padding-left: 10px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 17.38px;
            color: rgba(56, 56, 56, 1);
            text-align: center;
        }

        div::-webkit-scrollbar {
            width: 5px;
            height: 0;
        }

        // 小滑块
        div::-webkit-scrollbar-thumb {
            border-radius: 4px;
            box-shadow: inset 0 0 2px #d4d4d4;
            -webkit-box-shadow: inset 0 0 2px #d4d4d4;
            background: #d4d4d4;
        }

        .fixedPart {
            margin-left: 40px;
            padding: 20px;
            box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.03);
            opacity: 1;
            border-radius: 8px;
            background: rgba(225, 230, 235, 0.21);

            .buyCount {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 20.27px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }
            }

            .totalMoney {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 25px 0;

                .totalDes {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 1px;
                    line-height: 56px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                    padding-left: 20px;
                }

                .moneyCount {
                    font-size: 32px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 46.34px;
                    color: rgba(0, 0, 0, 1);
                    text-align: right;
                    vertical-align: bottom;
                }
            }

            .operate {
                display: flex;
                justify-content: space-between;

                .el-button {
                    flex: 1;
                    width: 50%;
                }

                // .addCart {
                //     padding: 12px 100px;
                // }
            }
        }
    }
}
</style>