<template>
    <div class="block_center">
        <div class="AccountSupportDetail">
            <div class="accountSuppor_content">
                <div class="related">
                    <div class="related_documents">
                        <div class="title">相关文档</div>
                        <img class="line" src="../../../../assets/image/related_line.png" alt="">
                        <ul>
                            <li v-for="(item, index) in aboutEssayList" :key="index" @click="toDetail(item.id)"
                                style="cursor: pointer;">
                                <div class="name">{{ item.title }}</div>
                                <img src="../../../../assets/image/line.png" alt="">
                            </li>
                        </ul>
                        <el-button type="success" round @click="toAll">浏览全部</el-button>
                    </div>
                </div>
                <div class="content">
                    <div class="article_title">{{ essayData.title }}</div>

                    <div class="article_contetn" v-html="essayData.content">
                    </div>
                </div>
            </div>
            <!-- <InterestPlate :interestedList="interestedList" class="InterestPlate"></InterestPlate> -->
        </div>
    </div>
</template>

<script>
import { getSupportDetail, getSupportEssayList } from "@/assets/API/pay.js";
import InterestPlate from "../InterestPlate/index.vue";
export default {
    components: {
        InterestPlate
    },
    data() {
        return {
            relatedDocuments: [
                {
                    name: "构建人字顶房屋",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "构建复杂屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "构建曲面屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "构建锥型屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "自动生成面片纹理",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "从原始影像中选择纹理",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "制作复杂屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "单体显示设置",
                    img: require("../../../../assets/image/line.png"),
                }
            ],
            relatedQuestion: [
                {
                    name: "构建人字顶房屋",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "构建复杂屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "构建曲面屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "构建锥型屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "自动生成面片纹理",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "从原始影像中选择纹理",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "制作复杂屋顶",
                    img: require("../../../../assets/image/line.png"),
                },
                {
                    name: "单体显示设置",
                    img: require("../../../../assets/image/line.png"),
                }
            ],
            interestedList: [
                [
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶1",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶2",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶3",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    }
                ],
                [
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶5",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶6",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶7",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    }
                ],
                [
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶9",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶10",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶11",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    }
                ],
                [
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶13",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶14",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    },
                    {
                        img: require("../../../../assets/image/interested_img.png"),
                        title: "构建复杂屋顶15",
                        publisher: "fenglingyihao",
                        time: "18 Sep 2023",
                        introduction: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简。"
                    }
                ]
            ],
            essayData: {},
            aboutEssayList: []
        };
    },
    mounted() {
        const { value } = this.$route.query;
        getSupportDetail(value).then(res => {
            this.essayData = res.data;
            let moduleId = res.data.moduleid;
            let query = {
                moduleId,
                superQueryJson: '',
                currentPage: 1,
                dataType: 0,
                pageSize: 10,
                sort: "desc",
                sidx: "sortcode",
            };
            getSupportEssayList(query).then(res => {
                this.aboutEssayList = res.data.list;
            });
        });
    },
    watch: {

    },
    methods: {
        toAll() {
            this.$router.push(
                {
                    path: "/AccountSupportDetail",
                }
            );
        },
        toDetail(id) {
            let toUrl = this.$router.resolve(
                {
                    path: "/AccountSupportContent",
                    query: {
                        value: id
                    }
                }
            );
            window.open(toUrl.href, '_blank');
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    .AccountSupportDetail {
        width: 1200px;
        padding-top: 180px;
    }

    .accountSuppor_content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 277px;

        .related {
            .related_documents {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 22px;
                color: rgba(114, 115, 132, 1);
                text-align: left;
                vertical-align: top;
                margin-bottom: 77px;

                .title {
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 48px;
                    color: rgba(67, 207, 124, 1);
                    margin-bottom: 35px;
                }

                .line {
                    width: 103px;
                    height: 2px;
                    margin-bottom: 60px;
                }

                ul {
                    li {

                        img {
                            width: 138.91px;
                            height: 1px;
                            margin: 10px 0 10px 0;
                        }
                    }
                }

                ::v-deep .el-button.is-round {
                    border-radius: 24px;
                    padding: 16px 39px;
                }

                ::v-deep .el-button--success {
                    background-color: rgba(67, 207, 124, 1);
                    border-color: rgba(67, 207, 124, 1);
                }

                ::v-deep .el-button {
                    font-size: 16px;
                }
            }
        }

        .content {
            width: 80%;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgba(114, 115, 132, 1);
            text-align: left;
            vertical-align: top;

            .article_title {
                display: flex;
                justify-content: center;
                font-size: 60px;
                font-weight: 700;
                line-height: 84px;
                color: rgba(67, 207, 124, 1);
                margin-bottom: 40px;
            }

            .introduction {
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                color: rgba(105, 107, 128, 1);
                margin-bottom: 80px;
            }

            .img {
                margin-bottom: 72px;

                img {
                    height: 554px;
                }
            }

            .article_contetn {
                font-size: 20px;
                line-height: 32px;
                color: rgba(105, 107, 128, 1);
                margin-bottom: 44px;
            }

            .textone {
                margin-bottom: 72px;
            }

            .small_title {
                font-size: 32px;
                font-weight: 500;
                line-height: 48px;
                color: rgba(67, 207, 124, 1);
                margin-bottom: 20px;
            }

            .texttwo {
                p {
                    margin-bottom: 20px;
                }
            }

        }
    }
}

.InterestPlate {
    margin-bottom: 130px;
}
</style>