<template>
  <div class="login">
    <div class="login_bg">
      <img class="logo" src="../../assets/image/峰岭科技LOGO.png" alt="" />
      <div class="banner">
        <div class="bannerText">成图软件建构者</div>
        <div class="bannerDes">
          <div><span class="dot"></span>为客户研发业内领先的生产力工具软件<br></div>
          <div><span class="dot"></span>基于生产力工具软件提供生产工艺改进服务<br></div>
          <div><span class="dot"></span>通过生产工艺改进助力智慧型地理数据工厂建设<br></div>
        </div>
      </div>
    </div>
    <div class="right login_right" v-if="loginFlag">
      <!-- <el-tabs v-model="activeType">
        <el-tab-pane label="用户登录" name="user"> -->
          <div class="title">登录</div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="手机号登录" name="phoneLogin">
              <el-form :model="ruleFormPhone" :rules="rulesPhone" ref="ruleFormPhone" class="demo-ruleForm">
                <el-form-item prop="phone">
                  <div class="input_box">
                    <span class="prefix">+86</span>
                    <el-input v-model="ruleFormPhone.phone" placeholder="请输入手机号" @input="usernameInput()"></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="input_box" style="width:242px;margin-right:17px;">
                    <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/code_icon.png"
                        alt=""></span>
                    <el-input v-model="ruleFormPhone.code" autocomplete="off" placeholder="请输入验证码"
                      @keyup.enter.native="handleSubmit()"></el-input>
                  </div>
                  <div class="code_div"><el-button style="width:100%;height:100%;font-size:16px;" :disabled="disable"
                      @click="Sends()">{{ yzm
                      }}</el-button></div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" :loading="loading" @click="submitFormPhone('ruleFormPhone')"
                    style="width:100%;height:100%;font-size:18px;">登录</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="二维码登录" name="rqCodeLogin" class="wechat">
              <div class="wechatCode">
                <div class="img">
                  <iframe id="vxIframe" :src="vxLoginMsg" frameborder="0" scrolling="auto"></iframe>
                  <!-- <vxLoginVue></vxLoginVue> -->
                </div>
              </div>
            </el-tab-pane>
          <!-- </el-tabs>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="经销商登录" name="retailer">
          <el-form :model="ruleRetailerPhone" :rules="rulesPhone" ref="ruleRetailerPhone" class="demo-ruleForm">
            <el-form-item prop="phone">
              <div class="input_box">
                <span class="prefix">+86</span>
                <el-input v-model="ruleRetailerPhone.phone" placeholder="请输入手机号"
                  @input="userretailerInput()"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="code">
              <div class="input_box" style="width:242px;margin-right:17px;">
                <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/code_icon.png"
                    alt=""></span>
                <el-input v-model="ruleRetailerPhone.code" autocomplete="off" placeholder="请输入验证码"
                  @keyup.enter.native="handleRetailerSubmit()"></el-input>
              </div>
              <div class="code_div"><el-button style="width:100%;height:100%;font-size:16px;"
                  :disabled="retailerDisable" @click="SendsRetailer()">{{ yzmRetailer
                  }}</el-button></div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="loading" @click="submitFormPhone('ruleRetailerPhone')"
                style="width:100%;height:100%;font-size:18px;">登录</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="right register_right" v-if="registerFlag">
      <div class="title"><img src="../../assets/image/login_arrow.png" alt="" @click="closeRegister"> 注册</div>
      <el-form :model="ruleFormRegister" :rules="rulesRegister" ref="ruleFormRegister" class="demo-ruleForm">
        <el-form-item prop="account">
          <div class="input_box">
            <span class="prefix"><img style="width:20px;height:20px;" src="../../assets/image/person_icon.png"
                alt=""></span>
            <el-input v-model="ruleFormRegister.account" placeholder="请输入账号"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="passwprd">
          <div class="input_box">
            <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/pass_icon.png"
                alt=""></span>
            <el-input type="password" v-model="ruleFormRegister.passwprd" autocomplete="off"
              placeholder="请输入密码"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="surePassword">
          <div class="input_box">
            <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/pass_icon.png"
                alt=""></span>
            <el-input type="password" v-model="ruleFormRegister.surePassword" autocomplete="off"
              placeholder="请确认密码"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="phone">
          <div class="input_box">
            <span class="prefix">+86</span>
            <el-input v-model="ruleFormRegister.phone" placeholder="请输入手机号"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="code">
          <div class="input_box" style="width:242px;margin-right:17px;">
            <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/code_icon.png"
                alt=""></span>
            <el-input v-model="ruleFormRegister.code" autocomplete="off" placeholder="请输入验证码"></el-input>
          </div>
          <div class="code_div"><el-button style="width:100%;height:100%;font-size:16px;">获取验证码</el-button></div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitFormRegister('ruleFormRegister')"
            style="width:100%;height:100%;font-size:18px;">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="right findPassword register_right" v-if="findPasswordFlag">
      <div class="title"><img src="../../assets/image/login_arrow.png" alt="" @click="closeFindPassword">找回密码</div>
      <el-form :model="ruleFormFindPassword" :rules="rulesFindPassword" ref="ruleFormFindPassword"
        class="demo-ruleForm">
        <el-form-item prop="account">
          <div class="input_box">
            <span class="prefix"><img style="width:20px;height:20px;" src="../../assets/image/person_icon.png"
                alt=""></span>
            <el-input v-model="ruleFormFindPassword.account" placeholder="请输入账号"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="phone">
          <div class="input_box">
            <span class="prefix">+86</span>
            <el-input v-model="ruleFormFindPassword.phone" placeholder="请输入手机号"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="code">
          <div class="input_box" style="width:242px;margin-right:17px;">
            <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/code_icon.png"
                alt=""></span>
            <el-input v-model="ruleFormFindPassword.code" autocomplete="off" placeholder="请输入验证码"></el-input>
          </div>
          <div class="code_div"><el-button style="width:100%;height:100%;font-size:16px;">获取验证码</el-button></div>
        </el-form-item>
        <el-form-item prop="newPassword">
          <div class="input_box">
            <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/pass_icon.png"
                alt=""></span>
            <el-input type="password" v-model="ruleFormFindPassword.newPassword" autocomplete="off"
              placeholder="请输入新密码"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="sureNewpPssword">
          <div class="input_box">
            <span class="prefix"><img style="width:15px;height:13px;" src="../../assets/image/pass_icon.png"
                alt=""></span>
            <el-input type="password" v-model="ruleFormFindPassword.sureNewpPssword" autocomplete="off"
              placeholder="请确认新密码"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitFormFindPassword('ruleFormFindPassword')"
            style="width:100%;height:100%;font-size:18px;">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  renderVxModel,
  GetVxUserMes,
  SendVerCode,
  LoginByWx,
  LoginByPhone,
  LoginByPhoneRetailer,

} from "@/assets/API/login";
import vxLoginVue from "./components/vxLogin.vue";
export default {
  components: { vxLoginVue },
  data() {
    return {
      activeName: 'phoneLogin',
      yzm: "获取验证码",
      yzmRetailer: "获取验证码",
      ruleForm: {
        account: '',
        password: '',
        isRememberPassword: false,
        graphical: ""
      },
      ruleFormPhone: {
        phone: "",
        code: "",
        graphical: ""
      },
      ruleRetailerPhone: {
        phone: "",
        code: "",
        graphical: ""
      },
      ruleFormRegister: {
        account: "",
        passwprd: "",
        surePassword: "",
        phone: "",
        code: "",
      },
      ruleFormFindPassword: {
        account: "",
        newPassword: "",
        sureNewpPssword: "",
        phone: "",
        code: "",
      },
      rules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ]
      },
      rulesPhone: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[345678]\d{9}))$/, message: '请输入正确的手机号', trigger: ['blur', 'change'] },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      rulesRegister: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        passwprd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        surePssword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[345678]\d{9}))$/, message: '请输入正确的手机号', trigger: ['blur', 'change'] },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      rulesFindPassword: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        sureNewpPssword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/, message: '请输入正确的手机号', trigger: ['blur', 'change'] },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      loginFlag: true,
      registerFlag: false,
      findPasswordFlag: false,
      disable: true,
      retailerDisable: false,
      dialogVisible: false,
      bool: true,
      totalTime: 60,
      totalRetailerTime: 60,
      objCodes: "",
      vxLoginMsg: '',
      loading: false,
      activeType: 'user'
    };
  },
  computed: {
    codes() {
      return this.$route.query.code ? this.$route.query.code : "";
    },
  },
  mounted() {
    this.$store.commit("isFull", true);
    // 扫码登录后跳转login路由 后拼接code state参数
    if (this.codes) {
      let query = {
        code: this.codes,
        state: this.$route.query.state
      };
      GetVxUserMes(query).then((res) => {
        // 老用户返回accessToken userInfo 新用户/老用户未绑定微信不返回
        this.objCodes = res.data;
        if (this.objCodes.accessToken && this.objCodes.userInfo) {
          // 登录成功 通过token获得用户数据
          let param = {
            token: res.data.accessToken,
          };
          this.$store.dispatch("user/setToken", param).then((res) => {
            // this.$router.push({
            //   path: "/Home",
            // });
            // 登录后返回上一页
            // let url = this.$route.query.redirect;
            // this.$router.push({
            //   path: decodeURIComponent(url),
            // });
            this.$store.dispatch("user/getInfo");
            const curr = localStorage.getItem('preRoute');
            if (curr == null) {
              this.$router.push({
                path: "/Home",
              });
            } else {
              this.$router.push({ path: curr });
            }
            this.$store.commit("isFull", false);
          });
        } else {
          // 新用户/老用户未绑定微信 跳转手机号发送验证码逻辑
          this.$message({
            message: '温馨提示：第一次扫码登陆需要绑定手机号。',
            type: 'warning'
          });
        }
      });
    } else {
      // 获取微信登录界面
      renderVxModel().then(res => {
        this.vxLoginMsg = res.msg;
      });
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    toRegister() {
      this.registerFlag = true;
      this.loginFlag = false;
    },
    closeRegister() {
      this.registerFlag = false;
      this.loginFlag = true;
    },
    toFindPassword() {
      this.findPasswordFlag = true;
      this.loginFlag = false;
    },
    closeFindPassword() {
      this.findPasswordFlag = false;
      this.loginFlag = true;
    },
    usernameInput() {
      const re = /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
      if (re.test(this.ruleFormPhone.phone) && this.ruleFormPhone.phone * 1 != "NaN") {
        if (this.totalTime < 60) {
          this.disable = true;
        }
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    userretailerInput() {
      const re = /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
      if (re.test(this.ruleRetailerPhone.phone) && this.ruleRetailerPhone.phone * 1 != "NaN") {
        if (this.totalTime < 60) {
          this.disable = true;
        }
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    // 60S验证码
    Sends() {
      this.disable = true;
      const data = this.ruleFormPhone.phone.toString();
      SendVerCode(data).then((res) => {
        if (res.code == 200) {
          // 发送成功
          this.$message.success("发送成功");
          this.yzm = this.totalTime + "s后重新发送";
          const clock = setInterval(() => {
            this.yzm = this.totalTime-- + "s后重新发送";
            if (this.totalTime < 0) {
              clearInterval(clock);
              this.disable = false;
              this.yzm = "重新发送验证码";
              this.totalTime = 60;
            }
          }, 1000);
        } else {
          // 发送失败
          this.$message.error(res.msg);
        }
      });

    },
    SendsRetailer() {
      this.retailerDisable = true;
      const data = this.ruleRetailerPhone.phone.toString();
      SendVerCode(data).then((res) => {
        if (res.code == 200) {
          // 发送成功
          this.$message.success("发送成功");
          this.yzmRetailer = this.totalRetailerTime + "s后重新发送";
          const clock = setInterval(() => {
            this.yzmRetailer = this.totalRetailerTime-- + "s后重新发送";
            if (this.totalRetailerTime < 0) {
              clearInterval(clock);
              this.retailerDisable = false;
              this.yzmRetailer = "重新发送验证码";
              this.totalRetailerTime = 60;
            }
          }, 1000);
        } else {
          // 发送失败
          this.$message.error(res.msg);
        }
      });
    },
    handleSubmit() {
      if (!this.codes) {
        this.loading = true;
        const re =
          /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[0-9])\d{8}$/;
        if (re.test(this.ruleFormPhone.phone)) {
          const data = {
            phone: this.ruleFormPhone.phone,
            verCode: this.ruleFormPhone.code,
          };
          // 短信验证码登录
          LoginByPhone(data).then(res => {
            this.loading = false;
            if (res.code == 200) {
              let param = {
                token: res.msg,
              };
              this.$store.dispatch("user/setToken", param).then((res) => {
                this.$store.dispatch("user/getInfo");
                // this.$router.push({
                //   path: "/Home",
                // });
                const curr = localStorage.getItem('preRoute');
                if (curr == null) {
                  this.$router.push({
                    path: "/Home",
                  });

                } else {
                  this.$router.push({ path: curr });
                }
                this.$store.commit("isFull", false);
              });
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              });
            }
          });
        } else {
          this.loading = false;
          this.$message({
            message: '请输入正确的手机号',
            type: 'error'
          });
        }
      } else {
        // GetLoginGwByWeixin // 首次扫码登录
        const re =
          /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
        if (re.test(this.ruleFormPhone.phone)) {
          const data = {
            phone: this.ruleFormPhone.phone,
            verCode: this.ruleFormPhone.code,
            openId: this.objCodes.openid,
            unionId: this.objCodes.unionid,
          };
          // 设置用户信息缓存
          LoginByWx(data).then(res => {
            this.loading = false;
            if (res.msg && res.code == 200) {
              let param = {
                token: res.msg,
              };
              this.$store.dispatch("user/setToken", param).then((res) => {
                // this.$router.push({
                //   path: "/Home",
                // });
                const curr = localStorage.getItem('preRoute');
                if (curr == null) {
                  this.$router.push({
                    path: "/Home",
                  });
                } else {
                  this.$router.push({ path: curr });
                }
                this.$store.commit("isFull", false);
              });
            } else {
              this.$message({
                message: '登录失败',
                type: 'warning'
              });
            }
          });
        } else {
          this.loading = false;
          this.$message({
            message: '请输入正确的手机号',
            type: 'error'
          });
        }
      }
    },
    handleRetailerSubmit() {
      if (!this.codes) {
        this.loading = true;
        const re =
          /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[0-9])\d{8}$/;
        if (re.test(this.ruleRetailerPhone.phone)) {
          const data = {
            phone: this.ruleRetailerPhone.phone,
            verCode: this.ruleRetailerPhone.code,
          };
          // 短信验证码登录
          LoginByPhoneRetailer(data).then(res => {
            this.loading = false;
            if (res.code == 200) {
              let param = {
                token: res.msg,
              };
              this.$store.dispatch("user/setToken", param).then((res) => {
                this.$store.dispatch("user/getRetailerInfo");
                // const curr = localStorage.getItem('preRoute');
                // if (curr == null) {
                this.$router.push({
                  path: "/Retailer",
                });

                // } else {
                //   this.$router.push({ path: curr });
                // }
              });

            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              });
            }
          });
        } else {
          this.loading = false;
          this.$message({
            message: '请输入正确的手机号',
            type: 'error'
          });
        }
      } else {
        // GetLoginGwByWeixin // 首次扫码登录
        const re =
          /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
        if (re.test(this.ruleRetailerPhone.phone)) {
          const data = {
            phone: this.ruleRetailerPhone.phone,
            verCode: this.ruleRetailerPhone.code,
            openId: this.objCodes.openid,
            unionId: this.objCodes.unionid,
          };
          // 设置用户信息缓存
          LoginByWx(data).then(res => {
            this.loading = false;
            if (res.msg && res.code == 200) {
              let param = {
                token: res.msg,
              };
              this.$store.dispatch("user/setToken", param).then((res) => {
                // this.$router.push({
                //   path: "/Home",
                // });
                const curr = localStorage.getItem('preRoute');
                if (curr == null) {
                  this.$router.push({
                    path: "/Home",
                  });
                } else {
                  this.$router.push({ path: curr });
                }
                this.$store.commit("isFull", false);
              });
            } else {
              this.$message({
                message: '登录失败',
                type: 'warning'
              });
            }
          });
        } else {
          this.loading = false;
          this.$message({
            message: '请输入正确的手机号',
            type: 'error'
          });
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("ruleForm", this.ruleForm);
        } else {
          return false;
        }
      });
    },
    submitFormPhone(formName) {
      if (formName == "ruleFormPhone") {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.handleSubmit();
          } else {
            return false;
          }
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.handleRetailerSubmit();
          } else {
            return false;
          }
        });
      }

    },
    submitFormRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("ruleFormRegister", this.ruleFormRegister);
        } else {
          return false;
        }
      });
    },
    submitFormFindPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("ruleFormFindPassword", this.ruleFormFindPassword);
        } else {
          return false;
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/image/newLogin_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  // display: flex;

  .login_bg {
    // width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    padding-left: 150px;
    position: relative;
    // background: yellow;

    .logo {
      width: 266px;
      position: absolute;
      top: 62px;
      left: 58px;
    }

    .banner {
      font-size: 24px;
      font-weight: 400;
      letter-spacing: -0.66px;
      line-height: 34px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;

      .bannerText {
        font-size: 60px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 60px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
        margin-bottom: 48px;
      }

      .bannerDes {
        div {
          display: flex;
          align-items: center;
          margin-bottom: 13px;
        }

        .dot {
          display: flex;
          width: 6px;
          height: 6px;
          background: #fff;
          margin-right: 7px;
        }
      }
    }
  }

  .right {
    position: absolute;
    top: 152px;
    right: 276px;
    width: 3rem;
    height: 3.5rem;
    opacity: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 8px rgba(39, 40, 41, 0.25);
    padding: 25px 48px 0 49px;

    .title {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 52.13px;
      color: rgba(0, 0, 0, 1);
      text-align: left;
      vertical-align: top;
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-right: 5px;
        cursor: pointer;
      }
    }

    ::v-deep .el-tabs__item {
      font-size: 16px;
      color: rgba(128, 128, 128, 1);
    }

    ::v-deep .el-tabs__item.is-active {
      color: rgba(0, 0, 0, 1);
    }

    ::v-deep .el-tabs__header {
      margin-bottom: 29px;
    }

    ::v-deep .el-tabs__nav-wrap::after {
      display: none;
    }

    ::v-deep .el-form-item {
      margin-bottom: 28px;
    }

    ::v-deep .el-form-item__content {
      display: flex;
      align-items: center;
      // border-radius: 2px;
      // border: 1px solid rgba(128, 128, 128, 0.5);
      height: 50px;
    }

    .input_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 2px;
      border: 1px solid rgba(128, 128, 128, 0.5);
    }

    .remember {
      ::v-deep .el-form-item__content {
        height: 27px;
      }
    }

    .prefix {
      display: block;
      width: 52px;
      height: 30px;
      border-right: 1px solid rgba(182, 187, 195, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .el-input__inner {
      border: none;
    }

    .graphical_div {
      width: 144px;
      height: 50px;
      opacity: 1;
      border-radius: 2px;
      background: rgba(229, 229, 229, 1);
    }

    .code_div {
      width: 144px;
      height: 50px;
      opacity: 1;
      border-radius: 2px;

      ::v-deep .el-button:hover {
        background-color: #fff;
      }
    }

    .register_div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 28.96px;
      color: rgba(100, 111, 127, 1);
      text-align: left;
      vertical-align: top;
      margin-top: -15px;
    }

    .wechatCode {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      // margin-bottom: 32px;

      .img {
        // width: 300px;
        width: 100%;
        height: 3rem;
      }

      .img {
        position: relative;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .title {
            display: none;
          }
        }

        iframe body {
          .title {
            display: none;
          }
        }
      }

      .vxIframe::after {
        content: "";
        position: absolute;
        left: 93%;
        width: 2px;
        height: 100%;
        background: #ddd;
        transform: scaleY(0.8);
      }
    }

    .openWechat {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 26.06px;
      color: rgba(100, 111, 127, 1);
      text-align: center;
      vertical-align: top;
    }

    .wechat {
      .register_div {
        margin-top: 10px;
      }
    }
  }

  .register_right {
    .title {
      margin-bottom: 30px;
    }
  }
}
</style>