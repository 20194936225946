<template>
    <div class="block_center">
        <div class="mianCenter">
            <div class="tabs">
                <span v-for="(item, index) in moduleList" :key="index" :class="{ 'color': item.select }"
                    @click="changeTabs(item)">{{ item.name
                    }}</span>
            </div>
            <ul class="question">
                <li v-for="(item, index) in totalList" :key="index">
                    <div>
                        <img :src="item.img" alt="">
                    </div>
                    <div style="padding-left:12px;padding-right:13px">
                        <div class="name">{{ item.title }}</div>
                        <div class="des">{{ item.description }}</div>
                        <div class="time">2024-01-23</div>
                        <div class="lookMore">
                            <span class="more" @click="toMore(item.id)">查看详情</span>
                            <el-button type="primary" round>产品技巧</el-button>
                        </div>
                    </div>
                </li>
            </ul>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage2" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                layout="sizes, prev, pager, next" :total="totalPage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getSupportEssayList, getSupportModuleList } from "@/assets/API/pay.js";
export default {
    components: {
    },
    data() {
        return {
            tabsList: [
                {
                    name: "全部",
                    select: true
                },
                {
                    name: "产品技巧",
                    select: false
                },
                {
                    name: "账号解疑答惑",
                    select: false
                },
                {
                    name: "订阅与购买",
                    select: false
                },
                {
                    name: "许可证问题",
                    select: false
                }
            ],
            questionList: [
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍"
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    img: require('../../../../assets/image/question.png'),
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
                {
                    name: "SummitMap中点云使用技巧介绍",
                    des: "SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍SummitMap中点云使用技巧介绍..."
                },
            ],
            currentPage2: 1,
            moduleList: [],
            totalList: [],
            query: {
                superQueryJson: '',
                currentPage: 1,
                dataType: 0,
                pageSize: 20,
                sort: "desc",
                sidx: "sortcode",
                moduleId: ""
            },
            pageSize: 0,
            totalPage: 0
        };
    },
    mounted() {
        // let query = {
        //     superQueryJson: '',
        //     currentPage: 1,
        //     pageSize: 20,
        //     sort: "desc",
        //     sidx: "sortcode",
        //     moduleId: ""
        // };
        const { value } = this.$route.query;
        this.getData();
        getSupportModuleList(this.query).then(res => {
            let data = res.data.list;
            data.forEach(e => {
                e.select = false;
            });
            data.unshift({
                name: '全部',
                select: true
            });
            this.moduleList = data;
            let queryData = '';
            if (value == 'Solid' || value == 'Map' || value == 'Lab') {
                queryData = value;
            } else if (value == '产品技巧') {
                queryData = 'map';
            } else if (value == '账号解疑') {
                queryData = '账号';
            } else if (value == '订阅购买') {
                queryData = '订阅';
            } else if (value == '许可证问题') {
                queryData = '许可';
            }
            if (queryData) {
                // let selectModule = this.moduleList.filter(v => v.name.includes(queryData));
                this.moduleList.forEach(el => {
                    if (el.name.includes(queryData)) {
                        el.select = true;
                        this.query.moduleId = el.id;
                        this.getData();
                    }
                });
            }
        });
    },
    watch: {

    },
    methods: {
        changeTabs(value) {
            this.moduleList.forEach(item => {
                if (item.name != value.name) {
                    item.select = false;
                } else {
                    item.select = true;
                }
            });

            // 更新数据
            this.query.moduleId = value.id;
            this.getData();

        },
        getData(data) {
            // let query = {
            //     superQueryJson: '',
            //     currentPage: 1,
            //     pageSize: 20,
            //     sort: "desc",
            //     sidx: "sortcode",
            //     moduleId: data
            // };
            getSupportEssayList(this.query).then(res => {
                this.totalList = res.data.list;
                this.totalPage = res.data.pagination.total;
                this.pageSize = res.data.pagination.pageSize;
            });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.query.pageSize = val;
            this.getData();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.query.currentPage = val;
            this.getData();
        },
        toMore(id) {
            this.$router.push(
                {
                    path: "/AccountSupportContent",
                    query: {
                        value: id
                    }
                }
            );
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;
}

.banner {
    width: 100vw;
    height: 628px;
    background-image: url("../../../../assets/image/AccountSupport_banner.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 53px;
}

.tabs {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 32px;
    color: rgba(40, 41, 56, 1);
    text-align: left;
    vertical-align: top;
    margin-bottom: 30px;

    span {
        margin-right: 30px;
        cursor: pointer;
    }

    .color {
        color: rgba(42, 130, 228, 1);
    }
}

.question {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 104px;

    li {
        width: 32%;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgb(128, 129, 133);
        text-align: left;
        vertical-align: top;
        margin-bottom: 30px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);

        img {
            width: 100%;
            margin-bottom: 15px;
        }

        .name {
            font-size: 22px;
            line-height: 36px;
            color: rgba(40, 41, 56, 1);
            margin-bottom: 11px;
        }

        .des {
            margin-bottom: 25px;
            display: -webkit-box;
            /* 必须指定display属性 */
            overflow: hidden;
            /* 必须指定overflow属性 */
            text-overflow: ellipsis;
            /* 显示省略号 */
            line-height: 1.5em;
            /* 每行的高度 */
            max-height: 3em;
            /* 最大高度（根据需求调整）*/
            -webkit-line-clamp: 2;
            /* 最多显示的行数 */
            -webkit-box-orient: vertical;
            /* 必须指定box-orient属性 */
        }

        .time {
            margin-bottom: 10px;
        }

        .lookMore {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 36px;
        }

        .more {
            font-size: 14px;
            color: rgba(28, 30, 83, 1);
            text-align: left;
            cursor: pointer;
        }

        ::v-deep .el-button.is-round {
            padding: 5px 13px;
        }

        ::v-deep .el-button {
            font-size: 12px;
        }
    }
}

::v-deep .el-pagination {
    text-align: center;
    margin-bottom: 140px;
}

.mianCenter {
    width: 1200px;
    margin: 0 auto;
}
</style>