import request from "@/utils/request";

// 支付宝支付
export function getAliPayQr(data) {
  return request({
    url: "/api/pay/ali/toPay",
    method: "post",
    data,
  });
}

// 创建订单
export function createOrder(data) {
  return request({
    url: "/api/business/C_orderManage",
    method: "post",
    data: data,
  });
}

// 获取所有产品信息
export function getAllProduction(data) {
  return request({
    url: "/api/business/C_production/getList",
    method: "post",
    data: data,
  });
}

// 获取订单详情
export function getOrderMes(id) {
  return request({
    url: "/api/business/C_orderManage/detail/" + id,
    method: "get",
  });
}

// 获取订单内容信息
export function getOrderDetailMes(data) {
  return request({
    url: "/api/business/C_orderDetail/getList/",
    method: "post",
    data,
  });
}

// 单个产品立即订阅订单生成许可证
export function makeSingleLicense(data) {
  return request({
    url: "/api/business/C_license/makelicense",
    method: "post",
    data: data,
  });
}

// 单个产品立即订阅订单生成许可证
export function makeLabStart(data) {
  return request({
    url: "/api/business/C_license/makeLabStart",
    method: "post",
    data: data,
  });
}

// 下载许可证
export function downloadLicense(id) {
  return request({
    url: `/api/business/C_license/Download/${id}`,
    method: "get",
  });
}

// 微信支付
export function getWeChatPayQr(data) {
  return request({
    url: `/api/pay/wechat/toQrPay`,
    method: "post",
    data,
  });
}

// 根据用户id获取订单信息
export function getOrdersByPhone(id) {
  return request({
    url: `/api/business/C_orderManage/getorderDetailList`,
    method: "post",
    data: {
      userid: id,
      paymentstate: 1,
    },
  });
}
// 根据用户id获取名下许可证
export function getLicensesByPhone(id) {
  return request({
    url: `/api/business/C_orderManage/getlicenseList`,
    method: "post",
    data: {
      orderuserid: id,
      state: 1,
    },
  });
}

// 根据手机号获取用户id
export function getUserIdByPhone(data) {
  return request({
    url: `/api/business/C_customers/byPhone/` + data,
    method: "get",
  });
}

// 加入购物车(新增/编辑)
export function addCart(data, id) {
  if (id) {
    return request({
      url: "/api/business/C_cart/" + id,
      method: "put",
      data: data,
    });
  } else {
    return request({
      url: "/api/business/C_cart",
      method: "post",
      data: data,
    });
  }
}

// 获取购物车list(根据用户id查询)
export function getCartList(data) {
  return request({
    url: `/api/business/C_cart/getList`,
    method: "post",
    data: data,
  });
}

// 删除购物车商品
export function deleteCartProjrct(id) {
  return request({
    url: `/api/business/C_cart/${id}`,
    method: "DELETE",
  });
}

// 获取公司信息
export function getCompanyMes() {
  return request({
    url: `/api/business/C_companyMes/getList`,
    method: "post",
    data: {
      superQueryJson: "",
      currentPage: 1,
      pageSize: 20,
      sort: "desc",
      sidx: "",
    },
  });
}
// 留下联系方式
export function leftMes(data) {
  return request({
    url: `/api/business/C_leftMes`,
    method: "post",
    data: data,
  });
}

//增值服务
export function getvipappreciation(data) {
  return request({
    url: `/api/business/C_vipappreciation/getList`,
    method: "post",
    data: {
      superQueryJson: "",
      currentPage: 1,
      pageSize: 20,
      sort: "desc",
      sidx: "",
    },
  });
}

//教学视频
export function getvideo(data) {
  return request({
    url: `/api/example/C_elsevideo/getList`,
    method: "post",
    data: {
      superQueryJson: "",
      currentPage: 1,
      pageSize: 20,
      sidx: "fSortcode",
      // sidx: "",
      sort: "asc",
      menuState: "教学视频",
      ptype: data,
    },
  });
}

//软件更新
export function getvipsoftware(data) {
  return request({
    url: `/api/business/C_vipsoftware/getList`,
    method: "post",
    data: {
      superQueryJson: "",
      currentPage: 1,
      pageSize: 20,
      sort: "desc",
      sidx: "",
    },
  });
}

// 软件下载
export function getSoftDownloadList(data) {
  return request({
    url: `/api/business/C_trialversion/getList`,
    method: "post",
    data,
  });
}

// 账号支持文章
export function getSupportEssayList(data) {
  return request({
    url: `/api/business/C_supportEssay/getList`,
    method: "post",
    data,
  });
}

// 账号支持模块
export function getSupportModuleList(data) {
  return request({
    url: `/api/business/C_supportModule/getList`,
    method: "post",
    data,
  });
}

// 常见问题
export function getProblems(data) {
  return request({
    url: `/api/business/C_problems/getList`,
    method: "post",
    data,
  });
}

// 文章详情
export function getSupportDetail(id) {
  return request({
    url: "/api/business/C_supportEssay/detail/" + id,
    method: "get",
  });
}

// 根据手机号查询
export function getLicenseByPhone(data) {
  return request({
    url: "/api/business/C_license/phoneList/" + data,
    method: "get",
  });
}

// 获取产品介绍详情
export function getProductDetailList(data) {
  return request({
    url: `/api/business/C_productmes/getList`,
    method: "post",
    data: data,
  });
}
// 获取产品功能点详情
export function getProductFuntionList(data) {
  return request({
    url: `/api/business/C_productfunctions/getList`,
    method: "post",
    data: data,
  });
}
