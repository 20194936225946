<template>
  <div class="summitek-header" :class="[{ bgImage: activePath != '/Home' }]">
    <div class="m-contain">
      <div class="logo">
        <img src="../../../assets/image/LOGO.png" alt="" @click="toHome" />
        <!-- <div class="gotohome">回到首页</div> -->
      </div>
      <div class="menuList">
        <!-- <div v-for="(item, index) in menuList" :key="index" @click="changeMenu(item.path)" -->
        <div v-for="(item, index) in menuList" :key="index"
          :class="[{ select: activePath == item.path }, item.name != '首页' && item.name != '免费软件' ? 'mnlu_ul_li' : 'mnlu_ul_home']">
          <span slot="title" :class="[{ active: activePath == item.path }]" v-if="item.children.length == 0"
            style="cursor: pointer;" @click="changeMenu(item.path)">
            {{ item.name }}
          </span>
          <div class="mnlu_ul_li_pull">
            <div class="mnlu_ul_li_pull_c">
              <div class="mnlu_ul_li_pull_div">
                <div class="mnlu_ul_li_pull_title">
                  <!-- <ul v-if="index != 3">
                    <li v-for="(it, i) in item.title" :key="i">{{ it }}</li>
                  </ul> -->
                  <ul>
                    <!-- <li v-for="(it, i) in item.title" :key="i" class="hoverItem" @click="changeThirdMenu(item, it, i)"> -->
                    <li v-for="(it, i) in item.title" :key="i" class="hoverItem" @click="changeMenu1(item.path, it, i)">
                      {{ it }}
                    </li>
                  </ul>
                </div>
                <!-- <div class="mnlu_ul_li_pull_line" v-show="index != menuList.length - 1"></div> -->
                <!-- <div class="mnlu_ul_li_pull_title mnlu_ul_li_pull_content" v-show="index != menuList.length - 1">
                  <ul>
                    <li v-for="(its, is) in item.content" :key="is">
                      <div v-for="(items, indexs) in its" :key="indexs" @click="changeMenu(item.path, its, is, items)">
                        <span :class="index != 3 ? 'hoverItem' : ''">{{ items }}</span>
                      </div>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="loginPart">
        <div class="m-login">
          <p>
          <div class="ops">
            <span class="loginBtn" v-show="!isLogin" @click="clickLoginBtn()">登录</span>
            <!-- <span v-show="isLogin" @click="clickPersonalCenterBtn()"> -->
            <!-- </span> -->
            <!-- <span v-show="isLogin" @click="clickLoginBtn()" style="margin-left: 5px;">退出</span> -->
            <el-menu class="el-menu-vertical-demo" mode="horizontal" :collapse="true" v-show="isLogin">
              <el-submenu index="1">
                <template slot="title">
                  <img v-show="isLogin" @click="clickPersonalCenterBtn()" src="../../../assets/image/personCenter.png"
                    alt="">
                </template>
                <el-menu-item index="1-1" @click="clickPersonalCenterBtn(3)">用户认证</el-menu-item>
                <el-menu-item index="1-2" @click="clickPersonalCenterBtn(0)">订单管理</el-menu-item>
                <el-menu-item index="1-3" @click="clickPersonalCenterBtn(1)">许可证申请</el-menu-item>
                <!-- <el-menu-item index="1-4" @click="clickPersonalCenterBtn(2)">合同管理</el-menu-item> -->
                <el-menu-item index="1-5" @click="clickPersonalCenterBtn(2)">发票管理</el-menu-item>
                <el-menu-item-group>
                  <el-menu-item index="1-6" @click="clickLoginBtn()">退出</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>

          </div>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getAllProduction } from "@/assets/API/pay";
import $ from 'jquery';
export default {
  components: {},
  computed: {
    ...mapState("user", ["isLogin", "userInfo", "cartListCount"]),
  },
  data() {
    return {
      activePath: "/Home",
      activeIndex: "/Home",
      input: "",
      menuList: [
        {
          name: "首页",
          path: "/Home",
          key: "Home",
          children: [],
          title: ["首页"],
          content: [
            ["首页"],
            ["首页"],
            ["首页"],
          ]
        },
        {
          name: "产品介绍",
          path: "/AllProducts",
          key: "AllProducts",
          children: [],
          title: ["SummitMap一站式智能成图平台", "SummitSoild一站式单体化系统", "SummitLab智能化内业培训系统"],
          // content: [
          //   ["SummitMAP"],
          //   ["SummitSOLID"],
          //   ["SummitLab"]
          // ]
        },
        {
          name: "按需订阅",
          path: '/Buy',
          key: "Buy",
          children: [
          ],
          title: ["SummitMap一站式智能成图平台", "SummitSoild一站式单体化系统", "SummitLab智能化内业培训系统"],
          // content: [
          //   ["点云版本", "倾斜版", "立体航测版"],
          //   ["点云版本", "倾斜版", "立体航测版"],
          //   ["点云版本", "倾斜版", "立体航测版"],
          // ]
        },
        {
          name: "免费软件",
          path: '/FreeSoft',
          key: "FreeSoft",
          children: [
          ],
          title: [],
          content: [
          ]
        },
        {
          name: "服务支持",
          path: "/Service",
          key: "Service",
          children: [],
          title: ["软件支持", "问题交流", "联系我们"],
          content: [
            // ["增值服务", "教学视频", "软件更新"],
            ["教学视频"],
            // ["最新问题", "问题解答"],
            [],
            // ["产品", "许可证", "账号", "订阅/购买"],
            ["联系方式"],
          ]
        },
        {
          name: "关于我们",
          path: "/AboutUs",
          key: "AboutUs",
          children: [],
          title: ["企业介绍", "企业文化", "荣誉资质", "联系我们"],
          content: [
            // ["企业介绍", "企业文化"],
            // ["荣誉成就", "资质实力"],
            // ["合作伙伴"],
          ]
        },
      ],
      activeName: "first",
      popoverVis: false,
      cartProjectNumber: "0"
    };
  },
  mounted() {
    // 一级导航经过变化
    $(".mnlu_ul_li").hover(
      function () {
        $(this).addClass("yxnav_active1").siblings().removeClass("yxnav_active1");
        $(this).find(".mnlu_ul_li_pull").stop().slideDown().addClass("show");
      },
      function () {
        $(this).removeClass("yxnav_active1");
        $(".mnlu_ul_li.on").addClass("yxnav_active1");
        $(this).find(".mnlu_ul_li_pull").stop().slideUp().removeClass("show");
      },
    );
    this.getProduct();
    this.$bus.$on('reloadactivePath', path => {
      console.log("path", path);
      this.activePath = path;
    });

    // if (localStorage.getExpire('token')) {
    //   // ----------------------
    //   // GetAllcTrialversionS(localStorage.getExpire('token')).then(res => {
    //   //   if (res.data.data.UserLv == '会员用户') {
    //   //     res.data.data.UserLv = 1;
    //   //   } else if (res.data.data.UserLv == '认证用户') {
    //   //     res.data.data.UserLv = 2;
    //   //   } else {
    //   //     res.data.data.UserLv = 3;
    //   //   }
    //   //   this.$store.commit('updataCount', res.data.data.UserLv);
    //   //   // console.log(res.data.data.UserLv)  
    //   // });
    //   // this.UserMemer = this.$route.query.id;
    //   // -----------------------------
    // } else {
    //   this.$store.commit('updataCount', 4);
    // }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        let router = "";
        console.log(newVal, 'iiiiii');

        switch (true) {
          case ["/Home"].indexOf(newVal.path) != -1:
            router = "/Home";
            break;
          case ["/AllProducts"].indexOf(newVal.path) != -1:
            router = "/AllProducts";
            break;
          case ["/Buy"].indexOf(newVal.path) != -1:
            router = "/Buy";
            break;
          case ["/Service"].indexOf(newVal.path) != -1:
            router = "/Service";
            break;
          case ["/AboutUs"].indexOf(newVal.path) != -1:
            router = "/AboutUs";
            break;
          default:
            router = this.$route.path;
            break;
        }
        this.activePath = router;
        this.activeIndex = router;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations("user", ["SET_LOGINVISBILIT"]),
    hoverHeader(url) {
      this.$router.push(url);
    },
    toHome() {
      this.activePath = "/Home";
      this.$router.push({ name: "首页", path: "/Home" });
    },
    changeThirdMenu(item, name, index, li) {
      console.log(item, index, 'iii  ');
      if (index == 1) {
        window.open('https://summitek.freshdesk.com/support/home');
      } else if (index == 0) {
        this.$router.push({
          path: item.path,
          query: {
            type: index + 1
          }
        });
      } else if (index == 2) {
        this.$router.push({
          path: item.path,
          query: {
            type: 4
          }
        });
      }

    },
    changeMenu1(path, name, index) {
      if (path == '/AllProducts') {
        let value;
        if (index == 0) {
          value = "SummitMap";
        } else if (index == 1) {
          value = "SummitSolid";
        } else if (index == 2) {
          value = "SummitLab";
        }
        this.activePath = path ? path : "";
        // this.$router.push(path);
        this.$router.push({
          path,
          query: {
            name: value
          }
        });
      }
      if (path == '/Buy') {
        let value;
        if (index == 0) {
          value = "SummitMap";
        } else if (index == 1) {
          value = "SummitSolid";
        } else if (index == 2) {
          value = "SummitLab";
        }
        this.activePath = path ? path : "";
        // this.$router.push(path);
        this.$router.push({
          path,
          query: {
            value
          }
        });
      }
      if (path == '/Service') {
        if (index == 0) {
          this.$router.push({
            path,
            query: {
              type: 'update'
            }
          });
        } else if (index == 1) {
          window.open('https://summitek.freshdesk.com/support/home');
        } else if (index == 2) {
          this.$router.push({
            path,
            query: {
              type: 'contact'
            }
          });
        }
      }
      if (path == 'AboutUs') {
        if (index == 0) {
          this.$router.push({
            path,
            query: {
              name: 'intro'
            }
          });
        }
        if (index == 1) {
          this.$router.push({
            path,
            query: {
              name: 'culture'
            }
          });
        }
        if (index == 2) {
          this.$router.push({
            path,
            query: {
              name: 'qualifications'
            }
          });
        }
        if (index == 3) {
          this.$router.push({
            path,
            query: {
              name: 'aboutmap'
            }
          });
        }
      }
    },
    changeMenu(item, name, index, li) {
      // 我的笔记跳转新页面 liuke 20230522
      if (item == "/FlashNote") {
        const { href } = this.$router.resolve({
          name: "我的笔记",
        });
        window.open(href, "_blank");
      } else {
        if (name) {
          // let obj = this.menuList.filter(item => item.content[index][0] == name[0])[0];
          let obj;
          this.menuList.forEach(e => {
            if (e.content[index]) {
              if (e.content[index][0] == name[0]) {
                obj = e;
              }
            }
          });

          if (obj.key == "Buy") {
            if (index == 0) {
              let value = "SummitMap";
              this.activePath = item ? item : "";
              this.$router.push(item);
              this.$router.push({
                path: item,
                query: {
                  // name: name[0],
                  value
                }
              });
            } else if (index == 1) {
              let value = "SummitSolid";
              this.activePath = item ? item : "";
              this.$router.push(item);
              this.$router.push({
                path: item,
                query: {
                  // name: name[0],
                  value
                }
              });
            } else if (index == 2) {
              let value = "SummitLab";
              this.activePath = item ? item : "";
              this.$router.push(item);
              this.$router.push({
                path: item,
                query: {
                  // name: name[0],
                  value
                }
              });
            }
          } else if (obj.key == "Service") {
            // console.log(name[index], 'jssss');
            // console.log(item, name, index, obj, li, 'ssssssss-----------');
            if (li == '增值服务') {
              // this.$router.push(
              //   {
              //     name: "软件支持",
              //     path: "/SoftwareSupport",
              //     query: {
              //       type: 1
              //     }
              //   }
              // );
              this.$router.push(
                {
                  path: "/Service",
                  query: {
                    type: 1
                  }
                }
              );
            } else if (li == '教学视频') {
              this.$router.push(
                {
                  path: "/Service",
                  query: {
                    type: 1
                  }
                }
              );

            } else if (li == '软件更新') {
              this.$router.push(
                {
                  path: "/Service",
                  query: {
                    type: 1
                  }
                }
              );

            } else if (li == '最新问题') {
              // this.$router.push(
              //   {
              //     name: "问题交流",
              //     path: "/Communicate",
              //     query: {
              //       type: 1
              //     }
              //   }
              // );
              this.$router.push(
                {
                  path: "/Service",
                  query: {
                    type: 2
                  }
                }
              );
            } else if (li == '问题解答') {
              this.$router.push(
                {
                  path: "/Service",
                  query: {
                    type: 2
                  }
                }
              );

            } else if (li == '产品') {
              // this.$router.push(
              //   {
              //     path: "/AccountSupportDetail",
              //     query: {
              //       value: "产品技巧"
              //     }
              //   }
              // );
              window.open('https://summitek.freshdesk.com/support/home');

            } else if (li == '许可证') {
              // this.$router.push(
              //   {
              //     path: "/AccountSupportDetail",
              //     query: {
              //       value: "许可证问题"
              //     }
              //   }
              // );
              window.open('https://summitek.freshdesk.com/support/home');


            } else if (li == '账号') {
              // this.$router.push(
              //   {
              //     path: "/AccountSupportDetail",
              //     query: {
              //       value: "账号解疑"
              //     }
              //   }
              // );
              window.open('https://summitek.freshdesk.com/support/home');

            } else if (li == '订阅/购买') {
              // this.$router.push(
              //   {
              //     path: "/AccountSupportDetail",
              //     query: {
              //       value: "订阅购买"
              //     }
              //   }
              // );
              window.open('https://summitek.freshdesk.com/support/home');

            } else if (li == '联系方式') {
              // this.$router.push({
              //   name: "联系我们", path: "/ContactUs"
              // });
              this.$router.push(
                {
                  path: "/Service",
                  query: {
                    type: 4
                  }
                }
              );
            }

          } else {
            this.activePath = item ? item : "";
            this.$router.push(item);
            this.$router.push({
              path: item,
              query: {
                name: name[0]
              }
            });
          }
        } else {
          // 大标题
          this.activePath = item ? item : "";
          this.$router.push(item);
          this.$router.push({
            path: item
          });
        }

      }
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      localStorage.removeItem("USERINFO");
      // 退出时保持本页面
      this.$router.push("/Home");
    },
    clickLoginBtn() {
      if (!this.isLogin) {
        this.$router.push({ name: "Login", path: "/login" });
      } else {
        this.logout();
      }
    },
    toCart() {
      this.$router.push({ name: "购物车", path: "/cart" });
    },
    clickPersonalCenterBtn(val) {
      if (!val && val != 0) {
        this.$router.push({ name: "我的中心", path: "/PersonalCenter" });
      } else {
        this.$router.push({ name: "我的中心", path: "/PersonalCenter", query: { value: val } });
      }
    },
    toFree() {
      // 跳转到免费试用页
      let value = "free";
      this.$router.push({ name: "订阅购买", path: "/Buy", query: { value } });
      this.$bus.$emit('reloadactivePath', "/Buy");
    },
    getProduct() {
      getAllProduction({
        typename: "SummitMap",
        saletype: "online",
        enabledmark: 1
      }).then(res => {
        let contentItem = [];
        let arr = [];
        res.data.list.forEach(item => {
          arr.push(item.name);
        });
        contentItem.push(arr);
        getAllProduction({
          typename: "SummitSolid",
          saletype: "online",
          enabledmark: 1
        }).then(res1 => {
          let arr1 = [];
          res1.data.list.forEach(item => {
            arr1.push(item.name);
          });
          contentItem.push(arr1);
          getAllProduction({
            typename: "SummitLab",
            saletype: "online",
            enabledmark: 1
          }).then(res2 => {
            let arr2 = [];
            res2.data.list.forEach(item => {
              arr2.push(item.name);
            });
            contentItem.push(arr2);
            this.menuList.forEach(it => {
              if (it.key == "Buy") {
                it.content = contentItem;
              }
            });
          });
        });
      });
    },
  },
};
</script>

<style lang="scss">
.summitek-header {
  // box-shadow: 10px 10px 20px #e0e0e0;
  //box-shadow: 0px 15px 10px -15px #e0e0e0;
  height: 60px;
  width: 100%;
  min-width: 1180px;
  position: fixed;
  top: 0;
  z-index: 11;
  background-color: #fff;

  .el-avatar {
    width: 20px;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .m-contain {
    // width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 100px;
  }

  .M_contain {
    width: 1200px;
  }

  .logo {
    // img {
    //   object-fit: contain;
    //   height: 28px;
    // }
    width: 150px;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    text-align: center;
    flex: 1;

    img {
      width: 150px;
      height: 100%;
    }

    .gotohome {
      position: absolute;
      right: -75px;
      top: 14px;
      font-size: 14px;
      color: black;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .menuList {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    flex: 1.5;

    .mnlu_ul_li {
      display: flex;
      width: 80px;
      font-size: 20px;
      line-height: 30px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .el-dropdown-link {
        color: black;
        font-size: 14px;
      }

      >span {
        color: black;
        font-size: 20px;
      }

      >span:hover {
        color: rgba(51, 105, 255, 1);
        border-bottom: 2px solid rgba(51, 105, 255, 1);
      }

      .active {
        color: rgba(51, 105, 255, 1);
        border-bottom: 2px solid;

        .el-dropdown-link {
          color: rgba(51, 105, 255, 1);
        }
      }
    }

    .mnlu_ul_home {
      display: flex;
      width: 80px;
      font-size: 20px;
      line-height: 30px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .el-dropdown-link {
        color: black;
        font-size: 20px;
      }

      >span {
        color: black;
        font-size: 20px;
      }

      >span:hover {
        color: rgba(51, 105, 255, 1);
        border-bottom: 2px solid rgba(51, 105, 255, 1);
      }

      .active {
        color: rgba(51, 105, 255, 1);
        border-bottom: 2px solid;

        .el-dropdown-link {
          color: rgba(51, 105, 255, 1);
        }
      }
    }

    .select {
      line-height: 28px;
    }
  }

  .search {
    width: 190px;
    height: 28px;
    position: relative;
    // margin: 0 7px 0 50px;
    border-radius: 4px;
    margin-right: 20px;

    .el-input {
      width: 90%;
      margin-left: 18px;
    }

    .el-input__icon {
      line-height: 31px;
    }

    .el-input__inner {
      font-size: 12px;
      height: 28px;
      line-height: 28px;
      padding: 0;
      border-color: #3a74e2 !important;
    }
  }

  .icon-shouji {
    font-size: 24px;
    margin: 0 10px;
  }

  .loginPart {
    flex: 1;
  }

  .m-login {
    font-size: 14px;
    // margin-left: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;

    p {
      display: flex;
      align-items: center;

      .el-button {
        margin-left: 10px;
      }
    }

    .item_badge {
      margin-top: 10px;
      margin-right: 25px;
    }

    .el-icon-shopping-cart-2 {
      font-size: 25px;
    }

    .ops {
      display: flex;
      align-items: center;

      .loginBtn {
        width: 53px;
        height: 30px;
        opacity: 1;
        border-radius: 6px;
        background: rgba(255, 141, 26, 1);
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        vertical-align: middle;

      }

      img {
        width: 20px;
        height: 20px;
      }
    }

  }

  .m-haslogin {
    display: flex;
    font-size: 16px;
    color: #fff;
    // width: 109px;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
  }

  .el-icon-search {
    cursor: pointer;
  }
}

.hoverStyle {
  p {
    font-weight: 600;
    font-size: 14px;
  }

  .popoverow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    i {
      margin-right: 10px;
    }

    .el-col-11 {
      text-align: center;
      background-color: #f5f5f5;
      padding: 5px 10px;
      margin-top: 10px;
      cursor: pointer;

      &:hover {
        background-color: #f4f9ff;
        color: #386cff;
      }
    }
  }

  .el-divider--horizontal {
    margin: 14px 0;
  }

  .outLogins {
    text-align: right;
    font-weight: normal;

    span {
      cursor: pointer;

      &:hover {
        color: #386cff;
      }
    }
  }
}

.mnlu_ul_li_pull {
  // height: 200px;
  position: fixed;
  width: 100%;
  top: 60px;
  left: 0;
  background-color: rgb(255, 255, 255);
  display: none;
  box-shadow: 0px 8px 30px 0px rgba(0, 61, 150, 0.1);

  .mnlu_ul_li_pull_c {
    width: 100%;
    // height: 200px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 34px;
    padding-bottom: 23px;

    .mnlu_ul_li_pull_div {
      width: 1300px;

      .mnlu_ul_li_pull_title {
        width: 1300px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin-bottom: 15px;

        ul {
          width: 1200px;
          display: flex;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(56, 56, 56, 1);
          text-align: left;
          vertical-align: top;

          li {
            width: 25%;
          }
        }
      }

      .mnlu_ul_li_pull_line {
        width: 1300px;
        height: 2px;
        background-color: rgba(229, 229, 229, 1);
        margin-bottom: 15px;
      }

      .mnlu_ul_li_pull_content ul li {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(56, 56, 56, 1);
        text-align: left;
        vertical-align: top;
        cursor: pointer;
      }

      .mnlu_ul_li_pull_content ul li div .hoverItem:hover {
        color: rgba(51, 140, 242, 1);
      }
    }
  }
}

.hoverItem:hover {
  color: rgba(51, 140, 242, 1);
}

.mnlu_ul_li_link {
  font-size: 16px;
  line-height: 85px;
  display: block;
  transition: all .36s;
  color: #333;
  position: relative;
  z-index: 5;
}
</style>
