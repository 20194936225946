<template>
    <div class="interested">
        <div class="interested_title">您可能感兴趣</div>
        <div class="recommend">根据您的浏览习惯为您推荐以下主题</div>

        <div class="carousel">
            <div class="left" @click="prevSlide" v-if="currentIndex != 0">
                <img src="../../../../assets/image/interested_prev.png" alt=""></div>
            <div class="cart">
                <ul>
                    <li v-for="(item, index) in interestedArr" :key="index">
                        <img :src="item.img" alt="">
                        <div class="interested_list_title">{{ item.title }}</div>
                        <div class="publish_info">
                            <span class="publisher">by {{ item.publisher }}</span>
                            <span>{{ item.time }}</span>
                        </div>
                        <div class="introduction">{{ item.introduction }}</div>
                    </li>
                </ul>
            </div>
            <div class="right" @click="next" v-if="currentIndex != (interestedList.length-1)">
                <img src="../../../../assets/image/interested_next.png" alt="">
            </div>
        </div>

        <div class="carousel_drop">
            <div v-for="(item, index) in interestedList" :key="index" :class="[{ active: currentIndex == index }]"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        interestedList:{
            type:Array
        }
    },
    data() {
        return {
            currentIndex: 0
        }
    },
    computed: {
        interestedArr() {
            return this.interestedList[this.currentIndex]
        }
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        prevSlide() {
            if (this.currentIndex > 0) {
                this.currentIndex--; // 切换到上一页
            }
            console.log("this.currentIndex" , this.currentIndex);
            // else {
            //     this.currentIndex = this.images.length - 1; // 如果已经是第一页，则切换到最后一页
            // }
        },
        next() {
            if (this.currentIndex < this.interestedList.length - 1) {
                this.currentIndex++; // 切换到下一页
            }
            // else {
            //     this.currentIndex = 0; // 如果已经是最后一页，则切换到第一页
            // }
        },

    }

}
</script>

<style scoped lang="scss" scoped>
.interested {
    .interested_title {
        display: flex;
        justify-content: center;
        font-size: 44px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 64px;
        color: rgba(67, 207, 124, 1);
        text-align: center;
        vertical-align: top;
        margin-bottom: 10px;
    }

    .recommend {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 32px;
        color: rgba(105, 107, 128, 1);
        text-align: center;
        vertical-align: top;
        margin-bottom: 70px;
    }

    .carousel {
        position: relative;
        margin-bottom: 113px;

        .left {
            position: absolute;
            left: -10px;
            top: 40%;
            cursor: pointer;

            img {
                height: 11.26px;
            }
        }

        .cart {
            width: 100%;
            font-size: 30px;
            text-align: center;
            line-height: 200px;

            ul {
                display: flex;
                justify-content: space-between;
                padding: 0 15px;

                li {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 22px;
                    color: rgba(114, 115, 132, 1);
                    text-align: left;
                    vertical-align: top;

                    img {
                        height: 288px;
                        margin-bottom: 24px;
                    }

                    .interested_list_title {
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 30px;
                        color: rgba(67, 207, 124, 1);
                        text-align: left;
                        vertical-align: top;
                        margin-bottom: 12px;
                    }

                    .publish_info {
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 18px;
                        color: rgba(166, 167, 178, 1);
                        text-align: left;
                        vertical-align: top;
                        margin-bottom: 22px;

                        .publisher {
                            margin-right: 13px;
                        }
                    }

                    .introduction {
                        width: 378px;
                    }
                }
            }
        }

        .right {
            position: absolute;
            right: -10px;
            top: 40%;
            cursor: pointer;

            img {
                height: 11.26px;
            }
        }
    }

    .carousel_drop {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            width: 8px;
            height: 8px;
            opacity: 1;
            background: rgba(114, 115, 132, 0.37);
            border-radius: 50%;
            margin: 0 8px;
        }

        .active {
            width: 16px;
            height: 16px;
            opacity: 1;
            background: rgba(67, 207, 124, 1);
            border-radius: 50%;
        }
    }
}
</style>