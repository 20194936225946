import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

import "./utils/flexible";

import "amfe-flexible";

import "@/assets/base.scss";

Vue.filter("dateFormat", (dateStr, pattern = "YYYY-MM-DD HH:mm:ss") => {
  return moment(dateStr).format(pattern);
});

Vue.filter("toDateText", (dateTimeStamp) => {
  let result = "";
  let minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
  let hour = minute * 60;
  let day = hour * 24;
  let week = day * 7;
  let halfamonth = day * 15;
  let month = day * 30;
  let now = new Date().getTime(); //获取当前时间毫秒
  let diffValue = now - dateTimeStamp; //时间差
  if (diffValue < 0) return "刚刚";
  let minC = diffValue / minute; //计算时间差的分，时，天，周，月
  let hourC = diffValue / hour;
  let dayC = diffValue / day;
  let weekC = diffValue / week;
  let monthC = diffValue / month;
  if (monthC >= 1 && monthC <= 3) {
    result = " " + parseInt(monthC) + "月前";
  } else if (weekC >= 1 && weekC <= 3) {
    result = " " + parseInt(weekC) + "周前";
  } else if (dayC >= 1 && dayC <= 6) {
    result = " " + parseInt(dayC) + "天前";
  } else if (hourC >= 1 && hourC <= 23) {
    result = " " + parseInt(hourC) + "小时前";
  } else if (minC >= 1 && minC <= 59) {
    result = " " + parseInt(minC) + "分钟前";
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚";
  } else {
    let datetime = new Date();
    datetime.setTime(dateTimeStamp);
    let Nyear = datetime.getFullYear();
    let Nmonth =
      datetime.getMonth() + 1 < 10
        ? "0" + (datetime.getMonth() + 1)
        : datetime.getMonth() + 1;
    let Ndate =
      datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
    let Nhour =
      datetime.getHours() < 10
        ? "0" + datetime.getHours()
        : datetime.getHours();
    let Nminute =
      datetime.getMinutes() < 10
        ? "0" + datetime.getMinutes()
        : datetime.getMinutes();
    let Nsecond =
      datetime.getSeconds() < 10
        ? "0" + datetime.getSeconds()
        : datetime.getSeconds();
    result = Nyear + "-" + Nmonth + "-" + Ndate;
  }
  return result;
});

import VueClipboards from "vue-clipboard2"; //复制
Vue.use(VueClipboards);

const utils = require("./utils/utils").default; //获取公共方法
const wsy = require("./utils/wsy").default;
const protocol = require("./utils/protocolcheck");
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
Vue.prototype.axios = axios;

import bus from "@/utils/bus.js";
Vue.use(bus);
Vue.prototype.$bus = bus;
import i18n from "./lang"; // internationalization
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});

// import 'default-passive-events'

import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);

Vue.config.productionTip = false;

// 添加实例属性
Object.assign(Vue.prototype, {
  define: require("./utils/define"), // 常量
  utils, // 公共方法
  wsy,
  protocol,
});

// 路由切换 默认回到页面顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
router.beforeEach((to, from, next) => {
  console.log(to, "uuuuuuuu");
  if (to.path == "/login" && to.query.code == null) {
    localStorage.setItem("preRoute", router.currentRoute.fullPath);
  }else {
    store.commit("isFull", false);
  }
  console.log(localStorage.getItem("preRoute"), "preroute");
  next();
});

import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

import { preventReClick } from "@/utils/plugins";
Vue.use(preventReClick);

//日期选择组件
import Calendar from "vue-mobile-calendar";
Vue.use(Calendar);

const VM = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export default VM;
