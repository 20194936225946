<template>
    <el-dialog title="创建许可证" :close-on-click-modal="false" class="Summit-dialog Summit-dialog_center" lock-scroll
        append-to-body width="1200px" :visible.sync="visible" :show-close="false">
        <div class="dialog-main">
            <el-form label-width="100px" @submit.native.prevent>
                <el-form-item label="机器码">
                    <!-- <el-table-column prop="machinecode" label="机器码" align="left"> -->
                    <template slot="label">
                        机器码
                        <el-tooltip effect="light" content="打开软件在许可信息弹窗中可获取机器ID" placement="top">
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </template>
                    <!-- </el-table-column> -->
                    <el-input v-model.trim="machineCode" placeholder="请输入机器码" clearable
                        @blur="checkMachineData($event)" />
                </el-form-item>
                <el-form-item label="选择产品">
                    <el-table v-loading="orderlistLoading" :data="orderList" :span-method="arrayOrderSpanMethod" border
                        @selection-change="handleSelectionChange" row-key="id" @cell-mouse-enter="onCellMouseEnter"
                        @cell-mouse-leave="onCellMouseLeave">
                        <el-table-column type="selection" width="50" align="center" :selectable="selectable"
                            fixed="left"></el-table-column>
                        <!-- <el-table-column type="selection" width="50" align="center" :selectable="selectable"
                            fixed="left">
                            <template slot-scope="scope">
                                <el-tooltip effect="dark" :content="getDisableReason(scope.row)" placement="top"
                                    :disabled="!getDisableReason(scope.row)">
                                    <el-checkbox :disabled="!selectable(scope.row)" v-model="scope.row.selected"
                                        @change.native="onCheckboxChange(scope.row)"></el-checkbox>
                                </el-tooltip>
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="ordernum" label="订单" align="left">
                            <template slot-scope="scope">
                                {{ scope.row.ordernum }} {{ scope.row.ordername }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="purchasemethod" label="订单类型" align="left">
                            <template slot-scope="scope">
                                {{ scope.row.purchasemethod == 'online' ? "线上" : "线下" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="producttype" label="产品家族" align="left" />
                        <el-table-column prop="productname" label="产品" align="left" />
                        <el-table-column prop="duration" label="时长" align="left">
                            <template slot-scope="scope">
                                {{ getDuration(scope.row) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" label="数量" align="left" />
                        <el-table-column prop="licensestate" label="许可证制作状态" align="left">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.licensestate == 0" effect="dark" type="success">已全部创建</el-tag>
                                <el-tag v-else effect="dark" type="danger">待使用产品{{ scope.row.licensestate }}个</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>

        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="onConfirm()">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import request from '@/utils/request';

export default {
    components: {},
    data() {
        return {
            visible: false,
            listLoading: true,
            machineCode: '',
            list: [],
            total: 0,
            checked: [],
            orderlistLoading: true,
            orderList: [],
            choosedProductIds: [],
            // 机器码原有的产品
            basePorducts: [],
            choosedMachineList: []
        };
    },
    computed: {

    },
    created() {
        // 填写机器码 获取该机器码当前的产品 相同的产品不可选 
        // 选择产品 不可选相同的产品
        // 确定 回传数据
        // 接受回传数据 整合数据
        // 创建单个许可证或者批量创建

    },
    methods: {
        getDisableReason(row) {
            if (!this.machineCode) {
                return '请输入机器码';
            }
            if (this.selectable(row)) {
                return '';
            } else {
                return '您已选择相同产品或已生成有效许可证，请选择其他产品';
            }
        },
        onCellMouseEnter(row, column, cell, event) {
            if (column.type == 'selection') {
                let reason = this.getDisableReason(row);
                if (reason) {
                    this.createTips(event, row, reason);
                }
            }
        },
        createTips(el, row, value) {
            const { id } = row;
            const tooltipDom = document.createElement('div');
            tooltipDom.style.cssText = `
        display: inline-block;
        max-width: 400px;
        max-height: 400px;
        position: absolute;
        top: ${el.clientY + 5}px;
        left: ${el.clientX}px;
        padding:5px 10px;
        overflow: auto;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #595959;
        background: #fff;
        border-radius: 5px;
        z-index: 19999;
        box-shadow: 0 4px 12px 1px #ccc;
      `;
            tooltipDom.innerHTML = value;
            tooltipDom.setAttribute('id', `tooltip-${id}`);
            // 将浮层插入到body中
            document.body.appendChild(tooltipDom);
        },
        onCellMouseLeave(row, column, cell, event) {
            const { id } = row;
            const tooltipDomLeave = document.querySelectorAll(`#tooltip-${id}`);
            if (tooltipDomLeave.length) {
                tooltipDomLeave.forEach(dom => {
                    document.body.removeChild(dom);
                });
            }
        },
        // 获取所有订单详细数据
        initOrderData(id, choosedMachineList) {
            this.orderList = [];
            this.choosedMachineList = choosedMachineList;
            let detailIds = choosedMachineList.map(item => {
                if (item.state != 1) {
                    return item.orderdetailproductid;
                }
            });
            this.orderlistLoading = true;
            let _query = {
                userid: id,
                paymentstate: 1
            };
            request({
                url: `/api/business/C_orderManage/getorderDetailList`,
                method: 'post',
                data: _query
            }).then(res => {
                // this.orderList = res.data.list;
                let orderlist = res.data.list;
                orderlist.forEach(e => {
                    e.selected = false;
                    e.orderdetaillist.forEach(ele => {
                        let detailData = ele;
                        detailData.ordername = e.ordername;
                        detailData.ordernum = e.ordernum;
                        detailData.purchasemethod = e.purchasemethod;
                        if (detailIds.includes(detailData.id)) {
                            let count = detailIds.filter(data => {
                                return data === detailData.id;
                            });
                            detailData.licensestate = detailData.licensestate - count.length;
                        }
                        if (detailData.producttype != "加密狗" && detailData.licensestate != 0 && detailData.purchasemethod == 'online') {
                            this.orderList.push(detailData);
                        }
                    });
                });
                this.orderlistLoading = false;
                this.total = res.data.pagination.total;
                this.listLoading = false;
                this.visible = true;
            });
        },
        arrayOrderSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 2) {
                const cellValue = row[column.property];
                const prevRow = this.orderList[rowIndex - 1];
                let nextRow = this.orderList[rowIndex + 1];
                if (prevRow && prevRow[column.property] === cellValue) {
                    return { rowspan: 0, colspan: 0 };
                } else {
                    let countRowspan = 1;
                    while (nextRow && nextRow[column.property] === cellValue) {
                        nextRow = this.orderList[++countRowspan + rowIndex];
                    }
                    if (countRowspan > 1) {
                        return { rowspan: countRowspan, colspan: 1 };
                    }
                }
            }
        },
        // 确认数据
        onConfirm() {
            if (!this.checked.length) return;
            this.$emit('refreshDataList', this.checked, this.machineCode);
            this.cancel();
        },
        handleSelectionChange(val) {
            console.log(val, 'iiiii');
            this.checked = val;
            this.choosedProductIds = val.map(e => {
                return e.productid;
            });
        },
        selectable(row, index) {
            let result = true;
            // 已全部创建的订单不可选 没填写机器码不可选
            if (!this.machineCode) result = false;
            // 该机器码已经存在且还在有效期的 相同产品不可选
            if (this.basePorducts.includes(row.productid)) {
                result = false;
                return;
            }
            // 之前选过的未生成的数据 相同机器码相同产品不可选
            this.choosedMachineList.forEach(e => {
                if (e.productid == row.productid && e.machinecode == this.machineCode) {
                    result = false;
                }
            });

            // 之前选过的产品不可选
            if (this.choosedProductIds.includes(row.productid)) {
                this.checked.forEach(e => {
                    if (e.productid == row.productid && e.id != row.id) {
                        result = false;
                    }
                });
            }

            return result;
        },
        // 查找输入的机器码信息
        checkMachineData() {
            let _query = {
                machinecode: this.machineCode,
                state: 1
            };
            request({
                url: `/api/business/C_license/getList`,
                method: 'post',
                data: _query
            }).then(res => {
                this.basePorducts = res.data.list.map(e => {
                    return e.productid;
                });
            });
        },
        cancel() {
            this.machineCode = '';
            this.checked = [];
            this.basePorducts = [];
            this.choosedProductIds = [];
            this.visible = false;
        },
        getDuration(data) {
            if (!data.durationunion) {
                return "永久";
            } else {
                if (data.durationunion == 'week') {
                    return data.duration + "周";
                } else if (data.durationunion == "month") {
                    return data.duration + "月";
                } else if (data.durationunion == "season") {
                    return data.duration + "季";
                } else if (data.durationunion == "halfyear") {
                    return data.duration + "半年";
                }
            }
        }
    }
};
</script>
<style scoped lang="scss">
.machineOps {
    display: flex;
    justify-content: end;

    .machineNum {
        margin-right: 20px;
        color: #290505;
        font-size: 13px;

        .el-tag {
            margin-right: 10px;
        }
    }
}
</style>