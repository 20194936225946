<template>
  <div>
    <el-table :data="newTopicList" style="width: 100%" :show-header="false" :row-style="{ height: '100px' }">
      <el-table-column width="75px">
        <template slot-scope="scope">
          <div :style="{ color: (scope.row.postCommentcount != 0 ? '#0099ee' : '#868e96') }" style="text-align:center">
            <div>
              {{ scope.row.postCommentcount }}
            </div>
            <div>回答</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="75px">
        <template slot-scope="scope">
          <div style="text-align:center;">
            <div>
              {{ scope.row.postWatch }}
            </div>
            <div>浏览</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div style="width:100%;">
            <span style="font-size: 16px;color: #303030;" class="click_hover" @click="toDetail(scope.row)">{{
              scope.row.postTitle
            }}</span>
            <!-- <span style="float:right;font-size: 12px;">
                            <span class="logo icon iconfont" style="color:red">&#xe610;</span>
                            {{ scope.row.Post_reward }}
                        </span> -->
          </div>
          <div style="color: #868e96 !important;font-size:14px;margin-top: 10px;">
            <!-- <span class="ques_tag" v-for="i in getTagName(scope.row.Post_tagsId)" :key="i.F_Id">{{
              i.Tag_name
            }}</span> -->
            <span>{{ scope.row.postAdmin }}</span>
            <span>・{{ scope.row.postCreatetime }} 提问</span>
            <el-button style="float: right;" type="primary" size="mini" @click="toAnswer(scope.row.id)">
              <span class="icon-edit iconfont"></span>
              写下回答</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
      :total="newTopicList.length" :page-size="pageSize">
    </el-pagination>
  </div>
</template>
<script>
import {
  GetForumPostsList,
  GetForumCommentList,
  GetForumModuleTagList,
} from "@/assets/API/forum.js";
export default {
  props: {
    questionId: "",
    type: "",
  },
  data() {
    return {
      newTopicList: [],
      currentPage: 1,
      pageSize: 10,
      questionIdSon: "",
      tagList: [],
    };
  },
  mounted() { },
  watch: {
    questionId: function (newVal, oldVal) {
      this.questionIdSon = newVal;
      this.getDataList();
      // let queryTag = {
      //   Tag_moduleId: this.questionIdSon,
      // };
      // GetForumModuleTagList(queryTag).then((res) => {
      //   this.tagList = [...res.data.list];
      // });
    },
  },
  methods: {
    toDetail(row) {
      let id = row.id;
      this.$router.push({
        path: "addQuestionDetail",
        query: {
          id,
          showRichText: 0
        },
      });
      // this.$router.push({
      //   path: "QuestionDetail",
      //   query: {
      //     data: id,
      //   },
      // });
    },
    // 分页方法
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      this.getDataList();
    },
    getDataList() {
      if (this.type == 0) {
        // 获取最新帖子列表
        let queryNewData = {
          postBiboid: this.questionIdSon,
          sidx: "postCreatetime",
          pageSize: 10,
          currentPage: this.currentPage,
          fEnabledmark: 1,
          userid: this.$store.state.user.userInfo ? this.$store.state.user.userInfo.id : null
        };
        GetForumPostsList(queryNewData)
          .then((res) => {
            this.newTopicList = [...res.data.list];
            this.pageSize = res.data.pagination.pageSize;
          });
      } else if (this.type == 1) {
        let queryNewData = {
          postBiboid: this.questionIdSon,
          sidx: "postWatch",
          pageSize: 10,
          currentPage: this.currentPage,
          fEnabledmark: 1,
          userid: this.$store.state.user.userInfo ? this.$store.state.user.userInfo.id : null
        };
        GetForumPostsList(queryNewData)
          .then((res) => {
            this.newTopicList = [...res.data.list];
            this.pageSize = res.data.pagination.pageSize;
          });
      } else if (this.type == 2) {
        let queryNewData = {
          postBiboid: this.questionIdSon,
          sidx: "postCreatetime",
          pageSize: 10,
          currentPage: this.currentPage,
          fEnabledmark: 1,
          userid: this.$store.state.user.userInfo ? this.$store.state.user.userInfo.id : null
        };
        GetForumPostsList(queryNewData).then((res) => {
          let result = [...res.data.list];
          this.pageSize = res.data.pagination.pageSize;
          result.forEach((i) => {
            if (i.postCommentcount == 0) {
              this.newTopicList.push(i);
            }
          });
        });
      }
    },
    // getTagName(id) {
    //   let tags = JSON.parse(id);
    //   if (tags.length > 0) {
    //     let result = [];
    //     for (var i = 0; i < this.tagList.length; i++) {
    //       for (var j = 0; j < tags.length; j++) {
    //         if (tags[j] == this.tagList[i].F_Id) {
    //           result.push(this.tagList[i]);
    //         }
    //       }
    //     }
    //     return result;
    //   }
    // },
    toAnswer(id) {
      this.$router.push({
        path: "addQuestionDetail",
        query: {
          id,
          showRichText: 1
        },
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.ques_tag {
  font-size: 12px;
  background-color: #ebf8ff;
  color: #0099ee;
  padding: 3px 10px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 5px;
}

.click_hover:hover {
  cursor: pointer;
}
</style>
