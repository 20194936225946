<template>
    <div class="main_content">
        <div class="darkBg">
            <div class="intro" style="padding-top: 117px;padding-bottom:87px">
                <div>
                    <span class="introText">系统优势</span>
                </div>
                <div>
                    <span class="introDes">SYSTEM ADVANTAGES</span>
                </div>
            </div>
            <div class="mianCenter">
                <div class="advantages">
                    <ul>
                        <li v-for="(item, index) in advantagesList" :key="index">
                            <div>
                                <img :src="item.img" alt="">
                            </div>
                            <div class="adContent">
                                <div class="advantages_title">{{ item.name }}</div>
                                <div>{{ item.des }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="featureBg">
            <div class="intro" style="padding-top: 120px;padding-bottom:120px">
                <div>
                    <span class="introText">功能模块</span>
                </div>
                <div>
                    <span class="introDes">FUNCTIONAL MODULES</span>
                </div>
            </div>
            <div class="featureList">
                <div class="featureLeft">
                    <!-- <div class="featureItem">全面的数据源支持</div> -->
                    <div v-for="(item, index) in featuresList " :key="index"
                        :class="item.choosed ? 'selectFeature featureItem' : 'featureItem'"
                        @mouseenter="chooseFeature(item, index)">
                        {{ item.name }}
                    </div>
                </div>
                <div class="featureRight">
                    <div class="featuresItem" v-show="activeFeature == index" v-for="( item, index ) in featuresList "
                        :key="index">
                        <div class="textContent" v-if="index == 0 || index == 2">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                    <div class="img" v-if="it.img"><img :src="it.img" alt=""></div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i"
                                        :style="{ 'width': (96 / item.imgList.length) + '%' }">
                                        <div>
                                            <img :src="it.img" alt="">
                                        </div>
                                        <div class="smallDes">{{ it.des }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="textContent1" v-if="index == 1">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                    <div class="img" v-if="it.img"><img :src="it.img" alt=""></div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i">
                                        <div :class="i == 1 ? 'img2' : 'imgs'">
                                            <img :src="it.img" alt="">
                                        </div>
                                    </li>
                                </ul>
                                <div class="imgDes">
                                    {{ item.imgDes }}
                                </div>
                            </div>
                        </div>
                        <div class="textContent1" v-if="index == 3">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                    <div class="img" v-if="it.img"><img :src="it.img" alt=""></div>
                                </div>
                            </div>
                            <div class="itemImg1">
                                <!-- <ul>
                                    <li v-for="( it, i ) in  item.imgList " :key="i">
                                        <div :class="i == 1 ? 'img2' : 'imgs'">
                                            <img :src="it.img" alt="">
                                        </div>
                                    </li>
                                </ul>
                                <div class="imgDes">
                                    {{ item.imgDes }}
                                </div> -->
                                <div class="dataList">
                                    <div class="dataItem" v-for="(it, i) in item.dataList" :key="i"
                                        :style="{ 'background-color': it.color }">
                                        <p class="num">{{ it.num }}</p>
                                        <p>{{ it.des }}</p>
                                    </div>
                                </div>
                                <div class="imgData">
                                    <div :class="ind == 0 ? 'firstImg' : 'secondImg'" v-for="(t, ind) in item.imgList"
                                        :key="ind">
                                        <img :src="t.img" alt="">
                                        <div class="des">{{ t.des }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="textContent1" v-if="index == 6">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in  item.desList " :key="i">
                                    <div class="des">{{ it.textContent }}</div>
                                </div>
                                <div class="textTotal">
                                    <div class="desItem" v-for="(item, index) in item.desItemList" :key="index">
                                        <div class="desTitle">{{ item.title }}</div>
                                        <div class="desDes">{{ item.des }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in  item.imgList " :key="i">
                                        <div>
                                            <img :src="it.img" alt="">
                                            <div class="imgDes">{{ it.des }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div> -->
                        <div class="textContent changeBox" v-if="index == 4">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i">
                                        <div>
                                            <img :src="it.img" alt="">
                                        </div>
                                        <div class="imgDes">{{ it.des }}</div>
                                    </li>
                                </ul>
                                <div class="imgDes">
                                    {{ item.imgDes }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="mianCenter">
            <div class="featuresContent">
                <div class="featuresItem" v-for="(item, index) in featuresList" :key="index">
                    <div class="featuresIntro"><span></span> {{ item.name }}</div>
                    <div class="textContent">
                        <div class="text">
                            <div class="item" v-for="(it, i) in item.desList" :key="i">
                                <div class="div_test">
                                    <div class="dot"></div>
                                </div>
                                <div style="width: calc(100% - 24px);">{{ it.textContent }}</div>
                            </div>
                        </div>
                        <div class="itemImg">
                            <ul v-if="index != 4">
                                <li v-for="(it, i) in item.imgList" :key="i"
                                    :style="{ 'width': index == 4 ? '43%' : (96 / item.imgList.length) + '%' }">
                                    <div :class="{ 'color': item.flag }">
                                        <img :src="it.img" :class="{ 'color1': item.flag }" alt="">
                                    </div>
                                    <div>{{ it.des }}</div>
                                </li>
                            </ul>
                            <ul v-if="index == 4">
                                <li style="width:100%;">
                                    <div :class="{ 'color': item.flag }">
                                        <img src="../../../assets/image/ProjectSummitLab_img5.png"
                                            :class="{ 'color1': item.flag }" alt="">
                                    </div>
                                    <div>基于DEM与DLG的地理实体生产</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="intro" style="padding-top: 180px;padding-bottom:80px">
            <div>
                <span class="introDes">System Composition</span>
            </div>
            <div style="margin-top: -20px;">
                <span class="introText">系统组成</span>
            </div>
        </div>
        <div class="mianCenter">
            <div class="baseContent systemCompStyle">
                <div class="compItems">
                    <div class="compItem1">
                        <div class="itemTitle1">资源库</div>
                        <div class="itemDes">提供了便捷的工具、统一管理、查询各种资原、素材。资源包括ppt、图片、文档、视频、音频等，用户可以基于资源库制作个性化的项目案例。</div>
                    </div>
                    <div class="compItem2">
                        <div class="itemTitle">实操考练</div>
                    </div>
                </div>
                <div class="centerStyle">
                    SYSTEM COMPOSITION
                </div>
                <div class="compItems" style="margin-top: 30px;">
                    <div class="compItem3">
                        <div class="itemTitle">考核评估</div>

                    </div>
                    <div class="compItem4">
                        <div class="itemTitle">双版本同步控制</div>

                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeFeature: 0,
            advantagesList: [
                {
                    name: "学习范围覆盖全面",
                    des: "学习范围覆盖倾斜采集、立体采集、点云采集、单体化建模等领域，全面满足航测内业生产需求。",
                    img: require("../../../assets/image/advantages_img1.png"),
                },
                {
                    name: "学习内容定制可控",
                    des: "既可根据教学内容修改现有剧本，也可以创建新章节、全新教学内容。",
                    img: require("../../../assets/image/advantages_img2.png"),
                },
                {
                    name: "学习方式循序渐进",
                    des: "将学习内容按类别、难易划分，按照分类循序渐进开展阶段性学习。",
                    img: require("../../../assets/image/advantages_img3.png"),
                },
                {
                    name: "学习过程沉浸高效",
                    des: "通过倒计时、一步一检和实时反馈严格把控学习过程，辅以全程智能语音播报提高学习注意力，打造游戏级教学体验。",
                    img: require("../../../assets/image/advantages_img4.png"),
                },
                {
                    name: "学习错误实时可校",
                    des: "实训过程中通过测标颜色变化反映实时精度，通过比对预置的正确成果高亮提示采集错误，便于学员实时校正。",
                    img: require("../../../assets/image/advantages_img5.png"),
                },
                {
                    name: "学习结果定量可测",
                    des: "在章节学习、操作实践、组卷考核后均生成对应评估报告，学员可参考评估报告轻松找到自身薄弱之处，并进行针对性学习。",
                    img: require("../../../assets/image/advantages_img6.png"),
                },
                {
                    name: "学习程度既精且深",
                    des: "通过教学实操令学员会测图，利用严格精度练习让学员测的准，内置上千实操案例助学员测的精。",
                    img: require("../../../assets/image/advantages_img7.png"),
                },
                {
                    name: "学生状态定时可查",
                    des: "教师通过教师中心轻松掌握学生学习情况，把握整体学习进度、学习效果。",
                    img: require("../../../assets/image/advantages_img8.png"),
                }
            ],
            featuresList: [
                {
                    name: "资源库",
                    desList: [
                        {
                            textContent: "• 集成PPT、文档、图片、视频、音频等丰富航测教学资源，支持用户基于资源库制作个性化项目案例。"
                        },
                        {
                            textContent: "• 提供便捷的资源管理工具，对各项资源素材进行精细化管理与高效检索，并自动关联展示检索内容相关的备选资源。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitLab_img1.png"),
                        }
                    ],
                    choosed: true,
                },
                {
                    name: "剧本编辑",
                    desList: [
                        {
                            textContent: "• 可根据教学内容任意调用资源修改现有剧本或新建剧本，所有剧本可以根据需要导出形成新的模组，所有模组也可以合并形成新的流程，实现定制化教学。"
                        },
                        {
                            textContent: "• 为剧本编辑提供了丰富的编辑功能，并预设了如鼠标键盘检测、工具栏高亮显示、矢量结果比对、飞行展示等大量插件命令，此外还可以随时根据需要添加新的命令到命令集。"
                        },
                        {
                            textContent: "• 支持生产软件界面提示、操作结果判定、语音视频文字展示与关闭等各种场景。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitLab_img2.png"),
                        },
                    ],
                    choosed: false,
                },
                {
                    name: "教学管理",
                    desList: [
                        {
                            textContent: "• 将整个班级纳入管理，让教师能够方便的控制教学内容，组织学习与考核。"
                        },
                        {
                            textContent: "• 可实时查看学生学习日志、学习进度及考核情况，科学的统筹安排后续教学计划。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitLab_img3.png"),
                        }
                    ],
                    choosed: false,
                },
                {
                    name: "实操考练",
                    desList: [
                        {
                            textContent: "• 内嵌SummitMap成图平台、SummitSolid单体化系统两款软件，兼顾内业数据采集与模型单体化处理教学。"
                        },
                        {
                            textContent: "• 覆盖倾斜采集、立体采集、点云采集、单体化建模等领域，分130余个章节篇幅、五个级别帮助用户循序渐进、由简到难的掌握航测内业作业方方面面的知识与技巧，且章节内容还在不断扩充更新中。"
                        },
                        {
                            textContent: "• 以PPT和图片的形式详细拆解理论与实践生产知识要点，以视频形式呈现完整生产作业流程，以交互指引的形式开展实操教学，以课后习题巩固所学知识。"
                        },
                        {
                            textContent: "• 结合国家规范和业内专家的实践经验，精心设计上千个实操案例，让学员在学习中体验实际项目生产。"
                        },
                        {
                            textContent: "• 章节学习与实践练习结束后均出具评估报告，从耗时、精确度、完成度等多个维度进行评分。"
                        }
                    ],
                    dataList: [
                        {
                            num: '130+',
                            des: "章节篇幅",
                            color: "#2A82E4"
                        },
                        {
                            num: '1000+',
                            des: "实操案例",
                            color: "#04AFB8"
                        },
                        {
                            num: '1000+',
                            des: "考核试题",
                            color: "#036EB7"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitLab_img4.png"),
                            des: "学习效果评估"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitLab_img5.png"),
                            des: "交互式实操教学"
                        },
                    ],
                    choosed: false,
                },
                {
                    name: "考核评估",
                    desList: [
                        {
                            title: "内置上千道考核试题",
                            textContent: "内置上千道考核试题，内容涵盖测绘理论、内业规范、操作实践等方方面面，更有文字题、图文题、符号题、实操题等多种题型，从理论到实践全方位检测学员学习效果。"
                        },
                        {
                            title: "每月更新真实案例试题",
                            textContent: "每月更新上百道源于作业一线真实案例的试题，考核内容与时俱进。"
                        },
                        {
                            title: "实操题可实时联机",
                            textContent: "实操题可实时联机软件进行操作答题，系统自动对比标准成果并开展多维度评估，在打分的同时给出改进意见。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/ProjectSummitLab_img6.png"),
                            des: "实操作业精度评估"
                        },
                        {
                            img: require("../../../assets/image/ProjectSummitLab_img7.png"),
                            des: "组卷考核"
                        },
                    ],
                    choosed: false,
                },
            ],
        };
    },
    methods: {
        chooseFeature(data, index) {
            this.activeFeature = index;
            this.featuresList.forEach(e => {
                e.choosed = false;
            });
            data.choosed = true;
        }
    }

};
</script>

<style scoped lang="scss" scoped>
.main_content {
    .intro {
        text-align: center;
        display: flex;
        flex-direction: column;

        .introDes {
            font-size: 30px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 43.44px;
            color: #CCCCCC;
            text-align: center;
            vertical-align: top;
        }

        .introText {
            font-size: 40px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 57.92px;
            color: #fff;
            text-align: center;
            vertical-align: top;
        }

    }

    // .advantages {
    //     ul {
    //         display: flex;
    //         flex-wrap: wrap;

    //         li {
    //             width: 50%;
    //             padding-right: 140px;
    //             padding-left: 35px;
    //             font-size: 16px;
    //             font-weight: 400;
    //             letter-spacing: 0px;
    //             line-height: 23.17px;
    //             color: rgba(102, 102, 102, 1);
    //             text-align: left;
    //             vertical-align: top;
    //             padding-bottom: 109px;
    //             box-sizing: border-box;
    //             border-right: 1px solid rgb(166, 166, 166);

    //             div {
    //                 display: flex;
    //                 justify-content: center;
    //             }

    //             .advantages_title {
    //                 font-size: 26px;
    //                 line-height: 37.65px;
    //                 color: rgba(56, 56, 56, 1);
    //                 margin-bottom: 32px;
    //             }

    //             img {
    //                 width: 85px;
    //                 height: 85px;
    //                 margin-bottom: 50px;
    //             }
    //         }
    //     }

    //     li:nth-child(2n) {
    //         padding-left: 140px;
    //         padding-right: 35px;
    //         border: none;
    //         border-left: 1px solid rgb(166, 166, 166);
    //     }
    // }

    .featureList {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 85px;
        display: flex;

        .featureLeft {
            width: 300px;
            height: 620px;
            opacity: 1;
            background: #276FBE;
            display: flex;
            flex-direction: column;
            font-size: 18px;
            font-weight: 400;

            .featureItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                // flex: 1;
                height: 53px;
                padding: 0 10px;
                color: #fff;
                justify-content: center;

            }

            .selectFeature {
                background-color: #2B85E5;
                color: #FFFFFF;
            }

        }

        .featureRight {
            flex: 1;
            height: 620px;
            opacity: 1;
            background: rgba(255, 255, 255, 1);
            overflow: auto;

            .featuresItem {
                padding: 22px 25px;
                display: flex;
                flex-direction: column;
                height: 100%;

                .featuresIntro {
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 34.75px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .itemText {
                    padding: 14px 0;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 32px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;

                    .dot {
                        width: 12px;
                        height: 12px;
                        opacity: 1;
                        background: rgba(56, 56, 56, 1);
                        border-radius: 50%;
                        margin-right: 12px;
                    }

                }

                .decomposeIntro {
                    flex: 1;
                    height: 100%;
                    padding-bottom: 35px;

                    ul {
                        display: flex;
                        height: 100%;

                        li {
                            // width: 177px;
                            // height: 318px;
                            flex: 1;
                            opacity: 1;
                            border-radius: 8px;
                            background: rgba(255, 255, 255, 1);
                            box-shadow: 0px 2px 8px rgba(146, 158, 168, 0.25);
                            padding: 30px 10px;
                            margin-left: 10px;
                            display: flex;
                            flex-direction: column;

                            text-align: center;

                            .decomTag {
                                opacity: 1;
                                border-radius: 20px;
                                background: rgba(43, 133, 229, 1);
                                margin: 0 17px;
                                color: #fff;
                            }

                            .decomImg {
                                // flex: 1;
                                height: 25%;
                            }

                            .decomDes {
                                font-size: 14px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 24px;
                                color: rgba(56, 56, 56, 1);
                                text-align: center;
                                vertical-align: top;
                                padding-top: 12px;

                                p {
                                    text-align: left;
                                    display: inline-block;
                                }

                            }
                        }

                        li:first-child {
                            margin-left: 0;
                        }
                    }
                }

                .textContent {
                    display: flex;
                    flex-direction: column; // justify-content: space-between;

                    .text {
                        // width: 44%;
                        // background-color: pink;
                        margin-top: 10px;
                        padding-bottom: 10px;

                        .item {
                            // display: flex;
                            margin-bottom: 10px;
                            line-height: 32px;

                            .title {
                                font-size: 20px;
                                font-weight: 500;
                                letter-spacing: 0px;
                                line-height: 32px;
                                color: rgba(42, 130, 228, 1);
                                text-align: left;
                                vertical-align: top;
                            }

                            .des {
                                font-size: 16px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 32px;
                                color: rgba(56, 56, 56, 1);
                                text-align: left;
                                vertical-align: top;
                                width: calc(100% - 24px);
                            }

                            .img {
                                text-align: center;
                                padding: 10px 0;
                            }
                        }

                        .div_test {
                            width: 24px;
                            padding-top: 10px;

                            .dot {
                                width: 12px;
                                height: 12px;
                                opacity: 1;
                                background: rgba(56, 56, 56, 1);
                                border-radius: 50%;
                                margin-right: 12px;
                            }
                        }
                    }

                    .itemImg {
                        // width: 48%;
                        // height: 200px;
                        // background-color: yellowgreen;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 20.27px;
                        text-align: center;
                        vertical-align: top;
                        // padding-top: 10px;

                        .imgDes {
                            padding-top: 10px;
                            color: rgba(56, 56, 56, 1);
                        }

                        ul {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            li {

                                div {
                                    img {
                                        // width: 100%;
                                    }
                                }

                                .smallDes {
                                    font-size: 10px;
                                    font-weight: 400;
                                    letter-spacing: -1px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                }
                            }
                        }

                        .img2 {
                            padding: 10px;
                        }

                    }
                }

                .textContent1 {
                    display: flex;
                    padding-top: 20px;

                    .text {
                        margin-right: 10px;
                        flex: 1;

                        .item {
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 32px;
                            color: rgba(56, 56, 56, 1);
                            text-align: left;
                            vertical-align: top;

                        }

                        .textTotal {
                            // padding: 15px;
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;

                            .desItem {
                                width: 30%;
                                // padding: 10px;
                                margin: 7px;
                                border-radius: 10px;
                                background: rgba(255, 255, 255, 1);
                                overflow: hidden;

                                .desTitle {
                                    opacity: 1;
                                    background: rgba(42, 130, 228, 1);
                                    padding: 10px;
                                    text-align: center;
                                    font-size: 18px;
                                    font-weight: 500;
                                    letter-spacing: 0px;
                                    line-height: 32px;
                                    color: rgba(255, 255, 255, 1);
                                    vertical-align: top;
                                }

                                .desDes {
                                    font-size: 13px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 0.89);
                                    text-align: justify;
                                    vertical-align: top;
                                    padding: 5px 10px;
                                }
                            }
                        }
                    }

                    .itemImg {
                        text-align: center;
                        flex: 1;

                        ul {
                            li {
                                img {
                                    margin-bottom: 20px;
                                    width: 600px;
                                }

                                .imgDes {
                                    font-size: 12px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                    text-align: center;
                                    vertical-align: top;

                                }
                            }
                        }
                    }

                    .itemImg1 {
                        width: 50%;
                        display: flex;
                        flex-direction: column;

                        .dataList {
                            display: flex;
                            padding-bottom: 20px;

                            .dataItem {
                                width: 85px;
                                height: 85px;
                                opacity: 1;
                                border-radius: 10px;
                                font-size: 16px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 24px;
                                color: rgba(255, 255, 255, 1);
                                text-align: center;
                                vertical-align: top;
                                padding: 15px 10px;
                                margin-right: 32px;


                                .num {
                                    font-size: 24px;
                                    font-weight: 700;
                                    letter-spacing: 0px;
                                    line-height: 30px;
                                }
                            }
                        }

                        .imgData {
                            position: relative;
                            flex: 1;
                            overflow: hidden;

                            .firstImg {
                                position: absolute;
                                top: 0;
                                left: 0;
                            }

                            .secondImg {
                                position: absolute;
                                bottom: 0;
                                right: 0;

                                .des {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                .changeBox {
                    // flex-direction: row;

                    .itemImg {
                        // margin-top: -30px;

                        .imgDes {
                            padding: 5px;
                        }

                        ul {
                            // flex-direction: row;

                        }
                    }
                }

                .diffFlex {
                    flex-direction: row;

                    .text {
                        width: 50%;
                    }

                    .itemImg {
                        width: 50%;
                    }
                }
            }
        }
    }

    .advantages {
        padding-bottom: 150px;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                width: 20%;
                display: flex;
                padding: 10px;
                padding-bottom: 40px;

                img {
                    width: 54px;
                    padding-top: 10px;
                }

                .adContent {
                    margin-left: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 24px;
                    color: #4F4F4F;
                    text-align: justify;
                    vertical-align: top;


                    .advantages_title {
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 40px;
                        color: #383838;
                        text-align: left;
                        vertical-align: top;

                    }
                }
            }
        }
    }

    // .featuresContent {
    //     font-size: 16px;
    //     font-weight: 400;
    //     line-height: 23.17px;
    //     color: rgba(56, 56, 56, 1);
    //     text-align: justify;
    //     vertical-align: top;

    //     .featuresItem {
    //         margin-bottom: 64px;

    //         .featuresIntro {
    //             display: flex;
    //             align-items: center;
    //             font-size: 26px;
    //             line-height: 37.65px;
    //             color: rgba(0, 94, 255, 1);
    //             text-align: left;
    //             margin-bottom: 48px;

    //             span {
    //                 display: block;
    //                 width: 7px;
    //                 height: 30px;
    //                 opacity: 1;
    //                 background: rgba(97, 171, 255, 1);
    //                 margin-right: 20px;
    //             }
    //         }

    //         .itemText {
    //             display: flex;
    //             align-items: center;

    //             .dot {
    //                 width: 12px;
    //                 height: 12px;
    //                 opacity: 1;
    //                 background: rgba(56, 56, 56, 1);
    //                 border-radius: 50%;
    //                 margin-right: 12px;
    //             }
    //         }

    //         .decomposeIntro {
    //             padding-left: 37px;

    //             ul {
    //                 margin-top: 50px;
    //                 display: flex;
    //                 justify-content: space-between;

    //                 li {
    //                     width: 16%;
    //                     font-size: 14px;
    //                     font-weight: 400;
    //                     letter-spacing: 0px;
    //                     line-height: 20.27px;
    //                     color: rgba(166, 166, 166, 1);
    //                     text-align: left;
    //                     vertical-align: top;

    //                     div {
    //                         display: flex;
    //                         justify-content: center;
    //                     }

    //                     img {
    //                         width: 75px;
    //                         margin-bottom: 12px;
    //                     }
    //                 }
    //             }
    //         }

    //         .textContent {
    //             display: flex;
    //             justify-content: space-between;

    //             .text {
    //                 width: 44%;
    //                 // background-color: pink;

    //                 .item {
    //                     display: flex;
    //                     margin-bottom: 35px;
    //                 }

    //                 .div_test {
    //                     width: 24px;
    //                     padding-top: 5px;

    //                     .dot {
    //                         width: 12px;
    //                         height: 12px;
    //                         opacity: 1;
    //                         background: rgba(56, 56, 56, 1);
    //                         border-radius: 50%;
    //                         margin-right: 12px;
    //                     }
    //                 }
    //             }

    //             .itemImg {
    //                 width: 48%;
    //                 // height: 200px;
    //                 // background-color: yellowgreen;
    //                 font-size: 14px;
    //                 font-weight: 400;
    //                 letter-spacing: 0px;
    //                 line-height: 20.27px;
    //                 color: rgba(166, 166, 166, 1);
    //                 text-align: left;
    //                 vertical-align: top;

    //                 ul {
    //                     display: flex;
    //                     justify-content: space-between;

    //                     li {
    //                         display: flex;
    //                         flex-wrap: wrap;

    //                         div {
    //                             width: 100%;
    //                             display: flex;
    //                             justify-content: center;
    //                             margin-bottom: 20px;

    //                             img {
    //                                 width: 100%;
    //                             }
    //                         }

    //                         .color {
    //                             border-bottom: 30px solid rgb(226, 226, 226);
    //                             border-radius: 0 0 10px 10px;
    //                         }

    //                         .color1 {
    //                             box-sizing: border-box;
    //                             border: 5px solid rgb(70, 70, 70);
    //                             border-radius: 10px 10px 0 0;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .systemCompStyle {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;

        .compItems {
            display: flex;
            height: 214px;

            .compItem1 {
                flex: 1;
                background-image: url("../../../assets/image/systemComBg1.png");
                margin-right: 20px;

                .itemTitle1 {
                    font-size: 26px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 37.65px;
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                    vertical-align: top;
                    margin: 30px 0 10px;
                }

                .itemDes {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 23.17px;
                    color: rgba(255, 255, 255, 1);
                    text-align: justify;
                    vertical-align: top;
                    padding: 0 60px;
                }
            }

            .itemTitle {
                font-size: 26px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 37.65px;
                color: rgba(42, 130, 228, 1);
                text-align: center;
                vertical-align: top;
                margin: 30px 0 10px;

            }

            .compItem2 {
                flex: 1;
                background-image: url("../../../assets/image/systemComBg2.png");
                margin-left: 20px;

            }

            .compItem3 {
                flex: 1;
                background-image: url("../../../assets/image/systemComBg3.png");
                margin-right: 20px;
            }

            .compItem4 {
                flex: 1;
                background-image: url("../../../assets/image/systemComBg4.png");
                margin-left: 20px;
            }
        }

        .centerStyle {
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0px;
            line-height: 130px;
            color: rgb(42, 130, 228);
            text-align: center;
            background-image: url("../../../assets/image/systemComBg5.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60% auto;
            position: absolute;
            top: 37%;
            left: 42.3%;
            height: 130px;
            width: 200px;

        }
    }

    .mianCenter {
        width: 1200px;
        margin: 0 auto;
    }

    .darkBg {
        background-image: url(../../../assets/image/labBg.png);
        background-size: 100% 100%;

        .intro {
            .introDes {
                color: #CCCCCC;

            }

            .introText {
                color: #383838;
            }
        }
    }

    .featureBg {
        // background-image: url(../../../assets/image/productBg2.png);
        // background-size: 100% 100%;
        background-color: #152235;
    }
}
</style>