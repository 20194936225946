<template>
    <div class="SoftwareSupport">
        <!-- 会员专享服务 -->
        <!-- 软件更新 -->
        <div class="content softUpdate_content" v-show="showVipContent">
            <div class="softUpdate">
                <div class="softUpdateTitle partTitle">软件更新</div>
                <!-- <div class="softUpdateDes partDes">日常软件升级维护版本更新</div> -->
                <el-tabs v-model="activeSoftName" @tab-click="handleSClick" class="productTabs softPart" stretch>
                    <el-tab-pane label="SummitMap" name="SummitMap">
                        <el-collapse accordion class="collapse">
                            <el-collapse-item v-for="(item, index) in softUpdate_SummitMap" :key="index">
                                <template slot="title">
                                    {{ item.newestversionnumber }}<img style="width:17px;height:17px;margin-left:30px"
                                        src="../../../../assets/image/download.png" alt="" @click="download(item)">
                                </template>
                                <div v-html="item.newestexplain"></div>
                            </el-collapse-item>
                        </el-collapse>
                        <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage1" :page-size="5" :page-count="5"
                            layout="total, prev, pager, next" :total="softUpdate_SummitMap.length" :small="true">
                        </el-pagination> -->
                    </el-tab-pane>
                    <el-tab-pane label="SummitSolid" name="SummitSolid">
                        <el-collapse accordion class="collapse">
                            <el-collapse-item v-for="(item, index) in softUpdate_SummitSolid" :key="index">
                                <template slot="title">
                                    {{ item.newestversionnumber }}<img style="width:17px;height:17px;margin-left:30px"
                                        src="../../../../assets/image/download.png" alt="">
                                </template>
                                <div v-html="item.newestexplain"></div>
                            </el-collapse-item>
                        </el-collapse>
                        <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage1" :page-size="5" :page-count="5"
                            layout="total, prev, pager, next" :total="softUpdate_SummitSolid.length" :small="true">
                        </el-pagination> -->
                    </el-tab-pane>
                    <el-tab-pane label="SummitLab" name="SummitLab">
                        <el-collapse accordion class="collapse">
                            <el-collapse-item v-for="(item, index) in softUpdate_SummitLab" :key="index">
                                <template slot="title">
                                    {{ item.newestversionnumber }}<img style="width:17px;height:17px;margin-left:30px"
                                        src="../../../../assets/image/download.png" alt="">
                                </template>
                                <div v-html="item.newestexplain"></div>
                            </el-collapse-item>
                        </el-collapse>
                        <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage1" :page-size="5" :page-count="5"
                            layout="total, prev, pager, next" :total="softUpdate_SummitLab.length" :small="true">
                        </el-pagination> -->
                    </el-tab-pane>

                </el-tabs>
                <!-- <ul>
                    <li>
                        <div class="li_name">SummitMap</div>
                        <div class="line"></div>

                    </li>
                    <li>
                        <div class="li_name">SummitSolid</div>
                        <div class="line"></div>

                    </li>
                    <li>
                        <div class="li_name">SummitLab</div>
                        <div class="line"></div>

                    </li>
                </ul> -->
            </div>
        </div>
        <!-- 教学视频 -->
        <div class="content">
            <div class="teachingVideos">
                <div class="videoTitle partTitle">教学视频</div>
                <!-- <div class="videoDes partDes">Summit系列软件教学视频</div> -->
                <el-tabs v-model="activeName" @tab-click="handleClick" class="productTabs" stretch>
                    <el-tab-pane label="SummitMAP" name="SummitMap">
                        <!-- <el-carousel indicator-position="outside" :autoplay="false" @change="changeLoop"> -->
                        <!-- <el-carousel-item v-for="items in videoListNum" :key="items"> -->
                        <ul>
                            <li v-for="(item, index) in loopVideoList" :key="index">
                                <div class="playVideo">
                                    <VideoPlayer :videosrc="item.videosrc"></VideoPlayer>
                                </div>
                                <div class="videoInfo">
                                    <div class="video_li_name">{{ item.descrh4 }}</div>
                                    <div class="publisher">
                                        <div class="publisher_info">
                                            <div class="avatar">
                                                <!-- <img src="../../../../assets/image/video_avatar.png" alt=""> -->
                                                <!-- <span>管理员</span> -->
                                                <span style="margin-right:8px;">2022-07-13</span>

                                            </div>
                                        </div>
                                        <div class="publish_time">
                                            <!-- <span>08:34:50</span> -->
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- </el-carousel-item> -->
                        <!-- </el-carousel> -->
                    </el-tab-pane>
                    <el-tab-pane label="SummitSOLID" name="SummitSolid">
                        <!-- <el-carousel indicator-position="outside" :autoplay="false" @change="changeLoop"> -->
                        <!-- <el-carousel-item v-for="items in videoListNum" :key="items"> -->
                        <ul>
                            <li v-for="(item, index) in loopVideoList" :key="index">
                                <div class="playVideo">
                                    <VideoPlayer :videosrc="item.videosrc"></VideoPlayer>
                                </div>
                                <div class="videoInfo">
                                    <div class="video_li_name">{{ item.descrh4 }}</div>
                                    <div class="publisher">
                                        <div class="publisher_info">
                                            <div class="avatar">
                                                <!-- <img src="../../../../assets/image/video_avatar.png" alt=""> -->
                                                <span style="margin-right:8px;">2022-07-13</span>

                                                <!-- <span>管理员</span> -->
                                            </div>
                                        </div>
                                        <div class="publish_time">
                                            <!-- <span>08:34:50</span> -->
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- </el-carousel-item> -->
                        <!-- </el-carousel> -->
                    </el-tab-pane>
                    <el-tab-pane label="SummitLAB" name="SummitLab">
                        <!-- <el-carousel indicator-position="outside" :autoplay="false" @change="changeLoop"> -->
                        <!-- <el-carousel-item v-for="items in videoListNum" :key="items"> -->
                        <ul>
                            <li v-for="(item, index) in loopVideoList" :key="index">
                                <div class="playVideo">
                                    <VideoPlayer :videosrc="item.videosrc"></VideoPlayer>
                                </div>
                                <div class="videoInfo">
                                    <div class="video_li_name">{{ item.descrh4 }}</div>
                                    <div class="publisher">
                                        <div class="publisher_info">
                                            <div class="avatar">
                                                <!-- <img src="../../../../assets/image/video_avatar.png" alt="">
                                                <span>管理员</span> -->
                                                <span style="margin-right:8px;">2022-07-13</span>
                                            </div>
                                        </div>
                                        <div class="publish_time">
                                            <!-- <span>08:34:50</span> -->
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- </el-carousel-item> -->
                        <!-- </el-carousel> -->
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- 增值服务 -->
        <div class="valueAdd" v-show="showVipContent">
            <div class="valueadd_main">
                <div class="valueaddTitle partTitle">文档资料</div>
                <!-- <div class="valueaddDes partDes">会员专享增值服务</div> -->
                <!-- <div class="relatedInformation">
                    <div>相关信息</div>
                    <div class="line"></div>
                </div> -->
                <ul>
                    <!-- <li>
                        <div class="tableHeader">
                            <div class="softName">软件名称</div>
                            <div class="version">版本号</div>
                            <div class="sofrDes">软件说明</div>
                            <div class="download">网盘下载</div>
                        </div>
                    </li> -->
                    <!-- <li v-for="(item, index) in valueAddList" :key="index">
                        <div class="tableBody" :class="{ 'tableBody_expand': item.isExpand }">
                            <div class="softName">{{ item.softwarename }}</div>
                            <div class="version">{{ item.versionnumber }}</div>
                            <div class="sofrDes" @click="showDes(item.id)">
                                <span>{{ item.softwareexplain }}</span>
                                <img :src="item.isExpand ? require('../../../../assets/image/hide.png') : require('../../../../assets/image/show.png')"
                                    alt="">
                            </div>
                            <div class="download">
                                <img src="../../../../assets/image/download.png" alt="" @click="download(item)">
                            </div>
                        </div>
                        <div class="detail" v-if="item.isExpand">
                            <div class="detail_div">
                                <span class="detail_name">软件名称：</span>
                                <span>{{ item.softwarename }}</span>
                            </div>
                            <div class="detail_div">
                                <span class="detail_name">版本号：</span>
                                <span>{{ item.versionnumber }}</span>
                            </div>
                            <div class="detail_div">
                                <span class="detail_name">软件说明： </span>
                                <span>{{ item.softwareexplain }}</span>
                            </div>
                        </div>
                    </li> -->
                    <li v-for="(item, index) in valueAddList" :key="index">
                        <div class="softName">{{ item.softwarename }}</div>
                        <div class="softDes">{{ item.softwareexplain }}</div>
                        <div class="softBtm">

                            <div class="version">{{ item.versionnumber }}</div>
                            <div class="download">
                                <img src="../../../../assets/image/download.png" alt="" @click="download(item)">
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>


    </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
import { getvipappreciation, getvipsoftware, getvideo } from '@/assets/API/pay';
export default {
    components: {
        VideoPlayer
    },
    data() {
        return {
            valueAddList: [],
            activeName: "SummitMap",
            activeSoftName: "SummitMap",
            currentPage1: 1,
            videoList: [],
            loopVideoList: [],
            videoListNum: 0,
            softUpdate_SummitMap: [],
            softUpdate_SummitSolid: [],
            softUpdate_SummitLab: [],
            showVipContent: false
        };
    },
    mounted() {
        this.getValueAddList();
        this.getSoftUpdateList();
        this.getVideoList('map');
        if (this.$store.state.user.isLogin) {
            // 登录
            let userInfo = this.$store.state.user.userInfo;
            if (userInfo.userLeval == 2 || userInfo.vippermission == 1) {
                this.showVipContent = true;
            }
        }
    },
    methods: {
        getValueAddList() {
            getvipappreciation().then(res => {
                let list = res.data.list;
                list.forEach(item => {
                    item.isExpand = false;
                });
                this.valueAddList = list;
            });
        },
        showDes(id) {
            this.valueAddList.forEach(item => {
                if (item.id != id) {
                    item.isExpand = false;
                } else {
                    item.isExpand = !item.isExpand;
                }
            });
        },
        // 下载
        download(data) {
            // &pwd=kuab
            let url = data.baidudown + "&pwd=" + data.tiqu;
            window.open(url, '_blank');
        },
        getSoftUpdateList() {
            getvipsoftware().then(res => {
                let list = res.data.list;
                let SummitMap = [];
                let SummitSolid = [];
                let SummitLab = [];
                list.forEach(item => {
                    let newStr = item.newestexplain.replace("\n", "<br/>");
                    item.newestexplain = newStr;
                    if (item.newestname == "SummitSolid") {
                        SummitSolid.push(item);
                    } else if (item.newestname == "SummitMap") {
                        SummitMap.push(item);
                    } else if (item.newestname == "SummitLab") {
                        SummitLab.push(item);
                    }
                });
                this.softUpdate_SummitMap = SummitMap;
                this.softUpdate_SummitSolid = SummitSolid;
                this.softUpdate_SummitLab = SummitLab;
            });
        },
        getVideoList(data) {
            getvideo(data).then(res => {
                this.videoList = res.data.list;
                // if (this.videoList.length % 6 == 0) {
                //     this.videoListNum = Number(this.videoList.length / 6);
                // } else {
                //     this.videoListNum = parseInt(this.videoList.length / 6) + 1;
                // }
                this.loopVideoList = this.videoList.slice(0, 6);
            });
        },
        changeLoop(value) {
            this.loopVideoList = this.videoList.slice(value * 6, (value + 1) * 6);
        },
        handleClick(e, event) {
            this.getVideoList(e.name);
        },
        handleSClick(e, event) {

        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        }
    }

};
</script>

<style scoped lang="scss" scoped>
.SoftwareSupport {
    padding-top: 60px;
    background: rgba(247, 248, 251, 1);

    .banner {
        width: 100vw;
        height: 628px;
        background-image: url(../../../../assets/image/SoftwareSupport_banner.png);
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .content {
        width: 100vw;
        display: flex;
        justify-content: center;
        background: rgba(255, 255, 255, 1);
    }

    .partTitle {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        vertical-align: middle;
        padding-bottom: 50px;
    }

    .partDes {
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        color: rgba(105, 107, 128, 1);
        text-align: center;
    }

    .member {
        width: 1200px;
        padding-top: 141px;
        display: flex;
        margin-bottom: 143px;

        .member_img {
            width: 659px;
            height: 544px;
            background-image: url(../../../../assets/image/member_img.png);
            background-size: 100%;
            background-repeat: no-repeat;
        }

        .member_service {
            width: 526px;
            letter-spacing: 0px;
            text-align: left;
            vertical-align: top;

            .service_title {
                font-size: 32px;
                font-weight: 700;
                line-height: 64px;
                color: rgba(0, 119, 255, 1);
                margin-bottom: 6px;
            }

            .serviseDes {
                font-size: 22px;
                font-weight: 400;
                line-height: 32px;
                color: rgba(105, 107, 128, 1);
                margin-bottom: 46px;
            }

            ul {
                width: 479px;

                li {
                    display: flex;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 32px;
                    color: rgba(105, 107, 128, 1);
                    margin-bottom: 56px;

                    .service_img {
                        width: 80px;
                        height: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 23px;

                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .li_name {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 30px;
                        color: rgba(0, 119, 255, 1);
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .valueAdd {
        width: 100vw;
        min-height: 750px;
        background-image: url(../../../../assets/image/valueAdd_bg.png);
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;

        .valueadd_main {
            width: 1200px;
            letter-spacing: 0px;
            vertical-align: top;
            padding-top: 85px;


            .valueaddDes {
                margin-bottom: 42px;
            }

            .relatedInformation {
                font-size: 22px;
                font-weight: 500;
                line-height: 48px;
                color: rgba(0, 119, 255, 1);
                text-align: left;

                .line {
                    width: 80px;
                    height: 2px;
                    background-color: rgba(0, 119, 255, 1);
                }
            }

            ul {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                // li {
                //     width: 100%;
                //     font-size: 16px;
                //     font-weight: 400;
                //     letter-spacing: 0px;
                //     line-height: 22px;
                //     color: rgba(114, 115, 132, 1);
                //     text-align: left;
                //     vertical-align: top;
                //     padding-top: 20px;
                //     padding-bottom: 24px;
                //     border-bottom: 1px solid rgba(232, 232, 232, 1);

                //     .tableHeader,
                //     .tableBody {
                //         width: 100%;
                //         display: flex;
                //     }

                //     .tableBody_expand {
                //         color: rgba(0, 119, 255, 1);
                //     }

                //     .softName {
                //         width: 25%;
                //     }

                //     .version {
                //         width: 25%;
                //     }

                //     .sofrDes {
                //         flex: 1;
                //         cursor: pointer;
                //         display: flex;
                //         align-items: center;

                //         span {
                //             width: 336px;
                //             white-space: nowrap;
                //             overflow: hidden;
                //             text-overflow: ellipsis;
                //         }


                //         img {
                //             width: 12px;
                //             height: 7px;
                //             margin-left: 20px;
                //         }
                //     }

                //     .download {
                //         width: 10%;
                //         display: flex;
                //         justify-content: center;
                //         align-items: center;

                //         img {
                //             width: 20px;
                //             height: 20px;
                //             cursor: pointer;
                //         }
                //     }

                //     .detail {
                //         font-size: 16px;
                //         font-weight: 400;
                //         color: rgba(114, 115, 132, 1);
                //         text-align: left;
                //         margin-top: 24px;

                //         .detail_div {
                //             margin-bottom: 13px;
                //             text-align: justify;
                //         }

                //         .detail_name {
                //             line-height: 22px;
                //             color: rgba(56, 56, 56, 1);
                //         }
                //     }
                // }

                li {
                    width: 28%;
                    height: 190px;
                    opacity: 1;
                    border-radius: 8px;
                    background: rgba(255, 255, 255, 1);
                    box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);
                    margin-bottom: 37px;
                    padding: 26px;
                    display: flex;
                    flex-direction: column;

                    .softName {
                        font-size: 20px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 22px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: top;
                        padding-bottom: 18px;
                    }

                    .softDes {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 22px;
                        color: rgba(114, 115, 132, 1);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;
                        white-space: nowrap;
                    }

                    .softBtm {
                        // align-self: flex-end;
                        margin-top: auto;
                        display: flex;
                        border-top: 1px solid rgba(229, 229, 229, 1);
                        padding-top: 17px;
                        justify-content: space-between;

                        .version {
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 22px;
                            color: rgba(114, 115, 132, 1);
                            text-align: left;
                            vertical-align: top;
                        }

                        .download {
                            img {
                                width: 28px;
                                height: 28px;
                            }
                        }
                    }
                }

                li:hover {
                    border: 1px solid rgba(43, 133, 229, 1);
                    // background-color: rgba(232, 232, 232, 0.3);
                }
            }
        }
    }

    .productTabs {
        ::v-deep .el-tabs__header {
            margin-bottom: 64px;
        }

        ::v-deep .el-tabs__nav-scroll {
            display: flex;
            justify-content: center;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            width: 0;
        }

        ::v-deep .el-tabs__nav.is-stretch {
            min-width: 50%;
        }

        ::v-deep .el-tabs__active-bar {
            display: none;
        }

        ::v-deep .el-tabs__item {
            // font-size: 18px;
            // font-weight: 400;
            // letter-spacing: 0px;
            // line-height: 32px;
            // color: rgba(128, 128, 128, 1);
            // text-align: left;
            opacity: 1;
            border-radius: 14px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 53.47%, rgba(255, 255, 255, 0.01) 100%);
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 34.75px;
            color: rgba(56, 56, 56, 1);
            text-align: center;
            vertical-align: middle;
            padding: 0 10px !important;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);

            margin: 0 20px;
        }

        ::v-deep .is-active {
            color: rgba(42, 130, 228, 1);
        }

        ::v-deep .is-top.is-active {
            border-left: 2px solid;
            border-image: linear-gradient(180deg, rgba(43, 133, 229, 1) 0%, rgba(43, 133, 229, 0) 100%) 1;
        }

        .el-carousel__item h3 {
            color: #475669;
            font-size: 18px;
            opacity: 0.75;
            line-height: 300px;
            margin: 0;
        }

        // .el-carousel__item:nth-child(2n) {
        //     background-color: #99a9bf;
        // }

        // .el-carousel__item:nth-child(2n+1) {
        //     background-color: #d3dce6;
        // }

        ::v-deep .el-carousel__container {
            min-height: 700px;
        }

        ::v-deep .el-carousel__indicators--outside {
            display: none;
        }
    }


    .teachingVideos {
        width: 1200px;
        padding-top: 85px;
        // margin-bottom: 73px;

        .videoTitle {
            text-align: center;
        }

        .videoDes {
            text-align: center;
            margin-bottom: 43px;
        }



        ul {
            width: 100%;
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;

            li {
                width: 31.5%;
                background: #fff;
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
                margin-bottom: 30px;
                margin-right: 16px;

                .playVideo {
                    height: 210px;
                    background-color: #000;
                }

                .videoInfo {
                    padding: 15px 30px 23px 15px;

                    .video_li_name {
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 30px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: top;
                        margin-bottom: 10px;
                        border-bottom: 1px solid rgba(227, 227, 227, 1);
                        padding-bottom: 10px;
                    }

                    .publisher {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 22px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;

                        .avatar {
                            display: flex;
                            align-items: center;

                            img {
                                width: 30px;
                                height: 30px;
                                margin-right: 5px;
                                border-radius: 50%;
                                border: 0.5px solid rgb(229, 229, 229, 1);
                                box-shadow: 0px 0px 6px rgb(42, 130, 228, 1);
                            }
                        }

                        .publish_time {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .softUpdate_content {
        background: rgba(247, 248, 251, 1);
    }

    .softUpdate {
        width: 885px;
        padding: 79px 0 167px 0;

        ::v-deep .el-collapse-item {
            margin-bottom: 17px;
            border-radius: 10px;

        }

        ::v-deep .el-collapse-item__header {
            height: 54px;
            opacity: 1;
            border-radius: 10px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);
            padding: 0 26px;

            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 28.96px;
            color: rgba(56, 56, 56, 1);


        }

        ::v-deep .el-collapse-item__content {

            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgba(114, 115, 132, 1);
            text-align: left;
            vertical-align: top;
            padding: 10px 26px;

        }

        .softUpdateDes {
            margin-bottom: 50px;
        }

        ul {
            width: 100%;
            display: flex;
            justify-content: space-between;

            li {
                width: 33%;
                // background-color: rgb(204, 238, 226);
                padding-left: 15px;

                .li_name {
                    font-size: 22px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 48px;
                    color: rgba(0, 119, 255, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .line {
                    width: 80.47px;
                    height: 2px;
                    opacity: 1;
                    background: rgba(0, 119, 255, 1);
                    margin-bottom: 10px;
                }

                .collapse {
                    margin-bottom: 50px;
                }

                ::v-deep .el-collapse-item__header {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 22px;
                    color: rgba(114, 115, 132, 1);
                    text-align: left;
                    vertical-align: top;
                    background: none;
                }

                ::v-deep .el-collapse-item__content {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 22px;
                    color: rgba(114, 115, 132, 1);
                    text-align: left;
                    vertical-align: top;
                    padding-right: 10px;
                }

                ::v-deep .el-collapse-item__wrap {
                    background: none;
                }

                ::v-deep .el-pager li {
                    background: none;
                }

                ::v-deep .el-pagination button:disabled {
                    background: none;
                }

                ::v-deep .el-pagination .btn-next,
                .el-pagination .btn-prev {
                    background: none;
                }
            }
        }
    }
}

.softPart {
    ::v-deep .el-tabs__content {
        max-height: 500px;
        overflow-y: scroll;
    }
}
</style>