<template>
    <div class="block_center">
        <div class="banner">
            <div class="usTitle">
                <div class="titleText">服务支持</div>
                <div class="titleDes">SUPPORT SERVICE</div>
            </div>
        </div>
        <div class="supportList" v-show="totalShow">
            <div class="supportItem" v-for="(item, index) in supportList" :key="item.value">
                <div class="imgPart" v-show="index == 0 || index == 2">
                    <div class="supportImg" :class="index == 0 ? 'indexBackground' : 'index2Background'">
                    </div>
                    <img :src="item.imgSrc" alt="">
                </div>
                <div class="supportRight" v-show="index == 0 || index == 2">
                    <div class="supportText">
                        <div class="supportTitle">{{ item.value }}</div>
                        <div class="supportDes">{{ item.des }}</div>

                    </div>
                    <div class="listContetn">
                        <div class="listItem" v-for="i in item.list" :key="i.value" @click="toMore(i.value)">{{ i.title
                            }}</div>
                    </div>
                </div>
                <div class="supportRight sqRight" v-show="index == 1">
                    <div class="supportText">
                        <div class="supportTitle">{{ item.value }}</div>
                        <div class="supportDes">{{ item.des }}</div>

                    </div>
                    <div class="listContetn">
                        <div class="listItem" v-for="i in item.list" :key="i.value" @click="toMore(i.value)">{{ i.title
                            }}</div>
                    </div>
                </div>

                <div class="supportImgRight" v-show="index == 1">
                    <img :src="item.imgSrc" alt="">
                </div>
            </div>
        </div>
        <div v-show="softwareSupportShow">
            <div class="guideContent">
                <div class="guide">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item @click.native="showTotal">服务支持</el-breadcrumb-item>
                        <el-breadcrumb-item>产品支持</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <SoftwareSupport></SoftwareSupport>
        </div>
        <!-- <div class="guide">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>服务支持</el-breadcrumb-item>
                <el-breadcrumb-item>产品支持</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->
        <div v-show="communicateShow">
            <div class="guideContent">
                <div class="guide">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item @click.native="showTotal">服务支持</el-breadcrumb-item>
                        <el-breadcrumb-item>问题交流</el-breadcrumb-item>
                        <el-breadcrumb-item>常见问题</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <Communicate></Communicate>
        </div>
        <div v-show="contactUsShow">
            <div class="guideContent">
                <div class="guide">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item @click.native="showTotal">服务支持</el-breadcrumb-item>
                        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <ContactUs></ContactUs>
        </div>
    </div>
</template>

<script>
import AccountSupportDetail from './AccountSupport/AccountSupportDetailComponent.vue';
import Communicate from './Communicate/CommunicateComponent.vue';
import ContactUs from './ContactUs/ContactUsComponent.vue';
import SoftwareSupport from './SoftwareSupport/SoftwareSupportComponent.vue';
export default {
    components: {
        SoftwareSupport, Communicate, ContactUs
    },
    data() {
        return {
            supportList: [
                {
                    value: '产品支持',
                    des: '了解软件特点及时查看更新和学习资源',
                    imgSrc: require('../../../assets/image/supportIcon1.png'),
                    list: [
                        {
                            value: 'update',
                            title: '软件更新'
                        },
                        {
                            value: 'video',
                            title: '教学视频'
                        },
                        {
                            value: 'word',
                            title: '文档资料'
                        },
                    ]
                },
                {
                    value: '问题交流',
                    des: '查找有关软件的常见问题解答',
                    imgSrc: require('../../../assets/image/supportIcon2.png'),
                    list: [
                        {
                            value: 'problem',
                            title: '常见问题'
                        },
                    ]
                },
                {
                    value: '联系我们',
                    des: '与相关负责技术人员进行联系',
                    imgSrc: require('../../../assets/image/supportIcon3.png'),
                    list: [
                        {
                            value: 'wechat',
                            title: '企业微信'
                        },
                        {
                            value: 'phone',
                            title: '联系电话'
                        },
                        {
                            value: 'address',
                            title: '公司地址'
                        },
                        {
                            value: 'email',
                            title: '电子邮箱'
                        },
                    ]
                },
            ],
            totalShow: true,
            softwareSupportShow: false,
            communicateShow: false,
            contactUsShow: false
        };
    },
    mounted() {
        const { type } = this.$route.query;
        if (type) {
            this.activeItem = type - 1;
        }
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                console.log(newVal, 'iiiiii');
                switch (true) {
                    case ["/Service"].indexOf(newVal.path) != -1:
                        if (newVal.query.type) {
                            // this.getProduct("SummitMap");
                            // this.activeItem = newVal.query.type - 1;
                            this.toMore(newVal.query.type);
                        } else {
                            this.totalShow = true
                        }
                        break;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        toSoftwareSupport() {
            this.$router.push({ name: "软件支持", path: "/SoftwareSupport" });
            // this.activeItem = 0;
        },
        toMore(value) {
            if (value == 'update' || value == 'video' || value == 'word') {
                // this.toSoftwareSupport();
                this.totalShow = false;
                this.softwareSupportShow = true;
            } else if (value == 'problem') {
                // this.totalShow = true;
                window.open('https://summitek.freshdesk.com/support/home');

            } else {
                this.totalShow = false;
                this.contactUsShow = true;
            }
        },
        toCommu() {
            // this.$router.push({ name: "问题交流", path: "/Communicate" });
            // this.activeItem = 1;
            window.open('https://summitek.freshdesk.com/support/home');
        },
        toAccountSupport() {
            // this.$router.push({ name: "账号支持", path: "/AccountSupport" });
            // this.activeItem = 2;
            window.open('https://summitek.freshdesk.com/support/home');
        },
        toContactUs() {
            // this.$router.push({ name: "联系我们", path: "/ContactUs" });
            this.activeItem = 3;
        },
        showTotal() {
            this.totalShow = true;
            this.softwareSupportShow = false;
            this.communicateShow = false;
            this.contactUsShow = false;
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.mainCenter {
    width: 1200px;
    margin: 0 auto;
}

.banner {
    // background-image: url('../../../assets/image/serviceBg1.png');
    // height: 480px;
    background-image: url('../../../assets/image/服务支持Banner.png');
    height: 628px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    // justify-content: center;

    .usTitle {
        padding-bottom: 265px;
        padding-left: 350px;

        .titleText {
            font-size: 48px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 69.5px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
        }

        .titleDes {
            font-size: 40px;
            font-weight: 200;
            letter-spacing: 0px;
            line-height: 57.92px;
            color: rgba(35, 173, 222, 1);
            text-align: left;
            vertical-align: top;
        }
    }



}

.supportList {
    padding: 60px 314px;

    .supportItem {
        display: flex;
        justify-content: space-between;

        .imgPart {
            position: relative;

            img {
                position: absolute;
                top: 0px;
                left: 15%;
                width: 300px;

            }
        }

        .supportImg {
            width: 450px;
            height: 416px;
            opacity: 1;
            border-radius: 6px;
            transform-origin: top left;
            transform: perspective(100px) rotateX(-3deg);
            text-align: center;
        }

        .indexBackground {
            background: linear-gradient(81deg, rgba(167, 227, 252, 1) 0%, rgba(169, 227, 252, 0) 100%);
        }

        .index2Background {
            background: linear-gradient(81deg, rgba(133, 183, 237, 1) 0%, rgba(132, 184, 240, 0) 100%);
        }

        .supportImgRight {
            width: 450px;
            height: 416px;
            opacity: 1;
            border-radius: 6px;
            background: linear-gradient(238deg, rgba(167, 227, 252, 1) 0%, rgba(169, 227, 252, 0) 100%);
            background: linear-gradient(221.16deg, rgba(154, 222, 230, 1) 0%, rgba(155, 222, 231, 0) 100%);
            transform-origin: top right;
            transform: perspective(100px) rotateX(-3deg);
            text-align: center;

            img {
                position: absolute;
                top: 0px;
                right: 15%;
                width: 300px;
                // height: 300px;
            }
        }

        .supportRight {
            flex: 1;
            // background: rgba(212, 214, 217, 0.2);
            margin: 14px 0 100px;
            margin-left: -108px;
            // border: 1px solid;
            // box-shadow: 0 0 10px rgba(212, 214, 217, 0.4);
            box-shadow: 4px 0 8px -2px rgba(212, 214, 217, 0.4);

            .supportText {
                border-radius: 6px;
                opacity: 1;
                background: rgb(255, 255, 255);
                padding: 20px 150px;
                padding-left: 200px;

                .supportTitle {
                    font-size: 40px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 57.92px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .supportDes {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 26.06px;
                    color: rgba(128, 128, 128, 1);
                    text-align: left;
                    vertical-align: top;
                }


            }

            .listContetn {
                overflow: hidden;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                color: rgba(56, 56, 56, 1);
                text-align: left;
                vertical-align: middle;

                .listItem {
                    height: 50px;
                    padding-left: 200px;
                    line-height: 50px;
                }

                .listItem:hover {
                    background: linear-gradient(90deg, rgba(7, 145, 192, 0) 0%, rgba(7, 145, 192, 1) 34.3%, rgba(7, 145, 192, 0) 100%);
                    color: #fff;
                }
            }
        }

        .sqRight {
            margin-left: 0px;
            margin-right: -108px;
            box-shadow: -4px 0 8px -2px rgba(212, 214, 217, 0.4);
        }
    }
}

.guideContent {
    background: rgba(247, 248, 251, 1);
}

.guide {
    padding-top: 60px;
    justify-content: flex-start !important;
    width: 1200px;
    margin: 0 auto;
    background: rgba(247, 248, 251, 1);

}
</style>