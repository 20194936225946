<template>
    <div class="block_center">
        <div class="PersonalCenter">
            <div class="PersonalCenter_tab">
                <div class="personInfo">
                    <div class="avatar">
                        <img src="../../../assets/image/avatar.png" alt="">
                    </div>
                    <div class="phone">{{ userInfo.username }}</div>
                </div>
                <ul class="addRedDot">
                    <li v-for="(item, index) in tabList" :key="index"
                        :class="[item.checked ? 'checked' : '', isRedDot(index) ? 'redDot' : '', index == 2 ? 'borderItem' : '']"
                        @click="selectTab(item)">
                        <img :src="item.checked ? item.imgActiveSrc : item.imgSrc" alt="">
                        {{ item.title }}
                    </li>
                </ul>
            </div>
            <div class="PersonalCenter_content">
                <div v-for="(item, index) in tabList" :key="index" class="PersonalCenter_content_div">
                    <template>
                        <component :is="item.component" v-if="item.checked" @toApply="toApply"></component>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import MemberCenter from "./MemberCenter.vue";
import OrderManagement from "./OrderManagement.vue";
import ContractManagement from "./ContractManagement.vue";
import InvoiceManagement from "./InvoiceManagement.vue";
import Authentication from "./Authentication.vue";
import LicenseApply from "./LicenseApply.vue";
export default {
    components: {
        MemberCenter,
        OrderManagement,
        ContractManagement,
        InvoiceManagement,
        Authentication,
        LicenseApply
    },
    computed: {
        ...mapState("user", ["isLogin", "userInfo"]),

    },
    data() {
        return {
            tabList: [
                // {
                //     title: "会员中心",
                //     checked: true,
                //     name: "MemberCenter",
                //     component: MemberCenter
                // },
                {
                    title: "订单管理",
                    checked: false,
                    component: OrderManagement,
                    imgSrc: require('../../../assets/image/订单管理1.png'),
                    imgActiveSrc: require('../../../assets/image/订单管理2.png'),
                },
                {
                    title: "许可证申请",
                    checked: false,
                    component: LicenseApply,
                    imgSrc: require('../../../assets/image/许可证申请1.png'),
                    imgActiveSrc: require('../../../assets/image/许可证申请2.png'),
                },
                // {
                //     title: "合同管理",
                //     checked: false,
                //     component: ContractManagement
                // },
                {
                    title: "发票管理",
                    checked: false,
                    component: InvoiceManagement,
                    imgSrc: require('../../../assets/image/发票管理1.png'),
                    imgActiveSrc: require('../../../assets/image/发票管理2.png'),
                },
                {
                    title: "用户认证",
                    checked: false,
                    component: Authentication,
                    imgSrc: require('../../../assets/image/用户认证1.png'),
                    imgActiveSrc: require('../../../assets/image/用户认证2.png'),
                },
                {
                    title: "退出",
                    checked: false,
                    imgSrc: require('../../../assets/image/退出1.png'),
                    imgActiveSrc: require('../../../assets/image/退出2.png'),
                },

            ],
            activeName: 'first',
        };
    },
    mounted() {
        // 判断是否登录 未登录跳转登录页
        if (!this.$store.state.user.token) {
            this.$router.replace({
                name: "Login",
                path: "/login",
            });
        }
        // 跳转参数接收
        const { value } = this.$route.query;
        if (value == 'license') {
            setTimeout(() => {
                this.selectTab(this.tabList[1]);
            }, 100);
        } else {
            if (typeof value == 'number') {
                this.selectTab(this.tabList[value])
            }
        }
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                switch (true) {
                    case ["/PersonalCenter"].indexOf(newVal.path) != -1:
                        if (typeof newVal.query.value == 'number') {
                            this.selectTab(this.tabList[newVal.query.value])
                        }
                        break;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        selectTab(row) {
            if (row.title != '退出') {
                this.tabList.forEach(item => {
                    item.checked = false;
                });
                row.checked = true;
                const index = this.tabList.findIndex(val => (val.title === row.title));
                this.tabList.splice(index, 1, row);
            } else {
                this.logout()
            }

        },
        async logout() {
            await this.$store.dispatch("user/logout");
            localStorage.removeItem("USERINFO");
            // 退出时保持本页面
            this.$router.push("/Home");
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        toApply() {
            this.selectTab(this.tabList[1]);
        },
        isRedDot(index) {
            // 判断用户是否已经认证
            console.log(this.userInfo, index, '____');
            if (index == this.tabList.length - 2) {
                if (!this.userInfo.companysocialcreditcode || !this.userInfo.companyname) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }

        }
    }
};
</script>

<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;
    background: rgba(243, 245, 249, 1);
}

.PersonalCenter {
    width: 100%;
    height: calc(100vh - 60px);
    border-top: 1px solid rgba(229, 229, 229, 1);
    display: flex;

    .PersonalCenter_tab {
        width: 20%;
        border-right: 1px solid rgba(229, 229, 229, 1);
        background: #fff;
        height: 556px;
        opacity: 1;
        border-radius: 10px;
        box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);
        margin: 30px 25px;

        .personInfo {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 42px;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            color: rgba(0, 0, 0, 1);

            .avatar {
                width: 80px;
                height: 80px;
                border-radius: 10px;
                background: rgba(213, 231, 250, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-right: 30px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .phone {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 28px;
                color: rgba(0, 0, 0, 1);
                text-align: center;
                vertical-align: top;
                padding-top: 8px;
            }
        }

        ul {
            // padding: 0 10px;

            li {
                padding: 20px;
                font-size: 20px;
                font-weight: 100;
                line-height: 33.6px;
                color: rgba(0, 0, 0, 1);
                cursor: pointer;
                text-align: center;
                display: flex;
                align-items: center;
                // justify-content: center;

                img {
                    width: 18px;
                    height: 18px;
                    margin-left: 20px;
                    margin-right: 7px;
                }
            }

            .checked {
                font-weight: 600;
                // border-radius: 8px;
                // background: rgba(240, 247, 255, 1);
                background: linear-gradient(90deg, rgba(43, 133, 229, 0) 0%, rgba(43, 133, 229, 0.19) 100%);
                border-right: 3px solid rgba(43, 133, 229, 1);

                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 28px;
                color: rgba(43, 133, 229, 1);
                vertical-align: top;

            }

            .borderItem {
                border-bottom: 1px solid rgba(229, 229, 229, 1);
            }
        }
    }

    .PersonalCenter_content {
        width: 75%;
        // flex: 1;

        .PersonalCenter_content_div {
            background: rgba(243, 245, 249, 1);
        }
    }
}

.redDot::before {

    content: '*';
    color: #F56C6C;
}
</style>