<template>
    <div class="block_center">
        <div class="ContactUs">
            <div class="writeContactInfo">
                <div class="stayContact">留下您的联系方式</div>
                <input type="text" placeholder="姓名" v-model="form.name">
                <input type="text" placeholder="邮箱/电话" v-model="form.contact">
                <el-input type="textarea" rows="4" placeholder="描述您的问题" v-model="form.description"></el-input>
                <el-button round @click="saveMes">保存</el-button>
            </div>
            <div class="contactWays">
                <div class="contact_way">
                    <div class="img_div">
                        <img src="../../../../assets/image/WorkingGroup.png" alt="">
                    </div>
                    <p class="englist_title work">联系我们</p>
                    <!-- <p class="title">Contact Us</p> -->
                    <!-- <p v-for="item in workGroup" :key="item.id">QQ：{{ item.content }}</p> -->
                    <img src="../../../../assets/image/companyContact.jpg" style="width: 100%;height: 170px;" />

                </div>
                <div class="contact_way">
                    <div class="img_div">
                        <img src="../../../../assets/image/Phone.png" alt="">
                    </div>
                    <p class="title"> </p>
                    <p class="englist_title">Phone</p>
                    <p v-for="item in phoneList" :key="item.id">{{ item.content }}</p>
                </div>
                <div class="contact_way">
                    <div class="img_div">
                        <img src="../../../../assets/image/Address.png" alt="">
                    </div>
                    <p class="title"> </p>
                    <p class="englist_title">Address</p>
                    <p v-for="item in addressList" :key="item.id">{{ item.content }}</p>
                </div>
                <div class="contact_way">
                    <div class="img_div">
                        <img src="../../../../assets/image/Email.png" alt="">
                    </div>
                    <p class="title"> </p>
                    <p class="englist_title">Email</p>
                    <p v-for="item in emailList" :key="item.id">{{ item.content }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyMes, leftMes } from "@/assets/API/pay";
export default {
    components: {
    },
    data() {
        return {
            form: {
                name: "",
                contact: "",
                description: "",
                creatoruserid: "",
                creatorusername: "",
            },
            dataList: [],
            workGroup: [],
            phoneList: [],
            addressList: [],
            emailList: []
        };
    },
    mounted() {
        getCompanyMes().then(res => {
            this.dataList = res.data.list;
            this.workGroup = this.dataList.filter(v => v.type == 'workGroup');
            this.phoneList = this.dataList.filter(v => v.type == 'phone');
            this.addressList = this.dataList.filter(v => v.type == 'address');
            this.emailList = this.dataList.filter(v => v.type == 'email');
        });
    },
    watch: {

    },
    methods: {
        saveMes() {
            if (this.$store.state.user.isLogin) {
                let userInfo = this.$store.state.user.userInfo;
                this.form.creatoruserid = userInfo.id;
                this.form.creatorusername = userInfo.username;
            }
            leftMes(this.form).then(res => {
                // 保存成功提示
                // 清空原有内容
                if (res.code == 200) {
                    this.$message("发表成功！");
                    this.form.name = '';
                    this.form.contact = '';
                    this.form.description = '';
                    this.form.creatoruserid = '';
                    this.form.creatorusername = '';
                }
            });
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;
}

.ContactUs {
    width: 100%;
    padding: 150px 193px 150px 268px;
    display: flex;
    justify-content: space-between;

    .writeContactInfo {

        .stayContact {
            font-size: 60px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 84px;
            color: rgba(42, 130, 228, 1);
            margin-bottom: 156px;
        }

        input {
            width: 467px;
            height: 60px;
            opacity: 1;
            border-radius: 6px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(222, 225, 230, 1);
            display: block;
            margin-bottom: 16px;
            padding-left: 25px;
        }

        :deep .el-textarea__inner {
            width: 467px;
            height: 140px;
            opacity: 1;
            border-radius: 6px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(222, 225, 230, 1);
            display: block;
            margin-bottom: 16px;
            padding-left: 25px;
        }

        :deep .el-button {
            width: 160px;
            height: 48px;
            // background: rgba(216, 216, 216, 1);
            // color: #fff;
            border-radius: 24px
        }
    }

    .contactWays {
        display: flex;
        flex-wrap: wrap;
        width: 790px;
        justify-content: space-between;

        .contact_way {
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 30px 60px;
            margin-bottom: 32px;

            .img_div {
                display: flex;
                justify-content: center;
                margin-bottom: 6px;
            }

            img {
                height: 64px;
            }

            p {
                width: 257px;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 23.17px;
                color: rgba(125, 126, 142, 1);
            }

            .englist_title {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                color: rgba(42, 130, 228, 1);
                margin-bottom: 16px;
            }

            .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
                margin-bottom: 16px;
            }

            .work {
                margin-bottom: 0;
            }
        }
    }

    :deep(.el-textarea__inner) {
        &::placeholder {
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            color: rgba(92, 103, 115, 1);
        }
    }
}
</style>

<style>
::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}
</style>
