import axios from 'axios'
import store from '@/store'
import define from '@/utils/define'
import { getToken, removeToken } from '@/utils/auth'
import { Loading } from 'element-ui'

// create an axios instance
const service = axios.create({
	baseURL: define.APIURl, // url = base url + request url
	withCredentials: false, // send cookies when cross-domain requests
	timeout: define.timeout // request timeout
})
let loadingInstance
// request interceptor
service.interceptors.request.use(
	config => {
		if (config.url.indexOf('http') > -1) config.baseURL = ''
		// 部分接口timeout时间单独处理
		if (
			config.url.indexOf('SynThirdInfo') > -1 ||
			config.url.indexOf('extend/Email/Receive') > -1 ||
			config.url.indexOf('Permission/Authority/Data') > -1 ||
			config.url.indexOf('DataSync/Actions/Execute') > -1
		) {
			config.timeout = 100000
		}
		// 根据后端规则 设置请求头
		config.headers['Summit-origin'] = 'pc'
		// do something before request is sent
		if (store.getters.token) {
			config.headers['Authorization'] = getToken()
		}
		if (config.method == 'get') {
			config.params = config.data
		}
		let timestamp = Date.parse(new Date()) / 1000
		if (config.url.indexOf('?') > -1) {
			config.url += `&n=${timestamp}`
		} else {
			config.url += `?n=${timestamp}`
		}
		// 调用避免页面持续Loading 选择性屏蔽接口
		// if (config.url.indexOf('GetGrade') == -1) {
		// 	loadingInstance = Loading.service({
		// 		fullscreen: true,
		// 		background: 'rgba(0,0,0,0.4)',
		// 		text: '数据加载中...'
		// 	})
		// }
		return config
	},
	error => {
		// do something with request error
		if (process.env.NODE_ENV === 'development') {
			console.log(error) // for debug
		}
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		let config = response.config
		let url = config.url
		// 特殊接口处理
		// loadingInstance.close()
		if (
			url.indexOf('/Base/DataSource/Actions/Test') > -1 ||
			(url.indexOf('Model') > -1 && url.indexOf('Config') > -1)
		)
			return res
		if (res.code == 600) {
			if (url.indexOf('/api/oauth/Login') > -1) return Promise.reject(res)
			return removeToken() //路由拦截修改
		} else {
			return res
		}
	},
	error => {
		if (process.env.NODE_ENV === 'development') {
			console.log(error) // for debug
		}
		return Promise.reject(error)
	}
)

export default service
