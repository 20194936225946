<template>
    <div class="buyCenter">
        <div class="headImg">
            <div class="headContent">
                <span class="headText">选择您的订阅产品</span>
                <div class="productContents" v-if="!showSlide">
                    <el-tabs v-model="activeName" @tab-click="handleClick" class="productTabs" stretch>
                        <el-tab-pane label="SummitMap" name="SummitMap">
                            <div class="softContent">
                                <div class="softList">
                                    <div class="softScroll">
                                        <div class="softItem" v-for="item in loopSoftList" :key="item.id"
                                            @click="chooseSoft(item)"
                                            :class="item.id == choosedSoft.id ? 'activeItem' : ''">
                                            <div class="softTitle">
                                                {{ item.name }}
                                            </div>
                                            <div class="softPrice">
                                                <div class="price" v-if="item.ismonth == 1">
                                                    <span class="priceValue">￥{{ item.monthprice }}</span>
                                                    <div class="priceType">/月</div>
                                                </div>
                                                <div class="price" v-else>
                                                    <span class="priceValue">￥{{ item.weekprice }}</span>
                                                    <div class="priceType">/周</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="product">
                                    <div class="itemContent">
                                        <div class="typeText">{{ choosedSoft.name }}</div>
                                        <div class="price" v-if="choosedSoft.ismonth == 1">
                                            <span class="priceValue">￥{{ choosedSoft.monthprice }}</span>
                                            <div class="priceType">/月</div>
                                        </div>
                                        <div class="price" v-else>
                                            <span class="priceValue">￥{{ choosedSoft.weekprice }}</span>
                                            <div class="priceType">/周</div>
                                        </div>
                                        <div class="buyBtns">
                                            <el-button class="freeBtn" v-if="choosedSoft.havefree"
                                                @click="toFree(choosedSoft.freeproductid)">免费试用</el-button>
                                            <el-button class="buyBtn" type="primary"
                                                @click="toBuy(choosedSoft.id)">立即订阅</el-button>
                                        </div>
                                        <div class="features">
                                            <div class="download">
                                                <span>{{ choosedSoft.type }}安装包+许可证</span>
                                            </div>
                                            <div class="baseContent">
                                                <ul>
                                                    <li>软件有效期：生成许可证日起-订阅时长</li>
                                                    <li>可安装设备：1个许可证/1台电脑</li>
                                                    <li>软 件 版 本：{{ choosedSoft.name }}</li>
                                                    <li>语言版本：简体中文</li>
                                                    <li>支持系统：Windows XP/7/10/11 64位</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="moreBtn">
                                            <div class="btn"><img src="@/assets/image/productAnc.png" alt=""></div>
                                            <div class="desBtn" @click="clickToMore()">
                                                <div class="desContent">
                                                    下滑查看产品详情
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="SummitSolid" name="SummitSolid">
                            <div class="softContent">
                                <div class="softList">
                                    <div class="softScroll">
                                        <div class="softItem" v-for="item in loopSoftList" :key="item.id"
                                            @click="chooseSoft(item)"
                                            :class="item.id == choosedSoft.id ? 'activeItem' : ''">
                                            <div class="softTitle">
                                                {{ item.name }}
                                            </div>
                                            <div class="softPrice">
                                                <div class="price" v-if="item.ismonth == 1">
                                                    <span class="priceValue">￥{{ item.monthprice }}</span>
                                                    <div class="priceType">/月</div>
                                                </div>
                                                <div class="price" v-else>
                                                    <span class="priceValue">￥{{ item.weekprice }}</span>
                                                    <div class="priceType">/周</div>
                                                </div>
                                                <!-- <div class="price">敬请期待</div> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="product">
                                    <div class="itemContent">
                                        <div class="typeText">{{ choosedSoft.name }}</div>
                                        <div class="price" v-if="choosedSoft.ismonth == 1">
                                            <span class="priceValue">￥{{ choosedSoft.monthprice }}</span>
                                            <div class="priceType">/月</div>
                                        </div>
                                        <div class="price" v-else>
                                            <span class="priceValue">￥{{ choosedSoft.weekprice }}</span>
                                            <div class="priceType">/周</div>
                                        </div>
                                        <div class="buyBtns">
                                            <el-button class="freeBtn" v-if="choosedSoft.havefree"
                                                @click="toFree(choosedSoft.freeproductid)">免费试用</el-button>
                                            <el-button class="buyBtn" type="primary"
                                                @click="toBuy(choosedSoft.id)">立即订阅</el-button>
                                        </div>
                                        <!-- <div class="stayContent">
                                            <div class="stayTuned">
                                                <p class="firstSpan">敬请</p>
                                                <p>期待</p>
                                            </div>
                                        </div> -->
                                        <div class="features">
                                            <div class="download">
                                                <span>{{ choosedSoft.type }}安装包+许可证</span>
                                            </div>
                                            <div class="baseContent">
                                                <ul>
                                                    <li>软件有效期：生成许可证日起-订阅时长</li>
                                                    <li>可安装设备：1个许可证/1台电脑</li>
                                                    <li>软 件 版 本：{{ choosedSoft.name }}</li>
                                                    <li>语言版本：简体中文</li>
                                                    <li>支持系统：Windows XP/7/10/11 64位</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="SummitLab" name="SummitLab">
                            <div class="softContent">
                                <div class="softList">
                                    <div class="softScroll">
                                        <div class="softItem" v-for="item in loopSoftList" :key="item.id"
                                            @click="chooseSoft(item)"
                                            :class="item.id == choosedSoft.id ? 'activeItem' : ''">
                                            <div class="softTitle">
                                                {{ item.name }}
                                            </div>
                                            <div class="softPrice">
                                                <div class="price" v-if="item.ismonth == 1">
                                                    <span class="priceValue">￥{{ item.monthprice }}</span>
                                                    <div class="priceType">/月</div>
                                                </div>
                                                <div class="price" v-else>
                                                    <span class="priceValue">￥{{ item.weekprice }}</span>
                                                    <div class="priceType">/周</div>
                                                </div>
                                                <!-- <div class="price">敬请期待</div> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="product">
                                    <div class="itemContent">
                                        <div class="typeText">{{ choosedSoft.name }}</div>
                                        <div class="price" v-if="choosedSoft.ismonth == 1">
                                            <span class="priceValue">￥{{ choosedSoft.monthprice }}</span>
                                            <div class="priceType">/月</div>
                                        </div>
                                        <div class="price" v-else>
                                            <span class="priceValue">￥{{ choosedSoft.weekprice }}</span>
                                            <div class="priceType">/周</div>
                                        </div>
                                        <el-button class="buyBtn" type="primary"
                                            @click="toBuy(choosedSoft.id)">立即订阅</el-button>
                                        <!-- <div class="stayContent">
                                            <div class="stayTuned">
                                                <p class="firstSpan">敬请</p>
                                                <p>期待</p>
                                            </div>
                                        </div> -->
                                        <div class="features">
                                            <div class="download">
                                                <span>{{ choosedSoft.type }}安装包+许可证</span>
                                            </div>
                                            <div class="baseContent">
                                                <ul>
                                                    <li>软件有效期：生成许可证日起-订阅时长</li>
                                                    <li>可安装设备：1个许可证/1台电脑</li>
                                                    <li>软 件 版 本：{{ choosedSoft.name }}</li>
                                                    <li>语言版本：简体中文</li>
                                                    <li>支持系统：Windows XP/7/10/11 64位</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="productContent_free" v-if="showSlide">
                    <div class="productContent">
                        <div class="product" v-for="item in freeSoftList" :key="item.id">
                            <div class="itemContent">
                                <div class="typeText">{{ item.type + item.name }}</div>
                                <div class="price">
                                    <span class="priceValue">￥{{ item.weekprice }}</span>
                                    <div class="priceType">元/周</div>
                                </div>
                                <el-button class="buyBtn" type="primary" @click="toFreeBuy(item)">免费试用</el-button>
                                <div class="intro">新用户限时免费体验一个月！</div>
                                <div class="features">
                                    <div class="featureItem" v-for="(it, i) in item.description" :key="i"
                                        style="font-size: 12px; text-align: left">
                                        <span v-html="it"></span>
                                    </div>
                                </div>
                                <div class="installation" @click="showInstall()">安装教程</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="productMes" id="productMes">
            <el-tabs v-model="activeMes" @tab-click="handleMesClick" class="productMes" stretch>
                <el-tab-pane name="productmes">
                    <div slot="label" class="tabTitle">
                        <img src="@/assets/image/productIcon1.png" alt="">
                        <p>产品详情</p>
                    </div>
                    <div class="productC">
                        <div class="headMes">
                            <div class="headText">
                                <div class="headTitle">{{ choosedSoft.type }}</div>
                                <div class="headSubtitle">{{ choosedSoft.name }}</div>
                                <div class="headDes" v-show="choosedSoft.slogan">
                                    <p>{{ choosedSoft.slogan }}</p>
                                </div>
                                <div class="headDesciption" v-html="choosedSoft.intro">
                                </div>
                                <!-- <div class="headDesciption">
                                    专版集成了SummitMap一站式智能成图平台的全面倾斜测图、立面测图以及DEM生产编辑功能，能够实现基于倾斜三维模型的快捷、高效的地物地貌采集、编辑、成图、入库全流程操作，并输出高质量的DLG与DEM成果。
                                </div> -->
                            </div>
                            <div class="headImgs">
                                <img style="width: 100%"
                                    v-if="choosedSoft.img && JSON.parse(choosedSoft.img)[0] && JSON.parse(choosedSoft.img)[0].url"
                                    :src="define.APIURl + JSON.parse(choosedSoft.img)[0].url" alt="">
                                <img style="width: 100%" v-else src="@/assets/image/mapProduct.png" alt="">
                            </div>
                        </div>
                        <div class="desDetail">
                            <div class="detailItem" v-for="item in detailList" :key="item.id">
                                <div class="title">{{ item.title }}</div>
                                <div class="detail" v-html="item.detail"></div>
                            </div>
                        </div>
                        <div class="desFunction" v-show="functionList.length > 0">
                            <el-table :data="functionList" style="width: 100%" row-key="id" :stripe="true"
                                :border="true"
                                :header-cell-style="{ background: '#D5E7FA', color: '#383838', textAlign: 'center' }"
                                :span-method="arraySpanMethod" :row-style="{ height: '48px' }">
                                <el-table-column prop="pointdata" label="功能点" width="200px" align="center">
                                    <template slot-scope="scope">
                                        <div class="pointDataStyle">{{ scope.row.pointdata }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="pointsubdata" label="子功能点" width="300px" align="center">
                                    <template slot-scope="scope">
                                        <div class="pointsubDataStyle">{{ scope.row.pointsubdata }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="functioncontent" label="功能描述">
                                    <template slot-scope="scope">
                                        <div class="functioncontent" v-html="scope.row.functioncontent"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="systemconfig">
                    <div slot="label" class="tabTitle">
                        <img src="@/assets/image/productIcon2.png" alt="">
                        <p>系统配置</p>
                    </div>
                    <div class="systemReqStyle">
                        <div class="system" v-for="(item, index) in requireContentList" :key="item.type">

                            <div class="system_right">
                                <div class="systemHead">
                                    <div class="systemTitle">
                                        <img :src="item.image" alt="" />
                                        <div>
                                            <span class="lefth" v-html="item.leftH"></span>
                                            <div class="leftDes">{{ item.leftDes }}</div>
                                        </div>

                                    </div>
                                    <div class="stitleContent">
                                        <span class="srTitle">{{ item.rightH }}</span>
                                        <span class="srDes">{{ item.rightP }}</span>
                                    </div>
                                </div>
                                <div></div>
                                <div class="optional">
                                    <div class="optional_left" v-for="(ite, i) in item.optional">
                                        <div class="standard">
                                            <div>{{ ite.leftH }}</div>
                                        </div>
                                        <p v-for="(it, index) in ite.leftP">
                                            <span class="weights">{{ it.weights }}</span>
                                            {{ it.spa }}
                                        </p>
                                        <div class="divideline"></div>
                                        <div class="demand" v-for="(item, index) in ite.demand">
                                            <span>{{ item.leftspa }}</span>
                                            <span>{{ item.spa }}</span>
                                        </div>
                                        <span style="font-size: 20px">
                                            <span class="weights">{{ ite.weights }}</span>
                                            {{ ite.spa }}
                                        </span>
                                    </div>
                                    <div class="optional_left optionRight" v-for="(ite, index) in item.optionalRight">
                                        <div class="standard">
                                            <div>{{ ite.leftH }}</div>
                                            <span class="recommend_span">推荐</span>
                                        </div>
                                        <p v-for="(it, index) in ite.leftP">
                                            <span class="weights">{{ it.weights }}</span>
                                            {{ it.spa }}
                                        </p>
                                        <div class="divideline"></div>
                                        <div class="demand" v-for="(requireContent, index) in ite.demand">
                                            <span>{{ requireContent.leftspa }}</span>
                                            <span>{{ requireContent.spa }}</span>
                                        </div>
                                        <span style="font-size: 20px">
                                            <span class="weights">{{ ite.weights }}</span>
                                            {{ ite.spa }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </el-tab-pane>
                <el-tab-pane name="buymes">
                    <div slot="label" class="tabTitle">
                        <img src="@/assets/image/productIcon3.png" alt="">
                        <p>订阅指南</p>
                    </div>
                    <div class="orderGuide">
                        <div class="guideLeft">
                            <el-menu default-active="1-1" class="el-menu-vertical-demo" @select="handleSelect">
                                <el-submenu index="1">
                                    <span slot="title">
                                        订阅流程
                                    </span>
                                    <el-menu-item index="1-1">订购流程</el-menu-item>
                                    <el-menu-item index="1-2">获取安装包&机器码</el-menu-item>
                                    <el-menu-item index="1-3">获取许可证方式一</el-menu-item>
                                    <el-menu-item index="1-4">获取许可证方式二</el-menu-item>
                                </el-submenu>
                                <el-submenu index="2">
                                    <span slot="title">订阅&安装常见问题</span>
                                    <el-submenu index="2-1">
                                        <template slot="title">SummitMap</template>
                                        <el-menu-item index="2-1-1">湖北新型基础测绘专版</el-menu-item>
                                        <el-menu-item index="2-1-2">倾斜测图专版</el-menu-item>
                                    </el-submenu>
                                    <el-menu-item index="2-2">SummitSolid</el-menu-item>
                                    <el-menu-item index="2-3">SummitLab</el-menu-item>
                                </el-submenu>
                            </el-menu>
                        </div>
                        <div class="guideRight">
                            <pdf :src="pdfSrc" v-for="i in pages" :key="i" :page="i"></pdf>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <el-dialog :visible.sync="showVideo" @close="playVideo">
            <article>
                <div class="block-center block-content" style="padding-left: 0px; padding-right: 0px">
                    <div class="items-features">
                        <div class="item">
                            <div>
                                <video :preload="preload" :poster="installData.videoImg" :height="height" :width="width"
                                    align="center" :controls="controls" :autoplay="autoplay" class="myVideoContent"
                                    id="myVideo">
                                    <source :src="installData.videosrc" />
                                </video>
                            </div>
                            <div class="descr">
                                <h4>{{ installData.descrh4 }}</h4>
                                <p v-html="installData.content"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </el-dialog>
    </div>
</template>

<script>
import { getAllProduction, getSoftDownloadList, getProblems, getProductDetailList, getProductFuntionList } from "@/assets/API/pay";
import pdf from 'vue-pdf';
import define from '@/utils/define';
export default {
    components: { pdf },
    data() {
        return {
            activeName: "SummitMap",
            activeMes: 'productmes',
            showSlide: false,
            showVideo: false,
            installData: {},
            width: "100%", // 设置视频播放器的显示宽度（以像素为单位）
            height: "350", // 设置视频播放器的显示高度（以像素为单位）
            preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
            autoplay: false,
            pages: [], // 存放所有页面的数组
            pdfEvent: undefined,
            pdfSrc: '',
            problemDataList: [],
            softList: [],
            loopSoftList: [],
            softListNum: 0,
            freeSoftList: [
                {
                    name: "试用版",
                    type: "SummitSolid",
                    weekprice: "0",
                    description: ["地物数量可达到200个", "一个月有效期，可重复申请"],
                },
                {
                    name: "试用版",
                    type: "SummitMAP",
                    weekprice: "0",
                    description: ["地物数量可达到20个", "一个月有效期，可重复申请"],
                },
                {
                    name: "试用版",
                    type: "SummitLab",
                    weekprice: "0",
                    description: [
                        "基础部分包括:概述、新建打开矢量、打开模型</br>1、打开模型</br>2、采集部分包括:居民地及设施",
                        "一个月有效期，可重复申请",
                    ],
                },
            ],
            functionFlag: true,
            requireContentList: [
                {
                    type: 'map',
                    image: require("../../../assets/image/summitmapLogo.png"),
                    leftH: `SummitMap`,
                    leftDes: "一站式智能成图平台",
                    rightH: `操作系统`,
                    rightP: "windows XP 64位/windows7 64位/windows10 64位",
                    optional: [
                        {
                            leftH: "入门配置",
                            leftP: [
                                {
                                    weights: "CPU:",
                                    spa: "intel i5-8500T、AMD 锐龙5 1500X",
                                },
                                {
                                    weights: "内存:",
                                    spa: "DDR3代 8GB",
                                },
                                {
                                    weights: "硬盘:",
                                    spa: "7200转机械硬盘2TB",
                                },
                            ],
                            demand: [
                                {
                                    leftspa: "有立体作业需求：",
                                    spa: "丽台P400显卡、明基2411p显示器、NVIDIA 立体发射器、立体眼镜",
                                },
                                {
                                    leftspa: "无立体作业需求：",
                                    spa: "NVIDIA GTX650、100HZ以上显示器",
                                },
                            ],
                            weights: "其它(选配):",
                            spa: "脚盘",
                        },
                    ],
                    optionalRight: [
                        {
                            leftH: "推荐配置",
                            image: require("../../../assets/image/推荐.png"),
                            leftP: [
                                {
                                    weights: "CPU:",
                                    spa: "intel 酷睿12代 i7",
                                },
                                {
                                    weights: "内存:",
                                    spa: "DDR4代 32GB",
                                },
                                {
                                    weights: "硬盘:",
                                    spa: "SSD：4TB",
                                },
                            ],
                            demand: [
                                {
                                    leftspa: "有立体作业需求：",
                                    spa: "丽台T1000 4GB显卡、明基2411p显示器、NVIDIA 立体发射器、立体眼镜",
                                },
                                {
                                    leftspa: "无立体作业需求：",
                                    spa: "NVIDIA GTX3060",
                                },
                            ],
                            weights: "其它(选配):",
                            spa: "手轮、脚盘、接口盒",
                        },
                    ],
                },
                {
                    type: 'solid',
                    image: require("../../../assets/image/SS图标.png"),
                    leftH: `SummitSolid`,
                    leftDes: "一站式单体化系统",
                    rightH: `操作系统`,
                    rightP: "windows XP 64位/windows7 64位/windows10 64位",
                    optional: [
                        {
                            leftH: "入门配置",
                            leftP: [
                                {
                                    weights: "CPU:",
                                    spa: "intel i5-8500T、AMD 锐龙5 1500X",
                                },
                                {
                                    weights: "内存:",
                                    spa: "DDR3代 8GB",
                                },
                                {
                                    weights: "硬盘:",
                                    spa: "7200转机械硬盘2TB",
                                },
                            ],
                            demand: [
                                {
                                    leftspa: "",
                                    spa: "NVIDIA GTX1660 4G、",
                                },
                                {
                                    leftspa: "",
                                    spa: "100HZ以上显示器",
                                },
                            ],
                            weights: "其它(选配):",
                            spa: "无",
                        },
                    ],
                    optionalRight: [
                        {
                            leftH: "推荐配置",
                            image: require("../../../assets/image/推荐.png"),
                            leftP: [
                                {
                                    weights: "CPU:",
                                    spa: "intel 酷睿12代 i7",
                                },
                                {
                                    weights: "内存:",
                                    spa: "DDR4代 32GB",
                                },
                                {
                                    weights: "硬盘:",
                                    spa: "SSD：4TB",
                                },

                            ],
                            demand: [
                                {
                                    leftspa: "",
                                    spa: "NVIDIA GTX1660 4G、",
                                },
                                {
                                    leftspa: "",
                                    spa: "100HZ以上显示器",
                                },
                            ],
                            weights: "其它(选配):",
                            spa: "无",
                        },
                    ],
                },
                {
                    type: 'lab',
                    image: require("../../../assets/image/SL图标.png"),
                    leftH: `SummitLab`,
                    leftDes: "智能化内业培训系统",
                    rightH: `操作系统`,
                    rightP: "windows XP 64位/windows7 64位/windows10 64位",
                    optional: [
                        {
                            leftH: "入门配置",
                            leftP: [
                                {
                                    weights: "CPU:",
                                    spa: "intel i5-8500T、AMD 锐龙5 1500X",
                                },
                                {
                                    weights: "内存:",
                                    spa: "DDR3代 8GB",
                                },
                                {
                                    weights: "硬盘:",
                                    spa: "7200转机械硬盘2TB",
                                },
                            ],
                            demand: [
                                {
                                    leftspa: "",
                                    spa: "",
                                },
                                {
                                    leftspa: "",
                                    spa: "NVIDIA GTX650、100HZ以上显示器",
                                },
                            ],
                            weights: "其它(选配):",
                            spa: "脚盘",
                        },
                    ],
                    optionalRight: [
                        {
                            leftH: "推荐配置",
                            image: require("../../../assets/image/推荐.png"),
                            leftP: [
                                {
                                    weights: "CPU:",
                                    spa: "intel 酷睿12代 i7",
                                },
                                {
                                    weights: "内存:",
                                    spa: "DDR4代 32GB",
                                },
                                {
                                    weights: "硬盘:",
                                    spa: "SSD：4TB",
                                },
                            ],
                            demand: [
                                {
                                    leftspa: "",
                                    spa: "丽台T1000 4GB显卡、明基2411p显示器、NVIDIA 立体发射器、立体眼镜",
                                },
                            ],
                            weights: "其它(选配):",
                            spa: "手轮、脚盘、接口盒",
                        },
                    ],
                },
            ],
            systemReqStyleFlag: false,
            choosedSoft: {},
            detailList: [],
            functionList: []
        };
    },
    mounted() {
        const { value, id } = this.$route.query;
        console.log("this.$route.query", this.$route.query);
        if (value && value != "free") {
            this.getProduct(value, id);
            this.activeName = value;
        } else {
            this.getProduct("SummitMap", id);
        }
        if (value == "free") {
            this.showSlide = true;
            document.getElementById("switch5").checked = true;
        }

        // 获取软件下载地址
        let query = {
            superQueryJson: "",
            currentPage: 1,
            pageSize: 20,
            sort: "desc",
            sidx: "",
            type: 0
        };
        getSoftDownloadList(query).then(res => {
            this.softDownList = res.data.list;
            let data = this.softDownList.filter(v => v.videosrc != null);
            if (data.length == 1) {
                this.installData = data[0];
            }

        });
        let queryP = {
            superQueryJson: "",
            currentPage: 1,
            pageSize: 20,
            sort: "desc",
            enabledmark: "1",
            sidx: "sortcode",
            type: 0
        };
        getProblems(queryP).then(res => {
            this.problemDataList = res.data.list;
        });
    },
    watch: {
        $route: {
            handler(newVal, oldVal) {
                console.log(newVal, 'iiiiii');
                switch (true) {
                    case ["/Buy"].indexOf(newVal.path) != -1:
                        if (newVal.query.value == "SummitMap") {
                            if (newVal.query.id) {
                                this.getProduct("SummitMap", newVal.query.id);
                            } else {
                                this.getProduct("SummitMap");
                            }
                            this.activeName = "SummitMap";
                        } else if (newVal.query.value == "SummitSolid") {
                            this.getProduct("SummitSolid");
                            this.activeName = "SummitSolid";

                        } else if (newVal.query.value == "SummitLab") {
                            this.getProduct("SummitLab");
                            this.activeName = "SummitLab";
                        } else {
                            this.getProduct("SummitMap");
                            this.activeName = "SummitMap";
                        }
                        break;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        getProduct(type, baseid) {
            let data = {
                typename: type,
                saletype: "online",
                sidx: "sortcode",
                enabledmark: 1
            };
            getAllProduction(data).then((res) => {
                this.softList = res.data.list;
                // this.softListNum = Number(this.softList.length % 4);
                // if (this.softList.length % 4 == 0) {
                //     this.softListNum = Number(this.softList.length / 4);
                // } else {
                //     this.softListNum = parseInt(this.softList.length / 4) + 1;
                // }
                this.loopSoftList = this.softList;
                if (this.softList.length > 0) {
                    if (baseid) {
                        this.choosedSoft = this.loopSoftList.filter(v => v.id == baseid)[0];
                    } else {
                        this.choosedSoft = this.loopSoftList[0];
                    }
                    this.getFunctionDetail();
                }
                this.handleSelect('1-1');

            });
        },
        handleClick(e, event) {
            this.getProduct(e.name);
        },
        handleMesClick(e, event) {

        },
        changeSlide() {
            this.showSlide = !this.showSlide;
        },
        changeLoop(value) {
            this.loopSoftList = this.softList.slice(value * 4, (value + 1) * 4);
        },
        toBuy(id) {
            this.$router.push({
                path: "/BuyMenu",
                query: {
                    id,
                },
            });
        },
        toFree(id) {
            this.$router.push({
                path: "/BuyMenu",
                query: {
                    id,
                },
            });
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            const span = column['property'] + '-span';
            if (row[span]) {
                return row[span];
            }
        },
        showFunction() {
            this.functionFlag = !this.functionFlag;
        },
        showSystemReqStyle() {
            this.systemReqStyleFlag = !this.systemReqStyleFlag;
        },
        toFreeBuy(data) {
            // let result;
            // if (data.type == 'SummitLab') {
            //     // 下载lab软件
            //     let data = this.softDownList.filter(v => v.descrh.includes("SummitLab"));
            //     if (data.length == 1) {
            //         result = data[0];
            //     }
            // } else if (data.type == 'SummitSolid') {
            //     // 下载solid软件
            //     let data = this.softDownList.filter(v => v.descrh.includes("SummitSolid"));
            //     if (data.length == 1) {
            //         result = data[0];
            //     }
            // } else if (data.type == 'SummitMAP') {
            //     // 下载map软件
            //     let data = this.softDownList.filter(v => v.descrh.includes("SummitMap"));
            //     if (data.length == 1) {
            //         result = data[0];
            //     }
            // }
            // if (result) {
            //     let url = result.f + "&pwd=" + result.m;
            //     window.open(url, '_blank');
            // } else {
            //     this.$message.error("未查找到该软件");
            // }
        },
        toGuide(value) {
            // 跳转
            this.$router.push({
                path: "/AccountSupportDetail",
                query: {
                    value
                }
            });
        },
        showInstall() {
            this.showVideo = true;
        },
        playVideo() {
            this.installData = {};
        },
        chooseSoft(data) {
            console.log(data, 'ddd');
            this.choosedSoft = data;
            this.getFunctionDetail();
        },
        getFunctionDetail() {
            let query = {
                productid: this.choosedSoft.id,
                sort: "asc",
                sidx: "sortcode",
                enabledmark: 1
            };
            let _query = {
                productid: this.choosedSoft.id,
                sort: "desc",
                sidx: 'sortcode'
            };
            getProductDetailList(_query).then(res => {
                this.detailList = res.data.list;
            });
            getProductFuntionList(query).then(res => {
                this.functionList = res.data.list;
                this.mergeRow(this.functionList, ['pointdata']);
            });

        },
        // 动态合并
        mergeRow(data, merge) {
            if (!merge || merge.length === 0) {
                return data;
            }
            merge.forEach((m) => {
                const mList = {};
                data = data.map((v, index) => {
                    const rowVal = v[m];
                    if (mList[rowVal] && mList[rowVal].newIndex === index) {
                        // console.log(rowVal, 'www');
                        mList[rowVal]['num']++;
                        mList[rowVal]['newIndex']++;
                        data[mList[rowVal]['index']][m + '-span'].rowspan++;
                        v[m + '-span'] = { rowspan: 0, colspan: 0 };
                    } else {
                        mList[rowVal] = {
                            num: 1,
                            index: index,
                            newIndex: index + 1
                        };
                        v[m + '-span'] = { rowspan: 1, colspan: 1 };
                    }
                    return v;
                });
            });
            return data;
        },
        handleSelect(key, keyPath) {
            // this.pdfSrc = '';
            // this.pdfEvent = undefined;
            // this.pages = [];
            if (key == '1-1') {
                this.pdfSrc = '1订阅流程.pdf';
                this.pdfEvent = pdf.createLoadingTask(this.pdfSrc);

                this.pdfEvent.promise.then(pdf => {
                    this.pages = pdf.numPages;
                    // console.log(this.pages);
                });
            } else if (key == '1-2') {
                this.pdfSrc = '2获取安装包&机器码.pdf';
                this.pdfEvent = pdf.createLoadingTask(this.pdfSrc);

                this.pdfEvent.promise.then(pdf => {
                    this.pages = pdf.numPages;
                    // console.log(this.pages);
                });
            } else if (key == '1-3') {
                this.pdfSrc = '3获取许可证方式一.pdf';
                this.pdfEvent = pdf.createLoadingTask(this.pdfSrc);
                this.pdfEvent.promise.then(pdf => {
                    this.pages = pdf.numPages;
                    // console.log(this.pages);
                });
            } else if (key == '1-4') {
                this.pdfSrc = '3获取许可证方式二.pdf';
                this.pdfEvent = pdf.createLoadingTask(this.pdfSrc);
                this.pdfEvent.promise.then(pdf => {
                    this.pages = pdf.numPages;
                    // console.log(this.pages);
                });
            }

        },
        clickToMore() {
            const scrollButton = document.getElementById('productMes');
            window.scrollTo({
                top: scrollButton.offsetTop - 60, // 滚动到目标元素的顶部位置
                behavior: 'smooth' // 平滑滚动
            });
        }
    },
};
</script>

<style scoped lang="scss" scoped>
.buyCenter {
    margin-top: 60px;
}

.headImg {
    // background-image: url("../../../assets/image/gradient.png");
    background-image: url("../../../assets/image/productBgNew.png");
    background-size: 100% 100%;

    .headContent {
        width: 1315px;
        margin: 0 auto;
        text-align: center;
        padding-top: 60px;

        .headText {
            font-size: 48px;
            font-weight: 400;
            letter-spacing: -1.58px;
            line-height: 60px;
            color: rgba(56, 56, 56, 1);
            text-align: center;
            vertical-align: top;
        }
    }
}

.slideContent {
    .slide {
        display: inline-block;
        position: relative;
        font-size: 12px;
        color: #fff;

        input[type="checkbox"] {
            height: 0;
            width: 0;
            visibility: hidden;
        }

        label {
            cursor: pointer;
            text-indent: -9999px;
            width: 180px;
            height: 30px;
            // background: #E8E8E8;
            display: block;
            border-radius: 15px;
            position: relative;
            border: 1px solid #416ff4;
        }

        label:after {
            content: "";
            position: absolute;
            top: 0;
            left: -1px;
            width: 100px;
            height: 28px;
            background: #3385ff;
            border-radius: 15px;
            transition: 0.3s;
            border: 1px solid #416ff4;
        }

        input:checked+label {
            // background: #E8E8E8;
        }

        input:checked+label:after {
            left: calc(100% + 1px);
            transform: translateX(-100%);
        }

        label:active:after {
            width: 0.15625rem;
        }

        .slide1 {
            position: absolute;
            top: 25px;
            left: 22px;
            pointer-events: none;
        }

        .color1 {
            color: #fff;
        }

        .slide2 {
            color: #fff;
            position: absolute;
            top: 25px;
            right: 22px;
            pointer-events: none;
        }
    }
}

.productContents {
    padding: 70px 0;

    .productTabs {

        // ::v-deep .el-tabs__nav-scroll {
        //     margin: 0 auto;
        // }
        ::v-deep .el-tabs__nav-scroll {
            display: flex;
            justify-content: center;
        }

        ::v-deep .el-tabs__nav-wrap::after {
            width: 0;
        }

        ::v-deep .el-tabs__nav.is-stretch {
            min-width: auto;

            .is-active {
                // color: rgba(255, 255, 255, 1);
                color: rgba(43, 133, 229, 1);
                // background: linear-gradient(90deg, rgba(43, 133, 229, 0) 0%, rgba(43, 133, 229, 1) 55.56%, rgba(43, 133, 229, 0) 100%);
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.64) 56.02%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 101%);
                ;
            }

            .is-top:last-child {
                padding-right: 50px;
            }

            .is-top:nth-child(2) {
                padding-left: 50px;
            }
        }


        ::v-deep .el-tabs__active-bar {
            display: none;
        }

        ::v-deep .el-tabs__item {
            font-size: 28px;
            font-weight: 400;
            letter-spacing: 0px;
            // color: #FFFFFF;
            color: rgba(56, 56, 56, 1);
            border-right: 1px solid rgba(166, 166, 166, 1);
            height: 52px;
            line-height: 52px;
            padding: 0 50px;
        }

        ::v-deep .el-tabs__item:last-child {
            border-right: 0px;
        }

        ::v-deep .is-top:last-child {
            padding-right: auto;
        }

        ::v-deep .is-top:first-child {
            padding-left: auto;
        }

        // ::v-deep .is-active {
        //     // color: rgba(255, 255, 255, 1);
        //     background: linear-gradient(90deg, #2B85E5 0%, #2B85E5 55.56%, #2B85E5 100%);
        // }

        // ::v-deep .el-tabs__content {
        //     width: 1315px;
        // }
    }

    ::v-deep .el-carousel__container {
        height: 550px;
        // width: 1315px;
    }

    ::v-deep .el-carousel__item {
        display: flex;
        justify-content: center;
    }
}

// .productContent {
//     display: flex;
//     justify-content: center;
//     padding: 40px 0;
//     width: 1200px;

//     .product {
//         width: 22%;
//         opacity: 1;
//         border-radius: 14px;
//         background: rgba(255, 255, 255, 1);
//         box-shadow: 3px 30px 33px rgba(111, 118, 138, 0.16);
//         color: rgba(56, 56, 56, 1);
//         overflow: hidden;
//         transition: transform 0.3s;
//         margin-right: 10px;

//         .itemContent {

//             .stayContent {
//                 padding-top: 30px;
//                 display: flex;
//                 justify-content: center;

//                 .stayTuned {
//                     border-radius: 50%;
//                     background-color: rgba(43, 133, 229, 1);
//                     width: 125px;
//                     height: 125px;
//                     font-size: 36px;
//                     font-weight: 400;
//                     letter-spacing: 0px;
//                     line-height: 52.13px;
//                     color: rgba(255, 255, 255, 1);
//                     text-align: center;
//                     vertical-align: top;
//                     padding: 12px;

//                     .firstSpan {
//                         border-bottom: 1px solid #fff;
//                     }
//                 }
//             }

//             .typeText {
//                 height: 72px;
//                 opacity: 1;
//                 background: rgba(43, 133, 229, 1);
//                 box-shadow: 3px 30px 33px rgba(111, 118, 138, 0.16);
//                 // font-size: 24px;
//                 font-weight: 400;
//                 letter-spacing: 0px;
//                 // line-height: 72px;
//                 color: rgba(255, 255, 255, 1);
//                 text-align: center;
//                 vertical-align: top;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//             }

//             .price {
//                 display: flex;
//                 align-items: baseline;
//                 justify-content: center;
//                 padding: 50px 0;

//                 .priceValue {
//                     font-size: 32px;
//                     font-weight: 700;
//                     letter-spacing: -1.58px;
//                     line-height: 46.34px;
//                     color: rgba(13, 21, 46, 1);
//                     margin-right: 10px;
//                 }

//                 .priceType {
//                     font-size: 16px;
//                     font-weight: 400;
//                     letter-spacing: -0.53px;
//                     line-height: 23.17px;
//                     color: rgba(56, 56, 56, 1);
//                 }
//             }

//             .buyBtn {
//                 margin: 0 10px;
//                 width: 90%;
//                 background-color: rgba(65, 111, 244, 1);
//             }

//             .features {
//                 margin-top: 15px;

//                 .download {
//                     font-size: 16px;
//                     font-weight: 400;
//                     letter-spacing: 0px;
//                     line-height: 24px;
//                     text-decoration-line: underline;
//                     color: rgba(56, 56, 56, 1);
//                     text-align: center;
//                     vertical-align: top;

//                 }

//                 .baseContent {
//                     font-size: 12px;
//                     font-weight: 400;
//                     letter-spacing: 0px;
//                     line-height: 24px;
//                     color: rgba(56, 56, 56, 1);
//                     text-align: center;
//                     vertical-align: top;
//                     margin-top: 10px;

//                     ul li {
//                         text-align: left;
//                         position: relative;
//                         padding-left: 22px;
//                     }

//                     ul li::after {
//                         position: absolute;
//                         left: 10px;
//                         right: 0;
//                         top: 10px;
//                         height: 5px;
//                         width: 5px;
//                         background-color: #2A82E4;
//                         border-radius: 50%;
//                         content: "";
//                     }

//                 }
//             }
//         }
//     }

//     .product:hover {
//         transform: scale(1.025);

//         .itemContent {
//             .typeText {
//                 opacity: 1;
//                 background: rgba(255, 141, 26, 1);
//             }

//             .stayContent .stayTuned {
//                 background-color: rgba(255, 141, 26, 1);
//             }

//             .intro {
//                 color: rgba(56, 56, 56, 1);
//             }
//         }

//         .buyBtn {
//             background-color: #ff8d1a;
//             border-color: #ff8d1a;
//         }


//     }
// }

.softContent {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    height: 550px;



    .softList {
        // flex: 1;
        width: 40%;
        // opacity: 0.6;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 2px 6px rgba(223, 232, 245, 0.5);
        padding: 22px 25px;
        padding-right: 0;
        margin-right: 100px;

        .softScroll {
            overflow-y: scroll;
            height: 100%;
            padding-right: 10px;
        }

        .activeItem {
            // border-left: 5px solid rgba(43, 133, 229, 1);
            background: url("../../../assets/image/productActive.png");
            background-size: 100% 100%;
        }

        .softItem {
            opacity: 1;
            border-radius: 0px 20px, 20px, 0px;
            background-color: rgba(241, 246, 252, 1);
            box-shadow: 0px 2px 6px rgba(223, 232, 245, 0.5);
            margin-bottom: 20px;
            padding: 25px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            align-items: center;

            .softTitle {
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 34.75px;
                color: rgba(56, 56, 56, 1);
                text-align: left;
                vertical-align: top;

            }

            .softPrice {
                min-width: 30%;

                .price {
                    display: flex;
                    align-items: baseline;
                    justify-content: flex-end;

                    .priceValue {
                        font-size: 30px;
                        font-weight: 700;
                        letter-spacing: -1.58px;
                        line-height: 43.44px;
                        color: rgba(13, 21, 46, 1);
                        text-align: left;
                        vertical-align: top;
                        opacity: 1;
                        margin-right: 10px;

                    }
                }
            }
        }
    }

    .product {
        // flex: 1;
        width: 25%;
        display: flex;
        opacity: 1;
        border-radius: 14px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 3px 30px 33px rgba(111, 118, 138, 0.16);
        color: rgba(56, 56, 56, 1);
        overflow: hidden;
        // transition: transform 0.3s;
        margin-right: 10px;

        .itemContent {
            width: 100%;

            .stayContent {
                padding-top: 30px;
                display: flex;
                justify-content: center;

                .stayTuned {
                    border-radius: 50%;
                    background-color: rgba(43, 133, 229, 1);
                    width: 125px;
                    height: 125px;
                    font-size: 36px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 52.13px;
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                    vertical-align: top;
                    padding: 12px;

                    .firstSpan {
                        border-bottom: 1px solid #fff;
                    }
                }
            }

            .typeText {
                height: 72px;
                opacity: 1;
                background: rgba(43, 133, 229, 1);
                box-shadow: 3px 30px 33px rgba(111, 118, 138, 0.16);
                // font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                // line-height: 72px;
                color: rgba(255, 255, 255, 1);
                text-align: center;
                vertical-align: top;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .price {
                display: flex;
                align-items: baseline;
                justify-content: center;
                padding: 50px 0;

                .priceValue {
                    font-size: 48px;
                    font-weight: 700;
                    letter-spacing: -1.58px;
                    line-height: 46.34px;
                    color: rgba(13, 21, 46, 1);
                    margin-right: 10px;
                }

                .priceType {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: -0.53px;
                    line-height: 23.17px;
                    color: rgba(56, 56, 56, 1);
                }
            }

            .buyBtns {
                display: flex;
                justify-content: center;

                .freeBtn {
                    margin: 0 10px;
                    width: 70%;
                }
            }

            .buyBtn {
                margin: 0 10px;
                width: 70%;
                background-color: rgba(42, 130, 228, 1);
            }

            .buyBtn:hover {
                background-color: rgba(255, 141, 26, 1);
                border-color: rgba(255, 141, 26, 1);
            }

            .features {
                margin-top: 15px;

                .download {
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 24px;
                    text-decoration-line: underline;
                    color: rgba(56, 56, 56, 1);
                    text-align: center;
                    vertical-align: top;

                }

                .baseContent {
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 24px;
                    color: rgba(56, 56, 56, 1);
                    text-align: center;
                    vertical-align: top;
                    margin-top: 10px;
                    display: flex;
                    padding: 0 20px;
                    // justify-content: center;

                    ul li {
                        text-align: left;
                        position: relative;
                        padding-left: 22px;
                    }

                    ul li::after {
                        position: absolute;
                        left: 10px;
                        right: 0;
                        top: 10px;
                        height: 5px;
                        width: 5px;
                        background-color: #2A82E4;
                        border-radius: 50%;
                        content: "";
                    }

                }
            }
        }
    }
}

.moreBtn {

    .btn {
        // height: 8px;
        // width: 8px;
        margin: 10px 0;
    }

    .desBtn {
        cursor: pointer;

        .desContent {
            margin: 0 auto;
            width: 143px;
            height: 30px;
            opacity: 1;
            border-radius: 30px;

            border: 1px solid rgba(42, 130, 228, 1);

            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 30px;
            color: rgba(42, 130, 228, 1);
            text-align: center;
            vertical-align: top;

        }
    }
}

.productContent_free {
    padding-top: 20px;
    padding-bottom: 204px;
    display: flex;
    justify-content: center;

    .productContent {
        justify-content: space-evenly;
    }

    .intro {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        text-decoration-line: underline;
        color: rgba(42, 130, 228, 1);
        text-align: center;
        vertical-align: top;
        padding-top: 10px;
    }

    .product {
        .itemContent {
            // position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;

            .features {
                padding: 15px 20px;
                flex: 1;
            }
        }
    }

    .installation {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.53px;
        line-height: 20.27px;
        color: rgba(93, 158, 232, 1);
        text-align: center;
        vertical-align: bottom;
        // position: absolute;
        // bottom: 40px;
        // left: 0;
        // right: 0;
        margin: 0 auto;
        cursor: pointer;
        padding-bottom: 20px;
    }
}

.functionCompare {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;

    .functionText {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 40.54px;
        color: rgba(56, 56, 56, 1);
        text-align: center;
        vertical-align: top;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            cursor: pointer;
        }

        span:hover {
            color: #3385ff;
        }

        img {
            width: 18px;
            height: 10px;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .functionTextFree {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 64px;
        color: rgba(56, 56, 56, 1);
        text-align: center;
        vertical-align: top;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            cursor: pointer;
        }

        span:hover {
            color: #3385ff;
        }

        img {
            width: 23px;
            height: 14px;
            margin-left: 40px;
            cursor: pointer;
        }
    }

    .funDes {
        display: flex;
        padding-bottom: 10px;

        div {
            flex: 1;
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgba(56, 56, 56, 1);
        }
    }
}

.systemReqStyle {
    // width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 167px;

    .functionText {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 64px;
        color: rgba(56, 56, 56, 1);
        text-align: center;
        vertical-align: top;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            cursor: pointer;
        }

        span:hover {
            color: #3385ff;
        }

        img {
            width: 23px;
            height: 14px;
            margin-left: 40px;
            cursor: pointer;
        }
    }

    .system {
        display: flex;
        width: 100%;
        // border-radius: 33px;
        background: #fff;
        // box-shadow: 0px 2px 12px #00000057;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 30px;
        margin-bottom: 50px;

        .systemHead {
            // width: 30%;
            // padding: 5% 0;
            display: flex;
            flex-direction: column;
            background: radial-gradient(24.54% 88% at 2.8461538461538463% -16.8%, rgba(213, 231, 250, 1) 0%, rgba(247, 251, 255, 1) 100%);
            padding: 27px 43px;

            .systemTitle {
                display: flex;
                padding-bottom: 25px;
                align-items: center;

                img {
                    display: block;
                    // margin: 15px auto;
                    // width: 30%;
                    width: 96px;
                    height: 96px;
                    margin-right: 35px;
                }

                .lefth {
                    // flex: 1;
                    font-size: 30px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 43.44px;
                    color: rgba(56, 56, 56, 1);

                }

                .leftDes {
                    font-size: 30px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 43.44px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                }
            }

            .stitleContent {
                border-top: 1px solid;
                border-image: linear-gradient(90deg, rgba(193, 212, 232, 1) 0%, rgba(213, 231, 250, 0) 100%) 1;
                padding-top: 30px;

                .srTitle {
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 34.75px;
                    color: rgba(0, 0, 0, 1);
                    text-align: left;
                    vertical-align: top;
                    margin-right: 35px;
                }

                .srDes {
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 34.75px;
                    color: rgba(56, 56, 56, 1);

                }
            }

        }

        .system_right {
            width: 100%;


            .standard {
                display: flex;
                align-items: center;
                flex-direction: row;
                align-items: flex-end;
                // height: 18px;
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 34.75px;
                color: rgba(0, 0, 0, 1);
                text-align: left;
                vertical-align: top;

            }

            h4 {
                margin-bottom: 1%;
                font-size: 28px !important;
                font-weight: 400;
            }

            .recommend_span {
                display: block;
                width: 56px;
                height: 26px;
                opacity: 1;
                border-radius: 30px;
                background: #FF8D1A;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 26px;
                color: rgba(255, 255, 255, 1);
                text-align: center;
                margin-bottom: 11px;
                margin-left: 10px;
            }
        }

        .optional {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 40px;

            .optional_left {
                opacity: 1;
                border-radius: 8px;
                background: radial-gradient(20.44% 29.8% at 6.386070383974955% 10.098522167487685%, rgba(213, 231, 250, 1) 0%, rgba(213, 231, 250, 0) 100%);

                border: 1px solid rgba(232, 232, 232, 1);
                width: 48%;
                padding: 28px 23px;
                /** 文本1 */
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 23.17px;
                color: rgba(56, 56, 56, 1);
                text-align: left;
                vertical-align: top;



                p {
                    font-size: 17px;
                }
            }

            .optionRight {
                background: radial-gradient(20.44% 29.8% at 6.386070383974955% 10.098522167487685%, rgba(255, 248, 240, 1) 0%, rgba(255, 255, 255, 1) 100%);
            }

            .divideline {
                height: 1px;
                width: 100%;
                border-bottom: 1px solid;
                border-image: linear-gradient(90deg, #D3E2F2 0%, #D5E7FA 100%) 1;
                // padding: 20px 0;
                margin-bottom: 10px;
            }

            .optional_right {
                width: 48%;
                opacity: 1;
                border-radius: 8px;
                background: radial-gradient(20.44% 29.8% at 6.386070383974955% 10.098522167487685%, rgba(213, 231, 250, 1) 0%, rgba(213, 231, 250, 0) 100%);

                border: 1px solid rgba(232, 232, 232, 1);

                h4 {
                    font-size: 20px;
                }

                p {
                    font-size: 17px;
                }
            }

            p {
                margin: 15px 0;
            }
        }

        .weights {
            // font-weight: bold;
            margin-right: 20px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 23.17px;
            color: rgba(0, 0, 0, 1);
            text-align: left;
            vertical-align: top;
            opacity: 1;

        }

        .demand {
            display: flex;
            font-size: 17px;
            margin-bottom: 10px;

            span:nth-child(2) {
                width: 58%;
            }
        }
    }
}

.useGuide {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 279px;

    .functionText {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 64px;
        color: rgba(56, 56, 56, 1);
        text-align: center;
        vertical-align: top;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            cursor: pointer;
        }

        span:hover {
            color: #3385ff;
        }

        img {
            width: 18px;
            height: 10px;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .giude {
        ul {
            display: flex;
            justify-content: center;

            li {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20.27px;
                color: rgba(56, 56, 56, 1);
                text-align: center;
                vertical-align: top;
                margin-right: 148px;

                img {
                    width: 98px;
                    height: 106px;
                    margin-bottom: 8px;
                }

                .lookGuide {
                    font-size: 13px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 18.82px;
                    color: rgba(42, 130, 228, 1);
                    margin-top: 28px;
                    cursor: pointer;
                }
            }

            li:last-child {
                margin-right: 0;
            }
        }
    }
}

.problems {
    background: #fcfcfc;
    padding: 50px 0;

    .problemContent {
        width: 1200px;
        margin: 0 auto;

        .pText {
            font-size: 42px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 64px;
            color: rgba(56, 56, 56, 1);
            text-align: center;
            vertical-align: top;
        }

        .pItem {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgba(128, 128, 128, 1);
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            justify-content: space-between;
            padding: 20px 10px;
            cursor: pointer;
        }

        ::v-deep .el-collapse-item__header {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgba(128, 128, 128, 1);
            padding: 30px 10px;
        }

        ::v-deep .el-collapse-item__content {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 22px;
            color: rgba(128, 128, 128, 1);
            padding: 0px 10px 10px 10px;
        }
    }
}

.contact {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;

    .cText {
        font-size: 42px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 64px;
        color: rgba(56, 56, 56, 1);
        text-align: center;
        vertical-align: top;
        margin-bottom: 20px;
    }

    .cContent {
        display: flex;
        justify-content: space-between;

        .cItem {
            flex: 1;
            text-align: center;

            img {
                width: 50%;
            }

            .cDes {
                font-size: 26px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 64px;
                color: rgba(56, 56, 56, 1);
                text-align: center;
                vertical-align: top;
            }

            .cIntro {
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 64px;
                color: rgba(128, 128, 128, 1);
                text-align: center;
                vertical-align: top;
            }

            .cPhone {
                opacity: 1;
                height: 108px;
                width: 300px;
                border-radius: 20px;
                background: #f7f7f7;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 64px;
                color: rgba(128, 128, 128, 1);
            }
        }
    }
}

.productMes {
    width: 1300px;
    margin: 70px auto;

    ::v-deep .el-tabs__item {
        height: auto;
    }

    .tabTitle {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 34.75px;
        text-align: center;
        vertical-align: middle;

        p {
            padding-bottom: 30px;
            padding-top: 5px;
        }
    }

    .productC {
        .headMes {
            display: flex;
            justify-content: space-between;
            padding: 80px 0;

            .headText {
                width: 50%;
                margin-right: 20px;

                .headTitle {
                    font-size: 48px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 69.5px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .headSubtitle {
                    font-size: 48px;
                    font-weight: 300;
                    letter-spacing: 0px;
                    line-height: 69.5px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .headDes {
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 34.75px;
                    color: rgba(255, 255, 255, 1);
                    text-align: justify;
                    vertical-align: top;
                    padding-top: 16px;
                    padding-bottom: 37px;
                    border-bottom: 1px solid rgba(128, 128, 128, 1);

                    p {
                        display: inline-block;
                        border-radius: 30px;
                        padding: 0 10px;
                        background: rgba(43, 133, 229, 1);
                    }

                }

                .headDesciption {
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 36px;
                    color: rgba(56, 56, 56, 1);
                    text-align: justify;
                    vertical-align: top;
                    padding-top: 22px;

                }
            }

            .headImg {
                width: 47%;
            }
        }

        .desDetail {
            .detailItem {
                opacity: 1;
                border-radius: 1.52px;
                background: rgba(247, 248, 251, 1);

                .title {
                    width: 300px;
                    height: 50px;
                    opacity: 1;
                    background: linear-gradient(90deg, rgba(43, 133, 229, 1) 0%, rgba(43, 133, 229, 0) 100%);
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 50px;
                    color: rgba(255, 255, 255, 1);
                    text-align: left;
                    vertical-align: top;
                    padding-left: 35px;

                }

                .detail {
                    padding: 22px 35px;

                    ::v-deep p {
                        line-height: 32px;
                    }
                }
            }
        }

        .desFunction {
            padding: 37px 0;

            .pointDataStyle {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0px;
                color: rgba(56, 56, 56, 1);
                text-align: center;
                vertical-align: middle;
            }

            .pointsubDataStyle {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 64px;
                color: rgba(56, 56, 56, 1);
                text-align: center;
                vertical-align: middle;
            }

            .functioncontent {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 26px;
                color: rgba(56, 56, 56, 1);
                text-align: justify;
                vertical-align: middle;

            }
        }
    }
}

.orderGuide {
    display: flex;

    .guideRight {
        height: 900px;
        overflow-y: auto;
        flex: 1;
    }
}
</style>
