<template>
    <div class="block_center">
        <div class="ContactUs">
            <div class="contactWays">
                <div class="contact_way">
                    <div>
                        <p>企业微信</p>
                        <div class="cDivide"></div>
                        <img class="companyWechet" src="../../../../assets/image/companyContact.jpg" />
                    </div>
                    <div class="img_div">
                        <img src="../../../../assets/image/WorkingGroup.png" alt="">
                    </div>
                </div>
                <div class="contact_way bgStyle1">
                    <div>
                        <p>联系电话</p>
                        <div class="cDivide"></div>
                        <div class="cText">+86 177 8608 3608</div>
                    </div>
                    <div class="img_div">
                        <img src="../../../../assets/image/WorkingGroup1.png" alt="">
                    </div>
                </div>
                <div class="contact_way bgStyle2">
                    <div>
                        <p>公司地址</p>
                        <div class="cDivide"></div>
                        <div class="cText">湖北省武汉市高新大道999号未来科技城B区1栋2楼</div>
                    </div>
                    <div class="img_div">
                        <img src="../../../../assets/image/WorkingGroup2.png" alt="">
                    </div>
                </div>
                <div class="contact_way">
                    <div>
                        <p>电子邮件</p>
                        <div class="cDivide"></div>
                        <div class="cText">sales@summitek.xyz</div>
                    </div>
                    <div class="img_div">
                        <img src="../../../../assets/image/WorkingGroup3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyMes, leftMes } from "@/assets/API/pay";
export default {
    components: {
    },
    data() {
        return {
            form: {
                name: "",
                contact: "",
                description: "",
                creatoruserid: "",
                creatorusername: "",
            },
            dataList: [],
            workGroup: [],
            phoneList: [],
            addressList: [],
            emailList: []
        };
    },
    mounted() {
        getCompanyMes().then(res => {
            this.dataList = res.data.list;
            this.workGroup = this.dataList.filter(v => v.type == 'workGroup');
            this.phoneList = this.dataList.filter(v => v.type == 'phone');
            this.addressList = this.dataList.filter(v => v.type == 'address');
            this.emailList = this.dataList.filter(v => v.type == 'email');
        });
    },
    watch: {

    },
    methods: {
        saveMes() {
            if (this.$store.state.user.isLogin) {
                let userInfo = this.$store.state.user.userInfo;
                this.form.creatoruserid = userInfo.id;
                this.form.creatorusername = userInfo.username;
            }
            leftMes(this.form).then(res => {
                // 保存成功提示
                // 清空原有内容
                if (res.code == 200) {
                    this.$message("发表成功！");
                    this.form.name = '';
                    this.form.contact = '';
                    this.form.description = '';
                    this.form.creatoruserid = '';
                    this.form.creatorusername = '';
                }
            });
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.block_center {
    padding-top: 60px;
    background: rgba(247, 248, 251, 1);
}

.ContactUs {
    padding-top: 35px;
    width: 1200px;
    margin: 0 auto;
    // display: flex;
    // justify-content: space-between;

    .writeContactInfo {

        .stayContact {
            font-size: 60px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 84px;
            color: rgba(42, 130, 228, 1);
            margin-bottom: 156px;
        }

        input {
            width: 467px;
            height: 60px;
            opacity: 1;
            border-radius: 6px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(222, 225, 230, 1);
            display: block;
            margin-bottom: 16px;
            padding-left: 25px;
        }

        :deep .el-textarea__inner {
            width: 467px;
            height: 140px;
            opacity: 1;
            border-radius: 6px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(222, 225, 230, 1);
            display: block;
            margin-bottom: 16px;
            padding-left: 25px;
        }

        :deep .el-button {
            width: 160px;
            height: 48px;
            // background: rgba(216, 216, 216, 1);
            // color: #fff;
            border-radius: 24px
        }
    }

    .contactWays {
        display: flex;
        flex-wrap: wrap;
        // width: 790px;
        justify-content: space-between;


        .contact_way {
            border-radius: 6px;
            padding: 30px 25px;
            margin-bottom: 32px;
            width: 23%;

            background: linear-gradient(135deg, rgba(255, 255, 255, 1) 33.33%, rgba(250, 238, 222, 1) 100%);
            box-shadow: 0px 3px 5px rgba(228, 235, 247, 0.5);

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .img_div {
                // display: flex;
                // justify-content: center;
                // margin-bottom: 6px;
                align-self: flex-end;
            }

            p {
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 16px;
                color: rgba(56, 56, 56, 1);
                text-align: left;
                vertical-align: top;
            }

            .cDivide {
                height: 0px;
                opacity: 1;
                border: 1px solid rgba(229, 229, 229, 1);
                margin: 20px 0;
            }

            .companyWechet {
                width: 128px;
                height: 121px;
            }

            .cText {
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 32px;
                color: rgba(56, 56, 56, 1);
                text-align: left;
                vertical-align: top;
            }

            .englist_title {
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                color: rgba(42, 130, 228, 1);
                margin-bottom: 16px;
            }

            .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
                margin-bottom: 16px;
            }

            .work {
                margin-bottom: 0;
            }
        }

        .bgStyle1 {
            background: linear-gradient(135deg, rgba(255, 255, 255, 1) 19.44%, rgba(218, 245, 232, 1) 100%);
        }

        .bgStyle2 {
            background: linear-gradient(135deg, rgba(255, 255, 255, 1) 30.56%, rgba(210, 230, 250, 1) 100%);
        }

        .bgStyle3 {
            background: linear-gradient(135deg, rgba(255, 255, 255, 1) 22.22%, rgba(222, 224, 250, 1) 100%);
        }
    }

    :deep(.el-textarea__inner) {
        &::placeholder {
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            color: rgba(92, 103, 115, 1);
        }
    }
}
</style>

<style>
::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(92, 103, 115, 1);
}

.guide {
    justify-content: flex-start !important;
    width: 1200px;
    margin: 0 auto;
    background: rgba(247, 248, 251, 1);
}
</style>
