<template>
    <div class="InvoiceManagement">
        <Search @search="search" :searchContent="searchContent"></Search>
        <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark"
            element-loading-text="Loading" fit :default-sort="{ prop: 'date', order: 'descending' }">
            <!-- <el-table-column prop="orderNumber" label="订单编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="product" label="开票公司名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="number" label="产品名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="buyWay" label="状态" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="duration" label="订单实付金额" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="可开发票金额" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="发票内容" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startTime" label="物流订单号" align="center" show-overflow-tooltip></el-table-column> -->
            <el-table-column label="序号" type="index" width="80" align="center">
            </el-table-column>
            <el-table-column prop="username_name" label="用户名" align="center" show-overflow-tooltip />
            <el-table-column prop="company_name" label="开票公司" align="center" show-overflow-tooltip />
            <el-table-column label="开票状态" prop="status" algin="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!-- {{ scope.row.status | dynamicText(statusOptions) }} -->
                    {{ getTextByType(scope.row.status, statusOptions) }}
                </template>
            </el-table-column>
            <el-table-column prop="amount_name" label="实付金额" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    ￥{{ scope.row.amount_name }}
                </template>
            </el-table-column>
            <el-table-column label="发票类型" prop="type" algin="center">
                <template slot-scope="scope">
                    {{ getTextByType(scope.row.type, typeOptions) }}
                </template>
            </el-table-column>
            <el-table-column prop="banknum_name" label="开户行及账号" align="center" show-overflow-tooltip />
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button type="text" @click="goDetail(scope.row.id)">详情
                    </el-button>
                    <el-button type="text" v-show="scope.row.status == 1" @click="download(scope.row)">下载发票
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="table-actions" @click="addNewInvoice" style="text-align: center;" v-show="invoiceMakeStatus">
            <!-- <el-button type="text" icon="el-icon-plus" v-show="licenseMakeStatus">创建许可证</el-button> -->
            您有订单还未开发票<el-button size="mini" type="primary" style="margin-left: 10px;">去申请开票</el-button>
        </div>
        <el-pagination :total="total" :page.sync="listQuery.currentPage" :limit.sync="listQuery.pageSize"
            @pagination="getInit" />
        <InvoiceDetail ref="InvoiceDetail" v-if="detailVisible" @refresh="detailVisible = false"></InvoiceDetail>
        <el-dialog title="申请发票" :close-on-click-modal="false" class="Summit-dialog Summit-dialog_center" lock-scroll
            append-to-body width="1200px" :visible.sync="invocieVisible" :show-close="false">
            <el-table :data="orderTableData" border @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
                <el-table-column prop="ordername_name" label="订单名" align="center"
                    show-overflow-tooltip></el-table-column>
                <el-table-column prop="ordernum_name" label="订单号" align="center"
                    show-overflow-tooltip></el-table-column>
                <el-table-column prop="creatortime" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="paymentamount_name" label="实付金额" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        ￥{{ scope.row.paymentamount_name }}
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="onConfirm()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Search from './Search.vue';
import InvoiceDetail from './InvoiceDetail.vue';
import request from '@/utils/request';
import { mapState, mapMutations } from "vuex";
import define from "@/utils/define";

export default {
    components: {
        Search, InvoiceDetail
    },
    data() {
        return {
            tableData: [],
            loading: false,
            detailVisible: false,
            listQuery: {
                superQueryJson: '',
                currentPage: 1,
                pageSize: 20,
                sort: "desc",
                sidx: "",
            },
            query: {
                userid: '',// 用户id
                dataType: 0,
            },
            total: 0,
            searchContent: "请输入软件产品标题或订单编号",
            statusOptions: [{ "fullName": "未开票", "id": "0" }, { "fullName": "已开票", "id": "1" }],
            statusProps: { "label": "fullName", "value": "id" },
            typeOptions: [{ "fullName": "普通发票", "id": "0" }, { "fullName": "专用发票", "id": "1" }],
            typeProps: { "label": "fullName", "value": "id" },
            orderTableData: [],
            orderTotal: 0,
            invoiceMakeStatus: false,
            invocieVisible: false,
            choosedOrderList: []
        };
    },
    computed: {
        ...mapState("user", ["userInfo"]),
    },
    mounted() {
        this.query.userid = this.userInfo.id;
        this.getInit();
        this.getOrderList();
    },
    watch: {

    },
    methods: {
        getTextByType(data, list) {
            let result = "-";
            list.forEach(e => {
                if (e.id == data) {
                    result = e.fullName;
                }
            });
            return result;
        },
        getOrderList() {
            if (this.query.userid) {
                let _query = {
                    userid: '',// 用户id
                    paymentstate: "1", //（0-未支付，1-已支付，2-支付失败）
                    purchasemethod: 'online',
                    menuId: "491144407886626501"
                };
                _query.userid = this.query.userid;
                request({
                    url: `/api/business/C_customers/getOrderListWithStatus`,
                    method: 'post',
                    data: _query
                }).then(res => {
                    if (res.code == 200) {
                        // this.orderTableData = res.data.list;
                        let orderList = [];
                        this.orderTotal = res.data.pagination.total;
                        res.data.list.forEach(e => {
                            if (e.invoiceid == null || e.invoiceid == '') {
                                this.invoiceMakeStatus = true;
                                orderList.push(e);
                            }
                        });
                        this.orderTableData = orderList;
                    }
                });
            }
        },
        // 数据初始化
        getInit() {
            this.loading = true;
            if (this.query.userid) {
                let _query = {
                    ...this.listQuery,
                    ...this.query
                };
                request({
                    url: `/api/business/C_invoice/getList`,
                    method: 'post',
                    data: _query
                }).then(res => {
                    if (res.code == 200) {
                        this.loading = false;
                        this.tableData = res.data.list;
                        this.total = res.data.pagination.total;
                    }
                });
            }
        },
        goDetail(id) {
            this.detailVisible = true;
            this.$nextTick(() => {
                this.$refs.InvoiceDetail.init(id);
            });
        },
        download(data) {
            // 下载发票
            let baseData = JSON.parse(data.content);
            if (baseData.length > 0) {
                console.log(baseData[0]);
                request({
                    url: `api/file/Download/annex/${baseData[0].fileId}`,
                    method: 'get',
                }).then(res => {
                    let url = res.data.url;
                    let name = baseData[0].name;
                    if (!url) return;
                    const baseUrl = url.indexOf("http") > -1 ? "" : this.define.APIURl;
                    const a = document.createElement("a");
                    a.setAttribute("download", "");
                    const realUrl = baseUrl + url + (name ? "&name=" + name : "");
                    a.setAttribute("href", realUrl);
                    a.click();
                });
            }
        },
        search(val) {
            console.log("val", val);
        },
        addNewInvoice() {
            this.invocieVisible = true;
        },
        handleSelectionChange(val) {
            this.choosedOrderList = val;
        },
        cancel() {
            this.invocieVisible = false;
        },
        onConfirm() {
            // 根据选中的订单 choosedOrderList 生成未开票记录
            console.log(this.choosedOrderList, this.userInfo, 'sss');

            let orderids = this.choosedOrderList.map(e => e.id);
            let ordernames = this.choosedOrderList.map(e => e.ordernum);
            let totalBaseAmount = this.choosedOrderList.reduce((accumulator, current) => {
                return accumulator + current.baseamount;
            }, 0);
            let totalPaymentAmount = this.choosedOrderList.reduce((accumulator, current) => {
                return accumulator + current.paymentamount;
            }, 0);
            let data = {
                status: 0,
                type: 0,
                taxrate: '',
                // 用户信息
                username: this.userInfo.username,
                userid: this.userInfo.id,
                company: this.userInfo.companyname,
                ratenumber: this.userInfo.companysocialcreditcode,
                phone: this.userInfo.phonenum,
                // 订单信息
                orderid: orderids.toString(),
                ordername: ordernames.toString(),
                amount: totalPaymentAmount,
                amountgross: totalBaseAmount,
                creatoruserid: this.userInfo.id
            };
            request({
                url: '/api/business/C_invoice',
                method: 'post',
                data
            }).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                        this.invocieVisible = false;
                        this.getInit();
                        this.getOrderList();
                    }
                });
            });
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.InvoiceManagement {
    height: calc(100vh - 120px);

    margin-top: 35px;
    // padding-left: 35px;
    // padding-right: 32px;
    background-color: #fff;
    padding: 10px;

    // table
    ::v-deep .el-table {
        // height: calc(100% - 125px) !important;
        height: calc(100% - 200px);
    }

    :deep .el-tabs__header {
        padding-top: 15px;
        // margin: 0 0 35px
    }

    ::v-deep .el-tabs__content {
        height: calc(100% - 90px);
    }

    ::v-deep .el-tab-pane {
        height: calc(100% - 50px);
    }

    ::v-deep .el-tabs--border-card {
        border: none;
        box-shadow: none;
    }

    :deep .el-table__header .has-gutter tr th {
        background-color: rgba(213, 231, 250, 1);
        border-right: 1px solid #fff;
        color: rgba(56, 56, 56, 1);
    }

    :deep .el-table__body-wrapper {
        height: calc(100% - 100px);
        overflow-y: auto;
    }

    // :deep .el-table__header {
    //     border-radius: 20px 20px, 0px, 0px;
    //     background-color: rgba(245, 245, 245, 1);
    // }

    // :deep .el-table th.el-table__cell {
    //     background-color: rgba(245, 245, 245, 1);
    //     border-radius: 20px;
    //     border: none;
    // }

    // :deep .el-table__body tr:hover>td {
    //     background-color: rgba(245, 245, 245, 1) !important;
    // }

    // :deep .el-table tr {
    //     height: 46px;
    // }

    // :deep .el-table__body-wrapper {
    //     height: calc(100% - 47px);
    //     overflow-y: auto;
    // }

    // :deep .el-table__empty-block {
    //     min-height: 50px;
    // }

    // :deep .el-table th.el-table__cell>.cell {
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 22.4px;
    //     color: rgba(128, 128, 128, 0.7);
    // }

    // ::v-deep .el-pagination {
    //     padding-top: 20px;
    // }
}

.table-actions {
    padding-top: 10px;
}
</style>