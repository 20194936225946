<template>
    <div class="block_center">
        <div class="banner">
            <div class="usTitle">
                <div class="titleText">免费软件</div>
                <div class="titleDes">FREE SOFTWARE</div>
            </div>

        </div>
        <div class="introduction">
            <div class="mianCenter">
                <div class="introContent">
                    <!-- 文本 -->
                    <div class="leftIntro leftBase">
                        <div class="introTitle">{{ introTitle }}</div>
                        <div class="introDes">{{ introDes }}</div>
                        <div class="introTextContent" v-html="introTextContent"></div>
                        <div class="downloadBtn">
                            <el-button type="primary" round style="background-color: #2B85E5;"
                                @click="toDownload">立即下载</el-button>
                            <el-button round @click="toDownloadOps">操作视频</el-button>
                        </div>
                    </div>
                    <div class="rightBase">
                        <img :src="img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="featureTotal">
            <div class="intro" style="padding-top: 117px;padding-bottom:87px">
                <div>
                    <span class="introText">功能特性</span>
                </div>
                <div>
                    <span class="introDes">FUNCTION CHARACTERISTICS</span>
                </div>
            </div>
            <div class="featureList">
                <div class="featureLeft">
                    <div v-for="(item, index) in featuresList " :key="index"
                        :class="item.choosed ? 'selectFeature featureItem' : 'featureItem'"
                        @mouseenter="chooseFeature(item, index)">
                        {{ item.name }}
                        <!-- <span>></span> -->
                    </div>
                </div>
                <div class="featureRight">
                    <div class="featuresItem" v-show="activeFeature == index" v-for="( item, index ) in featuresList "
                        :key="index">
                        <!-- <div class="featuresIntro">{{ item.name }}</div> -->
                        <div class="textContent" v-if="index == 0 || index == 6">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                    <div class="img" v-if="it.img"><img :src="it.img" alt=""></div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i"
                                        :style="{ 'width': (96 / item.imgList.length) + '%' }">
                                        <div>
                                            <img :src="it.img" alt="">
                                        </div>
                                        <div class="smallDes">{{ it.des }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="textContent" v-if="index == 1">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                    <div class="img" v-if="it.img"><img :src="it.img" alt=""></div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i">
                                        <div :class="i == 1 ? 'img2' : 'imgs'">
                                            <img :src="it.img" alt="">
                                        </div>
                                    </li>
                                </ul>
                                <div class="imgDes">
                                    {{ item.imgDes }}
                                </div>
                            </div>
                        </div>
                        <div class="textContent1" v-if="index == 2">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                </div>
                                <div class="textTotal">
                                    <div class="desItem" v-for="(item, index) in item.desItemList" :key="index">
                                        <div class="desTitle">{{ item.title }}</div>
                                        <div class="desDes">{{ item.des }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i">
                                        <div>
                                            <img :src="it.img" alt="">
                                            <div class="imgDes">{{ it.des }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div class="textContent" v-if="index == 3">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                </div>
                            </div>
                            <div class="itemImg1">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i">
                                        <div>
                                            <img :src="it.img" alt="">
                                        </div>
                                        <div class="imgDes">{{ it.des }}</div>
                                    </li>
                                </ul>
                                <div class="imgDes">
                                    {{ item.imgDes }}
                                </div>
                            </div>
                        </div>
                        <div class="textContent" v-if="index == 4 || index == 5">
                            <div class="text">
                                <div class="item" v-for="( it, i ) in item.desList " :key="i">
                                    <div class="title">{{ it.title }}</div>
                                    <div class="des">{{ it.textContent }}</div>
                                </div>
                            </div>
                            <div class="itemImg">
                                <ul>
                                    <li v-for="( it, i ) in item.imgList " :key="i">
                                        <div>
                                            <img :src="it.img" alt="">
                                        </div>
                                        <div class="imgDes">{{ it.des }}</div>
                                    </li>
                                </ul>
                                <div class="imgDes">
                                    {{ item.imgDes }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="intro" style="padding-top: 120px;">
            <div>
                <span class="introText" style="color: #383838;">典型应用</span>
            </div>
            <div>
                <span class="introDes">TYPICAL APPLICATIONS</span>
            </div>
        </div>
        <div class="mianCenter uniqueBg">
            <div class="totalType">
                <div class="uniqueItem" v-for="item in uniqueList" :key="item.name"
                    :style="`background: url(${item.img}) no-repeat;background-size: 100% 100%;`">
                    <div class="uniqueItemTitle">
                        <span>{{ item.title }}</span>
                    </div>
                    <div class="uniqueItemDes">{{ item.des }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSoftDownloadList } from "@/assets/API/pay";
export default {
    components: {

    },
    data() {
        return {
            introTitle: "SummitView",
            introDes: "空间数据浏览器",
            introTextContent: "SummitView空间数据浏览器专为多源、多维、异构空间数据浏览打造，不仅全面兼容各类常见地理信息主流数据及系统工程文件，更具备海量数据承载能力、色彩明暗调节切换功能、多种数据显示模式、数据分屏联动与一体融合显示支持，令时空数据浏览更流畅、更舒适。",
            img: require('../../../assets/image/summitview.png'),
            downloadList: [],
            featuresList: [
                {
                    name: "全面数据兼容",
                    desList: [
                        {
                            title: '兼容多源、多维、异构空间数据',
                            textContent: "全面兼容行业主流地理信息数据、空三成果以及基础产品。对于来自无人机、卫星、激光雷达、移动设备等多种数据采集设备和推扫、正视、倾斜、环绕等多样化数据采集手段的多源、多维、异构空间数据，以及多种传感器获取的立体像对、常见空三成果、基础地理信息产品，均可直接读取查看。",
                        },
                        {
                            title: '多源、多维、异构地理信息数据',
                            textContent: "航空摄影：UCD、UCX、UCXP、DMC、胶片、SWDC、无人机小数码影像等框幅式影像ADS40/80/100等推扫式影像"
                        },
                        {
                            textContent: '倾斜摄影：倾斜摄影三维模型'
                        },
                        {
                            textContent: '卫星遥感：国内外带RPC参数的卫星遥感影像等'
                        },
                        {
                            textContent: '激光雷达：机载Lidar数据'
                        },
                        {
                            textContent: '移动测量：全景影像、全景视频、背包/车载激光扫描数据、手机拍照数据等'
                        },
                        {
                            title: '基础地理信息产品',
                            textContent: "DEM、DSM、DOM、TDOM、DLG等",
                        },
                        {
                            title: "地理信息工程文件",
                            textContent: "ESRI SHP、远景XML、超图UDB、EPS EXF、.CAS等-20%。",
                        },
                    ],
                    imgList: [],
                    choosed: true,
                },
                {
                    name: "海量数据承载",
                    desList: [
                        {
                            title: '超大规模数据承载，快速流畅响应',
                            textContent: "大范围、海量数据，无需切片即可载入进行浏览查看，更大规模的数据场景也能快速响应、流畅浏览。",
                            img: require("../../../assets/image/freeTopic_img5.png")
                        },
                    ],
                    choosed: false
                },
                {
                    name: "色彩明暗调节",
                    desList: [
                        {
                            title: '调节显示参数，优化场景显示效果',
                            textContent: "视图中亮度、饱和度、对比度等丰富参数，均可根据场景展示需求任意调节，对于点云数据，更可设置多重条件标示渲染点云颜色，调节点云密度、单点大小，全面优化各类场景显示效果。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/freeTopic_img6.png"),
                            des: "调节三维场景亮度、饱和度"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img7.png"),
                            des: "根据RGB值、强度值、高程值、文件编组分类等分别渲染点云色彩"
                        },
                    ],
                    choosed: false
                },
                {
                    name: "多种显示模式",
                    desList: [
                        {
                            title: '十余种数据显示模式，多重场景浏览体验',
                            textContent: "内置光照模式、线模式、白模模式、白模+线框模式、透视测量模式、截面测量模式、EDL模式、正射（平面）模式、三维模式、分屏/红绿/闪屏立体模式、立面模式等十余种数据显示模式。用户可根据数据特性按需选择，获取多视角、更全面的数据场景浏览体验。"
                        }
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/freeTopic_img8.png"),
                            des: "光照模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img9.png"),
                            des: "线模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img10.png"),
                            des: "白模模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img11.png"),
                            des: "白模+线框模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img12.png"),
                            des: "透视测量模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img13.png"),
                            des: "截面测量模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img14.png"),
                            des: "EDL模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img15.png"),
                            des: "正射模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img16.png"),
                            des: "三维模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img17.png"),
                            des: "立体模式"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img18.png"),
                            des: "立面模式"
                        },

                    ],
                    choosed: false
                },
                {
                    name: "联动融合显示",
                    desList: [
                        {
                            title: '多源数据分屏联动，一体化语义级融合',
                            textContent: "同一坐标系下的多源数据，既可以分屏联动，也能够根据需要融合到一个窗口内，进行无感切换。"
                        },
                        {
                            textContent: "通过空天地多源数据间优势互补，为用户带来更全面的场景信息展示。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/freeTopic_img19.png"),
                            des: "倾斜三维模型与立体联动显示"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img20.png"),
                            des: "点云与倾斜三维模型融合显示"
                        },
                    ],
                    choosed: false
                },
                {
                    name: "精准基础测量",
                    desList: [
                        {
                            title: "全面测量工具，轻松测量二三维场景",
                            textContent: "提供全面的二三维基础测量工具，辅助用户轻松精准测量距离、角度、面积等信息。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/freeTopic_img21.png"),
                            des: "距离测量"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img22.png"),
                            des: "角度测量"
                        },
                        {
                            img: require("../../../assets/image/freeTopic_img23.png"),
                            des: "面积测量"
                        }
                    ],
                    choosed: false
                },
                {
                    name: "矢量格式转换",
                    desList: [
                        {
                            title: "丰富常用矢量格式，任意自由转换",
                            textContent: "VDB、DWG、SHP、MDB、GDB、EXF、KML等一系列常用矢量格式，均可任意自由转换，有效解决99%矢量数据格式困扰。"
                        },
                    ],
                    imgList: [
                        {
                            img: require("../../../assets/image/freeTopic_img24.png"),
                            des: ""
                        },

                    ],
                    // flag: true,
                    choosed: false
                },
            ],
            activeFeature: 0,
            decomposeIntroList: [
                {
                    name: '航空摄影',
                    des: "UCD、UCX、UCXP、DMC、胶片、SWDC、无人机小数码影像等框幅式影像ADS40/80/100等推扫式影像",
                    img: require("../../../assets/image/projectSummitMap_img1.png")
                },
                {
                    name: '倾斜摄影',
                    des: "倾斜摄影三维模型数据",
                    img: require("../../../assets/image/projectSummitMap_img2.png")
                },
                {
                    name: '卫星遥感',
                    des: "国内外带RPC参数的卫星遥感影像、InSAR数据等",
                    img: require("../../../assets/image/projectSummitMap_img3.png")
                },
                {
                    name: '激光雷达',
                    des: "机载Lidar数据",
                    img: require("../../../assets/image/projectSummitMap_img4.png")
                },
                {
                    name: '移动测量',
                    des: "全景影像、全景视频、背包/车载激光扫描数据、手机拍照数据、声纳回波数据等",
                    img: require("../../../assets/image/projectSummitMap_img5.png")
                },
            ],
            uniqueList: [
                {
                    img: require('../../../assets/image/freeTopic_img1.png'),
                    title: "空天地多源场景浏览",
                    des: "流畅浏览源于多传感器的各类型空天地数据场景，同一坐标系下的多源数据场景可根据需求联动或融合查看，同时还可对场景进行精确的二三维基础测量。"
                },
                {
                    img: require('../../../assets/image/freeTopic_img2.png'),
                    title: "外业航测漏洞检查",
                    des: "将外业航测数据实时导入SummitView检查，及时发现航测漏洞并进行补测。"
                },
                {
                    img: require('../../../assets/image/freeTopic_img3.png'),
                    title: "立体测图精度检查",
                    des: "利用SummitView强大的数据兼容能力，将无人机影像、卫星影像、ADS40/80影像等立体测图数据、各类型空三工程文件导入SummitView中，利用内置的分屏/闪闭/红绿立体模式检查矢量与控制点套合情况。"
                },
                {
                    img: require('../../../assets/image/freeTopic_img4.png'),
                    title: "常用矢量格式转换",
                    des: "VDB、DWG、SHP、MDB、GDB、EXF、KML等一系列常用的主流格式矢量文件，均可在SummitView中浏览并一键进行自由格式转换。"
                },
            ]
        };
    },
    mounted() {
        let query = {
            dataType: 1,
            sort: "desc",
            sidx: "",
            type: 1
        };
        getSoftDownloadList(query).then(res => {
            this.downloadList = res.data.list;
        });
    },
    watch: {

    },
    methods: {
        toDownload() {
            let url = '';
            let res = this.downloadList.filter(n => n.descrh == 'SummitView');
            if (res.length == 1) {
                url = res[0].f + "&pwd=" + res[0].m;
                window.open(url, '_blank');
            }
        },
        toDownloadOps() {
            let url = 'https://www.bilibili.com/video/BV12m421V7pw/?spm_id_from=333.999.0.0';
            window.open(url, '_blank');
        },
        chooseFeature(data, index) {
            this.activeFeature = index;
            this.featuresList.forEach(e => {
                e.choosed = false;
            });
            data.choosed = true;
        }
    },
    beforeDestroy() {
    },
};
</script>

<style scoped lang="scss" scoped>
.block_center {
    margin-top: 60px;

    .banner {
        // background-image: url("../../../assets/image/project_banner.png");
        // height: 442px;
        background-image: url("../../../assets/image/freesoft.png");
        height: 593px;
        background-size: 100% 100%;
        position: relative;
        display: flex;
        align-items: flex-end;

        .usTitle {
            padding-bottom: 265px;
            padding-left: 350px;

            .titleText {
                font-size: 48px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 69.5px;
                color: rgba(255, 255, 255, 1);
                vertical-align: top;
            }

            .titleDes {
                font-size: 40px;
                font-weight: 200;
                letter-spacing: 0px;
                line-height: 57.92px;
                color: rgba(35, 173, 222, 1);
                text-align: left;
                vertical-align: top;
            }
        }
    }
}


.mianCenter {
    width: 1200px;
    margin: 0 auto;
    padding-top: 74px;
    padding-bottom: 100px;
}

.introContent {
    // padding: 0 50px;

    display: flex;

    .leftIntro {
        width: 48%;

        .introTitle {
            font-size: 48px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 69.5px;
            color: rgba(56, 56, 56, 1);
            text-align: left;
            vertical-align: top;
        }

        .introDes {
            font-size: 48px;
            font-weight: 300;
            letter-spacing: 0px;
            line-height: 69.5px;
            color: rgba(56, 56, 56, 1);
            text-align: left;
            vertical-align: top;
            border-bottom: 1px solid rgba(128, 128, 128, 1);
            padding-bottom: 15px;
        }

        .introTag {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 26.06px;
            color: rgba(255, 255, 255, 1);
            text-align: justify;
            vertical-align: top;
            padding-bottom: 35px;
            padding-top: 20px;

            span {
                background: rgba(43, 133, 229, 1);
                border-radius: 30px;
                padding: 5px 20px;

            }
        }

        .introTextContent {
            // font-size: 16px;
            // font-weight: 400;
            // line-height: 36px;
            // color: rgba(56, 56, 56, 1);
            // text-align: left;
            padding-top: 15px;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 36px;
            color: rgba(56, 56, 56, 1);
            text-align: justify;
            vertical-align: top;

        }

        .downloadBtn {
            padding-top: 33px;
        }
    }

    .rightBase {
        display: flex;
        width: 48%;
        align-items: center;
    }

    .rightBase img {
        width: 100%;
        margin-left: 42px;
    }

}

.featureTotal {
    // background-image: url(../../../assets/image/productBg2.png);
    background-color: #152235;
    background-size: 100% 100%;

    .featureList {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 85px;
        display: flex;

        .featureLeft {
            width: 300px;
            height: 620px;
            opacity: 1;
            background: #276FBE;
            display: flex;
            flex-direction: column;
            font-size: 18px;
            font-weight: 400;

            .featureItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                flex: 1;
                padding: 0 10px;
                color: #fff;
                justify-content: center;
            }

            .selectFeature {
                background-color: #2B85E5;
                color: #FFFFFF;
            }

        }

        .featureRight {
            flex: 1;
            height: 620px;
            opacity: 1;
            background: rgba(255, 255, 255, 1);
            overflow: auto;

            .featuresItem {
                padding: 22px 25px;
                display: flex;
                flex-direction: column;
                height: 100%;

                .featuresIntro {
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 34.75px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .itemText {
                    padding-bottom: 14px;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 32px;
                    color: rgba(56, 56, 56, 1);
                    text-align: left;
                    vertical-align: top;

                    .dot {
                        width: 12px;
                        height: 12px;
                        opacity: 1;
                        background: rgba(56, 56, 56, 1);
                        border-radius: 50%;
                        margin-right: 12px;
                    }

                }

                .decomposeIntro {
                    flex: 1;
                    height: 100%;
                    padding-bottom: 35px;

                    ul {
                        display: flex;
                        height: 100%;

                        li {
                            // width: 177px;
                            // height: 318px;
                            flex: 1;
                            opacity: 1;
                            border-radius: 8px;
                            background: rgba(255, 255, 255, 1);
                            box-shadow: 0px 2px 8px rgba(146, 158, 168, 0.25);
                            padding: 30px 10px;
                            margin-left: 10px;
                            display: flex;
                            flex-direction: column;

                            text-align: center;

                            .decomTag {
                                opacity: 1;
                                border-radius: 20px;
                                background: rgba(43, 133, 229, 1);
                                margin: 0 17px;
                                color: #fff;
                            }

                            .decomImg {
                                // flex: 1;
                                height: 25%;
                            }

                            .decomDes {
                                font-size: 14px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 24px;
                                color: rgba(56, 56, 56, 1);
                                text-align: center;
                                vertical-align: top;
                                padding-top: 12px;

                                p {
                                    text-align: left;
                                    display: inline-block;
                                }

                            }
                        }

                        li:first-child {
                            margin-left: 0;
                        }
                    }
                }

                .textContent {
                    display: flex;
                    flex-direction: column; // justify-content: space-between;

                    .text {
                        // width: 44%;
                        // background-color: pink;
                        margin-top: 10px;
                        padding-bottom: 10px;

                        .item {
                            // display: flex;
                            margin-bottom: 10px;
                            line-height: 32px;

                            .title {
                                font-size: 20px;
                                font-weight: 500;
                                letter-spacing: 0px;
                                line-height: 32px;
                                color: rgba(42, 130, 228, 1);
                                text-align: left;
                                vertical-align: top;
                            }

                            .des {
                                font-size: 16px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 32px;
                                color: rgba(56, 56, 56, 1);
                                text-align: left;
                                vertical-align: top;
                                width: calc(100% - 24px);
                            }

                            .img {
                                text-align: center;
                                padding: 10px 0;
                            }
                        }

                        .div_test {
                            width: 24px;
                            padding-top: 10px;

                            .dot {
                                width: 12px;
                                height: 12px;
                                opacity: 1;
                                background: rgba(56, 56, 56, 1);
                                border-radius: 50%;
                                margin-right: 12px;
                            }
                        }
                    }

                    .itemImg {
                        // width: 48%;
                        // height: 200px;
                        // background-color: yellowgreen;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 20.27px;
                        text-align: center;
                        vertical-align: top;
                        // padding-top: 10px;

                        .imgDes {
                            padding-top: 10px;
                            color: rgba(56, 56, 56, 1);
                        }

                        ul {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            li {

                                div {
                                    img {
                                        width: 100%;
                                    }
                                }

                                .smallDes {
                                    font-size: 10px;
                                    font-weight: 400;
                                    letter-spacing: -1px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                }
                            }
                        }

                        .img2 {
                            padding: 10px;
                        }

                    }

                    .itemImg1 {
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 20.27px;
                        text-align: center;
                        vertical-align: top;
                        // padding-top: 10px;

                        .imgDes {
                            padding-top: 10px;
                            color: rgba(56, 56, 56, 1);
                        }

                        ul {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: wrap;


                            li {
                                width: 23%;
                                margin-right: 10px;

                                div {
                                    img {
                                        width: 100%;
                                    }
                                }

                                .smallDes {
                                    font-size: 10px;
                                    font-weight: 400;
                                    letter-spacing: -1px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                }
                            }
                        }

                        .img2 {
                            padding: 10px;
                        }
                    }
                }

                .textContent1 {
                    display: flex;
                    // padding-top: 20px;
                    flex-direction: column;

                    .text {
                        margin-right: 10px;

                        .item {
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 32px;
                            color: rgba(56, 56, 56, 1);
                            text-align: left;
                            vertical-align: top;

                            .title {
                                font-size: 20px;
                                font-weight: 500;
                                letter-spacing: 0px;
                                line-height: 32px;
                                color: rgba(42, 130, 228, 1);
                                text-align: left;
                                vertical-align: top;
                            }

                        }

                        .textTotal {
                            // padding: 15px;
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;

                            .desItem {
                                width: 30%;
                                // padding: 10px;
                                margin: 7px;
                                border-radius: 10px;
                                background: rgba(255, 255, 255, 1);
                                overflow: hidden;

                                .desTitle {
                                    opacity: 1;
                                    background: rgba(42, 130, 228, 1);
                                    padding: 10px;
                                    text-align: center;
                                    font-size: 18px;
                                    font-weight: 500;
                                    letter-spacing: 0px;
                                    line-height: 32px;
                                    color: rgba(255, 255, 255, 1);
                                    vertical-align: top;
                                }

                                .desDes {
                                    font-size: 13px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 0.89);
                                    text-align: justify;
                                    vertical-align: top;
                                    padding: 5px 10px;
                                }
                            }
                        }
                    }

                    .itemImg {
                        text-align: center;

                        ul {
                            li {
                                img {
                                    margin-bottom: 20px;

                                }

                                .imgDes {
                                    font-size: 12px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 24px;
                                    color: rgba(56, 56, 56, 1);
                                    text-align: center;
                                    vertical-align: top;

                                }
                            }
                        }
                    }
                }

                .diffFlex {
                    flex-direction: row;

                    .text {
                        width: 50%;
                    }

                    .itemImg {
                        width: 50%;
                    }
                }
            }
        }
    }
}

.intro {
    text-align: center;
    display: flex;
    flex-direction: column;

    .introDes {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 43.44px;
        color: #CCCCCC;
        text-align: center;
        vertical-align: top;
    }

    .introText {
        font-size: 42px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 57.92px;
        color: #fff;
        text-align: center;
        vertical-align: top;
    }
}

.uniqueBg {
    .totalType {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 29px));
        grid-template-rows: repeat(2, 1fr);
        gap: 38px;
        padding-bottom: 50px;

        .uniqueItem {
            padding: 31px;
            height: 314px;
            background-blend-mode: overlay;

            .uniqueItemTitle {
                margin-bottom: 10px;
                font-size: 26px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 37.65px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
                vertical-align: top;
            }

            .uniqueItemDes {
                width: 70%;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 32px;
                color: rgba(255, 255, 255, 1);
                text-align: justify;
                vertical-align: top;
            }
        }
    }
}

.mianCenter {
    width: 1200px;
    margin: 0 auto;
}
</style>